package com.fireavert.units.presentation

import com.fireavert.units.logic.UnitsNavigator
import com.fireavert.units.logic.UnitsNavigator.Destination.AddNewUnit
import com.fireavert.units.logic.UnitsNavigator.Destination.AddNewUnitCSV
import com.fireavert.units.logic.UnitsNavigator.Destination.PropertyDetails
import com.fireavert.units.logic.UnitsNavigator.Destination.UnitDetails

class RoutedUnitsNavigator(private val unitsRouter: UnitsRouter) : UnitsNavigator {
    override fun navigate(destination: UnitsNavigator.Destination) {
        when (destination) {
            is AddNewUnit ->
                unitsRouter.routeToAddNewUnitDialog(destination.propertyId)
            is PropertyDetails ->
                unitsRouter.routeToProperty(destination.propertyId)
            is UnitDetails ->
                unitsRouter.routeToUnit(destination.propertyId, destination.unitId)
            is AddNewUnitCSV ->
                unitsRouter.routeToAddNewUnitCSVDialog(destination.propertyId)

            is UnitsNavigator.Destination.ConvertToCommV2 ->
                unitsRouter.routeToConvertToCommV2Dialog(destination.propertyId)
        }
    }
}