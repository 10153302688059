package com.fireavert.devices.interface_adapters

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.devices.logic.models.Device
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.PowerStatus
import com.fireavert.devices.logic.requests.DeviceCreationRequest
import com.fireavert.devices.logic.requests.DevicePoweredOnRequest
import com.fireavert.devices.logic.requests.ModifyDeviceRequest
import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.info.logic.DeviceInfo
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.reboot.logic.RebootDeviceRequest
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ApiDeviceRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val dataSource: DeviceDataSource,
    private val preferences: Preferences,
    private val logger: Logger
) : ApiRepository(ioContext = ioContext, tokenRefreshService = tokenRefreshService),
    DeviceRepository {
    override suspend fun getDevicesForPropertyId(propertyId: Int): Try<List<Device>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getDevicesForPropertyId(
                    preferences.token,
                    propertyId
                )
            }.map { it.toDeviceList() }
        }

    override suspend fun getDevicesForUnitId(unitId: Int): Try<List<Device>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getDevicesForUnitId(
                    preferences.token,
                    unitId
                )
            }.map { it.toDeviceList() }
        }

    override suspend fun getDevicesPowerStatusForUnitId(request: DevicePoweredOnRequest): Try<Map<Int, PowerStatus>> {
        return withContext(ioContext) {
            callWithRefresh {
                dataSource.getDevicesPowerStatusForUnitId(
                    preferences.token,
                    request
                )
            }.map { it.devicesToPower }
        }
    }

    override suspend fun createDevice(
        request: DeviceCreationRequest
    ): Try<Int> = withContext(ioContext) {
        callWithRefresh { dataSource.createDevice(preferences.token, request) }.map { it.id ?: 0 }
    }

    override suspend fun getDevice(deviceId: Int): Try<Device> = withContext(ioContext) {
        callWithRefresh { dataSource.getDevice(preferences.token, deviceId) }.map { it.toDevice() }
    }

    override suspend fun getDeviceEvents(deviceId: Int): Try<List<DeviceEvent>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getDeviceEvents(
                    preferences.token,
                    deviceId
                )
            }.map { it.toListOfDeviceEvents() }
        }

    override suspend fun getActiveDeviceEvents(deviceId: Int): Try<List<DeviceEvent>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getActiveDeviceEvents(
                    preferences.token,
                    deviceId
                )
            }.map { it.toListOfDeviceEvents() }
        }

    override suspend fun modifyDevice(
        id: Int,
        deviceLocator: String,
        location: String,
        unitId: Int,
        type: DeviceType,
        commVersion: Int,
        rebootUUID: String,
        infoUUID: String
    ): Try<Boolean> = withContext(ioContext) {

        val modifyDeviceRequest = ModifyDeviceRequest(
            id = id,
            deviceLocator = deviceLocator,
            location = location,
            unitId = unitId,
            type = type,
            commVersion = commVersion,
            rebootUUID = rebootUUID,
            infoUUID = infoUUID
        )
        callWithRefresh {
            dataSource.modifyDevice(
                preferences.token,
                modifyDeviceRequest
            )
        }.map { it.success ?: false }
    }

    override suspend fun deleteDevice(deviceId: Int): Try<Boolean> = withContext(ioContext) {
        callWithRefresh { dataSource.deleteDevice(preferences.token, deviceId) }.map {
            it.success ?: false
        }
    }

    override suspend fun rebootDevice(deviceId: Int): Try<Boolean> = withContext(ioContext) {
        callWithRefresh { dataSource.rebootDevice(preferences.token, deviceId) }.map {
            it.success ?: false
        }
    }

    override suspend fun rebootDevice(deviceLocator: String): Try<Boolean> =
        withContext(ioContext) {
            val request = RebootDeviceRequest(
                deviceLocator = deviceLocator
            )
            dataSource.rebootDevice(request).map {
                it.success ?: false
            }
        }

    override suspend fun getDeviceLocatorForUUID(uuid: String): Try<String> =
        withContext(ioContext) {
            callWithRefresh { dataSource.getDeviceLocatorForUUID(preferences.token, uuid) }.map {
                it.deviceLocator ?: ""
            }
        }

    override suspend fun getDeviceInfoByUUID(uuid: String): Try<DeviceInfo> =
        withContext(ioContext) {
            callWithRefresh { dataSource.getDeviceInfoForUUID(preferences.token, uuid) }
        }

    override suspend fun getAllDeviceRebootUUIDs(): Try<List<String>> =
        withContext(ioContext) {
            callWithRefresh { dataSource.getAllDeviceRebootIds(preferences.token) }
        }

    override suspend fun getAllDeviceInfoUUIDs(): Try<List<String>> =
        withContext(ioContext) {
            callWithRefresh { dataSource.getAllDeviceInfoIds(preferences.token) }
        }

    override suspend fun isDeviceActive(deviceLocator: String): Try<Boolean> =
        withContext(ioContext) {
            callWithRefresh { dataSource.isDeviceActive(deviceLocator) }
        }
}

