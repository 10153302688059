package com.fireavert.components.icons

import react.FC
import react.dom.html.ReactHTML.img

val AddIcon = FC {
    img {
        src = "/static/add_icon.svg"
        width = 16.0
        height = 16.0
        alt = "+"
    }
}