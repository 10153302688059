package com.fireavert.components.dialogs

import com.fireavert.styles.DialogStyles
import emotion.react.css
import mui.material.Dialog
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import web.cssom.*

val UserMobileDialog = FC {
    Dialog {
        open = true
        onBackdropClick = {}
        onClose = { _, _ ->
        }
        maxWidth = Breakpoint.lg
        fullWidth = true
        sx {
            "& .MuiDialog-paper" {
                height = 65.pct
                maxHeight = 65.pct
                margin = Auto.auto
                display = Display.flex
                flexDirection = FlexDirection.column
                padding = 0.px
                borderRadius = 10.px
            }
        }
        DialogTitle {
            sx {padding = 0.px}
        }
        DialogContent {
            sx {
                flex = number(1.0)
                display = Display.flex
                flexDirection = FlexDirection.column
                overflow = Overflow.scroll
                padding = 0.px
            }
            ReactHTML.div {
                className = DialogStyles.mobileDialogActionTitle
                ReactHTML.div {
                    ReactHTML.div {
                        css { fontSize = 16.px }
                        +""
                    }
                }
            }
        }
    }
}