package com.fireavert.info.logic

import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.logging.Logger

class InfoPageImpl(
    private val deviceRepository: DeviceRepository,
    private val screen: InfoPageScreen,
    private val logger: Logger
) : InfoPage {
    override suspend fun processUUID(uuid: String) {
        val deviceInfo = when (val maybe = deviceRepository.getDeviceInfoByUUID(uuid = uuid)) {
            is Error -> {
                logger.e("Failed to get deviceInfo for uuid $uuid.  Exception: ${maybe.exception}")
                screen.error = true
                return
            }
            is Success -> maybe.value
        }

        screen.error = false
        screen.deviceType = deviceInfo.deviceType
        screen.unitName = deviceInfo.unit
        screen.propertyName = deviceInfo.propertyName
    }
}