package com.fireavert.components.dialogs

import com.fireavert.common.TableColumn
import com.fireavert.components.events.EventTypeIcon
import com.fireavert.components.help.HelpDialog
import com.fireavert.components.help.HelpDialog.TOOL_TIP_NEVER_INSTALLED
import com.fireavert.components.icons.DeleteIconButton
import com.fireavert.components.pages.UnitDetailsDialogProps
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.devices.logic.models.*
import com.fireavert.events.logic.models.EventType
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.styles.*
import com.fireavert.units.presentation.UnitDetailsController
import com.fireavert.units.presentation.UnitDetailsViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import mui.system.useMediaQuery
import react.FC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.router.dom.Link
import react.router.useParams
import react.useEffectOnce
import react.useState
import web.cssom.*

val UnitDetailsDialog = FC<UnitDetailsDialogProps> { props ->
    val controller = getKoinInstance<UnitDetailsController>()
    val viewModel = getKoinInstance<UnitDetailsViewModel>()
    val logger = getKoinInstance<Logger>()
    val preferences = getKoinInstance<Preferences>()
    val isMobileView = useMediaQuery("(max-width: 768px)")

    /**
     * Params
     */
    val params = useParams()
    val propertyId = params["propertyId"]?.toInt() ?: 0
    val unitId = params["unitId"]?.toInt() ?: 0

    /**
     * State
     */
    var isLoading: Boolean by useState(false)
    var unitNumber: String by useState("")
    var propertyName: String by useState("")
    var address: String by useState("")
    var city: String by useState("")
    var state: String by useState("")
    var zip: String by useState("")
    var tenantName: String by useState("")
    var tenantEmail: String by useState("")
    var tenantPhone: String by useState("")
    var totalAlarmEvents: Int by useState(0)
    var totalShutoffEvents: Int by useState(0)
    var totalLeakEvents: Int by useState(0)
    var totalTamperEvents: Int by useState(0)
    var totalFlowEvents: Int by useState(0)
    var devices: List<UnitDetailsDeviceDataState> by useState(emptyList())
    var eventHistory: List<UnitDetailsEventHistoryDataState> by useState(emptyList())
    var isPowerOn: Boolean by useState(false)
    var devicesColumns: List<TableColumn> by useState(
        listOf(
            TableColumn(
                text = "SENSOR TYPE", sortOrder = TableColumn.SortOrder.HighToLow, alignment = TableColumn.Alignment.Left
            ), TableColumn(
                text = "LOCATION", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ), TableColumn(
                text = "SENSOR ID", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ), TableColumn(
                text = "CONNECTION STATUS", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ), TableColumn(
                text = "SENSOR STATUS", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ), TableColumn(
                text = "",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left,
                enableSort = false,
            )
        )
    )
    var eventHistoryColumns: List<TableColumn> by useState(
        listOf(
            TableColumn(
                text = "DATE / TIME",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "SENSOR TYPE", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "LOCATION", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "EVENT", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left,
                enableSort = false,
            ),
            TableColumn(
                text = "ACTION",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center,
                enableSort = false,
            ),
        )
    )

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            logger.e("ViewState Changed")
            isLoading = viewState.isLoading
            propertyName = viewState.propertyName
            unitNumber = viewState.unitNumber
            address = viewState.address
            city = viewState.city
            state = viewState.state
            zip = viewState.zip
            tenantName = viewState.tenantName
            tenantEmail = viewState.tenantEmail
            tenantPhone = viewState.tenantPhone
            totalAlarmEvents = viewState.totalAlarmEvents
            totalShutoffEvents = viewState.totalShutoffEvents
            totalLeakEvents = viewState.totalLeakEvents
            totalFlowEvents = viewState.totalFlowEvents
            totalTamperEvents = viewState.totalTamperEvents
            isPowerOn = viewState.isPowerOn
            if(viewState.devices.isEmpty()) {
                devices = emptyList()
            } else {
                devices = viewState.devices.map {
                    UnitDetailsDeviceDataState(
                        id = it.id,
                        type = it.type,
                        location = it.location,
                        deviceId = it.deviceId,
                        connectionStatus = it.connectionStatus,
                        connectionStatusLevel = it.connectionStatusLevel,
                        deviceStatus = it.deviceStatus,
                        deviceStatusLevel = it.deviceStatusLevel,
                        deviceIsTriggered = it.deviceIsTriggered,
                        isPoweredOn  = it.powerOn,
                        installed = it.installed,
                    )
                }
            }
            if (viewState.eventHistory.isEmpty()) {
                eventHistory = emptyList()
            } else {
                eventHistory = viewState.eventHistory.map {
                    UnitDetailsEventHistoryDataState(
                        eventId = it.eventId,
                        dateTime = it.dateTime,
                        timestamp = it.timestamp,
                        deviceType = it.deviceType,
                        location = it.location,
                        eventType = it.eventType,
                        alarmActive = it.alarmActive,
                        deviceTriggered = it.deviceTriggered,
                        isTest = it.isTest,
                        isFalseAlert = it.isFalseAlert
                    )
                }
            }
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyId, unitId, preferences.timeZone, preferences.isAdmin)
        }

        cleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = {}
        onClose = { _, _ ->
            controller.close(propertyId)
        }
        maxWidth = Breakpoint.lg
        DialogTitle {
            sx {
                backgroundImage = linearGradient(180.deg, Color("#2A3042"), Color("#4C5566"))
            }
            div {
                className = DialogStyles.dialogTitleWithButton
                div {
                    +"Unit $unitNumber"
                }
                div {
                    if (preferences.isAdmin and !isMobileView && !isLoading) {
                        Link {
                            to = "${RoutePaths.properties}/${propertyId}/units/${unitId}/edit"
                            Button {
                                className = PageStylesV2.genericButton
                                color = ButtonColor.primary
                                variant = ButtonVariant.contained
                                +"Edit Unit"
                            }
                        }
                    }
                }
            }
        }
        DialogContent {

            if (isLoading) {
                css {
                    margin = important(0.px)
                    padding = important(10.px)
                    textAlign = TextAlign.center
                    backgroundImage = linearGradient(180.deg, Color("#FA683F"), Color("#FBAE41"))
                }
                CircularProgress {
                    color = CircularProgressColor.primary
                }
            } else {
                css {
                    margin = important(0.px)
                    padding = important(0.px)
                }
                table {
                    cellPadding = "0"
                    cellSpacing = "0"
                    className = UnitStyles.unitDetailsInfoTable
                    tr {
                        className = UnitStyles.unitInfoRow
                        td {
                            className = UnitStyles.unitInfoTitleCell
                            div {
                                +"Unit Address"
                                className = UnitStyles.unitInfoTitle
                            }
                            div {
                                +propertyName
                            }
                            div {
                                +address
                            }
                            div {
                                +"$city, $state, $zip"
                            }
                        }
                        td {
                            className = UnitStyles.unitInfoTitleCell
                            div {
                                className = UnitStyles.unitInfoTitle
                                +"Tenant Info"
                            }
                            div {
                                +tenantName
                            }
                            div {
                                +tenantEmail
                            }
                            div {
                                +tenantPhone
                            }
                        }
                    }
                }
                div {
                    className = cx(PageStyles.sectionTitle, ClassName {
                        padding = Padding(28.px, 28.px, 0.px, 28.px)
                    })
                    +"Sensors"
                }
                div {
                    className = cx(TableStyles.tableContainer, ClassName {
                        padding = Padding(0.px, 28.px)
                        marginTop = 15.px
                    })
                    div {
                        className = TableStyles.table
                        SortBar {
                            columns = mapToControlColumns(devicesColumns.toTypedArray())
                            includeLoading = false
                            this.isLoading = false
                            columnsChanged = {
                                devicesColumns = mapToStateColumns(it).toList()
                            }
                        }
                        val sortedDevices = controller.sortDevicesWithColumns(devicesColumns, devices)
                        sortedDevices.forEach { device ->
                            div {
                                className = TableStyles.tableRow
                                div {
                                    className = TableStyles.tableCell
                                    if ((device.type == DeviceType.FireAvert || device.type == DeviceType.FireAvertAppliance) && preferences.isAdmin) {
                                        div {
                                            css {
                                                display = Display.flex
                                                alignItems = AlignItems.center
                                                gap = 10.px
                                            }
                                            div {
                                                +controller.deviceTypeString(device.type)
                                            }
                                            div {
                                                css {
                                                    width = 8.px
                                                    height = 8.px
                                                    borderRadius = 50.pct
                                                    marginRight = 4.px
                                                    backgroundColor = when (device.isPoweredOn) {
                                                        PowerStatus.UNKNOWN -> Color("#808080") // Gray
                                                        PowerStatus.ON -> Color("#FF0000") // Red
                                                        PowerStatus.OFF -> Color("#00FF00") // Green
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        +controller.deviceTypeString(device.type)
                                    }
                                }
                                div {
                                    className = TableStyles.tableCell
                                    +device.location
                                }
                                div {
                                    className = TableStyles.tableCell
                                    +device.deviceId
                                }
                                div {
                                    className = when (device.connectionStatusLevel) {
                                        StatusLevel.Green -> cx(TableStyles.tableCell, TableStyles.greenBold)
                                        StatusLevel.Yellow -> cx(TableStyles.tableCell, TableStyles.yellowBold)
                                        StatusLevel.Red -> cx(TableStyles.tableCell, TableStyles.redBold)
                                        StatusLevel.Grey -> cx(TableStyles.tableCell, TableStyles.greyBold)
                                        StatusLevel.None -> TableStyles.tableCell
                                    }
                                    if (!device.installed) {
                                        Tooltip {
                                            title = HelpDialog.toolTipText(TOOL_TIP_NEVER_INSTALLED)
                                            +"Never Installed"
                                        }
                                    } else {
                                        +device.connectionStatus
                                    }
                                }
                                div {
                                    className = when (device.deviceStatusLevel) {
                                        StatusLevel.Green -> cx(TableStyles.tableCell, TableStyles.greenBold)
                                        StatusLevel.Yellow -> cx(TableStyles.tableCell, TableStyles.yellowBold)
                                        StatusLevel.Red -> cx(TableStyles.tableCell, TableStyles.redBold)
                                        StatusLevel.Grey -> cx(TableStyles.tableCell, TableStyles.greyBold)
                                        StatusLevel.None -> TableStyles.tableCell
                                    }
                                    if (!device.installed) {
                                        Tooltip {
                                            title = HelpDialog.toolTipText(TOOL_TIP_NEVER_INSTALLED)
                                            +"Never Installed"
                                        }
                                    } else {
                                        +device.deviceStatus
                                    }
                                }
                                div {
                                    className = TableStyles.tableCellCentered
                                    if (device.deviceIsTriggered && (device.type == DeviceType.FireAvert || device.type == DeviceType.FireAvertAppliance) && device.connectionStatus != "Offline") {
                                        Link {
                                            to = "${RoutePaths.properties}/$propertyId/reboot_device/${device.id}"
                                            +"Reboot"
                                        }
                                    } else if (device.deviceIsTriggered && device.type == DeviceType.FireAvertGas) {
                                        Link {
                                            to = "${RoutePaths.properties}/$propertyId/reboot_device/${device.id}/gas"
                                            +"Reboot"
                                        }
                                    } else if (device.type.isFireAvert) {
                                        span {
                                            className = TableStyles.disabledReboot
                                            +"Reboot"
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
                div {
                    className = cx(TableStyles.tableContainer, ClassName {
                        padding = Padding(0.px, 28.px)
                        marginTop = 15.px
                    })
                    div {
                        className = TableStyles.tableRow
                        div {
                            className = cx(TableStyles.tableCell, ClassName {
                                width = 18.pct
                                paddingLeft = 0.px
                                fontSize = 22.px
                                fontWeight = integer(700)
                            })
                            +"Event History"
                        }
                        div {
                            className = cx(TableStyles.tableCell, ClassName {
                                fontWeight = FontWeight.bold
                            })
                            span {
                                +"Smoke Events "
                            }
                            span {
                                className = EventsStyles.smokeText
                                +totalAlarmEvents.toString()
                            }
                            span {
                                className = UnitStyles.totalSlash
                                +" / "
                            }
                            span {
                                +"Stove Shut Offs "
                            }
                            span {
                                className = EventsStyles.shutoffText
                                +totalShutoffEvents.toString()
                            }
                            span {
                                className = UnitStyles.totalSlash
                                +" / "
                            }
                            span {
                                +"Leak Events "
                            }
                            span {
                                className = EventsStyles.waterText
                                +totalLeakEvents.toString()
                            }
                            span {
                                className = UnitStyles.totalSlash
                                +" / "
                            }
                            span {
                                +"Tamper Events "
                            }
                            span {
                                className = EventsStyles.tamperText
                                +totalTamperEvents.toString()
                            }

                        }
                        div {
                            className = cx(TableStyles.tableCellCentered, ClassName {
                                fontWeight = FontWeight.bold
                                width = 18.pct
                            })
                            if (props.isUserAdmin) {
                                a {
                                    href = "#"
                                    onClick = {
                                        viewModel.scope.launch {
                                            controller.clickedClearEventHistory(unitId)
                                        }
                                    }
                                    +"Clear All History"
                                }
                            }
                        }
                    }
                    div {
                        className = TableStyles.table
                        SortBar {
                            columns = mapToControlColumns(eventHistoryColumns.toTypedArray())
                            includeLoading = false
                            this.isLoading = false
                            columnsChanged = {
                                eventHistoryColumns = mapToStateColumns(it).toList()
                            }
                        }
                        val sortedEvents = controller.sortEventsByColumns(
                            eventHistoryColumns, eventHistory
                        )
                        sortedEvents.forEachIndexed { _, event ->
                            div {
                                className = TableStyles.tableRow
                                div {
                                    className = TableStyles.tableCell
                                    +event.dateTime
                                }
                                div {
                                    className = TableStyles.tableCell
                                    +controller.deviceTypeString(event.deviceType)
                                }
                                div {
                                    className = TableStyles.tableCell
                                    +event.location
                                }
                                div {
                                    className = TableStyles.tableCell
                                    EventTypeIcon {
                                        eventType = event.eventType
                                        deviceType = event.deviceType
                                        alarmActive = event.alarmActive
                                        deviceTriggered = event.deviceTriggered
                                        isMobile = false
                                    }
                                }
                                div {
                                    className = TableStyles.tableCell
                                    if (event.isTest || event.isFalseAlert) {
                                        css {
                                            color = colorForEvent(event)
                                        }
                                        +if (event.isTest) "TESTED" else "FALSE ALERT"
                                    } else {
                                        +""
                                    }
                                }
                                if ((event.eventType == EventType.SmokeAlarmActive && event.alarmActive) || (event.eventType == EventType.DeviceTriggered && event.deviceTriggered)) {
                                    //Show nothing
                                    div {
                                        className = TableStyles.tableCellCentered
                                    }
                                } else {
                                    div {
                                        className = cx(TableStyles.tableCellCentered, ClassName {
                                            width = 26.px
                                        })
                                        div {
                                            if (preferences.isAdmin) {
                                                DeleteIconButton {
                                                    onClicked = {
                                                        viewModel.scope.launch {
                                                            controller.deleteEventHistoryItem(event)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

private fun colorForEvent(event: UnitDetailsEventHistoryDataState) = when (event.deviceType) {
    DeviceType.FireAvert, DeviceType.FireAvertGas, DeviceType.FireAvertAppliance -> when (event.eventType) {
        EventType.SmokeAlarmActive -> EventsStyles.smokeBackgroundColor
        EventType.DeviceTriggered -> EventsStyles.shutoffBackgroundColor
        EventType.DeviceReset -> EventsStyles.resetBackgroundColor
        EventType.DeviceOffline -> EventsStyles.offlineBackgroundColor
        EventType.Unknown -> EventsStyles.unknownBackgroundColor
    }

    DeviceType.WaterSensor -> when (event.eventType) {
        EventType.DeviceOffline -> EventsStyles.offlineBackgroundColor
        else -> EventsStyles.waterBackgroundColor
    }

    DeviceType.FlowSensor -> when (event.eventType) {
        EventType.DeviceOffline -> EventsStyles.offlineBackgroundColor
        else -> EventsStyles.waterBackgroundColor
    }

    DeviceType.TamperSensor -> when (event.eventType) {
        EventType.DeviceOffline -> EventsStyles.offlineBackgroundColor
        else -> EventsStyles.tamperBackgroundColor
    }
}
