package com.fireavert.components.properties

import com.fireavert.logging.Logger
import com.fireavert.styles.PropertyStyles.propertyNotificationHeaders
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import web.cssom.pct
import web.cssom.rem

external interface NotificationsCheckboxTableProps: Props {
    var id: String
    var stoveShutoffTextNotifications: Boolean
    var stoveShutoffTextNotificationsChanged: (String, Boolean) -> Unit
    var stoveShutoffEmailNotifications: Boolean
    var stoveShutoffEmailNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmTextNotifications: Boolean
    var smokeAlarmTextNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmEmailNotifications: Boolean
    var smokeAlarmEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakTextNotifications: Boolean
    var leakTextNotificationsChanged: (String, Boolean) -> Unit
    var leakEmailNotifications: Boolean
    var leakEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperTextNotifications: Boolean
    var tamperTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperEmailNotifications: Boolean
    var tamperEmailNotificationsChanged: (String, Boolean) -> Unit
    var stoveOfflineEmailNotifications: Boolean
    var stoveOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var stoveOfflineTextNotifications: Boolean
    var stoveOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var leakOfflineEmailNotifications: Boolean
    var leakOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakOfflineTextNotifications: Boolean
    var leakOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperOfflineEmailNotifications: Boolean
    var tamperOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperOfflineTextNotifications: Boolean
    var tamperOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var dailyReports: Boolean
    var dailyReportsChanged: (String, Boolean) -> Unit
    var weeklyReports: Boolean
    var weeklyReportsChanged: (String, Boolean) -> Unit
    var monthlyReports: Boolean
    var monthlyReportsChanged: (String, Boolean) -> Unit
    var quarterlyReports: Boolean
    var quarterlyReportsChanged: (String, Boolean) -> Unit
    var yearlyReports: Boolean
    var yearlyReportsChanged: (String, Boolean) -> Unit
}

val NotificationsCheckboxTable = FC<NotificationsCheckboxTableProps> { props ->
    val logger = getKoinInstance<Logger>()
    table {
        css {
            width = 100.pct
            fontSize = 0.7.rem
        }
        tr {
            td {
                colSpan = 5
                span {
                    className = propertyNotificationHeaders
                    +"Text Notifications"
                }
            }
        }
        tr {
            CheckBoxCell {
                checked = props.stoveShutoffTextNotifications
                text = "Stove Shutoff"
                onChange = {
                    props.stoveShutoffTextNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.smokeAlarmTextNotifications
                text = "Smoke Alarms"
                onChange = {
                    props.smokeAlarmTextNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.leakTextNotifications
                text = "Leak"
                onChange = {
                    props.leakTextNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.tamperTextNotifications
                text = "Tamper"
                onChange = {
                    props.tamperTextNotificationsChanged(props.id, it)
                }
            }
            td {
                Typography.nbsp
            }
        }
        tr {
            td {
                colSpan = 5
                span {
                    className = propertyNotificationHeaders
                    +"Email Notifications"
                }
            }
        }
        tr {
            CheckBoxCell {
                checked = props.stoveShutoffEmailNotifications
                text = "Stove Shutoff"
                onChange = {
                    props.stoveShutoffEmailNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.smokeAlarmEmailNotifications
                text = "Smoke Alarms"
                onChange = {
                    props.smokeAlarmEmailNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.leakEmailNotifications
                text = "Leak"
                onChange = {
                    props.leakEmailNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.tamperEmailNotifications
                text = "Tamper"
                onChange = {
                    props.tamperEmailNotificationsChanged(props.id, it)
                }
            }
            td {
                Typography.nbsp
            }
        }
        tr {
            td {
                colSpan = 5
                span {
                    className = propertyNotificationHeaders
                    +"Offline Email Notifications"
                }
            }
        }
        tr {
            CheckBoxCell {
                checked = props.stoveOfflineEmailNotifications
                text = "Stove"
                onChange = {
                    props.stoveOfflineEmailNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.leakOfflineEmailNotifications
                text = "Leak"
                onChange = {
                    props.leakOfflineEmailNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.tamperOfflineEmailNotifications
                text = "Tamper"
                onChange = {
                    props.tamperOfflineEmailNotificationsChanged(props.id, it)
                }
            }
            td {
                Typography.nbsp
            }
            td {
                Typography.nbsp
            }
        }
        tr {
            td {
                colSpan = 5
                span {
                    className = propertyNotificationHeaders
                    +"Offline Text Notifications"
                }
            }
        }
        tr {
            CheckBoxCell {
                checked = props.stoveOfflineTextNotifications
                text = "Stove"
                onChange = {
                    props.stoveOfflineTextNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.leakOfflineTextNotifications
                text = "Leak"
                onChange = {
                    props.leakOfflineTextNotificationsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.tamperOfflineTextNotifications
                text = "Tamper"
                onChange = {
                    props.tamperOfflineTextNotificationsChanged(props.id, it)
                }
            }
            td {
                Typography.nbsp
            }
            td {
                Typography.nbsp
            }
        }
        tr {
            td {
                colSpan = 5
                span {
                    className = propertyNotificationHeaders
                    +"Report Subscriptions"
                }
            }
        }
        tr {
            CheckBoxCell {
                checked = props.dailyReports
                text = "Daily"
                onChange = {
                    props.dailyReportsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.weeklyReports
                text = "Weekly"
                onChange = {
                    props.weeklyReportsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.monthlyReports
                text = "Monthly"
                onChange = {
                    props.monthlyReportsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.quarterlyReports
                text = "Quarterly"
                onChange = {
                    props.quarterlyReportsChanged(props.id, it)
                }
            }
            CheckBoxCell {
                checked = props.yearlyReports
                text = "Yearly"
                onChange = {
                    props.yearlyReportsChanged(props.id, it)
                }
            }
            td {
                Typography.nbsp
            }
            td {
                Typography.nbsp
            }
        }
    }
}