package com.fireavert.components.properties

import com.fireavert.components.icons.AddIcon
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.UnitStyles
import com.fireavert.units.presentation.AddRemoveDevicesContainerController
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.div
import web.cssom.*

external interface AddRemoveDevicesContainerProps: Props {
    var deviceCreationData: Array<DeviceData>
    var clickedAddNewDevice: () -> Unit
    var deleteDeviceAtIndex: (index: Int) -> Unit
    var deviceAtIndexChanged: (index: Int, deviceCreationData: DeviceData) -> Unit
    var saveError: String?
}

val AddRemoveDevicesContainer = FC<AddRemoveDevicesContainerProps> { props ->
    val controller = getKoinInstance<AddRemoveDevicesContainerController>()

    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 990.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        // Devices Title
        div {
            className = cx(UnitStyles.devicesTitle, ClassName {
                marginBottom = 15.px
            })
            +"Devices"
        }

        // Device Rows
        props.deviceCreationData.forEachIndexed { index, deviceCreationData ->
            div {
                className = ClassName { marginBottom = 20.px }
                AddRemoveDeviceRow {
                    type = deviceCreationData.type
                    typeError = deviceCreationData.typeError
                    typeDisabled = deviceCreationData.deviceId != null
                    typeChanged = { newType ->
                        props.deviceAtIndexChanged(index, deviceCreationData.copy(type = newType))
                    }
                    commVersion = deviceCreationData.commVersion
                    commVersionError = deviceCreationData.commVersionError
                    commVersionChanged = { newCommVersion ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(commVersion = newCommVersion)
                        )
                    }
                    location = deviceCreationData.location
                    locationError = deviceCreationData.locationError
                    locationChanged = { newLocation ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(location = newLocation)
                        )
                    }
                    locator = deviceCreationData.locator
                    locatorError = deviceCreationData.locatorError
                    locatorChanged = { newLocator ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(locator = newLocator)
                        )
                    }
                    qrClicked = {}
                    deleteDeviceClicked = {
                        props.deleteDeviceAtIndex(index)
                    }
                    isAdmin = controller.isAdmin
                    rebootUUID = deviceCreationData.rebootUUID
                    rebootUUIDError = deviceCreationData.rebootUUIDError
                    rebootUUIDChanged = { newRebootUUID ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(rebootUUID = newRebootUUID)
                        )
                    }
                    infoUUID = deviceCreationData.infoUUID
                    infoUUIDError = deviceCreationData.infoUUIDError
                    infoUUIDChanged = { newInfoUUID ->
                        props.deviceAtIndexChanged(
                            index,
                            deviceCreationData.copy(infoUUID = newInfoUUID)
                        )
                    }
                }
            }
        }

        div {
            className = ClassName {
                textAlign = TextAlign.center
                marginTop = 15.px
                marginBottom = 15.px
            }
            Button {
                +"Add New Device"
                color = ButtonColor.primary
                variant = ButtonVariant.contained
                onClick = { props.clickedAddNewDevice() }
                startIcon = AddIcon.create()
            }
        }

        props.saveError?.let { saveError ->
            div {
                className = ClassName {
                    color = NamedColor.red
                    textAlign = TextAlign.center
                    marginTop = 15.px
                }
                +saveError
            }
        }
    }
}