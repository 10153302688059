package com.fireavert.devices.logic.models

data class DeviceData(
    val type: String,
    val typeError: Boolean,
    val location: String,
    val locationError: Boolean,
    val locator: String,
    val locatorError: Boolean,
    val commVersion: Int,
    val commVersionError: Boolean,
    val deviceId: Int? = null,
    val infoUUID: String = "",
    val infoUUIDError: Boolean = false,
    val rebootUUID: String = "",
    val rebootUUIDError: Boolean = false,
)
