package com.fireavert.components.dialogs

import com.fireavert.events.markasfalsealarm.presentation.MarkAsFalseAlarmController
import com.fireavert.events.markasfalsealarm.presentation.MarkAsFalseAlarmViewModel
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import react.FC
import react.router.useParams
import react.useEffectOnce
import react.useState

val MarkAsFalseAlarmDialog = FC {
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val eventId = useParams()["eventId"]?.toLong() ?: 0

    val controller = getKoinInstance<MarkAsFalseAlarmController>()
    val viewModel = getKoinInstance<MarkAsFalseAlarmViewModel>()

    var loading: Boolean by useState(false)

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
        }.launchIn(viewModel.scope)
        controller.onLoad(eventId)
        cleanup {
            viewStateJob.cancel()
        }
    }

    EventActionDialog {
        close = { controller.close(propertyId) }
        title = "Mark As False Alert"
        successMessage = "Event was marked as a false alert."
        this.loading = loading
    }
}