package com.fireavert.devices.logic.response

import com.fireavert.devices.logic.models.DeviceType
import kotlinx.serialization.Serializable

@Serializable
data class DeviceResponse(
    val id: Int? = null,
    val deviceLocator: String? = null,
    val location: String? = null,
    val unit: String? = null,
    val unitId: Int? = null,
    val propertyId: Int? = null,
    val tenantName: String? = null,
    val phone: String? = null,
    val email: String? = null,
    val alarmEvents: Int? = null,
    val shutoffEvents: Int? = null,
    val status: FireAvertStatusResponse? = null,
    val alarmIsOn: Boolean? = null,
    val deviceIsTriggered: Boolean? = null,
    val deviceOffline: Boolean? = null,
    val monitoring: Boolean? = null,
    val monitoringAccountId: Int? = null,
    val commVersion: Int? = null,
    val type: DeviceType? = null,
    val ignoreBefore: Long? = null,
    val watchdogCount: Long? = null,
    val lastWatchdogSend: Long? = null,
    val rebootUUID: String? = null,
    val infoUUID: String? = null,
)

@Serializable
enum class FireAvertStatusResponse {
    Active,
    Listening,
    Offline,
    NotFound,
    Rebooting,
    Error
}

