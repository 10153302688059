package com.fireavert.events.common.data

import com.fireavert.common.Try
import com.fireavert.devices.logic.response.DismissAllEventsForUnitReponse
import com.fireavert.devices.logic.response.GetDeviceEventsResponse
import com.fireavert.events.dismissevent.api.DismissEventResponse
import com.fireavert.events.common.api.EventsDataSource
import com.fireavert.events.markastest.api.MarkAsTestResponse
import com.fireavert.logging.Logger
import io.ktor.client.HttpClient
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.url
import io.ktor.http.*

class ClientEventsDataSource(
    private val httpClient: HttpClient,
    private val baseUrl: String,
    private val logger: Logger
) : EventsDataSource {
    override suspend fun getEventHistoryByUnit(
        token: String,
        unitId: Int
    ): Try<GetDeviceEventsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/$unitId/event_history")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun dismissEvent(token: String, eventId: Long): Try<DismissEventResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/events/$eventId/dismiss")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun markAsTest(token: String, eventId: Long): Try<MarkAsTestResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/events/$eventId/markAsTest")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun markAsFalseAlarm(
        token: String,
        eventId: Long
    ): Try<com.fireavert.events.markasfalsealarm.api.Response> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/events/$eventId/markAsFalseAlarm")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun deleteFromHistory(
        token: String,
        eventId: Long
    ): Try<com.fireavert.events.deleteeventfromhistory.Response> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/events/$eventId/deleteFromHistory")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun deleteAllEventsFromHistoryForUnit(token: String, unitId: Int): Try<DismissAllEventsForUnitReponse> {
        return Try.fromCoCallable {
            val response = httpClient.delete {
                header("Authorization", "Bearer $token")
                url("$baseUrl/units/$unitId/event_history")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}