package com.fireavert.menu.data

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.menu.api.MenuDataSource
import com.fireavert.menu.api.NotificationResult
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class RemoteMenuDataSource(
    private val httpClient: HttpClient,
    private val baseUrl: String,
    private val logger: Logger
) : MenuDataSource {
    override suspend fun getDashboardNotification(token: String): Try<NotificationResult> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/dashboard_notification")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }


    override suspend fun getPropertiesNotification(token: String): Try<NotificationResult> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties_notification")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}