package com.fireavert.devices.logic.models

import kotlin.js.JsExport

enum class DeviceType {
    FireAvert,
    FireAvertGas,
    FireAvertAppliance,
    WaterSensor,
    FlowSensor,
    TamperSensor;

    val string: String
        get() {
            return when (this) {
                FireAvert -> "FireAvert"
                FireAvertGas -> "FireAvert Gas"
                FireAvertAppliance -> "FireAvert Appliance"
                WaterSensor -> "Leak Sensor"
                FlowSensor -> "Flow Sensor"
                TamperSensor -> "Tamper Sensor"
            }
        }

    val isFireAvert: Boolean
        get() {
            return (this == FireAvert || this == FireAvertGas || this == FireAvertAppliance)
        }

    companion object {
        fun fromString(str: String): DeviceType {
            return when (str) {
                "FireAvert" -> FireAvert
                "FireAvert Gas" -> FireAvertGas
                "FireAvert Appliance" -> FireAvertAppliance
                "Leak Sensor" -> WaterSensor
                "Flow Sensor" -> FlowSensor
                "Tamper Sensor" -> TamperSensor
                else -> throw Exception("Invalid Device Type")
            }
        }
    }
}