package com.fireavert.auth.logic

import com.fireavert.auth.logic.AuthNavigator.Destination.Dashboard
import com.fireavert.auth.logic.AuthNavigator.Destination.Login
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.UserAuth
import com.fireavert.user.logic.UserRepository
import kotlinx.coroutines.launch

class ClientUserAuthenticator(
    private val userRepository: UserRepository,
    private val authScreen: AuthScreen,
    private val authNavigator: AuthNavigator,
    private val preferences: Preferences,
    private val logger: Logger,
) : UserAuthenticator {
    override fun authenticate(authenticateRequest: AuthenticateRequest) {
        authScreen.scope.launch {
            when (val userAuth =
                userRepository.authenticateUser(authenticateRequest.email, authenticateRequest.password)
                    .validate { it.isValid }) {
                is Try.Success -> {
                    preferences.setFromAuth(userAuth.value)
                    authNavigator.navigate(Dashboard)
                }
                is Try.Error -> {
                    logger.e("Authenticate Error: ${userAuth.exception.message}")
                    authScreen.setErrorState(userAuth.exception.message ?: "Unknown error")
                }
            }
        }
    }

    override fun logout() {
        preferences.clearAuth()
        authNavigator.navigate(Login)
    }

    override fun checkAuthStatus() {
        authScreen.scope.launch {
            val userId = preferences.userId
            val token = preferences.token
            val refresh = preferences.refresh

            val authorized = when (userRepository.getUserByToken(token = token)) {
                is Try.Success -> {
                    true
                }
                is Try.Error -> {
                    when (val maybeUserAuth = userRepository.authenticateByRefresh(id = userId, refresh = refresh)) {
                        is Try.Success -> {
                            preferences.setFromAuth(maybeUserAuth.value)
                            true
                        }
                        is Try.Error -> {
                            false
                        }
                    }
                }
            }

            if (authorized) {
                authNavigator.navigate(Dashboard)
            }
        }
    }

    override fun emailChanged(email: String) {
        authScreen.setEmail(email)
    }

    override fun passwordChanged(password: String) {
        authScreen.setPassword(password)
    }
}