package com.fireavert.events.activedeviceevents.logic

import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.HighToLow
import com.fireavert.common.TableColumn.SortOrder.LowToHigh
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.FireAvertStatus
import com.fireavert.events.logic.models.EventType
import kotlinx.datetime.Instant

data class ActiveDeviceEvent(
    val id: Long,
    val deviceId: Int,
    val timestamp: Instant,
    val unit: String,
    val unitId: Int,
    val eventType: EventType,
    val alarmActive: Boolean,
    val deviceTriggered: Boolean,
    val deviceOffline: Boolean,
    val fireAvertStatus: FireAvertStatus,
    val alarmEvents: Int,
    val shutoffEvents: Int,
    val deviceType: DeviceType,
    val timezoneId: String,
)

