package com.fireavert.common

import kotlinx.datetime.Clock

class InMemoryCache {
    private val data: MutableMap<String, Pair<Long, Any>> = mutableMapOf()

    fun get(key: String): Any? {
        val now = Clock.System.now().epochSeconds
        val pair = data[key] ?: return null
        return if (pair.first > now) pair.second else {
            data.remove(key)
            null
        }
    }

    fun set(key: String, value: Any, ttl: Int) {
        val now = Clock.System.now().epochSeconds
        data[key] = (now + ttl) to value
    }

    fun delete(key: String) {
        data.remove(key)
    }
}