package com.fireavert.topnav.presentation

import com.fireavert.common.ViewModel
import com.fireavert.topnav.logic.TopNavScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class TopNavViewModel : ViewModel(), TopNavScreen {
    private val _viewState = MutableStateFlow(TopNavViewState())
    val viewState = _viewState.asStateFlow()
    override val scope: CoroutineScope
        get() = viewModelScope

    override var firstName: String
        get() = viewState.value.firstName
        set(value) {
            _viewState.value = _viewState.value.copy(firstName = value)
        }

    override var lastName: String
        get() = viewState.value.lastName
        set(value) {
            _viewState.value = _viewState.value.copy(lastName = value)
        }

    override var roles: String
        get() = viewState.value.roles
        set(value) {
            _viewState.value = _viewState.value.copy(roles = value)
        }
}