package com.fireavert.devices.logic.response

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceType.FireAvert
import com.fireavert.devices.logic.models.FireAvertStatus
import com.fireavert.devices.logic.models.FireAvertStatus.Error
import com.fireavert.events.logic.models.EventType

@kotlinx.serialization.Serializable
data class ActiveDeviceEventResponse(
    val id: Long? = 0,
    val deviceId: Int? = 0,
    val timestamp: Long? = 0,
    val unit: String? = "",
    val unitId: Int? = 0,
    val alarmActive: Boolean? = false,
    val deviceTriggered: Boolean? = false,
    val deviceOffline: Boolean? = false,
    val eventType: EventType? = EventType.Unknown,
    val fireAvertStatus: FireAvertStatus? = Error,
    val alarmEvents: Int? = 0,
    val shutoffEvents: Int? = 0,
    val deviceType: DeviceType? = FireAvert,
    val timezoneId: String? = "",
)