package com.fireavert.units.logic.models.request

@kotlinx.serialization.Serializable
data class UnitModificationRequest(
    val unitId: Int? = null,
    val number: String? = null,
    val tenantName: String? = null,
    val tenantPhone: String? = null,
    val tenantEmail: String? = null,
    val propertyId: Int? = null,
    val streetAddress: String? = null,
    val city: String? = null,
    val state: String? = null,
    val zip: String? = null,
    val notifyStoveCurrent: Boolean? = null
)