package com.fireavert.dashboard.external

import com.fireavert.common.Try
import com.fireavert.dashboard.adapters.DashboardRepositoryDataSource
import com.fireavert.events.logic.models.responses.EventsAndActionsResponse
import com.fireavert.logging.Logger
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.datetime.LocalDate

class ClientDashboardRepositoryDataSource(
    private val httpClient: HttpClient,
    private val baseUrl: String,
    private val logger: Logger
) : DashboardRepositoryDataSource {
    override suspend fun getEventsAndActions(
        token: String,
        startDate: LocalDate,
        endDate: LocalDate,
        propertyId: Int?
    ): Try<EventsAndActionsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header(HttpHeaders.Authorization, "Bearer $token")
                var urlString = "$baseUrl/eventsAndActions?startDate=$startDate&endDate=$endDate"
                if (propertyId != null) {
                    urlString += "&propertyId=$propertyId"
                }
                url(urlString)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}