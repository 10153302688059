package com.fireavert.events.activeevents.presentation

import com.fireavert.common.TableColumn
import com.fireavert.events.activeevents.domain.ActiveEvent
import com.fireavert.events.activeevents.logic.ActiveEvents

class ActiveEventsController(private val interactor: ActiveEvents) {
    suspend fun onLoad() = interactor.onLoad()
    fun sortEventsByColumn(columns: Array<TableColumn>, events: List<ActiveEvent>): List<ActiveEvent> =
        interactor.sortEventsByColumn(columns, events)
}