package com.fireavert.components.common

import emotion.react.css
import mui.material.CircularProgress
import mui.material.Dialog
import mui.material.DialogContent
import mui.system.Breakpoint
import react.FC
import web.cssom.Color
import web.cssom.deg
import web.cssom.linearGradient
import web.cssom.px

val Loading = FC {
    Dialog {
        open = true
        maxWidth = Breakpoint.md
        DialogContent {
            css {
                backgroundImage = linearGradient(180.deg, Color("#FA683F"), Color("#FBAE41"))
                padding = 24.px
            }
            CircularProgress {}
        }
    }
}