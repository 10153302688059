package com.fireavert.units.logic

import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.devices.logic.requests.DeviceCreationRequest
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.units.logic.UnitsNavigator.Destination
import com.fireavert.units.logic.models.request.UnitCreationRequest
import io.ktor.client.plugins.*
import io.ktor.http.*

class ClientAddNewUnit(
    private val unitsNavigator: UnitsNavigator,
    private val screen: AddOrEditUnitScreen,
    private val propertyRepository: ClientPropertyRepository,
    private val unitsRepository: UnitsRepository,
    private val deviceRepository: DeviceRepository,
    private val logger: Logger,
) : AddNewUnit {

    override suspend fun onLoad(propertyId: Int) {
        screen.isLoading = true
        val property =
            when (val maybeProperty = propertyRepository.getPropertyByIdAsync(propertyId)) {
                is Error -> {
                    logger.e("ClientAddNewUnit Failed to get property with exception: ${maybeProperty.exception}")
                    screen.isLoading = false
                    return
                }
                is Success -> {
                    maybeProperty.value
                }
            }

        screen.number = ""
        screen.city = property.city
        screen.state = property.state
        screen.streetAddress = property.address
        screen.zip = property.zip.toString()
        screen.tenantName = ""
        screen.email = ""
        screen.phone = ""
        screen.city = ""
        screen.state = ""
        screen.isLoading = false
    }

    override suspend fun save(propertyId: Int) {
        screen.saveDisabled = true
        screen.isLoading = true
        doSave(propertyId)
        screen.isLoading = false
        screen.saveDisabled = false
    }

    override fun notifyStoveCurrentChanged(value: Boolean) {
        screen.saveError = null
        screen.notifyStoveCurrent = value
    }

    private suspend fun doSave(propertyId: Int) {
        if (!UnitValidator.validate(screen)) {
            logger.d("Failed to validate unit.")
            screen.saveError = "Invalid data entry for unit."
            return
        }

        /// Get the unit data
        val number = screen.number
        val streetAddress = screen.streetAddress
        val city = screen.city
        val state = screen.state
        val zip = screen.zip
        val tenantName = screen.tenantName
        val phone = screen.phone
        val email = screen.email
        val notifyStoveCurrent = screen.notifyStoveCurrent

        /// Validate the devices data
        if (!DeviceDataValidator.validate(screen)) {
            logger.d("Failed to validate Device Creation Data")
            screen.saveError = "Invalid data entry for one or more devices."
            return
        }

        val unitCreationRequest = UnitCreationRequest(
            propertyId = propertyId,
            number = number,
            streetAddress = streetAddress,
            city = city,
            state = state,
            zip = zip,
            tenantName = tenantName,
            phone = phone,
            email = email,
            notifyStoveCurrent = notifyStoveCurrent
        )

        val unitId =
            when (val maybeUnit = unitsRepository.createUnit(request = unitCreationRequest)) {
                is Success -> maybeUnit.value
                is Error -> {
                    logger.e("Failed to create unit exception ${maybeUnit.exception}")
                    screen.saveError = maybeUnit.exception.toString()
                    return
                }
            }

        val createdDevices: MutableList<Int> = mutableListOf()
        screen.deviceData.forEachIndexed { index, deviceCreation ->
            val deviceCreationRequest = DeviceCreationRequest.fromDeviceData(deviceCreation, unitId)
            createdDevices += when (val maybeDevice = deviceRepository.createDevice(deviceCreationRequest)) {
                is Error -> {
                    if (maybeDevice.exception is ClientRequestException &&
                        maybeDevice.exception.response.status == HttpStatusCode.Conflict) {
                        /** TODO: handle conflicts here **/
                        val message = if(maybeDevice.exception.message.contains("locator")) {
                            screen.setDeviceLocatorError(index, true)
                            "A device with the locator ${deviceCreation.locator} already exists."
                        }
                        else if (maybeDevice.exception.message.contains("reboot")){
                            screen.setRebootUUIDError(index, true)
                            "A device with the reboot UUID ${deviceCreation.rebootUUID} already exists."
                        }
                        else if (maybeDevice.exception.message.contains("info")){
                            screen.setInfoUUIDError(index, true)
                            "A device with the info UUID ${deviceCreation.infoUUID} already exists."
                        }
                        else "Failed to create device. Unknown Error."
                        logger.e(message)
                        screen.saveError = message
                        return@forEachIndexed
                    }
                    val message =
                        "Failed to create device with locator ${deviceCreation.locator} for unit $unitId"
                    logger.e(message)
                    screen.saveError = message
                    return@forEachIndexed
                }
                is Success -> maybeDevice.value
            }
        }

        if (createdDevices.size < screen.deviceData.size) {
            /// Something went wrong when trying to create all the devices so remove the unit and fail
            /// Deleting the unit should delete any devices that got created
            val deleted = when (val maybeResult = unitsRepository.delete(unitId)) {
                is Error -> {
                    val message =
                        "Failed to delete unit after device creation failure.  ${maybeResult.exception}"
                    logger.e(message)
                    false
                }
                is Success -> maybeResult.value
            }

            screen.saveError =
                "There was a problem creating one or more devices with the unit.  The unit ${if (deleted) "was" else "was not"} deleted."
            return
        }
        unitsNavigator.navigate(Destination.UnitDetails(propertyId = propertyId, unitId = unitId))
    }

    override fun clickedCancel(propertyId: Int) {
        unitsNavigator.navigate(Destination.PropertyDetails(propertyId))
    }

    override fun numberChanged(value: String) {
        screen.saveError = null
        screen.number = value
        screen.numberError = !UnitValidator.numberIsValid(screen)
    }

    override fun addressChanged(value: String) {
        screen.saveError = null
        screen.streetAddress = value
        screen.streetAddressError = !UnitValidator.addressIsValid(screen)
    }

    override fun cityChanged(value: String) {
        screen.saveError = null
        screen.city = value
        screen.cityError = !UnitValidator.cityIsValid(screen)
    }

    override fun stateChanged(value: String) {
        screen.saveError = null
        screen.state = value
        screen.stateError = !UnitValidator.stateIsValid(screen)
    }

    override fun zipChanged(value: String) {
        screen.saveError = null
        screen.zip = value
        screen.zipError = !UnitValidator.zipIsValid(screen)
    }

    override fun tenantNameChanged(value: String) {
        screen.saveError = null
        screen.tenantName = value
        screen.tenantNameError = false
    }

    override fun phoneChanged(value: String) {
        screen.saveError = null
        screen.phone = value
        screen.phoneError = !UnitValidator.phoneIsValid(screen)
    }

    override fun emailChanged(value: String) {
        screen.saveError = null
        screen.email = value
        screen.emailError = !UnitValidator.emailIsValid(screen)
    }
}