package com.fireavert.units.presentation

import com.fireavert.common.TableColumn
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.UnitDetailsDeviceDataState
import com.fireavert.devices.logic.models.UnitDetailsEventHistoryDataState
import com.fireavert.units.logic.UnitDetails
import com.fireavert.units.logic.UnitDetailsScreen

class UnitDetailsController(
    private val interactor: UnitDetails,
    private val screen: UnitDetailsScreen
) {
    suspend fun onLoad(propertyId: Int, unitId: Int?, timeZone: String) {
        if (unitId != null) {
            screen.isLoading = true
            interactor.onLoad(propertyId, unitId, timeZone)
            screen.isLoading = false
        }
    }

    fun deviceTypeString(type: DeviceType): String = type.string
    fun close(propertyId: Int) = interactor.close(propertyId)
    suspend fun deleteEventHistoryItem(eventHistoryItem: UnitDetailsEventHistoryDataState) = interactor.deleteEventHistoryItem(eventHistoryItem)
    suspend fun clickedClearEventHistory(unitId: Int) = interactor.clearEventHistoryForUnit(unitId)
    fun sortDevicesWithColumns(
        devicesColumns: List<TableColumn>,
        devices: List<UnitDetailsDeviceDataState>
    ): List<UnitDetailsDeviceDataState> = interactor.sortDevicesWithColumns(devicesColumns, devices)

    fun sortEventsByColumns(eventHistoryColumns: List<TableColumn>, eventHistory: List<UnitDetailsEventHistoryDataState>): List<UnitDetailsEventHistoryDataState> = interactor.sortEventsByColumns(eventHistoryColumns, eventHistory)
}