package com.fireavert.events.activedeviceevents.interface_adapters

import com.fireavert.common.TableColumn
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvents
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvent
import com.fireavert.events.logic.models.UnitEventTotals

class ActiveDeviceEventsController(
    private val interactor: ActiveDeviceEvents
) {
    suspend fun onLoad(propertyId: Int) = interactor.onLoad(propertyId)
    fun getTimeForEvent(event: ActiveDeviceEvent, timezoneId: String): String = interactor.getTimeStringForEvent(event, timezoneId)
    fun stringOutputForCount(count: Int): String = interactor.stringOutputForCount(count)
    fun sortWithColumns(
        columns: Array<TableColumn>,
        events: List<ActiveDeviceEvent>,
        unitEventTotalsMap: Map<Int, UnitEventTotals>
    ): List<ActiveDeviceEvent> = interactor.sortWithColumns(columns, events, unitEventTotalsMap)
}