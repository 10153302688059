package com.fireavert.properties.logic.property_admin

import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository

class ClientDeletePropertyAdmin(
    private val propertyRepository: ClientPropertyRepository,
    private val screen: DeletePropertyAdminDialogScreen,
    private val logger: Logger
) : DeletePropertyAdmin {

    override suspend fun onLoad(propertyId: Int, propertyAdminUserId: Int) {
        screen.isLoading = true

        val propertyAdmin =
            when (val maybePropertyAdmins = propertyRepository.getPropertyAdmins(propertyId)) {
                is Success -> maybePropertyAdmins.value
                is Error -> {
                    screen.isLoading = false
                    screen.loadFailed = true
                    logger.e("Failed to load delete property admin dialog due to error ${maybePropertyAdmins.exception.message}")
                    return
                }
            }.firstOrNull { it.id.isNotBlank() && it.id == propertyAdminUserId.toString() }

        if (propertyAdmin == null) {
            screen.isLoading = false
            screen.loadFailed = true
            logger.e("Failed to find property admin in list from server")
            return
        }

        screen.propertyAdminModel = propertyAdmin

        screen.isLoading = false
    }

    override suspend fun deletePropertyAdmin(propertyId: Int, propertyAdminUserId: Int) {
        screen.isDeleting = true

        when (val maybeSuccess =
            propertyRepository.deletePropertyAdmin(propertyId, propertyAdminUserId)) {
            is Success -> {
                screen.deletionAttempted = true
                screen.deletionFailed = false
                screen.isDeleting = false
            }
            is Error -> {
                screen.deletionAttempted = true
                screen.deletionFailed = true
                logger.e("Failed to delete property admin with error: ${maybeSuccess.exception.message}")
                screen.isDeleting = false
            }
        }
    }

}