package com.fireavert.events.activeevents.logic

import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.events.activeevents.domain.ActiveEvent
import com.fireavert.events.logic.models.EventTypeIconOption
import com.fireavert.logging.Logger

class ClientActiveEvents(
    private val screen: ActiveEventsScreen,
    private val activeEventsRepository: ActiveEventsRepository,
    private val logger: Logger,
) : ActiveEvents {
    override suspend fun onLoad() {
        screen.setLoading(true)

        val propertiesWithActiveEvents = when (val maybePropertiesWithActiveEvents =
            activeEventsRepository.getActiveEventsForAllProperties()) {
            is Error -> {
                logger.e("Failed to get properties with active events.")
                screen.setLoading(false)
                null
            }
            is Success -> {
                maybePropertiesWithActiveEvents.value
            }
        } ?: return

        val activeEvents: MutableList<ActiveEvent> = mutableListOf()
        val alarmOnDeviceMap = mutableMapOf<Int, Boolean>()

        propertiesWithActiveEvents.forEach { propertyWithActiveEvents ->
            val property = propertyWithActiveEvents.property
            val events = propertyWithActiveEvents.activeDeviceEvents
            val alarmActiveDeviceMap = propertyWithActiveEvents.alarmActiveDeviceMap

            alarmOnDeviceMap.putAll(alarmActiveDeviceMap)

            activeEvents.addAll(events.map {
                ActiveEvent(
                    id = it.id,
                    property = property,
                    deviceId = it.deviceId,
                    unitName = it.unit,
                    unitId = it.unitId,
                    eventType = it.eventType,
                    alarmActive = it.alarmActive,
                    deviceTriggered = it.deviceTriggered,
                    deviceOffline = it.deviceOffline,
                    fireAvertStatus = it.fireAvertStatus,
                    alarmEvents = it.alarmEvents,
                    shutoffEvents = it.shutoffEvents,
                    deviceType = it.deviceType
                )
            })
        }

        screen.setAlarmOnDeviceMap(alarmOnDeviceMap)
        screen.setEvents(activeEvents)
        screen.setLoading(false)
    }

    override fun sortEventsByColumn(
        columns: Array<TableColumn>,
        events: List<ActiveEvent>
    ): List<ActiveEvent> {
        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return events
        return when (sortColumn.text) {
            "PROPERTY" -> {
                TableColumn.sortList(true, sortColumn, events) { it.property.name }
            }
            "UNIT" -> {
                TableColumn.sortList(true, sortColumn, events) { it.unitName }
            }
            "EVENT TYPE" -> {
                TableColumn.sortList(
                    true,
                    sortColumn,
                    events
                ) { EventTypeIconOption.from(it.deviceType, it.eventType) }
            }
            "ADDRESS" -> {
                TableColumn.sortList(true, sortColumn, events) { it.property.address }
            }
            else -> {
                events
            }
        }
    }
}