package com.fireavert.common

import kotlin.random.Random

object RandomStringGenerator {
    fun generateRandomString(length: Int = 10): String {
        val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
        return (1..length)
            .map { allowedChars.random(Random) }
            .joinToString("")
    }
}