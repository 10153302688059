package com.fireavert.dashboard.adapters

import com.fireavert.dashboard.logic.DashboardInteractor
import kotlinx.datetime.LocalDate

class DashboardController(
    private val interactor: DashboardInteractor
) {
    suspend fun onLoad(startDate: LocalDate, endDate: LocalDate, propertyId: Int?) = interactor.onLoad(
        startDate, endDate, propertyId
    )

    suspend fun updateData(newStartDate: LocalDate?, newEndDate: LocalDate?, propertyId: Int?) =
        interactor.updateData(newStartDate, newEndDate, propertyId)
}