package com.fireavert.router

import com.fireavert.components.pages.*
import com.fireavert.components.redirect.Redirect
import com.fireavert.menu.RoutePaths
import js.objects.jso
import react.create
import react.router.RouteObject
import kotlin.random.Random

val PropertiesRoutes = arrayOf<RouteObject>(
    /** Properties Routes **/
    jso {
        path = "${RoutePaths.reboot}_not_found"
        element = Redirect.create {
            external = true
            location = "https://fireavert.com/restoring-power-to-your-fireavert-protected-stove-3/"
        }
    },
    jso {
        path = "${RoutePaths.reboot}_success"
        element = Redirect.create {
            external = true
            location = if (Random.nextBoolean())
                "https://www.fireavert.com/smoke-alerts"
            else
                "https://www.fireavert.com/fire-alerts"
        }
    },
    jso {
        path = "${RoutePaths.properties}/add_new"
        element = PropertiesV2.create {
            addNewPropertyDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/reboot_device/:deviceId/gas"
        element = PropertyDetailsV2.create {
            rebootDeviceDialogIsOpen = true
            rebootIsGas = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/reboot_device/:deviceId"
        element = PropertyDetailsV2.create {
            rebootDeviceDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/mark_as_false_alarm/:eventId"
        element = PropertyDetailsV2.create {
            markAsFalseAlarmDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/mark_as_test/:eventId"
        element = PropertyDetailsV2.create {
            markAsTestDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/dismiss_event/:eventId"
        element = PropertyDetailsV2.create {
            dismissEventDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/units/:unitId/delete"
        element = PropertyDetailsV2.create {
            deleteUnitDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/units/:unitId/edit"
        element = PropertyDetailsV2.create {
            editUnitDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/units/:unitId"
        element = PropertyDetailsV2.create {
            unitDetailsDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/removePropertyAdmin/:userId"
        element = PropertyDetailsV2.create {
            deletePropertyAdminDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/delete"
        element = PropertiesV2.create {
            deletePropertyDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/edit"
        element = PropertyDetailsV2.create {
            editPropertyDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/convert_to_comm_v2"
        element = PropertyDetailsV2.create {
            convertToV2DialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/add_new_units_csv"
        element = PropertyDetailsV2.create {
            addNewUnitCSVDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId/add_new_unit"
        element = PropertyDetailsV2.create {
            addNewUnitDialogIsOpen = true
        }
    },
    jso {
        path = "${RoutePaths.properties}/:propertyId"
        element = PropertyDetailsV2.create {
        }
    },
    jso {
        path = RoutePaths.properties
        element = PropertiesV2.create {
        }
    },
)