package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import web.cssom.*

object ReportPageStyles {

    val reportEventCardStyle = ClassName {
        display = Display.flex
        gap = 30.px
        marginTop = 10.px
    }

    val firesAvertedValue = ClassName {
        fontSize = 64.px
        fontWeight = FontWeight.bold
    }

    val costsSavingsText = ClassName {
        fontSize = 16.px
        fontWeight = FontWeight.bold
    }

    val chartContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        gap = 30.px
        backgroundColor = Color("White")
        borderRadius = 15.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            // Adjust the overall font size
            fontSize = important(10.px)

            // Adjust legend styles for mobile
            ".MuiChartsLegend-root" {
                fontSize = important(10.px)
            }

            ".MuiChartsLegend-series text" {
                fontSize = important(10.px)
            }
        }
    }

    val reportPageStyle = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        marginTop = 25.px
        overflowX = Overflow.hidden
    }

    val reportCardText = ClassName {
        position = Position.absolute
        top = 0.px
        fontSize = 16.px
        color = Color("Black")
        marginLeft = 15.px
        maxWidth = 165.px
        marginTop = 5.px
        fontWeight = FontWeight.bold

    }

    val reportEventCardNumber = ClassName {
        position = Position.absolute
        bottom = 0.px
        fontSize = 64.px
        fontWeight = FontWeight.bold
        lineHeight = 64.px
        marginLeft = 20.px
        marginBottom = 15.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 28.px
            lineHeight = 0.px
            marginBottom = 20.px
            right = 0.px
            marginLeft = 0.px
            marginRight = 20.px

        }
    }

    val reportCardTitleSmoke = ClassName {
        height = 35.px
        background = Color("linear-gradient(to right, #FF7E60, #FFAA70)")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val reportCardTitleWater = ClassName {
        height = 35.px
        background = Color("linear-gradient(to right, #35ABFE, #87F6EB)")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val reportCardTitleTamper = ClassName {
        height = 35.px
        background = Color( "linear-gradient(to right, #23D769, #AFF675)")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val reportCardTitleStoveShutoff = ClassName {
        height = 35.px
        background = Color("linear-gradient(to right, #FF7F60, #FFAF70)")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val reportCardTitle = ClassName {
        height = 35.px
        background = Color("Blue")
        borderTopLeftRadius = 15.px
        borderTopRightRadius = 15.px
        borderBottomLeftRadius = 0.px
        borderBottomRightRadius = 0.px
    }

    val dropDownContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        gap = 30.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
        }
    }

    val titleHeading = ClassName {
        fontSize = 20.px
        padding = Padding(16.px, 16.px)
        color = NamedColor.black
        backgroundColor = Color("#EDEFF3")
        padding = 0.px
        height = 36.px
        marginTop = 20.px
        position = Position.relative
        fontWeight = FontWeight.bold
    }

    val highRiskInfo = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        margin = 20.px
    }

    val reportHighRiskTenantRow = ClassName {
        display = Display.tableRow
        cursor = important(Cursor.pointer)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = 15.px
            "& a, & a:visited" {
                color = important(NamedColor.black)
            }
            borderBottom = Border(1.px, LineStyle.solid, Color("#DEE2E6"))
        }
    }

    val noDataMessage = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        alignItems = AlignItems.center
        height = 100.pct
        fontSize = 20.px
        fontWeight = FontWeight.bold
        padding = 20.px
    }

    val highRiskTenantTable = ClassName {
        width = 100.pct
        borderRadius = 4.px
        backgroundColor = NamedColor.white
        marginTop = 0.px
        marginBottom = 23.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginTop = 0.px
            marginBottom = 0.px
            maxHeight = 450.px
            overflow = Overflow.scroll
            overflowX = Overflow.hidden
            borderRadius = 10.px
        }
    }

    val costSavingsContainer = ClassName {
        width = 100.pct
        borderRadius = 15.px
        backgroundColor = NamedColor.white
        marginTop = 0.px
        padding = 15.px
        marginBottom = 23.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginTop = 0.px
            marginBottom = 0.px
            maxHeight = 450.px
            overflow = Overflow.scroll
            overflowX = Overflow.hidden
            borderRadius = 10.px
        }
    }

    val headingAndDropDownContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = AlignItems.center
        gap = 20.px
        marginTop = 20.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
            gap = 10.px
            alignItems = AlignItems.start
        }
    }

}