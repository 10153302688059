package com.fireavert.events.markasfalsealarm.presentation

import com.fireavert.events.common.logic.EventsNavigator
import com.fireavert.events.markasfalsealarm.logic.Screen
import com.fireavert.events.markasfalsealarm.logic.OnLoadUseCase
import kotlinx.coroutines.launch
import kotlin.coroutines.CoroutineContext

class MarkAsFalseAlarmController(
    private val onLoadUseCase: OnLoadUseCase,
    private val navigator: EventsNavigator,
    private val screen: Screen,
    private val ioContext: CoroutineContext
) {
    fun onLoad(eventId: Long) {
        screen.scope.launch(ioContext) {
            screen.loading = true
            onLoadUseCase.onLoad(eventId)
            screen.loading = false
        }
    }

    fun close(propertyId: Int) {
        navigator.navToDestination(EventsNavigator.Destination.PropertyDetails(propertyId = propertyId))
    }
}