package com.fireavert.components.events

import com.fireavert.styles.TableStyles
import emotion.css.ClassName
import emotion.css.cx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import web.cssom.ClassName
import web.cssom.FontWeight

external interface EventTotalTextProps: Props {
    var hasEvents: Boolean
    var style: ClassName
    var text: String
}

val EventTotalCell = FC<EventTotalTextProps> { props ->
    div {
        if (props.hasEvents) {
            className = cx(
                TableStyles.tableCellCentered,
                props.style,
                ClassName { fontWeight = FontWeight.bold }
            )
        } else {
            className = TableStyles.tableCellCentered
        }
        +props.text
    }
}