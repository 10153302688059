package com.fireavert.units.presentation

import com.fireavert.devices.logic.models.Device
import com.fireavert.jobs.logic.JobModel
import com.fireavert.units.logic.models.SkippedCSVRow
import com.fireavert.units.logic.models.UnitModel
import com.fireavert.units.logic.models.request.MultiUnitCreationRequest

data class AddNewUnitCSVScreenViewState(
    val loading: Boolean = false,
    val loadingMessage: String = "",
    val errorMessage: String = "",
    val existingUnits: List<UnitModel> = emptyList(),
    val existingDevices: List<Device> = emptyList(),
    val existingInfoIds: List<String> = emptyList(),
    val existingRebootIds: List<String> = emptyList(),
    val pendingRequest: MultiUnitCreationRequest? = null,
    val skippedCSVRows: List<SkippedCSVRow> = emptyList(),
    val jobModel: JobModel? = null,
    val checking: Boolean = false
) {
}
