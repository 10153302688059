package com.fireavert.events.activeevents.logic

import com.fireavert.common.Try
import com.fireavert.events.activeevents.domain.ActiveSensorType
import com.fireavert.logging.Logger

class ActiveEventsV2Interactor(
    private val activeEventsV2Screen: ActiveEventsV2Screen,
    private val activeEventsRepository: ActiveEventsRepository,
    private val logger: Logger
) {
    suspend fun onLoad(dateRange: Long) {
        val eventsWithActions = when (val maybe = activeEventsRepository.getActiveEventsWithActions(dateRange)) {
            is Try.Error -> {
                logger.e("Failed to get events with actions")
                emptyList()
            }
            is Try.Success -> maybe.value
        }

        val filteredEvents = eventsWithActions.filter { event ->
            // Filter out events that only have Offline sensor type
            !(event.activeSensors.size == 1 && event.activeSensors.contains(ActiveSensorType.Offline))
        }
        activeEventsV2Screen.eventsWithActions = filteredEvents
    }
}