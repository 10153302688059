import com.fireavert.ConsoleKoinLogger
import com.fireavert.KoinModules
import com.fireavert.app.App
import com.fireavert.preferences.logic.Preferences
import com.fireavert.utilities.getKoinInstance
import csstype.sx
import kotlinx.datetime.TimeZone
import org.koin.core.context.startKoin
import react.create
import react.dom.client.createRoot
import web.cssom.pct
import web.dom.appendText
import web.dom.document
import web.html.HTML.div

@JsModule("@js-joda/timezone")
@JsNonModule
external object JsJodaTimeZoneModule

private val jsJodaTz = JsJodaTimeZoneModule

fun main() {
    startKoin {
        logger(ConsoleKoinLogger())
        modules(KoinModules.all)
    }
    val preferences = getKoinInstance<Preferences>()
    preferences.timeZone = TimeZone.currentSystemDefault().id
    val root = document.createElement(div)
        .apply {
            sx { height = 100.pct }
            id = "root"
        }
        .also { document.body.appendChild(it) }

    val styleElement = document.createElement("style")
    styleElement.appendText("""
        a, a:visited {
            color: #3580E1;
            text-decoration: none;
        }
        html, body, #root {
            overflow: hidden;
            position: relative;
            height: 100%;
        }
        .MuiCircularProgress-colorPrimary {
            color: #FFFFFF !important;
        }
    """.trimIndent()
    )
    document.head.appendChild(styleElement)

    createRoot(root).render(
        App.create()
    )
}
