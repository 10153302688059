package com.fireavert.components.common

import com.fireavert.app.interface_adapters.AppController
import com.fireavert.app.interface_adapters.AppViewModel
import com.fireavert.logging.Logger
import com.fireavert.utilities.getKoinInstance
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLElement
import react.FC
import react.Props
import react.router.useLocation
import react.useEffect
import react.useEffectOnce

external interface NotLoggedInProps: Props {
    var whiteBackground: Boolean
}
val NotLoggedIn = FC<NotLoggedInProps> { props ->
    val appController = getKoinInstance<AppController>()
    val appViewModel = getKoinInstance<AppViewModel>()
    val logger = getKoinInstance<Logger>()
    val location = useLocation()
    useEffect(location) {
        appViewModel.scope.launch {
            appController.createPageView(location.pathname)
        }
    }
    useEffectOnce {
        val html = document.documentElement as HTMLElement
        html.style.apply{
            backgroundColor = if (props.whiteBackground) "white" else "black"
            backgroundSize = "cover"
            backgroundPosition = "center"
            if (!props.whiteBackground) backgroundImage = "url('/static/smoke_bg.svg')"
        }
        val body = document.body as HTMLElement
        body.style.apply {
            backgroundColor = "transparent"
        }
    }
}