package com.fireavert.units.logic

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceData

object DeviceDataValidator {

    private val validCommVersions = listOf(1, 2, 3)

    fun validate(screen: AddOrEditUnitScreen): Boolean {
        val deviceCreationDataList = screen.deviceData
        for (index in deviceCreationDataList.indices) {
            val valid = checkDeviceType(screen, index, deviceCreationDataList) &&
                checkDeviceLocation(screen, index, deviceCreationDataList) &&
                checkDeviceLocator(screen, index, deviceCreationDataList) &&
                checkCommVersion(screen, index, deviceCreationDataList)
            if (!valid) return false
        }
        return true
    }

    private fun checkDeviceLocator(
        screen: AddOrEditUnitScreen,
        index: Int,
        deviceDataList: List<DeviceData>
    ): Boolean {
        val isError = !isDeviceLocatorValid(index, deviceDataList)
        screen.setDeviceLocatorError(index, isError)
        return !isError
    }

    fun isDeviceLocatorValid(
        index: Int, deviceDataList: List<DeviceData>
    ): Boolean {
        if (index >= deviceDataList.size) return false
        return deviceDataList[index].locator.isNotBlank()
    }

    private fun checkDeviceLocation(
        screen: AddOrEditUnitScreen,
        index: Int,
        deviceDataList: List<DeviceData>
    ): Boolean {
        val isError = !isLocationValid(index, deviceDataList)
        screen.setDeviceLocationError(index, isError)
        return !isError
    }

    fun isLocationValid(
        index: Int, deviceDataList: List<DeviceData>
    ): Boolean {
        if (index >= deviceDataList.size) return false
        return deviceDataList[index].location.isNotBlank()
    }

    private fun checkDeviceType(
        screen: AddOrEditUnitScreen,
        index: Int,
        deviceDataList: List<DeviceData>
    ): Boolean {
        val isError = !isDeviceTypeValid(index, deviceDataList)
        screen.setDeviceTypeError(index, isError)
        return !isError
    }

    private fun checkCommVersion(
        screen: AddOrEditUnitScreen,
        index: Int,
        deviceDataList: List<DeviceData>
    ): Boolean {
        val isError = !isDeviceCommVersionValid(index, deviceDataList)
        screen.setCommVersionError(index, isError)
        return !isError
    }

    fun isDeviceTypeValid(
        index: Int, deviceDataList: List<DeviceData>
    ): Boolean {
        if (index >= deviceDataList.size) return false
        val type = deviceDataList[index].type
        return (type == DeviceType.FireAvert.string ||
            type == DeviceType.FireAvertGas.string ||
            type == DeviceType.FireAvertAppliance.string ||
            type == DeviceType.WaterSensor.string ||
            type == DeviceType.FlowSensor.string ||
            type == DeviceType.TamperSensor.string)
    }

    fun isDeviceCommVersionValid(index: Int, deviceDataList: List<DeviceData>): Boolean {
        if (index >= deviceDataList.size) return false
        val commVersion = deviceDataList[index].commVersion
        return validCommVersions.contains(commVersion)
    }

    fun isDeviceRebootUUIDValid(index: Int, deviceDataList: List<DeviceData>): Boolean {
        return index < deviceDataList.size
    }

    fun isDeviceInfoUUIDValid(index: Int, deviceDataList: List<DeviceData>): Boolean {
        return index < deviceDataList.size
    }

}