package com.fireavert.utilities

import js.objects.jso
import kotlinx.datetime.LocalDate
import kotlinx.datetime.format
import kotlinx.datetime.format.char
import kotlin.js.Date


fun Date.toLocalDate(): LocalDate {
    val dateElements = toLocaleDateString("en-US", jso {
        this.timeZone = "America/Denver"
    }).split('/').map{
        if(it.length == 1) {
            "0$it"
        } else {
            it
        }
    }
    val dateString = "${dateElements[2]}-${dateElements[0]}-${dateElements[1]}"
    return LocalDate.parse(dateString)
}

fun LocalDate.toDate(): Date {
    val dateString = this.format(LocalDate.Format {
        year()
        char('-')
        monthNumber()
        char('-')
        dayOfMonth()
        chars("T00:00:00-07:00")
    })
    return Date(dateString = dateString)
}