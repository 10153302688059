package com.fireavert.administration_page.wireless_id_finder.logic

import com.fireavert.common.Try
import com.fireavert.logging.Logger

class WirelessIdSectionInteractor(
    private val screen: WirelessIdSectionScreen,
    private val repository: WirelessIdRepository,
    private val logger: Logger,
) {

    suspend fun loadWirelessIdData(deviceId: String) {
        val awsTitle = "https://us-west-2.console.aws.amazon.com/iot/home?region=us-west-2#/wireless/devices/details/"
        val deviceLocationTitle = "https://connect.fireavert.com/app/properties/"
        screen.wirelessDeviceId = ""
        if (!checkValidId(deviceId)) {
            return
        }

        try {
            val response = when (val maybe = repository.loadWirelessIdData(deviceId)) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    screen.wirelessDeviceId = "Error loading data (probably not found)"
                    return
                }
            }
            if (response.wirelessId.isEmpty()) {
                screen.wirelessDeviceId = "Device found, but no wireless Id has been set :("
                return
            }
            screen.wirelessDeviceId = awsTitle + response.wirelessId
            screen.unitName = response.unitName ?: "Unable to get unit name"
            screen.propertyName = response.propertyName ?: "Unable to get property name"
            screen.deviceLocationLink = deviceLocationTitle + response.propertyId + "/units/" + response.unitId
        } catch (e: Exception) {
            screen.wirelessDeviceId = "Error loading data"
        }
    }

    suspend fun loadGatewayDeviceData(propertyId: String) {
        screen.gatewayDeviceData = emptyMap()
        screen.gatewayDeviceDataLoading = true
        try {
            val response = when (val maybe = repository.loadGatewayDeviceData(propertyId)) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    screen.gatewayDeviceDataLoading = false
                    return
                }
            }

            screen.gatewayDeviceDataLoading = false
            screen.gatewayDeviceData = response.deviceToGateway

        } catch (e: Exception) {
            logger.e("Error loading gateway device data", e)
        }
    }

    suspend fun reloadAllGatewayInfo() {
        try {
            val response = when (val maybe = repository.reloadAllGatewayInfo()) {
                is Try.Success -> maybe.value
                is Try.Error -> {
                    return
                }
            }

        } catch (e: Exception) {
            logger.e("Error loading gateway device data", e)
        }
    }


    private fun checkValidId(deviceId: String): Boolean {
        val lowercaseDeviceId = deviceId.lowercase()

        return when {
            lowercaseDeviceId.matches(Regex("^ttn-v3-integration_[a-z0-9-]{7,9}$")) -> true // 26-28 characters
            lowercaseDeviceId.matches(Regex("^ls-res[0-9]{8}$")) -> true // 14 characters
            lowercaseDeviceId.matches(Regex("^ws-res[0-9]{8}$")) -> true // 14 characters
            lowercaseDeviceId.matches(Regex("^[a-z0-9]{3}-[a-z0-9]{3}$")) -> true // 7 characters
            lowercaseDeviceId.matches(Regex("^eui-[a-f0-9]{16}$")) -> true // 20 characters
            lowercaseDeviceId.matches(Regex("^[a-z]{2}-[a-f0-9]{16}$")) -> true // 19 characters
            else -> {
                screen.wirelessDeviceId = "Invalid ID"
                false
            }
        }
    }
}