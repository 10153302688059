package com.fireavert.units.logic.models.request

@kotlinx.serialization.Serializable
data class SimpleUnitCreationRequest(
    val propertyId: Int = 0,
    val building: String = "",
    val unitNumber: String = "",
    val fireAverts: List<CsvEntryWithRebootId> = emptyList(),
    val fireAvertGass: List<CsvEntryWithRebootId> = emptyList(),
    val fireAvertAppliances: List<CsvEntryWithRebootId> = emptyList(),
    val leakSensors: List<CsvEntry> = emptyList(),
    val tamperSensors: List<CsvEntry> = emptyList(),
)
@kotlinx.serialization.Serializable
data class CsvEntry(
    val location: String = "",
    val infoId: String = "",
    val locator: String = "",
    val commVersion: Int = 1,
)
@kotlinx.serialization.Serializable
data class CsvEntryWithRebootId(
    val location: String = "",
    val infoId: String = "",
    val locator: String = "",
    val rebootId: String = "",
    val commVersion: Int = 1,
)