package com.fireavert.properties.logic

import com.fireavert.events.logic.EmailValidator
import com.fireavert.events.logic.SMSValidator

class AddRemovePropertyAdminValidator {
    fun checkName(name: String): Boolean {
        return name.isNotBlank()
    }

    fun checkPhone(phone: String): Boolean {
        return SMSValidator.isSMSValid(phone)
    }

    fun checkEmail(email: String): Boolean {
        return EmailValidator.isEmailValid(email)
    }

    fun checkPassword(password: String): Boolean {
        return password.isNotBlank() && password.length > 4
    }
}