package com.fireavert.menu.presentation

import com.fireavert.common.ViewModel
import com.fireavert.menu.logic.MenuScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class MenuViewModel : ViewModel(), MenuScreen {
    private val _viewState = MutableStateFlow(MenuViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope
    override var showAdministrationMenuItem: Boolean
        get() = viewState.value.showAdministrationMenuItem
        set(value) {
            _viewState.value = _viewState.value.copy(showAdministrationMenuItem = value)
        }

    override fun setShowClientsMenuItem(show: Boolean) {
        _viewState.value = _viewState.value.copy(showClientsMenuItem = show)
    }

    override fun setShowUsersMenuItem(show: Boolean) {
        _viewState.value = _viewState.value.copy(showUsersMenuItem = show)
    }

    override fun setDashboardNumber(number: Int) {
        _viewState.value = _viewState.value.copy(dashboardNumber = number)
    }

    override fun setPropertiesNumber(number: Int) {
       _viewState.value = _viewState.value.copy(propertiesNumber = number)
    }

}