package com.fireavert.components.administration

import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionController
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionViewModel
import com.fireavert.styles.HelpStyles
import com.fireavert.styles.ReportPageStyles
import com.fireavert.styles.SearchbarStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.TextField
import react.FC
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.html.HTMLInputElement
import web.window.WindowTarget


val DeviceIdMatchingSection = FC {
    val controller = getKoinInstance<WirelessIdSectionController>()
    val viewModel = getKoinInstance<WirelessIdSectionViewModel>()

    var wirelessDeviceId: String by useState("")
    val (searchValue, setSearchValue) = useState("")

    useEffectOnce {
        viewModel.viewState.onEach { viewState ->
            wirelessDeviceId = viewState.wirelessDeviceId
        }.launchIn(viewModel.scope)
    }

    fun searchForId(value: String) {
        viewModel.scope.launch {
            controller.loadWirelessIdData(value)
        }
    }


    div {
        className = ReportPageStyles.costSavingsContainer
        div {
            className = HelpStyles.eventTitle
            +"Wireless Device ID Matching (AWS)"
        }

        div {
            className = ReportPageStyles.headingAndDropDownContainer
            div {
                TextField {
                    className = SearchbarStyles.searchBarInput(15)
                    placeholder = "Enter Device ID"
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }

            div {
                Button {
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    onClick = {
                        searchForId(searchValue)
                    }
                    +"Search"
                }
            }
        }
        div {
            if (wirelessDeviceId.length > 20) {
                a {
                    href = wirelessDeviceId
                    target = WindowTarget._blank
                    +wirelessDeviceId
                }
            } else {
                +wirelessDeviceId
            }
        }
    }

}