package com.fireavert.properties.presentation.property_details

import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.property_details.PropertyDetailsScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class PropertyDetailsViewModel : ViewModel(), PropertyDetailsScreen {
    private val _viewState = MutableStateFlow(PropertyDetailsViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope

    override fun setPropertyName(name: String) {
        _viewState.value = _viewState.value.copy(propertyName = name)
    }

    override fun setPropertyTotalUnits(value: Int) {
        _viewState.value = _viewState.value.copy(propertyTotalUnits = value)
    }
    override fun setIdAdmin(value: Boolean) {
        _viewState.value = _viewState.value.copy(isAdmin = value)
    }
}