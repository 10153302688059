package com.fireavert.reports_page.logic.models.responses

import kotlinx.serialization.Serializable

@Serializable
data class ReportYearlyDataResponse(
    val thisYearSmokeEvents: Map<String, Int>,
    val thisYearLeakEvents: Map<String, Int>,
    val thisYearTamperEvents: Map<String, Int>,
    val thisYearStoveShutoffEvents: Map<String, Int>,
    val thisYearOfflineEvents: Map<String, Int>,
)
