package com.fireavert.administration_page.user_tracking.interface_adapters

import com.fireavert.administration_page.user_tracking.logic.UserTrackingRecord
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class UserTrackingRecordDTO(
    val name: String? = null,
    val email: String? = null,
    val phone: String? = null,
    val managementCompany: String? = null,
    val userVisitLog:  Map<Long?, String?>
) {
    fun toUserTrackingRecord(): UserTrackingRecord {
        val lastVisitMap: Map<Instant?, String?> = userVisitLog.mapKeys { entry ->
            entry.key?.let { Instant.fromEpochSeconds(it) } ?: Instant.fromEpochSeconds(0)
        }
        return UserTrackingRecord(
            name = name ?: "",
            email = email ?: "",
            phone = phone ?: "",
            managementCompany = managementCompany ?: "",
            userVisitLog = lastVisitMap
        )
    }

}
