package com.fireavert.dashboard.logic

import com.fireavert.actions.logic.EventLinkedActionType
import com.fireavert.common.Try
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.events.logic.models.EventType
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class DashboardInteractor(
    private val screen: DashboardScreen,
    private val propertyRepository: ClientPropertyRepository,
    private val dashboardRepository: DashboardRepository,
    private val logger: Logger
) {
    suspend fun onLoad(startDate: LocalDate, endDate: LocalDate, propertyId: Int?) {
        val properties = when(val maybe = propertyRepository.getProperties()) {
            is Try.Error -> {
                logger.e("Failed to get list of properties")
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }
        screen.propertyMap = mapOf("All" to null) + properties.sortedBy { it.name }.associate { it.name to it.id }
        updateData(startDate, endDate, propertyId)
    }

    suspend fun updateData(newStartDate: LocalDate?, newEndDate: LocalDate?, propertyId: Int?) {
        screen.startDate = newStartDate
        screen.endDate = newEndDate
        screen.propertyId = propertyId

        var endDate = newEndDate
        if (newStartDate == null) {
            return // Do nothing
        }
        if (endDate == null) {
            endDate = newStartDate
        }
        screen.isLoading = true
        val eventsAndActions =
            when (val maybe = dashboardRepository.getEventsAndActions(newStartDate, endDate, propertyId)) {
                is Try.Error -> {
                    logger.e("Failed to get events and actions ${maybe.exception}")
                    screen.isLoading = false
                    return
                }

                is Try.Success -> {
                    maybe.value
                }
            }
        screen.smokeEvents =
            eventsAndActions.first.filter { it.type == EventType.SmokeAlarmActive && it.deviceType.isFireAvert }
        screen.waterEvents =
            eventsAndActions.first.filter { it.type == EventType.DeviceTriggered && it.deviceType == DeviceType.WaterSensor }
        screen.tamperEvents =
            eventsAndActions.first.filter { it.type == EventType.DeviceTriggered && it.deviceType == DeviceType.TamperSensor }
        screen.offlineEvents = eventsAndActions.first.filter {
            (it.type == EventType.DeviceOffline) &&
                    (it.timestamp.toLocalDateTime(TimeZone.currentSystemDefault()).date == Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).date)
        }

        screen.shutoffActions = eventsAndActions.second.filter { it.type == EventLinkedActionType.StoveShutoff }
        screen.textAlertActions = eventsAndActions.second.filter { it.type == EventLinkedActionType.TextAlert }
        screen.resetActions = eventsAndActions.second.filter { it.type == EventLinkedActionType.StoveReset }
        screen.isLoading = false
    }
}