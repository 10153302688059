package com.fireavert.components.dialogs

import com.fireavert.components.common.WarningIcon
import com.fireavert.events.rebootdevice.presentation.RebootDeviceDialogController
import com.fireavert.events.rebootdevice.presentation.RebootDeviceDialogViewModel
import com.fireavert.logging.Logger
import com.fireavert.styles.DialogStyles
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.Breakpoint
import react.*
import react.dom.html.ReactHTML.div
import react.router.useParams
import web.cssom.JustifyContent
import web.cssom.NamedColor
import web.cssom.important
import web.cssom.px

external interface RebootDeviceDialogProps : Props {
    var isGas: Boolean
}

val RebootDeviceDialog = FC<RebootDeviceDialogProps> { props ->

    val propertyId = useParams()["propertyId"]?.toInt() ?: 0
    val deviceId = useParams()["deviceId"]?.toInt() ?: 0

    val controller = getKoinInstance<RebootDeviceDialogController>()
    val viewModel = getKoinInstance<RebootDeviceDialogViewModel>()
    val logger = getKoinInstance<Logger>()

    var isLoading: Boolean by useState(false)
    var success: Boolean by useState(false)
    var confirmed: Boolean by useState(false)

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isLoading = viewState.loading
            success = viewState.success
        }.launchIn(viewModel.scope)

        cleanup {
            viewStateJob.cancel()
        }
    }

    Dialog {
        open = true
        onBackdropClick = { controller.close(propertyId) }
        onClose = { _, _ -> controller.close(propertyId) }
        maxWidth = Breakpoint.lg
        if (!confirmed) {
            DialogContent {
                className = DialogStyles.warningDialogContent
                WarningIcon {}
                div {
                    className = DialogStyles.warningTitle
                    +"WARNING"
                }
                div {
                    className = cx(DialogStyles.warningText, ClassName {
                        marginBottom = 20.px
                    })
                    +"Please contact the tenant before remotely rebooting any appliance."
                }
                div {
                    className = DialogStyles.warningText
                    +"Have you received verbal confirmation from the tenant that ALL appliance knobs and switches are in the OFF position?"
                }
                if (props.isGas) {
                    div {
                        className = cx(DialogStyles.warningText, ClassName {
                            marginTop = 20.px
                        })
                        +"Have you checked all the pilot lights?"
                    }
                }
                DialogActions {
                    css {
                        height = 104.px
                        padding = 24.px
                        justifyContent = important(JustifyContent.center)
                    }
                    Button {
                        +"No, not yet"
                        color = ButtonColor.inherit
                        onClick = { controller.close(propertyId) }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            marginRight = 6.px
                            backgroundColor = NamedColor.white
                        }
                    }
                    Button {
                        +"Yes, turn appliance ON"
                        color = ButtonColor.inherit
                        onClick = {
                            confirmed = true
                            viewModel.scope.launch {
                                controller.clickedRebootDevice(deviceId)
                            }
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            backgroundColor = NamedColor.white
                        }
                    }
                }
            }
        } else if (isLoading) {
            DialogTitle {
                +"Reboot Device"
                className = DialogStyles.dialogTitle
            }
            DialogContent {
                className = DialogStyles.warningDialogContent
                CircularProgress{}
            }
        } else {
            DialogTitle {
                className = DialogStyles.dialogTitle
                +"Reboot Device"
            }
            DialogContent {
                className = DialogStyles.warningDialogContent
                div {
                    className = DialogStyles.warningText
                    if (success) {
                        +"Reboot signal was sent to device"
                    } else {
                        +"There was an error when sending reboot signal to device"
                    }
                }
                DialogActions {
                    css {
                        height = 104.px
                        justifyContent = important(JustifyContent.center)
                    }
                    Button {
                        +"OK"
                        color = ButtonColor.inherit
                        onClick = {
                            controller.close(propertyId)
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                        }
                    }
                }
            }
        }
    }
}