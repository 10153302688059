package com.fireavert.components.pages

import com.fireavert.components.common.NotLoggedIn
import com.fireavert.components.unauthenticated.useQuery
import com.fireavert.logging.Logger
import com.fireavert.reboot.presentation.RebootPageController
import com.fireavert.reboot.presentation.RebootViewModel
import com.fireavert.styles.MobileStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import js.objects.jso
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.Input
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import web.cssom.Auto
import web.cssom.TextAlign
import web.cssom.px
import kotlin.random.Random

external interface RebootPageProps : Props {
    var uuid: Boolean
}

val RebootPage = FC<RebootPageProps> { props ->
    NotLoggedIn {
        whiteBackground = true
    }

    val queryString = useQuery() ?: ""

    val controller = getKoinInstance<RebootPageController>()
    val viewModel = getKoinInstance<RebootViewModel>()
    val logger = getKoinInstance<Logger>()

    var deviceId: String? by useState(null)
    var deviceError: Boolean by useState(false)
    var deviceNotActive: Boolean by useState(false)

    fun setIdFromQueryString(queryString: String) {
        val sections = queryString.split("&")
        for (section in sections) {
            val keyValue = section.split("=")
            if (keyValue.size < 2)
                continue
            if (keyValue[0] == "id") {
                viewModel.scope.launch {
                    controller.textChanged(text = keyValue[1], uuid = props.uuid)
                }
                return
            }
        }
        controller.noIdFound()
    }

    useEffectOnce {
        setIdFromQueryString(queryString)
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            deviceId = viewState.deviceId
            deviceError = viewState.deviceError
            deviceNotActive = viewState.deviceNotActive
        }.launchIn(viewModel.scope)

        cleanup {
            viewStateJob.cancel()
        }
    }

    val submitFunction = {
        viewModel.scope.launch {
            controller.clickedSubmit(deviceId)
        }
    }

    val stoveId = FC {
        div {
            className = MobileStyles.stoveIdNumberTitle
            +"Stove ID"
        }
        div {
            css {
                margin = Auto.auto
                textAlign = TextAlign.center
            }
            Input {
                inputProps = jso {
                    style = jso {
                        textAlign = TextAlign.center
                    }
                }
                disabled = true
                value = deviceId ?: ""
                className = MobileStyles.stoveIdInput
            }
        }
    }


    val customerSupportStoveIssues = FC {
        div {
            className = MobileStyles.stoveIdButtonContainer

            a {
                href = "https://fireavert.com/stovereset"
                Button {
                    sx {
                        height = 45.px
                        width = 300.px
                        borderRadius = 25.px
                    }
                    className = MobileStyles.turnOnSafetyAlertsText
                    variant = ButtonVariant.contained
                    color = ButtonColor.error
                    +"Turn my stove back on"
                }
            }
        }
    }

    val customerSupportContactCustomerSupport = FC {
        div {
            className = MobileStyles.contactCustomerServiceLinkOuter
            a {
                href = "https://fireavert.com/contact/"
                div {
                    className = MobileStyles.contactCustomerServiceLink
                    +"Contact Customer Support"
                }
            }
        }
    }

    fun getRandomABLink(): String {
        if (Random.nextBoolean()) {
            return "https://www.fireavert.com/safe-alerts"
        } else {
            return "https://www.fireavert.com/safety-alerts"
        }
    }

    val yourHuman = FC {
        div {
            className = MobileStyles.outerBlock
            div {
                className = MobileStyles.topRectBlack
                div {
                    className = MobileStyles.fireAvertLogo
                }
            }
            div {
                className = MobileStyles.stoveIdContainer

                stoveId {}
                customerSupportStoveIssues {}
            }
            div {
                className = MobileStyles.yourHumanContainer
                div {
                    className = MobileStyles.yourHuman
                    +"You're human"
                    div {
                        className = MobileStyles.andAccidentsHappen
                        +"and accidents happen"
                    }
                }
                div {
                    className = MobileStyles.phoneImage
                }
            }
            a {
                href = getRandomABLink()
                className = MobileStyles.turnOnSafetyAlertsButtonOuter
                div {
                    className = MobileStyles.turnOnSafetyAlertsText
                    +"Get safety alerts"
                }
            }
        }
    }

    val letsGetYouCooking = FC { ->
        div {
            className = MobileStyles.outerBlock
            div {
                className = MobileStyles.topRectBlack
                div {
                    className = MobileStyles.fireAvertLogo
                }
            }
            div {
                className = MobileStyles.letsGetYouBackToCooking
                +"Let's get you back to cooking"
            }
            div {
                className = MobileStyles.notice
                +"NOTICE:"
                br {}
                p {
                    className = MobileStyles.noticeP
                    +"Please check that all stove knobs are in the "
                    span {
                        className = MobileStyles.noticePSpan
                        +"OFF"
                    }
                    +" position"
                }
            }
            stoveId {}
            a {
                href = "#"
                onClick = {
                    submitFunction()
                }
                className = MobileStyles.turnStoveBackOnOuter
                div {
                    className = MobileStyles.turnStoveBackOnText
                    +"Turn Stove Back On"
                }
            }
            customerSupportContactCustomerSupport {}
            div {
                className = MobileStyles.bottomRect
                div {
                    className = MobileStyles.bottomRectRow
                    div {
                        a {
                            className = MobileStyles.bottomRectLinkBlack
                            href = "https://www.fireavert.com"
                            +"www.fireavert.com"
                        }
                    }
                    div {
                        +"©2024 FireAvert"
                    }
                }
            }
        }
    }

    if (deviceNotActive) {
        yourHuman {}
    } else {
        letsGetYouCooking {}
    }
}