package com.fireavert.invite.logic

import com.fireavert.auth.logic.UserAuthenticator
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.user.logic.UserRepository

class Invite(
    private val screen: InviteScreen,
    private val userRepository: UserRepository,
    private val authenticator: UserAuthenticator,
    private val logger: Logger,
) {

    fun passwordChanged(password: String) {
        screen.error = null
        screen.password = password
        if (password.length < 5) {
            screen.error = PasswordMinimumLengthException()
            return
        }
        if (screen.passwordCheck.isNotEmpty() && screen.passwordCheck != password) {
            screen.error = PasswordsDoNotMatchException()
            return
        }
    }

    fun passwordCheckChanged(passwordCheck: String) {
        screen.error = null
        screen.passwordCheck = passwordCheck
        if (passwordCheck.length < 5) {
            screen.error = PasswordMinimumLengthException()
            return
        }
        if (screen.password.isNotEmpty() && screen.password != passwordCheck) {
            screen.error = PasswordsDoNotMatchException()
            return
        }
    }

    suspend fun createNewPassword() {
        screen.error = null
        if (screen.password.isEmpty() || screen.passwordCheck.isEmpty()) {
            screen.error = PasswordMinimumLengthException()
            return
        }
        if (screen.password != screen.passwordCheck) {
            screen.error = PasswordsDoNotMatchException()
            return
        }

        val token = screen.token
        val userId = screen.userId

        val success = when (val maybe = userRepository.setPassword(
            token = token,
            userId = userId,
            password = screen.password
        )) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to set new password ${maybe.exception.message}")
                screen.error = maybe.exception
                return
            }
        }
        if (!success) {
            screen.error = Exception("Failed to set password")
        }

        authenticator.logout()
    }

    suspend fun tokenChanged(token: String) {
        screen.token = token
        val userId = when (val maybe = userRepository.getUserIdByInviteToken(token)) {
            is Try.Success -> maybe.value
            is Try.Error -> {
                logger.e("Failed to get user id for token!")
                screen.error = maybe.exception
                return
            }
        }
        screen.userId = userId
        screen.loading = false
    }

}