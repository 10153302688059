package com.fireavert.auth.external

import com.fireavert.auth.interface_adapters.ResetPasswordSenderSource
import com.fireavert.auth.logic.model.PasswordResetRequest
import com.fireavert.auth.logic.model.PasswordResetResponse
import com.fireavert.common.Try
import io.ktor.client.HttpClient
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.client.request.*

class HttpPasswordSenderSource(private val baseUrl: String, private val httpClient: HttpClient) :
    ResetPasswordSenderSource {
    override suspend fun send(request: PasswordResetRequest): Try<PasswordResetResponse> =
        Try.fromCoCallable {
            httpClient.post {
                url("$baseUrl/password_reset")
                contentType(ContentType.Application.Json)
                setBody(request)
            }.body()
        }
}