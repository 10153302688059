package com.fireavert.properties.presentation.delete_property_admin_dialog

import com.fireavert.properties.logic.models.PropertyAdminModel

data class DeletePropertyAdminDialogViewState(
    val isLoading: Boolean = false,
    val isDeleting: Boolean = false,
    val deletionAttempted: Boolean = false,
    val deletionFailed: Boolean = false,
    val loadFailed: Boolean = false,
    val propertyAdminModel: PropertyAdminModel? = null
)
