package com.fireavert.events.rebootdevice.logic

import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.events.common.logic.EventsNavigator
import com.fireavert.events.common.logic.EventsNavigator.Destination
import com.fireavert.logging.Logger

class ClientRebootDevice(
    private val deviceRepository: DeviceRepository,
    private val screen: RebootDeviceDialogScreen,
    private val eventsNavigator: EventsNavigator,
    private val logger: Logger
) : RebootDevice {
    override suspend fun rebootDevice(deviceId: Int) {
        screen.loading = true
        val success = when (val maybeSuccess = deviceRepository.rebootDevice(deviceId)) {
            is Error -> {
                logger.e("Failed to reboot device id $deviceId ${maybeSuccess.exception}")
                false
            }
            is Success -> maybeSuccess.value
        }
        logger.d("ClientRebootDevice Reboot was success = $success")
        screen.success = success
        screen.loading = false
    }

    override fun close(propertyId: Int) {
        eventsNavigator.navToDestination(Destination.PropertyDetails(propertyId))
    }
}