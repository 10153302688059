package com.fireavert.units.presentation

import com.fireavert.units.logic.EditUnit
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.units.logic.AddEditDevice

class EditUnitDialogController(
    private val editUnitInteractor: EditUnit,
    addEditDevice: AddEditDevice
): ClientUnitController(addEditDevice) {
    suspend fun onLoad(propertyId: Int, unitId: Int) = editUnitInteractor.onLoad(propertyId, unitId)
    fun clickedCancel(propertyId: Int) = editUnitInteractor.clickedCancel(propertyId)

    fun numberChanged(value: String) = editUnitInteractor.numberChanged(value)

    fun addressChanged(value: String) = editUnitInteractor.addressChanged(value)

    fun cityChanged(value: String) = editUnitInteractor.cityChanged(value)

    fun stateChanged(state: String) = editUnitInteractor.stateChanged(state)

    fun zipChanged(value: String) = editUnitInteractor.zipChanged(value)

    fun tenantNameChanged(value: String) = editUnitInteractor.tenantNameChanged(value)

    fun phoneChanged(value: String) = editUnitInteractor.phoneChanged(value)

    fun emailChanged(value: String) = editUnitInteractor.emailChanged(value)

    fun deleteDeviceAtIndex(index: Int) = addEditDevice.deleteDeviceAtIndex(index)

    fun clickedAddNewDevice() = addEditDevice.clickedAddNewDevice()

    suspend fun clickedSave(propertyId: Int, unitId: Int) = editUnitInteractor.clickedSave(propertyId, unitId)

    fun notifyStoveCurrentChanged(value: Boolean) = editUnitInteractor.notifyStoveCurrentChanged(value)
}