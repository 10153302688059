package com.fireavert.reports_page.logic

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.reports_page.frameworks.EventTypes
import kotlin.math.round


class ReportsPagesSectionInteractor(
    private val screen: ReportsPageScreen,
    private val propertyRepository: ClientPropertyRepository,
    private val reportsPageRepository: ReportsPageRepository,
    private val logger: Logger
) {

    suspend fun onLoad(propertyId: Int?, eventTypeValue: String, yearToGet: Int) {
        screen.loading = true
        screen.loadingYearlyEvents = true
        screen.loadingAllTimeEvents = true
        screen.loadingHighRiskTenants = true
        val properties = when(val maybe = propertyRepository.getProperties()) {
            is Try.Error -> {
                logger.e("Failed to get list of properties")
                screen.loading = false
                return
            }
            is Try.Success -> {

                maybe.value
            }
        }
        screen.propertyMap = mapOf("None" to -1, "All" to null) + properties.sortedBy { it.name }.associate { it.name to it.id }
        if (propertyId == -1) {
            screen.propertyId = -1
            screen.loading = false
            screen.loadingYearlyEvents = false
            screen.loadingAllTimeEvents = false
            screen.loadingHighRiskTenants = false
            return
        }
        updateData(propertyId, eventTypeValue)
        loadHighRiskTenantData(propertyId, listOf(EventTypes.SMOKE.toString(), EventTypes.TAMPER.toString(), EventTypes.LEAK.toString(), EventTypes.SHUTOFF.toString()))
        loadYearlyEventTrends(propertyId, yearToGet)
    }

    suspend fun updateData(propertyId: Int?, eventTypeValue: String) {
        screen.propertyId = propertyId
        screen.loading = true
        screen.loadingYearlyEvents = true
        screen.loadingAllTimeEvents = true
        screen.loadingHighRiskTenants = true


        val currentAllPropertyEventValue = when (val maybe = reportsPageRepository.getAllPropertiesAverageValue()) {
            is Try.Error -> {
                logger.e("Failed to get all properties average value ${maybe.exception}")
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        val eventsOverTimeCount = when (val maybe = reportsPageRepository.getPropertyEventsOverTime(propertyId)) {
            is Try.Error -> {
                logger.e("Failed to get events over time ${maybe.exception}")
                screen.loading = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        screen.yearStartedUsingFireAvert = eventsOverTimeCount.yearStarted
        screen.lastFiveYearClaims = eventsOverTimeCount.fireClaimData
        screen.allTimeAverageEventValue = (round(currentAllPropertyEventValue * 100) / 100)

        loadEventsAndActions(propertyId, eventTypeValue)
        screen.loading = false
    }

    suspend fun loadYearlyEventTrends(propertyId: Int?, yearToGet: Int) {
        screen.loadingYearlyEvents = true
        val yearlyEventsData =
            when (val maybe = reportsPageRepository.getYearlyEvents(propertyId, yearToGet)) {
                is Try.Error -> {
                    logger.e("Failed to get events and actions ${maybe.exception}")
                    screen.loadingYearlyEvents = false
                    return
                }
                is Try.Success -> {
                    maybe.value
                }
            }

        screen.thisYearSmokeEvents = yearlyEventsData.thisYearSmokeEvents
        screen.thisYearTamperEvents = yearlyEventsData.thisYearTamperEvents
        screen.thisYearLeakEvents = yearlyEventsData.thisYearLeakEvents
        screen.thisYearStoveShutoffEvents = yearlyEventsData.thisYearStoveShutoffEvents
        screen.thisYearOfflineEvents = yearlyEventsData.thisYearOfflineEvents
        screen.loadingYearlyEvents = false
    }

    suspend fun loadEventsAndActions(propertyId: Int?, eventTypeValue: String) {
        screen.loadingAllTimeEvents = true
        val eventsAndActions =
            when (val maybe = reportsPageRepository.getEventsAndActions(propertyId, eventTypeValue)) {
                is Try.Error -> {
                    logger.e("Failed to get events and actions ${maybe.exception}")
                    screen.loadingAllTimeEvents = false
                    return
                }
                is Try.Success -> {
                    maybe.value
                }
            }

        // Load yearly event trends separately, so we can also call the function from the UI

        screen.allTimeData = eventsAndActions.first
        screen.eventCount = eventsAndActions.second
        screen.loadingAllTimeEvents = false
    }

    suspend fun loadHighRiskTenantData(propertyId: Int?, eventTypeArray: List<String>) {
        screen.loadingHighRiskTenants = true
        val highRiskTenants = when (val maybe = reportsPageRepository.getHighRiskTenants(propertyId, eventTypeArray)) {
            is Try.Error -> {
                logger.e("Failed to get high risk tenants ${maybe.exception}")
                screen.loadingHighRiskTenants = false
                return
            }
            is Try.Success -> {
                maybe.value
            }
        }

        screen.highRiskTenants = highRiskTenants.highRiskTenants
        screen.averageEventsPerMonth = (round(highRiskTenants.averageEventsPerMonth * 100) / 100)
        screen.loadingHighRiskTenants = false
    }
}