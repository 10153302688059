package com.fireavert.invite.interface_adapters

data class InviteViewState(
    val password: String = "",
    val passwordCheck: String = "",
    val loading: Boolean = true,
    val error: Exception? = null,
    val userId: Int = 0,
    val token: String = "",
)
