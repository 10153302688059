package com.fireavert.menu.api

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.menu.logic.MenuNotificationRepository
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ApiMenuNotificationRepository(
    private val dataSource: MenuDataSource,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val preferences: Preferences
) : MenuNotificationRepository,
    ApiRepository(ioContext = ioContext, tokenRefreshService = tokenRefreshService) {
    override suspend fun getDashboardNotification(tryRefresh: Boolean): Try<Int> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getDashboardNotification(token = preferences.token)
                    .map { it.number ?: 0 }
            }
        }

    override suspend fun getPropertiesNotification(tryRefresh: Boolean): Try<Int> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getPropertiesNotification(token = preferences.token)
                    .map { it.number ?: 0 }
            }
        }
}