package com.fireavert.events.markasfalsealarm.logic

import com.fireavert.common.Try
import com.fireavert.events.common.logic.EventsRepository

interface OnLoadUseCase {
    suspend fun onLoad(eventId: Long): Try<Boolean>
}

class OnLoadUseCaseImpl(private val eventsRepository: EventsRepository): OnLoadUseCase {
    override suspend fun onLoad(eventId: Long): Try<Boolean> {
        return eventsRepository.markAsFalseAlert(eventId)
    }
}