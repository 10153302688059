package com.fireavert.administration_page.wireless_id_finder.logic

import com.fireavert.common.Try
import com.fireavert.logging.Logger

class WirelessIdSectionInteractor(
    private val screen: WirelessIdSectionScreen,
    private val repository: WirelessIdRepository,
    private val logger: Logger,
) {

    suspend fun loadWirelessIdData(deviceId: String) {
        val awsTitle = "https://us-west-2.console.aws.amazon.com/iot/home?region=us-west-2#/wireless/devices/details/"
        screen.wirelessDeviceId = ""
        if (!checkValidId(deviceId)) {
            return
        }

        try {
            val wirelessDevice = when (val maybe = repository.loadWirelessIdData(deviceId)) {
                is Try.Success -> maybe.value
                    is Try.Error -> {
                    logger.e("Error loading wireless device data", maybe.exception)
                    screen.wirelessDeviceId = "Error loading data (probably not found)"
                    return
                }
            }
            if (wirelessDevice.isEmpty()) {
                screen.wirelessDeviceId = "Device found, but no wireless Id has been set :("
                return
            }
        screen.wirelessDeviceId = awsTitle + wirelessDevice
        } catch (e: Exception) {
            logger.e("Error loading wireless device data", e)
            screen.wirelessDeviceId = "Error loading data"
        }
    }


    private fun checkValidId(deviceId: String): Boolean {
        if (deviceId.length != 7) {
            screen.wirelessDeviceId = "Invalid ID Length"
            return false
        }
        if (!Regex("^[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}\$").matches(deviceId)) {
            screen.wirelessDeviceId = "Invalid ID"
            return false
        }
        return true
    }
}