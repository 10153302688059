package com.fireavert.events.activeevents.presentation

import com.fireavert.common.ViewModel
import com.fireavert.events.activeevents.logic.ActiveEventsV2Screen
import com.fireavert.events.activeevents.domain.ActiveEventWithActions
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ActiveEventsV2ViewModel: ViewModel(), ActiveEventsV2Screen {
    private val _viewState = MutableStateFlow(ActiveEventsV2ViewState())
    val viewState = _viewState.asStateFlow()
    val scope = viewModelScope
    override var eventsWithActions: List<ActiveEventWithActions>
        get() {
            return viewState.value.eventsWithActions
        }
        set(value) {
            _viewState.value = viewState.value.copy(eventsWithActions = value)
        }
}