package com.fireavert.invite.interface_adapters

import com.fireavert.invite.logic.Invite

class InviteController(
    private val invite: Invite
) {
    suspend fun clickedCreateNewPassword() = invite.createNewPassword()
    fun passwordChanged(targetInputValue: String) = invite.passwordChanged(targetInputValue)
    fun passwordCheckChanged(targetInputValue: String) = invite.passwordCheckChanged(targetInputValue)
    suspend fun tokenChanged(token: String) = invite.tokenChanged(token)
}