package com.fireavert.properties.presentation.delete_property_dialog

import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.delete_property.DeletePropertyDialogScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class DeletePropertyDialogViewModel : ViewModel(), DeletePropertyDialogScreen {
    private val _viewState = MutableStateFlow(DeletePropertyDialogViewState())
    val viewState = _viewState.asStateFlow()

    override fun setIsDeleting(isDeleting: Boolean) {
        _viewState.value = _viewState.value.copy(isDeleting = isDeleting)
    }

    override val scope: CoroutineScope
        get() = viewModelScope
}