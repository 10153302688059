package com.fireavert.administration_page.user_tracking.interface_adapters

import com.fireavert.common.TableColumn
import com.fireavert.administration_page.user_tracking.logic.UserTrackingRecord
import com.fireavert.administration_page.user_tracking.logic.UserTrackingSectionInteractor

class UserTrackingSectionController(
    private val interactor: UserTrackingSectionInteractor,

    ) {
    suspend fun onLoad() = interactor.onLoad()

    fun sortTrackingRecordsByColumn(
        columns: Array<TableColumn>,
        trackingRecords: List<UserTrackingRecord>,
        searchValue: String
    ): List<UserTrackingRecord> = interactor.searchAndSortTrackingRecordsByColumn(columns, trackingRecords, searchValue)
}