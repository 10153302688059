package com.fireavert.gateways.interface_adaptors

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.gateways.frameworks.GatewayPropertyDataSource
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.gateways.models.request.GatewayDetailChangeRequest
import com.fireavert.gateways.models.request.GatewayImageDataRequest
import com.fireavert.gateways.models.request.GatewayImageUploadRequest
import com.fireavert.gateways.models.request.PropertyGatewayChangeRequest
import com.fireavert.gateways.models.response.GatewaysStatusResponse
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class GatewayPropertyRepositoryImpl(
    private val dataSource: GatewayPropertyDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
): GatewayPropertyRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun onLoad(propertyId: Int): Try<List<GatewayModel>> = withContext(ioContext) {
        callWithRefresh { dataSource.getAssociatedGateways(propertyId, preferences.token) }
            .map { it }
    }

    override suspend fun getAllGateways(): Try<List<GatewayModel>> {
        return callWithRefresh {
            dataSource.getAllGateways(preferences.token)
        }.map { it} }

    override suspend fun refreshGatewayStatus(propertyId: Int): Try<GatewaysStatusResponse> {
        return callWithRefresh { dataSource.refreshGatewayStatus(propertyId, preferences.token) }
            .map { it }
    }

    override suspend fun saveAssociatedGateways(newGateways: List<GatewayModel>, propertyId: Int): Try<Boolean> {
        val request = PropertyGatewayChangeRequest(propertyId, newGateways)
        return callWithRefresh {
            dataSource.saveAssociatedGateways(request, preferences.token)
        }.map { it }
    }

    override suspend fun updateGatewayDetails(
        gatewayName: String,
        gatewayDescription: String,
        gatewayId: String,
    ): Try<Boolean> {
        val request = GatewayDetailChangeRequest(gatewayName, gatewayDescription, gatewayId)
        return callWithRefresh {
            dataSource.updateGatewayDetails(request, preferences.token)
        }.map { it }
    }

    override suspend fun uploadGatewayImage(
        file: String,
        fileSize: Double,
        fileType: String,
        fileName: String,
        gatewayId: String
    ): Try<Boolean> {
        val request = GatewayImageUploadRequest(file, fileSize, fileType, fileName, gatewayId)
        return callWithRefresh {
            dataSource.uploadGatewayImage(request, preferences.token)
        }.map { it }
    }

    override suspend fun getGatewayImages(gatewayId: String, propertyId: Int): Try<List<String>> {
        val request = GatewayImageDataRequest(gatewayId, propertyId)
        return callWithRefresh {
            dataSource.getGatewayImages(request, preferences.token)
        }.map { it.imageList }
    }

    override suspend fun loadGatewayDeviceData(propertyId: String) = withContext(ioContext) {
        callWithRefresh { dataSource.loadGatewayDeviceData(propertyId, preferences.token) }
    }
}