package com.fireavert.styles

import web.cssom.*
import emotion.css.ClassName
import react.dom.html.ReactHTML.div

object SearchbarStyles {
    val searchBar = ClassName {
        fontSize = 16.px
        marginRight = Auto.auto
        marginLeft = 30.px
    }

    fun searchBarInput(widthSize: Int): ClassName =
        ClassName {
            div {
                borderRadius = important(30.px)
            }
            width = important(widthSize.rem)
        }

    fun searchBarInputNoButton(widthSize: Int): ClassName =
        ClassName {
            div {
                borderRadius = important(30.px)
            }
            marginRight = important(Auto.auto)
            marginLeft = important( 30.px)
            width = important(widthSize.rem)
        }

    var searchBarAndMobileTitle = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = AlignItems.center
        marginTop = 20.px
    }

}