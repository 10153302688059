package com.fireavert.properties.logic.models

import kotlinx.serialization.Serializable

@Serializable
data class FireClaimData(
    val propertyId: Int,
    val year: Int,
    val claimCount: Int,
    val cost: Double
)
