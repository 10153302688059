package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import emotion.css.css
import emotion.css.cx
import js.objects.jso
import react.dom.html.ReactHTML.a
import web.cssom.*
import web.cssom.LineStyle.Companion.solid

object EventsStylesV2 {
    val eventsTableContainer = ClassName {
        overflowY = Auto.auto
        overflowX = Overflow.hidden
        maxHeight = 300.px
        borderBottomLeftRadius = 15.px
        borderBottomRightRadius = 15.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            maxHeight = 30.vh
            borderRadius = 15.px
            maxWidth = 100.pct
            overflowX = Overflow.hidden
        }
    }

    val activeEventsTableTitle = ClassName {
        fontSize = 16.px
        color = Color("#C73035")
        fontWeight = FontWeight.bold
        marginTop = 20.px
        marginBottom = 20.px
    }

    val activeEventsTableTitleMobile = ClassName {
        fontSize = 22.px
        color = Color("black")
        fontWeight = FontWeight.bold
        marginTop = 20.px
        marginBottom = 20.px
    }
    val automatedActionsTakenIconSetContainer = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.center
        gap = 20.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            justifyContent = JustifyContent.spaceBetween
            padding = 15.px
            position = Position.relative
            "::after" {
                content = string("''")
                position = Position.absolute
                left = 50.pct
                width = 75.pct
                bottom = 0.px
                height = 1.px
                backgroundColor = Color("grey")
                transform = translatex((-50).pct)
            }
        }
    }
    val eventsStatusIconSetContainer = ClassName {
        display = Display.flex
        gap = 15.px
        justifyContent = JustifyContent.center
    }
    val eventsTableRowCell = ClassName {
        display = Display.tableCell
        borderTop = Border(1.px, solid, Color("#b8b8b8"))
        verticalAlign = VerticalAlign.middle
        padding = Padding(10.px, 10.px)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            width = 33.pct
            overflow = Overflow.hidden
            textOverflow = TextOverflow.ellipsis
            whiteSpace = WhiteSpace.nowrap
        }
    }
    val eventsTableRowCellLeftAlign = cx(eventsTableRowCell, ClassName {
        textAlign = TextAlign.left
        flexGrow = number(1.0)
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            textDecoration = TextDecoration.underline
        }
    })
    val eventsTableRow = ClassName {
        display = Display.tableRow
        textAlign = TextAlign.center
    }
    val eventsTableHeaderCell = ClassName {
        display = Display.tableCell
        textAlign = TextAlign.center
        verticalAlign = VerticalAlign.middle
        padding = Padding(5.px, 10.px)
    }
    val eventsTableHeaderCellLeftAlign = cx(eventsTableHeaderCell, ClassName {
        textAlign = TextAlign.left
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            color = NamedColor.black
            paddingBottom = 15.px
            paddingTop = 15.px
            fontSize = 14.px
            fontWeight = FontWeight.bold
        }
    })
    val eventsContainer = ClassName {
        marginTop = 50.px
        width = 1118.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            marginTop = 25.px
            width = 100.pct
        }
    }
    val eventsTable = ClassName {
        width = 100.pct
        display = Display.table
        color = Color("#939589")
        fontSize = 16.px
        backgroundColor = NamedColor.white
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            fontSize = 12.px
            borderRadius = 5.px
            tableLayout = TableLayout.fixed
            width = 100.pct
        }
    }
    val eventsTableHeader = ClassName {
        display = Display.tableRow
        backgroundColor = Color("#C73035")
        color = NamedColor.white
    }

    val eventsTableHeaderMobile = ClassName {
        position = Position.sticky
        top = 0.px
        zIndex = integer(2)
        backgroundColor = NamedColor.white
        display = Display.tableRow
        padding = 10.px
        borderBottom = Border(1.px, solid, Color("#e0e0e0"))
    }

    val rebootDeviceButton = ClassName {
        color = NamedColor.white
        textDecoration = None.none
        cursor = Cursor.pointer
        visited { color = NamedColor.white }
        a { color = NamedColor.white }
    }

    val notReadyToReboot = ClassName {
        color = NamedColor.darkgray
        textDecoration = None.none
        cursor = Cursor.notAllowed
    }

}
object EventsStyles {
    val eventIconContainer = ClassName {
        display = Display.flex
    }

    val tamperBackgroundColor = Color("rgb(185, 230, 224)")
    val tamperTextColor = Color("rgb(23, 172, 152)")

    val smokeBackgroundColor = Color("rgb(254, 211, 206)")
    val smokeTextColor = Color("rgb(214, 49, 31)")

    val shutoffBackgroundColor = Color("rgb(234, 210, 162)")
    val shutoffTextColor = Color("rgb(241, 130, 16)")

    val waterBackgroundColor = Color("rgb(194, 217, 246)")
    val waterTextColor = Color("rgb(53, 128, 225)")

    val offlineBackgroundColor = Color("rgb(254, 236, 202)")
    val offlineTextColor = Color("rgb(197, 135, 0)")

    val resetBackgroundColor = NamedColor.lightgreen
    val unknownBackgroundColor = NamedColor.lightgray

    val eventOval = ClassName {
        width = 89.px
        height = 19.px
        fontWeight = integer(600)
        color = Color("#ED5544")
        fontSize = 12.px
        position = Position.relative
        textAlign = TextAlign.center
        borderRadius = 9.px
    }

    val tamperText = ClassName {
        color = tamperTextColor
    }
    val smokeText = ClassName {
        color = smokeTextColor
    }
    val shutoffText = ClassName {
        color = shutoffTextColor
    }
    val waterText = ClassName {
        color = waterTextColor
    }
    val offlineText = ClassName {
        color = offlineTextColor
    }
    val tamperOval = cx(eventOval, tamperText, css(jso {
        backgroundColor = tamperBackgroundColor
    }))
    val smokeOval = cx(eventOval, smokeText, css(jso {
        backgroundColor = smokeBackgroundColor
    }))
    val shutoffOval = cx(eventOval, shutoffText, css(jso {
        backgroundColor = shutoffBackgroundColor
    }))
    val waterOval = cx(eventOval, waterText, css(jso {
        backgroundColor = waterBackgroundColor
    }))
    val resetOval = cx(eventOval, css(jso {
        color = NamedColor.darkgreen
        backgroundColor = resetBackgroundColor
    }))
    val offlineOval = cx(eventOval, offlineText, css(jso {
        backgroundColor = offlineBackgroundColor
    }))
    val unknownOval = cx(eventOval, css(jso {
        color = NamedColor.darkgray
        backgroundColor = unknownBackgroundColor
    }))
    val eventIcon = ClassName {
        width = 20.px
        height = 20.px
        marginLeft = 16.px
    }

    val eventTypeCircle = ClassName {
        width = 10.px
        height = 10.px
        borderRadius = 50.pct
        marginRight = 8.px
    }
}