package com.fireavert.user.api

import com.fireavert.user.logic.Role

object RoleMapper {
    fun mapFromRoleResponse(roleResponse: RoleResponse) : Role {
        return Role(
            id = roleResponse.id ?: 0,
            name = roleResponse.name ?: "",
            permissions = roleResponse.permissions?.map { it.toPermission() } ?: emptyList(),
            pageVersions = roleResponse.pageVersions
        )
    }
}

fun RoleResponse.toRole() : Role = RoleMapper.mapFromRoleResponse(this)