package com.fireavert.user.api

import kotlinx.serialization.Serializable

@Serializable
data class UserByTokenResponse(
    val id: Int? = 0,
    val firstName: String? = "",
    val lastName: String? = "",
    val email: String? = "",
    val phone: String? = "",
    val roles: List<RoleResponse>? = emptyList()
)