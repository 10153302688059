package com.fireavert.units.presentation

import com.fireavert.units.logic.AddNewUnit
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.units.logic.AddEditDevice

class AddNewUnitController(
    private val addNewUnit: AddNewUnit,
    addEditDevice: AddEditDevice,
) : ClientUnitController(addEditDevice) {
    suspend fun onLoad(propertyId: Int) = addNewUnit.onLoad(propertyId)

    fun clickedCancel(propertyId: Int) = addNewUnit.clickedCancel(propertyId)

    fun numberChanged(value: String) = addNewUnit.numberChanged(value)

    fun addressChanged(value: String) = addNewUnit.addressChanged(value)

    fun cityChanged(value: String) = addNewUnit.cityChanged(value)

    fun stateChanged(value: String) = addNewUnit.stateChanged(value)

    fun zipChanged(value: String) = addNewUnit.zipChanged(value)

    fun tenantNameChanged(value: String) = addNewUnit.tenantNameChanged(value)

    fun phoneChanged(value: String) = addNewUnit.phoneChanged(value)

    fun emailChanged(value: String) = addNewUnit.emailChanged(value)

    fun clickedAddNewDevice() = addEditDevice.addNewDevice()

    suspend fun clickedSave(propertyId: Int) = addNewUnit.save(propertyId)

    fun deleteDeviceAtIndex(index: Int) = addEditDevice.deleteDeviceAtIndex(index)
    fun notifyStoveCurrentChanged(value: Boolean) = addNewUnit.notifyStoveCurrentChanged(value)
}