package com.fireavert.units.presentation

import com.fireavert.common.ViewModel
import com.fireavert.devices.logic.models.UnitDetailsEventHistoryDataState
import com.fireavert.units.logic.UnitDetailsScreen
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class UnitDetailsViewModel : ViewModel(), UnitDetailsScreen {
    private val _viewState = MutableStateFlow(UnitDetailsViewState())
    val viewState = _viewState.asStateFlow()

    val scope = viewModelScope
    override fun removeEventHistoryItem(eventHistoryItem: UnitDetailsEventHistoryDataState) {
        val newEventHistory: MutableList<UnitDetailsEventHistoryData> = viewState.value.eventHistory.toMutableList()
        newEventHistory.removeAll { it.eventId == eventHistoryItem.eventId }
        _viewState.value = viewState.value.copy(eventHistory = newEventHistory)
    }

    override var isLoading: Boolean
        get() = viewState.value.isLoading
        set(value) {
            _viewState.value = _viewState.value.copy(isLoading = value)
        }
    override var unitNumber: String
        get() = viewState.value.unitNumber
        set(value) {
            _viewState.value = viewState.value.copy(unitNumber = value)
        }
    override var propertyName: String
        get() = viewState.value.propertyName
        set(value) {
            _viewState.value = viewState.value.copy(propertyName = value)
        }
    override var address: String
        get() = viewState.value.address
        set(value) {
            _viewState.value = viewState.value.copy(address = value)
        }
    override var city: String
        get() = viewState.value.city
        set(value) {
            _viewState.value = viewState.value.copy(city = value)
        }
    override var state: String
        get() = viewState.value.state
        set(value) {
            _viewState.value = viewState.value.copy(state = value)
        }
    override var zip: String
        get() = viewState.value.zip
        set(value) {
            _viewState.value = viewState.value.copy(zip = value)
        }
    override var tenantName: String
        get() = viewState.value.tenantName
        set(value) {
            _viewState.value = viewState.value.copy(tenantName = value)
        }
    override var tenantEmail: String
        get() = viewState.value.tenantEmail
        set(value) {
            _viewState.value = viewState.value.copy(tenantEmail = value)
        }
    override var tenantPhone: String
        get() = viewState.value.tenantPhone
        set(value) {
            _viewState.value = viewState.value.copy(tenantPhone = value)
        }
    override var totalAlarmEvents: Int
        get() = viewState.value.totalAlarmEvents
        set(value) {
            _viewState.value = viewState.value.copy(totalAlarmEvents = value)
        }
    override var totalShutoffEvents: Int
        get() = viewState.value.totalShutoffEvents
        set(value) {
            _viewState.value = viewState.value.copy(totalShutoffEvents = value)
        }
    override var totalLeakEvents: Int
        get() = viewState.value.totalLeakEvents
        set(value) {
            _viewState.value = viewState.value.copy(totalLeakEvents = value)
        }
    override var totalFlowEvents: Int
        get() = viewState.value.totalFlowEvents
        set(value) {
            _viewState.value = viewState.value.copy(totalFlowEvents = value)
        }
    override var totalTamperEvents: Int
        get() = viewState.value.totalTamperEvents
        set(value) {
            _viewState.value = viewState.value.copy(totalTamperEvents = value)
        }
    override var devices: List<UnitDetailsDeviceData>
        get() = viewState.value.devices
        set(value) {
            _viewState.value = viewState.value.copy(devices = value)
        }
    override var eventHistory: List<UnitDetailsEventHistoryData>
        get() = viewState.value.eventHistory
        set(value) {
            _viewState.value = viewState.value.copy(eventHistory = value)
        }
}