package com.fireavert.components.reset_password

import com.fireavert.components.common.NotLoggedIn
import com.fireavert.styles.AuthStyles
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.img
import web.html.ButtonType


external interface ResetPasswordSentPageProps: Props {
    var onSubmitCalled: () -> Unit
}

val ResetPasswordSentPage = FC<ResetPasswordSentPageProps> { props ->
    val submitFunction = {
        props.onSubmitCalled()
    }

    NotLoggedIn {}
    form {
        onSubmit = {
            it.preventDefault()
            submitFunction()
        }
        noValidate = true
        className = AuthStyles.loginForm
        div {
            className = AuthStyles.welcomeTitle
            +"Password Reset Submitted"
        }
        div {
            className = AuthStyles.formBody
            img {
                alt = "FireAvert"
                src = "/static/logo.svg"
                className = AuthStyles.logo
            }
            div {
                className = AuthStyles.controlFields
                div {
                    +"If the provided email address is linked to a FireAvert account, you will receive an email with a password reset link shortly."
                }

                Button {
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    type = ButtonType.submit
                    fullWidth = true
                    onClick = { submitFunction() }
                    className = AuthStyles.loginButton
                    +"Ok"
                }
            }
        }
    }
}