package com.fireavert.events.activedeviceevents.external

import com.fireavert.devices.logic.models.DeviceType.FireAvert
import com.fireavert.devices.logic.models.FireAvertStatus.Error
import com.fireavert.devices.logic.response.ActiveDeviceEventResponse
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvent
import com.fireavert.events.logic.models.EventType.Unknown
import kotlinx.datetime.Clock.System
import kotlinx.datetime.Instant

private object ActiveDeviceEventMapper {
    fun withActiveDeviceEventResponse(from: ActiveDeviceEventResponse): ActiveDeviceEvent {
        return ActiveDeviceEvent(
            id = from.id ?: 0,
            deviceId = from.deviceId ?: 0,
            timestamp = Instant.fromEpochSeconds(
                from.timestamp ?: System.now().epochSeconds,
                0
            ),
            unit = from.unit ?: "",
            unitId = from.unitId ?: 0,
            eventType = from.eventType ?: Unknown,
            alarmActive = from.alarmActive ?: false,
            deviceTriggered = from.deviceTriggered ?: false,
            deviceOffline = from.deviceOffline ?: false,
            fireAvertStatus = from.fireAvertStatus ?: Error,
            alarmEvents = from.alarmEvents ?: 0,
            shutoffEvents = from.shutoffEvents ?: 0,
            deviceType = from.deviceType ?: FireAvert,
            timezoneId = from.timezoneId ?: "",
        )
    }

    fun withActiveDeviceEvent(from: ActiveDeviceEvent): ActiveDeviceEventResponse {
        return ActiveDeviceEventResponse(
            id = from.id,
            deviceId = from.deviceId,
            timestamp = from.timestamp.epochSeconds,
            unit = from.unit,
            unitId = from.unitId,
            eventType = from.eventType,
            alarmActive = from.alarmActive,
            deviceTriggered = from.deviceTriggered,
            deviceOffline = from.deviceOffline,
            fireAvertStatus = from.fireAvertStatus,
            alarmEvents = from.alarmEvents,
            shutoffEvents = from.shutoffEvents,
            deviceType = from.deviceType,
            timezoneId = from.timezoneId,
        )
    }
}

fun ActiveDeviceEventResponse.toActiveDeviceEvent(): ActiveDeviceEvent =
    ActiveDeviceEventMapper.withActiveDeviceEventResponse(this)
fun ActiveDeviceEvent.toActiveDeviceEventResponse(): ActiveDeviceEventResponse =
    ActiveDeviceEventMapper.withActiveDeviceEvent(this)