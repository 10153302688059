package com.fireavert.components.active_events

import com.fireavert.actions.logic.EventLinkedActionType
import com.fireavert.events.activeevents.presentation.ActiveEventsV2Controller
import com.fireavert.events.activeevents.presentation.ActiveEventsV2ViewModel
import com.fireavert.events.activeevents.domain.ActiveEventWithActions
import com.fireavert.events.activeevents.domain.ActiveSensorType
import com.fireavert.events.logic.InstantTimeStringFormatter
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.styles.EventsStylesV2
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import react.*
import mui.material.Tooltip
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.router.dom.Link
import react.useEffectOnce
import react.useState
import web.cssom.Position
import web.cssom.integer
import web.cssom.px

external interface ActiveEventsV2Props: Props {
    var propertyId: Int?
    var isMobileVersion: Boolean
    var eventsBasedOnTime: Long
}

val ActiveEventsV2 = FC<ActiveEventsV2Props> { props ->
    val controller = getKoinInstance<ActiveEventsV2Controller>()
    val viewModel = getKoinInstance<ActiveEventsV2ViewModel>()

    var activeEvents: List<ActiveEventWithActions> by useState(emptyList())
    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            activeEvents = viewState.eventsWithActions
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(props.eventsBasedOnTime)
        }

        cleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    useEffect(props.eventsBasedOnTime) {
        val loadJob = viewModel.scope.launch {
            controller.onLoad(props.eventsBasedOnTime)
        }

        cleanup {
            loadJob.cancel()
        }
    }

    if (props.isMobileVersion) {
        div {
            ariaLabel = "Active Events Happening Now"
            className = EventsStylesV2.eventsContainer
            div {
                className = EventsStylesV2.activeEventsTableTitleMobile
                +"Recent Events"
            }
            div {
                className = EventsStylesV2.eventsTableContainer
                div {
                    className = EventsStylesV2.eventsTable
                    div {
                        className = EventsStylesV2.eventsTableHeaderMobile
                        div {
                            className = EventsStylesV2.eventsTableHeaderCellLeftAlign
                            +"Property"
                        }
                        div {
                            className = EventsStylesV2.eventsTableHeaderCellLeftAlign
                            +"Building - Unit #"
                        }
                        div {
                            className = EventsStylesV2.eventsTableHeaderCellLeftAlign
                            +"Active Event"
                        }
                    }
                    val activeEventsToUse = if (props.propertyId == null) {
                        activeEvents
                    } else {
                        activeEvents.filter { it.propertyId == props.propertyId }
                    }
                    activeEventsToUse.sortedBy {
                        it.eventTimestamp
                    }.reversed().forEach { event ->
                        ActiveEventsRowMobileV2 {
                            this.event = event
                        }
                    }
                }
            }
        }
    } else {
        div {
            ariaLabel = "Active Events Happening Now"
            className = EventsStylesV2.eventsContainer
            div {
                className = EventsStylesV2.activeEventsTableTitle
                +"Active Events Happening Now"
            }
            div {
                className = EventsStylesV2.eventsTableContainer
                div {
                    className = EventsStylesV2.eventsTable
                    div {
                        className = cx(EventsStylesV2.eventsTableHeader, ClassName {
                            position = Position.sticky
                            top = 0.px
                            zIndex = integer(2)
                        })
                        div {
                            className = EventsStylesV2.eventsTableHeaderCell
                            +"Date"
                        }
                        div {
                            className = EventsStylesV2.eventsTableHeaderCellLeftAlign
                            +"Property"
                        }
                        div {
                            className = EventsStylesV2.eventsTableHeaderCellLeftAlign
                            +"Building - Unit #"
                        }
                        div {
                            className = EventsStylesV2.eventsTableHeaderCell
                            +"Active Event"
                        }
                        div {
                            className = cx(EventsStylesV2.eventsTableHeaderCell, ClassName {
                                width = 230.px
                            })
                            +"Automated Actions Taken"
                        }
                        div {
                            className = cx(EventsStylesV2.eventsTableHeaderCell, ClassName {
                                width = 115.px
                            })
                            +"Force Action"
                        }
                    }
                    val activeEventsToUse = if (props.propertyId == null) {
                        activeEvents
                    } else {
                        activeEvents.filter { it.propertyId == props.propertyId }
                    }
                    activeEventsToUse.sortedBy {
                        it.eventTimestamp
                    }.reversed().forEach { event ->
                        ActiveEventsRowV2 {
                            this.event = event
                        }
                    }
                }
            }
        }
    }
}

external interface ActiveEventsRowV2Props : Props {
    var event: ActiveEventWithActions
}

val ActiveEventsRowV2 = FC<ActiveEventsRowV2Props> { props ->
    val preferences = getKoinInstance<Preferences>()

    div {
        className = EventsStylesV2.eventsTableRow
        div {
            className = EventsStylesV2.eventsTableRowCell
            +InstantTimeStringFormatter.formatC(
                Instant.fromEpochSeconds(props.event.eventTimestamp),
                preferences.timeZone
            )
        }
        div {
            className = EventsStylesV2.eventsTableRowCellLeftAlign
            Link {
                to = "${RoutePaths.properties}/${props.event.propertyId}"
                +props.event.propertyName
            }
        }
        div {
            className = EventsStylesV2.eventsTableRowCellLeftAlign
            Link {
                to = "${RoutePaths.properties}/${props.event.propertyId}/units/${props.event.unitId}"
                +props.event.unitNumber
            }
        }
        div {
            className = EventsStylesV2.eventsTableRowCell
            ActiveEventsStatusIconSet {
                activeSensors = props.event.activeSensors
            }
        }
        div {
            className = EventsStylesV2.eventsTableRowCell
            AutomatedActionsTakenIconSet {
                automatedActionsTaken = props.event.actionsTaken
            }
        }
        div {
            className = EventsStylesV2.eventsTableRowCell
            if (props.event.activeSensors.contains(ActiveSensorType.Shutoff) && !props.event.activeSensors.contains(
                    ActiveSensorType.Offline)) {
                span {
                    className = EventsStylesV2.rebootDeviceButton
                    Link {
                        to = "${RoutePaths.properties}/${props.event.propertyId}/reboot_device/${props.event.deviceId}"
                        Tooltip {
                            title = ReactNode("Reboot the FireAvert Pro device")
                            Button {
                                color = ButtonColor.error
                                variant = ButtonVariant.contained
                                +"Reboot"
                            }
                        }
                    }
                }
            }
            else {
                span {
                    className = EventsStylesV2.notReadyToReboot
                    +"Reboot"
                }
            }
        }
    }
}

external interface ActiveEventsV2StatusIconSetProps : Props {
    var activeSensors: Set<ActiveSensorType>
}

val ActiveEventsStatusIconSet = FC<ActiveEventsV2StatusIconSetProps> { props ->
    div {
        className = EventsStylesV2.eventsStatusIconSetContainer

        if (props.activeSensors.contains(ActiveSensorType.Smoke)) {
            Tooltip {
                title = ReactNode("A smoke event is happening!")
                img {
                    src = "/static/active_event_smoke_icon_active.svg"
                }
            }
        } else {
            img {
                src = "/static/active_event_smoke_icon.svg"
            }
        }

        if (props.activeSensors.contains(ActiveSensorType.Water)) {
            Tooltip {
                title = ReactNode("A water leak is happening!")
                img {
                    src = "/static/active_event_leak_icon_active.svg"
                }
            }
        } else {
            img {
                src = "/static/active_event_leak_icon.svg"
            }
        }

        if (props.activeSensors.contains(ActiveSensorType.Tamper)) {
            Tooltip {
                title = ReactNode("A smoke alarm is tampered with!")
                img {
                    src = "/static/active_event_tamper_icon_active.svg"
                }
            }
        } else {
            img {
                src = "/static/active_event_tamper_icon.svg"
            }
        }

        if (props.activeSensors.contains(ActiveSensorType.Offline)) {
            Tooltip {
                title = ReactNode("A device is offline!")
                img {
                    src = "/static/active_event_offline_icon_active.svg"
                }
            }
        } else {
            img {
                src = "/static/active_event_offline_icon.svg"
            }
        }
    }
}

external interface AutomatedActionsTakenIconSetProps : Props {
    var automatedActionsTaken: Set<EventLinkedActionType>
}

val AutomatedActionsTakenIconSet = FC<AutomatedActionsTakenIconSetProps> { props ->
    div {
        className = EventsStylesV2.automatedActionsTakenIconSetContainer
        if (props.automatedActionsTaken.contains(EventLinkedActionType.StoveShutoff)) {
            Tooltip {
                title = ReactNode("Stove shutoff action taken")
                img {
                    src = "/static/automated_actions_stove_shutoff_icon_active.svg"
                }
            }
        } else {
            img {
                src = "/static/automated_actions_stove_shutoff_icon.svg"
            }
        }
        if (props.automatedActionsTaken.contains(EventLinkedActionType.TextAlert)) {
            Tooltip {
                title = ReactNode("Message alert was sent out")
                img {
                    src = "/static/automated_actions_text_alerts_icon_active.svg"
                }
            }
        } else {
            img {
                src = "/static/automated_actions_text_alerts_icon.svg"
            }
        }
    }
}