package com.fireavert.dashboard.adapters

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.dashboard.logic.DashboardRepository
import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.events.logic.models.EventLinkedAction
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext
import kotlinx.datetime.LocalDate

class ApiDashboardRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val preferences: Preferences,
    private val dataSource: DashboardRepositoryDataSource,
    private val logger: Logger
) : DashboardRepository, ApiRepository(
    ioContext = ioContext,
    tokenRefreshService = tokenRefreshService,
) {
    override suspend fun getEventsAndActions(
        startDate: LocalDate,
        endDate: LocalDate,
        propertyId: Int?
    ): Try<Pair<List<DeviceEvent>, List<EventLinkedAction>>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getEventsAndActions(preferences.token, startDate, endDate, propertyId)
            }.map {
                it.events to it.actions
            }
        }
}