package com.fireavert.user.logic

data class Permission(
    val id: Int,
    val key: String,
    val name: String
) {
    companion object {
        const val ReadClients = "READ_CLIENTS"
        const val CreateClients = "CREATE_CLIENTS"
        const val ReadUsers = "READ_USERS"
        const val CreateUsers = "CREATE_USERS"
        const val ViewAllProperties = "VIEW_ALL_PROPERTIES"
        const val CreateProperty = "CREATE_PROPERTY"
        const val EditProperty = "EDIT_PROPERTY"
        const val DeleteProperty = "DELETE_PROPERTY"
        const val CreateDevices = "CREATE_DEVICES"
        const val EditDevice = "EDIT_DEVICE"
        const val DeleteDevice = "DELETE_DEVICE"
        const val CreateUnits = "CREATE_UNITS"
        const val EditUnits = "EDIT_UNITS"
        const val DeleteUnits = "DELETE_UNITS"
        const val DeletePropertyAdmin = "DELETE_PROPERTY_ADMIN"
        const val ModifyPropertyAdmin = "MODIFY_PROPERTY_ADMIN"
        const val CreatePropertyAdmin = "CREATE_PROPERTY_ADMIN"
    }
}
