package com.fireavert.components.dashboard

import com.fireavert.components.help.HelpDialog
import com.fireavert.styles.DashboardStylesV2
import com.fireavert.styles.DashboardStylesV2.mobileEventCardTitleOffline
import com.fireavert.styles.DashboardStylesV2.mobileEventCardTitleSmoke
import com.fireavert.styles.DashboardStylesV2.mobileEventCardTitleTamper
import com.fireavert.styles.DashboardStylesV2.mobileEventCardTitleToiletFlow
import com.fireavert.styles.DashboardStylesV2.mobileEventCardTitleWater
import com.fireavert.styles.HelpStyles
import mui.material.IconButton
import mui.material.Tooltip
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.window.WindowTarget

external interface EventCardProps : Props {
    var imageSrc: String
    var text: String
    var number: Int
    var linkText: String
    var linkHref: String
    var showLink: Boolean
    var isMobileVersion: Boolean
    var isToiletFlowMonitor: Boolean

}

val EventCard = FC<EventCardProps> { props ->
    if (props.isMobileVersion) {
        val titleClass = when (props.text) {
            "Smoke Events" -> mobileEventCardTitleSmoke
            "Water Events" -> mobileEventCardTitleWater
            "Tamper Events" -> mobileEventCardTitleTamper
            "Offline Events" -> mobileEventCardTitleOffline
            "Toilet Flow Monitor" -> mobileEventCardTitleToiletFlow
            else -> mobileEventCardTitleSmoke
        }
        div {
            className = DashboardStylesV2.eventCard
            div {
                className = titleClass
                div {
                    className = DashboardStylesV2.eventCardText
                    +props.text
                }
            }
            if (props.isToiletFlowMonitor) {
                div {
                    a {
                        href = props.linkHref
                        target = WindowTarget._blank
                        +props.linkText

                        className = DashboardStylesV2.eventCardNumber(props.isToiletFlowMonitor)
                    }
                }
            } else {
                div {
                    className = DashboardStylesV2.eventCardNumber(props.isToiletFlowMonitor)
                    +props.number.toString()
                }
            }
        }
    } else {
        div {
            className = DashboardStylesV2.eventCard
            Tooltip {
                title = HelpDialog.toolTipText(props.text)
                IconButton {
                    className = HelpStyles.helpIcon
                    img {
                        src = "/static/info_icon_grey.svg"
                        alt = "Info icon"
                    }
                }
            }
            img {
                className = DashboardStylesV2.eventCardImage
                src = props.imageSrc
            }
            div {
                className = DashboardStylesV2.eventCardText
                +props.text
            }
            div {
                className = DashboardStylesV2.eventCardNumber(props.isToiletFlowMonitor)
                if (props.isToiletFlowMonitor) {
                    +"-"
                } else {
                    +props.number.toString()
                }
            }
            if (props.showLink) {
                a {
                    className = DashboardStylesV2.eventCardLink(props.isToiletFlowMonitor)
                    href = props.linkHref
                    target = WindowTarget._blank
                    +props.linkText
                }
            }
        }
    }
}
