package com.fireavert.administration_page.user_tracking.logic

import kotlinx.datetime.Instant

data class UserTrackingRecord(
    val name: String,
    val email: String,
    val phone: String,
    val managementCompany: String,
    val userVisitLog: Map<Instant?,String?>
)
