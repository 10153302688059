package com.fireavert.components.dialogs

import com.fireavert.components.common.Loading
import com.fireavert.components.common.WarningIcon
import com.fireavert.properties.presentation.delete_property_dialog.DeletePropertyDialogController
import com.fireavert.properties.presentation.delete_property_dialog.DeletePropertyDialogViewModel
import com.fireavert.styles.DialogStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.router.useParams
import react.useEffectOnce
import react.useState
import web.cssom.*


val DeletePropertyDialog = FC {
    var isWorking: Boolean by useState(false)
    val viewModel = getKoinInstance<DeletePropertyDialogViewModel>()
    val controller = getKoinInstance<DeletePropertyDialogController>()
    val propertyId = useParams()["propertyId"]?.toInt() ?: 0

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            isWorking = viewState.isDeleting
        }.launchIn(viewModel.scope)

        cleanup {
            viewStateJob.cancel()
        }
    }

    if (isWorking) {
        Loading {}
    } else {
        Dialog {
            open = true
            onBackdropClick = { controller.clickedCancel() }
            onClose = { _, _ -> controller.clickedCancel() }
            maxWidth = Breakpoint.md
            DialogContent {
                className = DialogStyles.warningDialogContent
                WarningIcon {}
                div {
                    className = DialogStyles.warningText
                    +"You are about to delete a FireAvert Property."
                }
                div {
                    className = DialogStyles.warningText
                    +"This will also delete any devices attached to this property."
                }
                div {
                    className = DialogStyles.warningBold
                    +"Are you sure you want to proceed?"
                }
                DialogActions {
                    css {
                        height = 94.px
                        width = 500.px
                        padding = 24.px
                    }
                    Button {
                        +"No, keep property"
                        color = ButtonColor.inherit
                        onClick = { controller.clickedCancel() }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            marginRight = 6.px
                            backgroundColor = NamedColor.white
                        }
                    }
                    Button {
                        +"Yes, proceed to delete"
                        color = ButtonColor.inherit
                        onClick = { controller.clickedDeleteProperty(propertyId) }
                        variant = ButtonVariant.contained
                        css {
                            width = 400.px
                            backgroundColor = NamedColor.white
                        }
                    }

                }
            }

        }
    }
}