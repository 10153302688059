package com.fireavert.units.api

import com.fireavert.units.logic.models.UnitModel
import com.fireavert.units.logic.models.UnitStatus
import com.fireavert.units.logic.models.response.GetUnitsByPropertyIdResponse
import com.fireavert.units.logic.models.response.UnitResponse

private object UnitsMapper {
    fun withGetUnitsByPropertyIdResponse(from: GetUnitsByPropertyIdResponse): List<UnitModel> {
        return from.unitModels?.map { withUnitResponse(it) } ?: emptyList()
    }

    fun withUnitModels(from: List<UnitModel>): GetUnitsByPropertyIdResponse {
        return GetUnitsByPropertyIdResponse(unitModels = from.map { withUnitModel(it) })
    }

    fun withUnitResponse(from: UnitResponse): UnitModel {
        return UnitModel(
            id = from.id ?: -1,
            propertyName = from.propertyName ?: "",
            number = from.number ?: "",
            tenantName = from.tenantName ?: "",
            tenantEmail = from.tenantEmail ?: "",
            tenantPhone = from.tenantPhone ?: "",
            propertyId = from.propertyId ?: 0,
            streetAddress = from.streetAddress ?: "",
            city = from.city ?: "",
            state = from.state ?: "",
            zip = from.zip ?: "",
            status = from.status ?: UnitStatus.Error,
            alarmEventCount = from.alarmEventCount ?: 0,
            shutoffEventCount = from.shutoffEventCount ?: 0,
            leakEventCount = from.leakEventCount ?: 0,
            toiletFlowEventCount = from.toiletFlowEventCount ?: 0,
            tamperEventCount = from.tamperEventCount ?: 0,
            activeAlarm = from.activeAlarm ?: false,
            activeFireAvert = from.activeFireAvert ?: false,
            activeLeakSensor = from.activeLeakSensor ?: false,
            activeToiletFlowSensor = from.activeToiletFlowSensor ?: false,
            activeTamperSensor = from.activeTamperSensor ?: false,
            offlineAlarm = from.offlineAlarm ?: false,
            offlineFireAvert = from.offlineFireAvert ?: false,
            offlineLeakSensor = from.offlineLeakSensor ?: false,
            offlineToiletFlowSensor = from.offlineToiletFlowSensor ?: false,
            offlineTamperSensor = from.offlineTamperSensor ?: false,
            notifyStoveCurrent = from.notifyStoveCurrent,
            deviceNotInstalled = from.deviceNotInstalled,
        )
    }

    fun withUnitModel(from: UnitModel): UnitResponse {
        return UnitResponse(
            id = from.id,
            propertyName = from.propertyName,
            number = from.number,
            tenantName = from.tenantName,
            tenantEmail = from.tenantEmail,
            tenantPhone = from.tenantPhone,
            propertyId = from.propertyId,
            streetAddress = from.streetAddress,
            city = from.city,
            state = from.state,
            zip = from.zip,
            status = from.status,
            alarmEventCount = from.alarmEventCount,
            leakEventCount = from.leakEventCount,
            toiletFlowEventCount = from.toiletFlowEventCount,
            shutoffEventCount = from.shutoffEventCount,
            tamperEventCount = from.tamperEventCount,
            activeAlarm = from.activeAlarm,
            activeFireAvert = from.activeFireAvert,
            activeLeakSensor = from.activeLeakSensor,
            activeToiletFlowSensor = from.activeToiletFlowSensor,
            activeTamperSensor = from.activeTamperSensor,
            offlineAlarm = from.offlineAlarm,
            offlineFireAvert = from.offlineFireAvert,
            offlineLeakSensor = from.offlineLeakSensor,
            offlineToiletFlowSensor = from.offlineToiletFlowSensor,
            offlineTamperSensor = from.offlineTamperSensor,
            notifyStoveCurrent = from.notifyStoveCurrent,
            deviceNotInstalled = from.deviceNotInstalled,
        )
    }
}

fun List<UnitModel>.toGetUnitsByPropertyIdResponse(): GetUnitsByPropertyIdResponse =
    UnitsMapper.withUnitModels(this)

fun GetUnitsByPropertyIdResponse.toUnitsList(): List<UnitModel> =
    UnitsMapper.withGetUnitsByPropertyIdResponse(this)

fun UnitResponse.toUnitModel(): UnitModel = UnitsMapper.withUnitResponse(this)
fun UnitModel.toUnitResponse(): UnitResponse = UnitsMapper.withUnitModel(this)