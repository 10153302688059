package com.fireavert.units.presentation

import com.fireavert.units.logic.DeleteUnit

class DeleteUnitDialogController(
    private val interactor: DeleteUnit
) {
    fun clickedCancel(propertyId: Int) = interactor.cancel(propertyId)

    suspend fun clickedDeleteUnit(unitId: Int, propertyId: Int) = interactor.deleteUnit(unitId, propertyId)
}