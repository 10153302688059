package com.fireavert.events.activeevents.presentation

import com.fireavert.events.activeevents.domain.ActiveEvent

data class ActiveEventsViewState(
    val events: List<ActiveEvent> = emptyList(),
    val loading: Boolean = true,
    val alarmOnDeviceMap: Map<Int, Boolean> = emptyMap()
)

