package com.fireavert.components.properties

import com.fireavert.common.TableColumn
import com.fireavert.components.common.ThreeDotsButton
import com.fireavert.components.events.UnitTotalCell
import com.fireavert.components.help.HelpDialog
import com.fireavert.components.icons.AddIcon
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.EventsStyles
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.TableStyles
import com.fireavert.units.logic.models.UnitModel
import com.fireavert.units.logic.models.UnitStatus
import com.fireavert.units.presentation.UnitsSectionController
import com.fireavert.units.presentation.UnitsSectionViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.css.cx
import emotion.react.css
import io.ktor.http.*
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import react.*
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.router.dom.Link
import web.cssom.Color
import web.cssom.important
import web.cssom.px
import web.html.HTMLInputElement

external interface ListUnitsAndDevicesProps : Props {
    var propertyId: Int
    var propertyTotalUnitCount: Int
    var queryString: String
    var isMobileVersion: Boolean
}

external interface ListUnitAndDevicesViewProps: ListUnitsAndDevicesProps {
    var propUnitModels: List<UnitModel>
    var propShowAddUnit: Boolean
    var propController: UnitsSectionController
    var propIsLoading: Boolean
    var propShowDeleteUnit: Boolean

}

val ListUnitsAndDevices = FC<ListUnitsAndDevicesProps> { props ->

    var unitModels: List<UnitModel> by useState(emptyList())
    var loading: Boolean by useState(false)
    var showAddUnit: Boolean by useState(false)
    var showEditUnit: Boolean by useState(false)
    var showDeleteUnit: Boolean by useState(false)

    val controller = getKoinInstance<UnitsSectionController>()
    val viewModel = getKoinInstance<UnitsSectionViewModel>()
    val logger = getKoinInstance<Logger>()

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            unitModels = viewState.unitModels
            loading = viewState.loading
            showAddUnit = viewState.showAddUnit
            showEditUnit = viewState.showEditUnit
            showDeleteUnit = viewState.showDeleteUnit
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(props.propertyId)
        }

        parseQueryString(props.queryString)

        cleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }
    if (!props.isMobileVersion) {
        DesktopListUnitsAndDevices {
            propertyId = props.propertyId
            propertyTotalUnitCount = props.propertyTotalUnitCount
            queryString = props.queryString
            propUnitModels = unitModels
            propShowAddUnit = showAddUnit
            propController = controller
            propIsLoading = loading
            propShowDeleteUnit = showDeleteUnit

        }
    } else {
        MobileListUnitsAndDevices {
            propertyId = props.propertyId
            propertyTotalUnitCount = props.propertyTotalUnitCount
            queryString = props.queryString
            propUnitModels = unitModels
            propShowAddUnit = showAddUnit
            propController = controller
            propIsLoading = loading
            propShowDeleteUnit = showDeleteUnit
        }
    }
}

val DesktopListUnitsAndDevices = FC<ListUnitAndDevicesViewProps> { props ->
    val (searchValue, setSearchValue) = useState("")
    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "UNIT",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "CONNECTION STATUS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "SMOKE EVENTS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "STOVE SHUTOFFS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "LEAK EVENTS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "TAMPER EVENTS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            )
        )
    )

    val result = parseQueryString(props.queryString)
    if (result.contains("sortOffline")) {
        val newColumns = stateColumns.copyOf()
        newColumns[0] = stateColumns[0].copy(sortOrder = TableColumn.SortOrder.Neutral)
        newColumns[1] = stateColumns[1].copy(sortOrder = TableColumn.SortOrder.LowToHigh)
        stateColumns = newColumns
    }

    div {
        className = TableStyles.tableContainer
        div {
            className = TableStyles.tableTitle
            val totalUnits = props.propertyTotalUnitCount
            val numberOfUnits = props.propUnitModels.size
            val displayedUnitRatio = "$numberOfUnits / $totalUnits"
            val offlineUnitRatio = props.propUnitModels.count { it.status == UnitStatus.Offline }

            +"Total units installed: $displayedUnitRatio"
            br {}
            +"Units with offline sensors: $offlineUnitRatio"
            br{}
            +"Units with never installed sensors: ${props.propUnitModels.count { it.status == UnitStatus.NeverInstalled }}"
            div {
                className = SearchbarStyles.searchBar
                TextField {
                    className = SearchbarStyles.searchBarInput(20)
                    placeholder = "Search unit..."
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }
            div {
                className = TableStyles.tableTopButtonContainer
                if (props.propShowAddUnit) {
                    Button {
                        color = ButtonColor.secondary
                        variant = ButtonVariant.contained
                        onClick = { props.propController.clickedCommV2Conversion(propertyId = props.propertyId) }
                        css {
                            color = important(Color("#ffffff"))
                            marginRight = important(10.px)
                        }
                        +"Comm V2 Conversion"
                    }
                    Button {
                        color = ButtonColor.primary
                        variant = ButtonVariant.contained
                        startIcon = AddIcon.create()
                        onClick = { props.propController.clickedAddNewUnitCSV(propertyId = props.propertyId) }
                        this.css {
                            marginRight = important(10.px)
                        }
                        +"Upload New Units CSV"
                    }
                    Button {
                        color = ButtonColor.primary
                        variant = ButtonVariant.contained
                        startIcon = AddIcon.create()
                        onClick = { props.propController.clickedAddNewUnit(propertyId = props.propertyId) }
                        +"Add New Unit"
                    }
                }
            }
        }
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(stateColumns)
                includeLoading = true
                isLoading = props.propIsLoading
                columnsChanged = {
                    stateColumns = mapToStateColumns(it)
                }
            }
            val sortedUnitModels =
                props.propController.sortWithColumns(stateColumns, props.propUnitModels.toTypedArray(), searchValue)
            sortedUnitModels.forEach { unitModel ->
                div {
                    className = TableStyles.tableRow
                    div {
                        className = TableStyles.tableCell
                        Link {
                            to = "${RoutePaths.properties}/${props.propertyId}/units/${unitModel.id}"
                            +unitModel.number
                        }
                    }
                    div {
                        className = when (unitModel.status) {
                            UnitStatus.Online -> cx(TableStyles.tableCell, TableStyles.greenBold)
                            UnitStatus.Offline -> cx(TableStyles.tableCell, TableStyles.yellowBold)
                            UnitStatus.Error -> cx(TableStyles.tableCell, TableStyles.redBold)
                            UnitStatus.NeverInstalled -> cx(TableStyles.tableCell, TableStyles.greyBold)
                        }
                        Tooltip {
                            title = HelpDialog.toolTipText(unitModel.status.toString())
                            +when (unitModel.status) {
                                UnitStatus.Online -> "Online"
                                UnitStatus.Offline -> "Offline"
                                UnitStatus.Error -> "Error"
                                UnitStatus.NeverInstalled -> "Never Installed"
                            }
                        }
                    }
                    UnitTotalCell {
                        hasEvents = unitModel.alarmEventCount > 0
                        style = EventsStyles.smokeText
                        isOffline = unitModel.offlineAlarm
                        isActive = unitModel.activeAlarm
                        activeIcon = "/static/active_fireavert_icon.svg"
                        isInstalled = unitModel.deviceNotInstalled?.contains(DeviceType.FireAvert) == false
                        text = props.propController.stringOutputForCount(unitModel.alarmEventCount)
                    }

                    UnitTotalCell {
                        hasEvents = unitModel.shutoffEventCount > 0
                        style = EventsStyles.shutoffText
                        isOffline = unitModel.offlineFireAvert
                        isActive = unitModel.activeFireAvert
                        activeIcon = "/static/active_fireavert_icon.svg"
                        isInstalled = (unitModel.deviceNotInstalled?.contains(DeviceType.FireAvert) == false) && (unitModel.deviceNotInstalled?.contains(DeviceType.FireAvertGas) == false)
                        text = props.propController.stringOutputForCount(unitModel.shutoffEventCount)
                    }

                    UnitTotalCell {
                        hasEvents = unitModel.leakEventCount > 0
                        style = EventsStyles.waterText
                        isOffline = unitModel.offlineLeakSensor
                        isActive = unitModel.activeLeakSensor
                        activeIcon = "/static/active_water_icon.svg"
                        isInstalled = unitModel.deviceNotInstalled?.contains(DeviceType.WaterSensor) == false
                        text = props.propController.stringOutputForCount(unitModel.leakEventCount)
                    }

                    UnitTotalCell {
                        hasEvents = unitModel.tamperEventCount > 0
                        style = EventsStyles.tamperText
                        isOffline = unitModel.offlineTamperSensor
                        isActive = unitModel.activeTamperSensor
                        activeIcon = "/static/active_tamper_icon.svg"
                        isInstalled = unitModel.deviceNotInstalled?.contains(DeviceType.TamperSensor) == false
                        text = props.propController.stringOutputForCount(unitModel.tamperEventCount)
                    }

                    div {
                        className = TableStyles.tableCell
                        ThreeDotsButton {
                            val unitPath = "${RoutePaths.properties}/${props.propertyId}/units/${unitModel.id}"
                            val menuItems = mutableListOf(
                                unitPath to "View Unit Detail"
                            )
                            if (props.propShowAddUnit) {
                                menuItems.add(
                                    "$unitPath/edit" to "Edit Unit"
                                )
                            }
                            if (props.propShowDeleteUnit) {
                                menuItems.add(
                                    "$unitPath/delete" to "Delete Unit"
                                )
                            }
                            pathAndTextPairs = menuItems.toTypedArray()
                        }
                    }
                }
            }
        }
    }
}

val MobileListUnitsAndDevices = FC<ListUnitAndDevicesViewProps> { props ->
    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "Units",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "Smoke",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "Stove",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "Leak",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "Tamper",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            )
        )
    )

    val result = parseQueryString(props.queryString)
    if (result.contains("sortOffline")) {
        val newColumns = stateColumns.copyOf()
        newColumns[0] = stateColumns[0].copy(sortOrder = TableColumn.SortOrder.Neutral)
        newColumns[1] = stateColumns[1].copy(sortOrder = TableColumn.SortOrder.LowToHigh)
        stateColumns = newColumns
    }

    div {
        className = TableStyles.mobileTableTitleUnitList
        val totalUnits = props.propertyTotalUnitCount
        val numberOfUnits = props.propUnitModels.size
        val displayedUnitRatio = "$numberOfUnits / $totalUnits"
        val offlineUnitRatio = props.propUnitModels.count { it.status == UnitStatus.Offline }

        +"Total Units Installed: "
        div {
            css {
                fontSize = 14.px
            }
            +displayedUnitRatio
            br {}
            +"Units with offline units: $offlineUnitRatio"
        }
    }
    div {
        className = TableStyles.tableContainer
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(stateColumns)
                includeLoading = false
                isLoading = props.propIsLoading
                columnsChanged = {
                    stateColumns = mapToStateColumns(it)
                }
            }
            val sortedUnitModels =
                props.propController.sortWithColumns(stateColumns, props.propUnitModels.toTypedArray(), "")
            sortedUnitModels.forEach { unitModel ->
                div {
                    className = when (unitModel.status) {
                        UnitStatus.Offline -> TableStyles.tableRowWithConnectionStatus(Color("#FECA57"))
                        UnitStatus.Error -> TableStyles.tableRowWithConnectionStatus(Color("black"))
                        UnitStatus.Online -> TableStyles.tableRowWithConnectionStatus(Color("black"))
                        UnitStatus.NeverInstalled -> TableStyles.tableRowWithConnectionStatus(Color("black"))
                    }
                    div {
                        className = TableStyles.tableCell
                        Link {
                            to = "${RoutePaths.properties}/${props.propertyId}/units/${unitModel.id}"
                            css {
                                borderTopLeftRadius = 8.px
                                borderTopRightRadius = 8.px
                                borderBottomLeftRadius = 8.px
                                borderBottomRightRadius = 8.px
                                padding = 5.px
                            }
                            +unitModel.number
                        }
                    }
                    UnitTotalCell {
                        hasEvents = unitModel.alarmEventCount > 0
                        style = EventsStyles.smokeText
                        isOffline = unitModel.offlineAlarm
                        isActive = unitModel.activeAlarm
                        activeIcon = "/static/active_offline_icon.svg"
                        text = props.propController.stringOutputForCount(unitModel.alarmEventCount)
                    }

                    UnitTotalCell {
                        hasEvents = unitModel.shutoffEventCount > 0
                        style = EventsStyles.shutoffText
                        isOffline = unitModel.offlineFireAvert
                        isActive = unitModel.activeFireAvert
                        activeIcon = "/static/active_fireavert_icon.svg"
                        text = props.propController.stringOutputForCount(unitModel.shutoffEventCount)
                    }

                    UnitTotalCell {
                        hasEvents = unitModel.leakEventCount > 0
                        style = EventsStyles.waterText
                        isOffline = unitModel.offlineLeakSensor
                        isActive = unitModel.activeLeakSensor
                        activeIcon = "/static/active_water_icon.svg"
                        text = props.propController.stringOutputForCount(unitModel.leakEventCount)
                    }

                    UnitTotalCell {
                        hasEvents = unitModel.tamperEventCount > 0
                        style = EventsStyles.tamperText
                        isOffline = unitModel.offlineTamperSensor
                        isActive = unitModel.activeTamperSensor
                        activeIcon = "/static/active_tamper_icon.svg"
                        text = props.propController.stringOutputForCount(unitModel.tamperEventCount)
                    }

//                    div {
//                        className = TableStyles.tableCell
//                        img {
//                            src = "/static/mobile_arrow_black.svg"
//                        }
//                    }
                }
            }
        }
    }
}

