package com.fireavert.common

import com.fireavert.common.TableColumn.SortOrder.HighToLow
import com.fireavert.common.TableColumn.SortOrder.LowToHigh
import com.fireavert.common.TableColumn.SortOrder.Neutral
import kotlin.js.JsExport

data class TableColumn(
    val text: String,
    val sortOrder: SortOrder,
    val alignment : Alignment,
    val enableSort : Boolean = true,
) {
    sealed class Alignment {
        object Left: Alignment()
        object Right: Alignment()
        object Center: Alignment()
    }
    sealed class SortOrder {
        object HighToLow: SortOrder()
        object LowToHigh: SortOrder()
        object Neutral: SortOrder()
    }

    companion object {
        fun <T, R : Comparable<R>> sortList(
            reverse: Boolean,
            sortColumn: TableColumn,
            eventsToSort: List<T>,
            sortFunc: (T) -> R
        ): List<T> {
            return when (sortColumn.sortOrder) {
                HighToLow -> if (!reverse) eventsToSort.sortedByDescending(sortFunc) else eventsToSort.sortedBy(
                    sortFunc
                )
                LowToHigh -> if (!reverse) eventsToSort.sortedBy(sortFunc) else eventsToSort.sortedByDescending(
                    sortFunc
                )
                Neutral -> eventsToSort
            }
        }
    }
}
