package com.fireavert.auth.interface_adapters

import com.fireavert.auth.logic.AuthNavigator
import com.fireavert.auth.logic.AuthNavigator.Destination.Dashboard
import com.fireavert.auth.logic.AuthNavigator.Destination.Login
import com.fireavert.auth.logic.AuthNavigator.Destination.ResetPasswordSent

class RoutedAuthNavigator(private val authRouter: AuthRouter) : AuthNavigator {
    override fun navigate(destination: AuthNavigator.Destination) {
        when (destination) {
            Dashboard -> authRouter.routeToDashboard()
            Login -> authRouter.routeToLogin()
            ResetPasswordSent -> authRouter.routeToResetPasswordSent()
        }
    }
}