package com.fireavert.events.activeevents.api

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.events.activedeviceevents.external.toActiveDeviceEvent
import com.fireavert.events.activeevents.logic.ActiveEventsRepository
import com.fireavert.events.activeevents.PropertyWithActiveEventsResponse
import com.fireavert.events.activeevents.domain.ActiveEventWithActions
import com.fireavert.events.logic.models.PropertyWithActiveEvents
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.api.toProperty
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ApiActiveEventsRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val preferences: Preferences,
    private val activeEventsDataSource: ActiveEventsDataSource
) : ApiRepository(ioContext, tokenRefreshService), ActiveEventsRepository {
    override suspend fun getActiveEventsForAllProperties(): Try<List<PropertyWithActiveEvents>> =
        withContext(ioContext) {
            callWithRefresh { activeEventsDataSource.getActiveEventsForAllProperties(preferences.token) }
                .map {
                    it.propertiesWithEvents?.map { propertyWithActiveEventsResponse ->
                        propertyWithActiveEvents(from = propertyWithActiveEventsResponse)
                    } ?: emptyList()
                }
        }

    override suspend fun getActiveEventsForProperty(propertyId: Int): Try<PropertyWithActiveEvents> =
        withContext(ioContext) {
            callWithRefresh { activeEventsDataSource.getActiveEventsForProperty(propertyId, preferences.token) }
                .map {
                    propertyWithActiveEvents(from = it.propertyWithActiveEventsResponse)
                }
        }

    override suspend fun getActiveEventsWithActions(dateRange: Long): Try<List<ActiveEventWithActions>> =
        withContext(ioContext) {
            callWithRefresh { activeEventsDataSource.getActiveEventsWithActions(dateRange, preferences.token) }
                .map { it.activeEventWithActions }
        }

    private fun propertyWithActiveEvents(from: PropertyWithActiveEventsResponse): PropertyWithActiveEvents {
        return PropertyWithActiveEvents(
            property = from.property?.toProperty()
                ?: throw Exception("Missing property from response $from"),
            activeDeviceEvents = from.activeDeviceEvents
                ?.map { activeDeviceEventResponse ->
                    activeDeviceEventResponse.toActiveDeviceEvent()
                } ?: emptyList(),
            alarmActiveDeviceMap = from.alarmActiveDeviceMap
                ?: emptyMap()
        )
    }
}