package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.*

object AuthStyles {
    val errorMessage = ClassName {
        color = NamedColor.red
        fontWeight = FontWeight.bold
    }
    val loginForm = ClassName {
        position = Position.fixed
        top = 50.pct
        left = 50.pct
        transform = translate((-50).pct, (-50).pct)
        width = 455.px
    }
    val welcomeTitle = ClassName {
        fontSize = 40.px
        color = NamedColor.white
        textAlign = TextAlign.center
    }
    val formBody = ClassName {
        paddingTop = 41.px
        paddingBottom = 41.px
        backgroundColor = Color("#F4F5F7")
        borderRadius = 4.px
        marginTop = 15.px
    }
    val logo = ClassName {
        marginLeft = Auto.auto
        marginRight = Auto.auto
        marginBottom = 25.px
        display = Display.block
        width = 233.px
    }
    val controlFields = ClassName {
        marginLeft = 56.px
        marginRight = 56.px
    }
    val textField = ClassName {
        backgroundColor = NamedColor.white
        border = None.none
        marginTop = 0.px
        marginBottom = 16.px
    }
    val linkWrappingDiv = ClassName {
        paddingTop = 10.px
        paddingBottom = 15.px
    }
    val forgotPasswordLink = ClassName {
        color = Color("#0777C8")
        textDecoration = None.none
    }
    val loginButton = ClassName {
        marginTop = important(18.px)
        height = 40.px
    }
}