package com.fireavert.auth.interface_adapters

import com.fireavert.auth.logic.ResetPasswordSender
import com.fireavert.auth.logic.model.PasswordResetRequest
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ResetPasswordSenderImpl(
    private val resetPasswordSenderSource: ResetPasswordSenderSource,
    private val ioContext: CoroutineDispatcher,
    private val logger: Logger
) : ResetPasswordSender {
    override suspend fun send(email: String) = withContext(ioContext) {
        val result = resetPasswordSenderSource.send(PasswordResetRequest(email))
        if (result is Try.Error) {
            logger.e("ResetPasswordSenderImpl - Error: " + (result.exception.message ?: "No Message"))
        }
    }
}