package com.fireavert.components.dialogs

import com.fireavert.components.common.WarningIcon
import com.fireavert.styles.DialogStyles
import com.fireavert.styles.TableStyles
import emotion.react.css
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.*

typealias ErrorDialogCloseCallback = () -> Unit

external interface ErrorDialogProps : Props {
    var message: String
    var closeCallback: ErrorDialogCloseCallback
}

val ErrorDialog = FC<ErrorDialogProps> { props ->
    Dialog {
        open = true
        onBackdropClick = { props.closeCallback() }
        onClose = { _, _ -> props.closeCallback() }
        maxWidth = Breakpoint.md
        DialogContent {

            className = DialogStyles.warningDialogContent

            WarningIcon {}
            div {
                className = DialogStyles.warningText
                +"Error"
            }
            div {
                className = DialogStyles.warningText
                +props.message
            }
            DialogActions {
                css {
                    height = 94.px
                    width = 500.px
                    padding = 24.px
                    justifyContent = important(JustifyContent.center)
                }
                Button {
                    +"Ok"
                    color = ButtonColor.inherit
                    onClick = {
                        props.closeCallback()
                    }
                    variant = ButtonVariant.contained
                    css {
                        width = 400.px
                        backgroundColor = NamedColor.white
                    }
                }
            }
        }
    }
}