package com.fireavert.components.active_events

import com.fireavert.events.activeevents.domain.ActiveEventWithActions
import com.fireavert.events.activeevents.domain.ActiveSensorType
import com.fireavert.menu.RoutePaths
import com.fireavert.styles.EventsStylesV2
import emotion.react.css

import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.router.dom.Link
import react.useState
import web.cssom.*

external interface ActiveEventsRowMobileV2Props : Props {
    var event: ActiveEventWithActions
}


val ActiveEventsRowMobileV2 = FC<ActiveEventsRowMobileV2Props> { props ->
    val (isDialogOpen, setIsDialogOpen) = useState(false)
    ReactHTML.div {
        className = EventsStylesV2.eventsTableRow
        ReactHTML.div {
            className = EventsStylesV2.eventsTableRowCellLeftAlign
            Link {
                to = "${RoutePaths.properties}/${props.event.propertyId}"
                +truncateText(props.event.propertyName, 12)
            }
        }
        ReactHTML.div {
            className = EventsStylesV2.eventsTableRowCellLeftAlign
            Link {
                to = "${RoutePaths.properties}/${props.event.propertyId}/units/${props.event.unitId}"
                +props.event.unitNumber
            }
        }
        ReactHTML.div {
            className = EventsStylesV2.eventsTableRowCellLeftAlign
            ReactHTML.div {
                onClick = { event ->
                    event.stopPropagation()
                    setIsDialogOpen(true)
                }
                css {
                    display = Display.flex
                    alignItems = AlignItems.center
                    cursor = Cursor.pointer
                }
                ReactHTML.div {
                    css {
                        backgroundColor = getEventColor(props.event)
                        width = 10.px
                        height = 10.px
                        borderRadius = 50.pct
                        marginRight = 2.px
                    }
                }
                +getEventType(props.event)
            }
        }
        if (isDialogOpen) {
            ActiveEventsMobileDialog {
                event = props.event
                titleText = getEventType(props.event)
                onClose = { setIsDialogOpen(false) }
                backgroundColor = getEventColor(props.event)
            }
        }
    }

}

fun getEventType(event: ActiveEventWithActions): String {
    return when {
        event.activeSensors.contains(ActiveSensorType.Shutoff) -> "Stove Shutoff"
        event.activeSensors.contains(ActiveSensorType.Smoke) -> "Smoke detected"
        event.activeSensors.contains(ActiveSensorType.Water) -> "Leak"
        event.activeSensors.contains(ActiveSensorType.Tamper) -> "Tamper"
        event.activeSensors.contains(ActiveSensorType.Offline) -> "Offline"
        else -> "Unknown"
    }
}

fun getEventColor(event: ActiveEventWithActions): Color {
    return when {
        event.activeSensors.contains(ActiveSensorType.Shutoff) -> Color("#FF6B6B")
        event.activeSensors.contains(ActiveSensorType.Smoke) -> Color("#FF6B6B")
        event.activeSensors.contains(ActiveSensorType.Water) -> Color("#58B9FF")
        event.activeSensors.contains(ActiveSensorType.Tamper) -> Color("#FECA57")
        event.activeSensors.contains(ActiveSensorType.Offline) -> Color("#FECA57")
        else -> Color("#000000")
    }
}
fun truncateText(text: String, maxLength: Int): String {
    return if (text.length > maxLength) {
        text.substring(0, maxLength) + "..."
    } else {
        text
    }
}