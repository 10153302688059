package com.fireavert.administration_page.user_tracking.interface_adapters

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import com.fireavert.administration_page.user_tracking.logic.UserTrackingRecord
import com.fireavert.administration_page.user_tracking.logic.UserTrackingRepository
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class UserTrackingRepositoryImpl(
    private val dataSource: UserTrackingRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
) : UserTrackingRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getUserTrackingData(): Try<List<UserTrackingRecord>> = withContext(ioContext) {
        callWithRefresh { dataSource.getUserTrackingData(preferences.token) }
            .map { dtoList ->
                dtoList.map { dto ->
                    dto.toUserTrackingRecord()
                }
            }
    }
}