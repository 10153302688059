package com.fireavert.jobs.external

import com.fireavert.common.Try
import com.fireavert.jobs.interface_adapters.ClientJobDataSource
import com.fireavert.jobs.logic.JobModel
import io.ktor.client.HttpClient
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.url
import io.ktor.http.*

class CommonClientJobDataSource(val httpClient: HttpClient, val baseUrl: String) :
    ClientJobDataSource {
    override suspend fun getJob(token: String, id: Int): Try<JobModel> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer: $token")
                url("$baseUrl/jobs/$id")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}