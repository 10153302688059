package com.fireavert.user.api

import kotlinx.serialization.Serializable

@Serializable
data class PermissionResponse(
    val id: Int? = 0,
    val key: String? = "",
    val name: String? = ""
)
