package com.fireavert.menu

import com.fireavert.auth.interface_adapters.AuthRouter
import com.fireavert.devices.interface_adapters.DevicesRouter
import com.fireavert.events.presentation.EventsRouter
import com.fireavert.menu.presentation.MenuRouter
import com.fireavert.properties.presentation.PropertyRouter
import com.fireavert.reboot.presentation.RebootRouter
import com.fireavert.units.presentation.UnitsRouter
import remix.run.router.Router


class NavRouter :
    AuthRouter,
    MenuRouter,
    PropertyRouter,
    DevicesRouter,
    EventsRouter,
    RebootRouter,
    UnitsRouter {

    var router: Router? = null

    /***********************************************************************************************
     * Reboot
     **********************************************************************************************/
    override fun routeToSuccess() {

        router?.navigate("${RoutePaths.reboot}_success")
    }

    override fun routeToNotFoundOrNotPro() {
        router?.navigate("${RoutePaths.reboot}_not_found")
    }

    /***********************************************************************************************
     * Dashboard
     **********************************************************************************************/
    override fun routeToDashboard() {
        print("Route To Dashboard")
        router?.navigate(RoutePaths.dashboard)
    }

    /***********************************************************************************************
     * Dashboard
     **********************************************************************************************/
    override fun routeToAdministration() {
        router?.navigate(RoutePaths.administration)
    }

    override fun routeToReports() {
        router?.navigate(RoutePaths.reports)
    }

    override fun routeToHelp() {
        router?.navigate(RoutePaths.help)
    }

    /***********************************************************************************************
     * Login
     **********************************************************************************************/
    override fun routeToLogin() {
        print("Route To Login")
        router?.navigate(RoutePaths.root)
    }

    /***********************************************************************************************
     * Password Reset Sent
    **********************************************************************************************/
    override fun routeToResetPasswordSent() {
        router?.navigate(RoutePaths.resetPasswordSent)
    }

    /***********************************************************************************************
     * Device
     **********************************************************************************************/
    override fun routeToUsers() {
        router?.navigate(RoutePaths.users)
    }

    /***********************************************************************************************
     * Property
     **********************************************************************************************/
    override fun routeToProperties() {
        router?.navigate(RoutePaths.properties)
    }

    override fun routeToProperty(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId")
    }

    override fun routeToAddNewPropertyDialog() {
        router?.navigate(RoutePaths.addNewProperty)
    }

    override fun routeToRemovePropertyAdmin(propertyId: Int, propertyAdminUserId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/removePropertyAdmin/$propertyAdminUserId")
    }

    override fun routeToEditProperty(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/edit")
    }

    /***********************************************************************************************
     * Reload
     **********************************************************************************************/
    override fun reload() {
        router?.navigate(0)
    }

    /***********************************************************************************************
     * Device
     **********************************************************************************************/
    override fun routeToAddNewDeviceDialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/add_new_device")
    }

    override fun routeToDeviceDetails(propertyId: Int, deviceId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/devices/$deviceId")
    }

    /***********************************************************************************************
     * Unit
     **********************************************************************************************/
    override fun routeToUnit(propertyId: Int, unitId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/units/$unitId")
    }

    override fun routeToAddNewUnitCSVDialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/add_new_units_csv")
    }

    override fun routeToAddNewUnitDialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/add_new_unit")
    }

    override fun routeToConvertToCommV2Dialog(propertyId: Int) {
        router?.navigate("${RoutePaths.properties}/$propertyId/convert_to_comm_v2")
    }
}