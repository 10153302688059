package com.fireavert.devices.interface_adapters

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.response.DeviceEventResponse
import com.fireavert.devices.logic.response.EventTypeResponse
import com.fireavert.devices.logic.response.GetDeviceEventsResponse
import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventType.DeviceOffline
import com.fireavert.events.logic.models.EventType.DeviceReset
import com.fireavert.events.logic.models.EventType.DeviceTriggered
import com.fireavert.events.logic.models.EventType.SmokeAlarmActive
import com.fireavert.events.logic.models.EventType.Unknown
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant

private object DeviceEventMapper {
    fun withDeviceEventResponse(from: DeviceEventResponse): DeviceEvent {
        return DeviceEvent(
            id = from.id ?: 0,
            timestamp = if (from.timestamp != null) Instant.parse(from.timestamp) else {
                Clock.System.now()
            },
            deviceId = from.deviceId ?: 0,
            unitId = from.unitId ?: 0,
            type = when (from.type) {
                EventTypeResponse.SmokeAlarmActive -> EventType.SmokeAlarmActive
                EventTypeResponse.DeviceTriggered -> EventType.DeviceTriggered
                EventTypeResponse.DeviceReset -> EventType.DeviceReset
                EventTypeResponse.DeviceOffline -> EventType.DeviceOffline
                EventTypeResponse.Unknown,
                null -> EventType.Unknown
            },
            deviceType = from.deviceType ?: DeviceType.FireAvert,
            isCleared = from.isCleared ?: false,
            isTest = from.isTest ?: false,
            isFalseAlert = from.isFalseAlert ?: false,
            timezoneId = from.timezoneId ?: ""
        )
    }

    fun withDeviceEvent(from: DeviceEvent): DeviceEventResponse {
        return DeviceEventResponse(
            id = from.id,
            timestamp = from.timestamp.toString(),
            deviceId = from.deviceId,
            unitId = from.unitId,
            type = when (from.type) {
                SmokeAlarmActive -> EventTypeResponse.SmokeAlarmActive
                DeviceTriggered -> EventTypeResponse.DeviceTriggered
                DeviceReset -> EventTypeResponse.DeviceReset
                DeviceOffline -> EventTypeResponse.DeviceOffline
                Unknown -> EventTypeResponse.Unknown
            },
            deviceType = from.deviceType,
            isCleared = from.isCleared,
            isTest = from.isTest,
            isFalseAlert = from.isFalseAlert,
            timezoneId = from.timezoneId,
        )
    }
}

fun DeviceEvent.toDeviceEventResponse(): DeviceEventResponse =
    DeviceEventMapper.withDeviceEvent(this)

fun DeviceEventResponse.toDeviceEvent(): DeviceEvent =
    DeviceEventMapper.withDeviceEventResponse(this)

fun GetDeviceEventsResponse.toListOfDeviceEvents(): List<DeviceEvent> {
    return this.events?.map { it.toDeviceEvent() } ?: emptyList()
}