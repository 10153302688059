package com.fireavert.properties.logic.models.response

import com.fireavert.events.logic.HandleEventNotifications

@kotlinx.serialization.Serializable
data class PropertyAdminResponse(
    val userId: Int? = null,
    val firstName: String? = null,
    val lastName: String? = null,
    val phone: String? = null,
    val email: String? = null,
    val stoveTextNotifications: Boolean? = null,
    val leakTextNotifications: Boolean? = null,
    val tamperTextNotifications: Boolean? = null,
    val stoveEmailNotifications: Boolean? = null,
    val leakEmailNotifications: Boolean? = null,
    val tamperEmailNotifications: Boolean? = null,
    val smokeAlarmEmailNotifications: Boolean? = null,
    val smokeAlarmTextNotifications: Boolean? = null,
    val stoveOfflineTextNotifications: Boolean? = null,
    val stoveOfflineEmailNotifications: Boolean? = null,
    val leakOfflineTextNotifications: Boolean? = null,
    val leakOfflineEmailNotifications: Boolean? = null,
    val tamperOfflineTextNotifications: Boolean? = null,
    val tamperOfflineEmailNotifications: Boolean? = null,
    val dailyReports: Boolean? = null,
    val weeklyReports: Boolean? = null,
    val monthlyReports: Boolean? = null,
    val quarterlyReports: Boolean? = null,
    val yearlyReports: Boolean? = null
)
