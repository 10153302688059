package com.fireavert.events.logic.models

import  com.fireavert.devices.logic.models.DeviceType
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class DeviceEvent(
    val id: Long,
    val timestamp: Instant,
    val deviceId: Int,
    val unitId: Int,
    val type: EventType,
    val isCleared: Boolean,
    val isTest: Boolean,
    val isFalseAlert: Boolean,
    val deviceType: DeviceType,
    val timezoneId: String,
)
