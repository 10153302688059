package com.fireavert.unsubscribe.adapters

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.unsubscribe.logic.UnsubscribeSetter
import io.ktor.client.HttpClient
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ClientUnsubscribeSetter(
    private val ioContext: CoroutineDispatcher,
    private val dataSource: UnsubscribeSetterDataSource,
) : UnsubscribeSetter {
    override suspend fun set(email: String): Boolean = withContext(ioContext) {
        when(dataSource.setEmailToUnsubscribe(email)) {
            is Try.Success -> {
                true
            }
            is Try.Error -> {
                false
            }
        }

    }
}