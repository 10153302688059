package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import web.cssom.*

object GatewayStyles {

    val gatewayTitle = ClassName {
        fontSize = 24.px
        fontWeight = FontWeight.bold
        marginTop = 20.px
        paddingLeft = 25.px
    }


    val gatewayHeaderWithButton = ClassName {
        fontSize = 20.pt
        fontWeight = FontWeight.bold
        color = NamedColor.white
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        alignItems = AlignItems.center
    }

    val gatewayDialogBody = ClassName {
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
        padding = 25.px
    }

    val gatewayDescription = ClassName {
        backgroundColor = NamedColor.white
        borderRadius = 10.px
        padding = 20.px
        height = 100.px
        maxHeight = 100.px
        width = 95.pct
        margin = Auto.auto
    }

    val gatewayPhotoContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
        alignItems = AlignItems.center
        padding = 20.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            flexDirection = FlexDirection.column
        }
    }
}