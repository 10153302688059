package com.fireavert.units.logic

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.units.logic.UnitsNavigator.Destination

class ClientDeleteUnit(
    private val unitsNavigator: UnitsNavigator,
    private val screen: DeleteUnitDialogScreen,
    private val unitsRepository: UnitsRepository,
    private val logger: Logger
) : DeleteUnit {
    override fun cancel(propertyId: Int) {
        unitsNavigator.navigate(Destination.PropertyDetails(propertyId))
    }

    override suspend fun deleteUnit(unitId: Int, propertyId: Int) {
        screen.isDeleting = true

        when (val maybeSuccess = unitsRepository.delete(unitId)) {
            is Try.Error -> {
                logger.e("Error deleting unit $unitId, exception was ${maybeSuccess.exception.message}")
                screen.isDeleting = false
            }
            is Try.Success -> {
                screen.isDeleting = false
                unitsNavigator.navigate(Destination.PropertyDetails(propertyId))
            }
        }
    }
}