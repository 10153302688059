package com.fireavert.units.logic

import com.fireavert.events.logic.EmailValidator
import com.fireavert.events.logic.SMSValidator

object UnitValidator {
    fun validate(screen: UnitScreen): Boolean {
        val number = checkNumber(screen)
        val address = checkAddress(screen)
        val city = checkCity(screen)
        val state = checkState(screen)
        val zip = checkZip(screen)
        val phone = checkPhone(screen)
        val email = checkEmail(screen)
        return number && address && city && state && zip && phone && email
    }

    private fun checkNumber(screen: UnitScreen): Boolean {
        screen.numberError = !numberIsValid(screen)
        return !screen.numberError
    }

    private fun checkAddress(screen: UnitScreen): Boolean {
        screen.streetAddressError = !addressIsValid(screen)
        return !screen.streetAddressError
    }

    private fun checkCity(screen: UnitScreen): Boolean {
        screen.cityError = !cityIsValid(screen)
        return !screen.cityError
    }

    private fun checkState(screen: UnitScreen): Boolean {
        screen.stateError = !stateIsValid(screen)
        return !screen.stateError
    }

    private fun checkZip(screen: UnitScreen): Boolean {
        screen.zipError = !zipIsValid(screen)
        return !screen.zipError
    }

    private fun checkPhone(screen: UnitScreen): Boolean {
        screen.phoneError = !phoneIsValid(screen)
        return !screen.phoneError
    }

    private fun checkEmail(screen: UnitScreen): Boolean {
        screen.emailError = !emailIsValid(screen)
        return !screen.emailError
    }

    /**
     * Email can be blank but should be verified if not
     */
    fun emailIsValid(screen: UnitScreen): Boolean {
        return if (screen.email.isNotBlank()) {
            EmailValidator.isEmailValid(screen.email)
        } else {
            true
        }
    }

    /**
     * Phone can be blank but should be verified if not
     */
    fun phoneIsValid(screen: UnitScreen): Boolean {
        return if (screen.phone.isNotBlank()) {
            SMSValidator.isSMSValid(screen.phone)
        } else {
            true
        }
    }

    /**
     * Must be either 5 digits a dash and 4 digits or just 5 digits
     */
    fun zipIsValid(screen: UnitScreen): Boolean {
        return screen.zip.isNotBlank() && "^\\d{5}(?:-\\d{4})?$".toRegex().matches(screen.zip)
    }

    fun stateIsValid(screen: UnitScreen): Boolean {
        return screen.state.isNotBlank()
    }

    fun cityIsValid(screen: UnitScreen): Boolean {
        return screen.city.isNotBlank()
    }

    fun addressIsValid(screen: UnitScreen): Boolean {
        return screen.streetAddress.isNotBlank()
    }

    /**
     * Format should start with a letter representing the building number followed by the unit number.
     */
    fun numberIsValid(screen: UnitScreen): Boolean {
        return screen.number.isNotBlank()
    }
}