package com.fireavert.units.presentation

import com.fireavert.devices.logic.models.DeviceType

class AddRemoveDeviceRowController {
    fun isSecondRowVisible(type: String): Boolean {
        return type == DeviceType.FireAvert.string ||
                type == DeviceType.FireAvertGas.string ||
                type == DeviceType.FireAvertAppliance.string
    }
}