package com.fireavert.auth.logic

interface AuthNavigator {
    sealed class Destination {
        object Dashboard: Destination()
        object Login: Destination()
        object ResetPasswordSent: Destination()
    }

    fun navigate(destination: Destination)
}