package com.fireavert.reports_page.interface_adaptors

import com.fireavert.reports_page.logic.ReportsPagesSectionInteractor

class ReportsPageSectionController(
    private val interactor: ReportsPagesSectionInteractor,
) {
    suspend fun onLoad(propertyId: Int?, eventTypeValue: String, yearToGet: Int) = interactor.onLoad(propertyId, eventTypeValue, yearToGet)

    suspend fun updateData(propertyId: Int?, eventTypeValue: String, yearToGet: Int) = interactor.updateData(
        propertyId,
        eventTypeValue
    )

    suspend fun loadYearlyEventTrends(propertyId: Int?, yearToGet: Int) = interactor.loadYearlyEventTrends(propertyId, yearToGet)

    suspend fun loadEventsAndActions(propertyId: Int?, eventTypeValue: String) = interactor.loadEventsAndActions(
        propertyId,
        eventTypeValue
    )

    suspend fun loadHighRiskTenantData(propertyId: Int?, eventTypeArray: List<String>) = interactor.loadHighRiskTenantData(propertyId, eventTypeArray)

}