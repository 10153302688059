package com.fireavert.components.properties

import com.fireavert.common.TableColumn
import com.fireavert.components.dialogs.GatewayDialog
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.gateways.interface_adaptors.GatewayPropertyViewModel
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.properties.presentation.GatewayPropertyController
import com.fireavert.styles.HeadingStyles
import com.fireavert.styles.HelpStyles
import com.fireavert.styles.TableStyles
import com.fireavert.units.logic.models.UnitStatus
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import emotion.css.cx
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.onChange
import web.cssom.*
import web.html.HTMLInputElement


external interface GatewayDetailsProps : Props {
    var propsIsAdmin: Boolean
    var propsIsMobile: Boolean
    var propertyId: Int
    var propsPropertyName: String
}

val GatewayDetails = FC<GatewayDetailsProps> { props ->
    val controller = getKoinInstance<GatewayPropertyController>()
    val viewModel = getKoinInstance<GatewayPropertyViewModel>()

    var loading: Boolean by useState(true)
    var gatewayDeviceDataLoading by useState(false)
    var showEditView: Boolean by useState(false)


    var associatedGateways: List<GatewayModel> by useState(emptyList())
    var allGateways: List<GatewayModel> by useState(emptyList())
    var selectedGateways by useState(associatedGateways.toSet())
    var deletedGateways by useState(emptySet<GatewayModel>())
    val (selectedGateway, setSelectedGateway) = useState<GatewayModel?>(null)
    var gatewayDeviceData by useState(emptyMap<String, List<String>>())

    var imageUploadError by useState("")

    val (searchValue, setSearchValue) = useState("")
    val (openDialog, setOpenDialog) = useState(false)

    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "NAME",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "DESCRIPTION",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "STATUS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            )
        )
    )

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            associatedGateways = viewState.associatedGateways
            loading = viewState.loading
            allGateways = viewState.allGateways
            imageUploadError = viewState.imageUploadError
            gatewayDeviceData = viewState.gatewayDeviceData
            gatewayDeviceDataLoading = viewState.gatewayDeviceDataLoading

        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(props.propertyId)
        }

        cleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }
    Accordion {
        AccordionSummary {
            id = "gateway-panel"
            expandIcon = ReactNode(
                arrayOf(
                    img.create {
                        src = "/static/accordion_arrow_black.svg"
                        alt = "Expand"
                    }
                )
            )
            div {
                className = HeadingStyles.titleHeadingWhiteBackground
                +"Gateways"
            }
        }
        AccordionDetails {
            div {
                className = TableStyles.tableContainerAccordion
                div {
                    className = TableStyles.tableTitle
                    +"Associated Gateways"
                    if (props.propsIsAdmin) {
                        div {
                            className = TableStyles.duelButtonContainer
                            if (!gatewayDeviceDataLoading) {
                                Button {
                                    color = ButtonColor.secondary
                                    variant = ButtonVariant.contained
                                    onClick = {
                                        viewModel.scope.launch {
                                            controller.loadGatewayDeviceData(props.propertyId.toString())
                                        }
                                    }
                                    +"Device Connection Info"
                                }
                            } else {
                                CircularProgress {
                                    color = CircularProgressColor.info
                                }
                            }
                            if (!loading) {
                                Button {
                                    color = ButtonColor.secondary
                                    variant = ButtonVariant.contained
                                    onClick = {
                                        viewModel.scope.launch {
                                            controller.refreshGatewayStatus(props.propertyId)
                                        }
                                    }
                                    +"Refresh Gateway Status"
                                }
                            } else {
                                CircularProgress {
                                    color = CircularProgressColor.info
                                }
                            }
                            Button {
                                color = ButtonColor.primary
                                variant = ButtonVariant.contained
                                onClick = {
                                    if (showEditView) {
                                        showEditView = false
                                        if (selectedGateways.isNotEmpty()) {
                                            viewModel.scope.launch {
                                                controller.saveAssociatedGateways(
                                                    selectedGateways.toList() + associatedGateways,
                                                    props.propertyId
                                                )
                                            }
                                            selectedGateways = emptySet()
                                        }
                                    } else
                                        showEditView = true
                                }
                                +if (showEditView) "Save" else "Add/Edit Gateways"
                            }
                        }
                    }
                }
                div {
                    className = TableStyles.table
                    SortBar {
                        columns = mapToControlColumns(stateColumns)
                        includeLoading = false
                        isLoading = false
                        columnsChanged = {
                            stateColumns = mapToStateColumns(it)
                        }
                    }
                    (associatedGateways + selectedGateways).forEach { gateway ->
                        val isDeleted = deletedGateways.contains(gateway)
                        div {
                            onClick = {
                                setSelectedGateway(gateway)
                                setOpenDialog(true)
                            }
                            className = cx(TableStyles.tableRow, ClassName {
                                textDecoration = if (isDeleted) TextDecoration.lineThrough else None.none
                                color = if (isDeleted) Color("#999999") else Color("inherit")
                                cursor = Cursor.pointer})
                            div {
                                img {
                                    src = "/static/gateway_icon.svg"
                                    alt = "Gateway"
                                }
                                className = TableStyles.tableCell
                                +" ${gateway.name}"
                            }
                            div {
                                className = TableStyles.tableCell
                                +gateway.description.ifEmpty { "N/A" }
                            }
                            div {
                                className = when (gateway.status) {
                                    UnitStatus.Online -> cx(TableStyles.tableCell, TableStyles.greenBold)
                                    UnitStatus.Offline -> cx(TableStyles.tableCell, TableStyles.yellowBold)
                                    else -> cx(TableStyles.tableCell, TableStyles.greyBold)
                                }
                                +when (gateway.status) {
                                    UnitStatus.Online -> "Online"
                                    UnitStatus.Offline -> "Offline"
                                    UnitStatus.Error -> "Never Installed"
                                    UnitStatus.NeverInstalled -> "Never Installed"
                                }
                            }
                        }
                    }

                    if (openDialog && selectedGateway != null) {
                        GatewayDialog {
                            propsPropertyName = props.propsPropertyName
                            propsGatewayName = selectedGateway.name
                            propsGatewayDescription = selectedGateway.description
                            propsGatewayLastUplink = selectedGateway.lastUplink
                            propsGatewaysStatus = selectedGateway.status
                            propsIsAdmin = props.propsIsAdmin
                            propsGatewayId = selectedGateway.gatewayId
                            propsController = controller
                            propsImageUploadError = imageUploadError
                            propsPropertyId = props.propertyId
                            onClose = { setOpenDialog(false) }
                        }
                    }
                }
            }
            if(showEditView) {
                div {
                    className = HeadingStyles.titleHeadingWhiteBackground
                    +"Add/Remove Gateways"
                }
                TextField {
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                    placeholder = "Search gateways..."
                    fullWidth = true
                    sx {
                        marginBottom = 2.px
                    }
                }

                Paper {
                    sx {
                        height = 300.px
                        overflow = Auto.auto
                    }
                    List {
                        allGateways
                            .filter { gateway ->
                                gateway.name.contains(searchValue, ignoreCase = true) ||
                                        gateway.description.contains(searchValue, ignoreCase = true)
                            }
                            .forEach { gateway ->
                                ListItem {
                                    ListItemIcon {
                                        Checkbox {
                                            checked = selectedGateways.contains(gateway) || associatedGateways.contains(gateway)
                                            onChange = { _, checked ->
                                                if (checked) {
                                                    selectedGateways = selectedGateways + gateway
                                                    deletedGateways = deletedGateways - gateway
                                                } else {
                                                    selectedGateways = selectedGateways - gateway
                                                    deletedGateways = deletedGateways - gateway
                                                }
                                            }
                                        }
                                    }
                                    ListItemText {
                                        primary = ReactNode(gateway.name)
                                        secondary = ReactNode(gateway.description)
                                    }
                                }
                            }
                    }
                }
            }

            div {
                for ((key, value) in gatewayDeviceData) {
                    div {
                        className = HelpStyles.eventTitle
                        +"$key - # of devices connected: ${value.size}"
                    }
                    div {
                        +"Sensors: ${value.joinToString(", ")}"
                    }
                }
            }
            div {
                className = TableStyles.tableRow

            }
        }
    }
}
