package com.fireavert.units.presentation

import com.fireavert.common.ViewModel
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.units.logic.AddOrEditUnitScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class AddOrEditUnitViewModel : ViewModel(), AddOrEditUnitScreen {
    private val _viewState = MutableStateFlow(UnitViewState())
    val viewState = _viewState.asStateFlow()
    override var notifyStoveCurrent: Boolean
        get() = viewState.value.notifyStoveCurrent
        set(value) {
            _viewState.value = viewState.value.copy(notifyStoveCurrent = value)
        }
    override val scope: CoroutineScope
        get() = viewModelScope
    override var isLoading: Boolean
        get() = viewState.value.isLoading
        set(value) {
            _viewState.value = viewState.value.copy(isLoading = value)
        }
    override var saveDisabled: Boolean
        get() = viewState.value.saveDisabled
        set(value) {
            _viewState.value = viewState.value.copy(saveDisabled = value)
        }
    override var number: String
        get() = viewState.value.number ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(number = value)
        }
    override var numberError: Boolean
        get() = viewState.value.numberError
        set(value) {
            _viewState.value = viewState.value.copy(numberError = value)
        }
    override var streetAddress: String
        get() = viewState.value.streetAddress ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(streetAddress = value)
        }
    override var streetAddressError: Boolean
        get() = viewState.value.streetError
        set(value) {
            _viewState.value = viewState.value.copy(streetError = value)
        }
    override var city: String
        get() = viewState.value.city ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(city = value)
        }
    override var cityError: Boolean
        get() = viewState.value.cityError
        set(value) {
            _viewState.value = viewState.value.copy(cityError = value)
        }
    override var state: String
        get() = viewState.value.state ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(state = value)
        }
    override var stateError: Boolean
        get() = viewState.value.stateError
        set(value) {
            _viewState.value = viewState.value.copy(stateError = value)
        }
    override var zip: String
        get() = viewState.value.zip ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(zip = value)
        }
    override var zipError: Boolean
        get() = viewState.value.zipError
        set(value) {
            _viewState.value = viewState.value.copy(zipError = value)
        }
    override var tenantName: String
        get() = viewState.value.tenantName ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(tenantName = value)
        }
    override var tenantNameError: Boolean
        get() = viewState.value.tenantNameError
        set(value) {
            _viewState.value = viewState.value.copy(tenantNameError = value)
        }

    override var phone: String
        get() = viewState.value.phone ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(phone = value)
        }
    override var phoneError: Boolean
        get() = viewState.value.phoneError
        set(value) {
            _viewState.value = viewState.value.copy(phoneError = value)
        }
    override var email: String
        get() = viewState.value.email ?: ""
        set(value) {
            _viewState.value = viewState.value.copy(email = value)
        }
    override var emailError: Boolean
        get() = viewState.value.emailError
        set(value) {
            _viewState.value = viewState.value.copy(emailError = value)
        }
    override var deviceData: List<DeviceData>
        get() = viewState.value.deviceDataStateList.map {
            DeviceData(
                type = it.type ?: "", typeError = it.typeError,
                location = it.location ?: "", locationError = it.locationError,
                locator = it.locator ?: "", locatorError = it.locatorError,
                commVersion = it.commVersion ?: 1, commVersionError = it.commVersionError,
                deviceId = it.deviceId,
                rebootUUID = it.rebootUUID, rebootUUIDError = it.rebootUUIDError,
                infoUUID = it.infoUUID, infoUUIDError = it.infoUUIDError
            )
        }
        set(value) {
            _viewState.value = viewState.value.copy(deviceDataStateList = value.map {
                DeviceDataViewState(
                    type = it.type,
                    typeError = it.typeError,
                    location = it.location,
                    locationError = it.locationError,
                    locator = it.locator,
                    locatorError = it.locatorError,
                    deviceId = it.deviceId,
                    commVersion = it.commVersion,
                    commVersionError = it.commVersionError,
                    rebootUUID = it.rebootUUID,
                    rebootUUIDError = it.rebootUUIDError,
                    infoUUID = it.infoUUID,
                    infoUUIDError = it.infoUUIDError,
                )
            })
        }

    override var saveError: String?
        get() = viewState.value.saveError
        set(value) {
            _viewState.value = viewState.value.copy(saveError = value)
        }

    override fun addNewDeviceRow() {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        newList.add(DeviceDataViewState())
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceTypeError(deviceIndex: Int, deviceTypeError: Boolean) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(deviceIndex)
        newList.add(index = deviceIndex, element = existingData.copy(typeError = deviceTypeError))
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceLocationError(deviceIndex: Int, deviceLocationError: Boolean) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(deviceIndex)
        newList.add(
            index = deviceIndex,
            element = existingData.copy(locationError = deviceLocationError)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceLocatorError(deviceIndex: Int, deviceLocatorError: Boolean) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(deviceIndex)
        newList.add(
            index = deviceIndex,
            element = existingData.copy(locatorError = deviceLocatorError)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun removeDeviceAtIndex(index: Int) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        newList.removeAt(index)
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceCommVersion(index: Int, commVersion: Int) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(index)
        newList.add(
            index = index,
            element = existingData.copy(commVersion = commVersion)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setCommVersionError(index: Int, deviceCommVersionError: Boolean) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(index)
        newList.add(
            index = index,
            element = existingData.copy(commVersionError = deviceCommVersionError)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setRebootUUID(index: Int, rebootUUID: String) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(index)
        newList.add(
            index = index,
            element = existingData.copy(rebootUUID = rebootUUID)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setRebootUUIDError(index: Int, rebootUUIDError: Boolean) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(index)
        newList.add(
            index = index,
            element = existingData.copy(rebootUUIDError = rebootUUIDError)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setInfoUUID(index: Int, infoUUID: String) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(index)
        newList.add(
            index = index,
            element = existingData.copy(infoUUID = infoUUID)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setInfoUUIDError(index: Int, infoUUIDError: Boolean) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(index)
        newList.add(
            index = index,
            element = existingData.copy(infoUUIDError = infoUUIDError)
        )
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceType(deviceIndex: Int, value: String) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(deviceIndex)
        newList.add(index = deviceIndex, element = existingData.copy(type = value))
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceLocation(deviceIndex: Int, value: String) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(deviceIndex)
        newList.add(index = deviceIndex, element = existingData.copy(location = value))
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }

    override fun setDeviceLocator(deviceIndex: Int, value: String) {
        val newList = viewState.value.deviceDataStateList.toMutableList()
        val existingData = newList.removeAt(deviceIndex)
        newList.add(index = deviceIndex, element = existingData.copy(locator = value))
        _viewState.value = viewState.value.copy(deviceDataStateList = newList.toList())
    }
}