package com.fireavert.components.icons

import com.fireavert.styles.ButtonStyles
import emotion.react.css
import mui.material.IconButton
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface DeleteIconProps: Props {
    var onClicked: () -> Unit
}
val DeleteIconButton = FC<DeleteIconProps> { props ->
    IconButton {
        onClick = { props.onClicked() }
        css {
            ButtonStyles.trashIconStyle
        }
        ReactHTML.img {
            alt = "Delete"
            src = "/static/delete.svg"
        }
    }
}