package com.fireavert.components.properties

import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import web.html.HTMLInputElement

external interface StateDropDownProps : Props {
    var value: String
    var error: Boolean
    var changed: (newValue: String) -> Unit
}

val StateDropDown = FC<StateDropDownProps> { props ->
    FormControl {
        fullWidth = true
        InputLabel {
            id = "state-input-label"
            +"State"
        }
        Select {
            label = ReactNode("State")
            labelId = "state-input-label"
            value = props.value
            error = props.error
            fullWidth = true
            onChange = { event: ChangeEvent<HTMLInputElement>, _: ReactNode ->
                props.changed(event.target.value)
            }
            MenuItem {
                value = "AL"
                +"Alabama"
            }
            MenuItem {
                +"Alaska"
                value = "AK"
            }
            MenuItem {
                +"Arizona"
                value = "AZ"
            }
            MenuItem {
                +"Arkansas"
                value = "AR"
            }
            MenuItem {
                +"California"
                value = "CA"
            }
            MenuItem {
                +"Colorado"
                value = "CO"
            }
            MenuItem {
                +"Connecticut"
                value = "CT"
            }
            MenuItem {
                +"Delaware"
                value = "DE"
            }
            MenuItem {
                +"Florida"
                value = "FL"
            }
            MenuItem {
                +"Georgia"
                value = "GA"
            }
            MenuItem {
                +"Hawaii"
                value = "HI"
            }
            MenuItem {
                +"Idaho"
                value = "ID"
            }
            MenuItem {
                +"Illinois"
                value = "IL"
            }
            MenuItem {
                +"Indiana"
                value = "IN"
            }
            MenuItem {
                +"Iowa"
                value = "IA"
            }
            MenuItem {
                +"Kansas"
                value = "KS"
            }
            MenuItem {
                +"Kentucky"
                value = "KY"
            }
            MenuItem {
                +"Louisiana"
                value = "LA"
            }
            MenuItem {
                +"Maine"
                value = "ME"
            }
            MenuItem {
                +"Maryland"
                value = "MD"
            }
            MenuItem {
                +"Massachusetts"
                value = "MA"
            }
            MenuItem {
                +"Michigan"
                value = "MI"
            }
            MenuItem {
                +"Minnesota"
                value = "MN"
            }
            MenuItem {
                +"Mississippi"
                value = "MS"
            }
            MenuItem {
                +"Missouri"
                value = "MO"
            }
            MenuItem {
                +"Montana"
                value = "MT"
            }
            MenuItem {
                +"Nebraska"
                value = "NE"
            }
            MenuItem {
                +"Nevada"
                value = "NV"
            }
            MenuItem {
                +"New Hampshire"
                value = "NH"
            }
            MenuItem {
                +"New Jersey"
                value = "NJ"
            }
            MenuItem {
                +"New Mexico"
                value = "NM"
            }
            MenuItem {
                +"New York"
                value = "NY"
            }
            MenuItem {
                +"North Carolina"
                value = "NC"
            }
            MenuItem {
                +"North Dakota"
                value = "ND"
            }
            MenuItem {
                +"Ohio"
                value = "OH"
            }
            MenuItem {
                +"Oklahoma"
                value = "OK"
            }
            MenuItem {
                +"Oregon"
                value = "OR"
            }
            MenuItem {
                +"Pennsylvania"
                value = "PA"
            }
            MenuItem {
                +"Rhode Island"
                value = "RI"
            }
            MenuItem {
                +"South Carolina"
                value = "SC"
            }
            MenuItem {
                +"South Dakota"
                value = "SD"
            }
            MenuItem {
                +"Tennessee"
                value = "TN"
            }
            MenuItem {
                +"Texas"
                value = "TX"
            }
            MenuItem {
                +"Utah"
                value = "UT"
            }
            MenuItem {
                +"Vermont"
                value = "VT"
            }
            MenuItem {
                +"Virginia"
                value = "VA"
            }
            MenuItem {
                +"Washington"
                value = "WA"
            }
            MenuItem {
                +"West Virginia"
                value = "WV"
            }
            MenuItem {
                +"Wisconsin"
                value = "WI"
            }
            MenuItem {
                +"Wyoming"
                value = "WY"
            }
        }
    }
}