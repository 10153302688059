package com.fireavert.components.pages

import com.fireavert.components.common.NotLoggedIn
import com.fireavert.components.unauthenticated.useQuery
import com.fireavert.info.presentation.InfoPageController
import com.fireavert.info.presentation.InfoPageViewModel
import com.fireavert.styles.MobileStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.hr
import react.useEffectOnce
import react.useState

val InfoPage = FC<Props> {

    NotLoggedIn {
        whiteBackground = true
    }

    val queryString = useQuery() ?: ""

    val controller = getKoinInstance<InfoPageController>()
    val viewModel = getKoinInstance<InfoPageViewModel>()

    var deviceType: String by useState("")
    var unit: String by useState("")
    var propertyName: String by useState("")
    var error: Boolean by useState(false)

    fun setIdFromQueryString(queryString: String) {
        val sections = queryString.split("&")
        for (section in sections) {
            val keyValue = section.split("=")
            if (keyValue.size < 2) continue
            if (keyValue[0] == "id") {
                viewModel.scope.launch {
                    controller.queryStringUUID(uuid = keyValue[1])
                }
            }
        }
    }

    useEffectOnce {
        setIdFromQueryString(queryString)
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            propertyName = viewState.propertyName
            unit = viewState.unit
            deviceType = viewState.deviceType
            error = viewState.error
        }.launchIn(viewModel.scope)

        cleanup {
            viewStateJob.cancel()
        }
    }

    div {
        className = MobileStyles.verticalContainer
        form {
            className = MobileStyles.mobileForm
            div {
                className = MobileStyles.topContainer
                div {
                    className = MobileStyles.topContainerLogo
                }
            }
            div {
                className = MobileStyles.middleContainer
                div {
                    className = MobileStyles.innerContainer
                    if (error) {
                        div {
                            className = MobileStyles.error
                            +"There was an error when trying to retrieve your device information."
                        }
                    }
                    else if (unit.isNotBlank()) {
                        div {
                            className = MobileStyles.title
                            +"This $deviceType device belongs in Unit #"
                        }
                        div {
                            className = MobileStyles.unit
                            +unit
                        }
                        hr {}
                        div {
                            className = MobileStyles.propertyInfo
                            +"Property Name: $propertyName"
                        }
                        hr {}
                    }
                }
            }
            div {
                className = MobileStyles.bottomContainer
                div {
                    className = MobileStyles.bottomContainerInner
                    div {
                        a {
                            href = "https://www.fireavert.com"
                            className = MobileStyles.bottomLink
                            +"www.fireavert.com"
                        }
                    }
                    div {
                        +"©2024 FireAvert"
                    }
                }
            }
        }
    }

}