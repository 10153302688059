package com.fireavert.properties.presentation.edit_property

import com.fireavert.properties.logic.edit_property.EditProperty
import com.fireavert.properties.logic.models.*

class EditPropertyController(private val editProperty: EditProperty) {
    fun clickedCancel(propertyId: Int) = editProperty.cancel(propertyId)

    fun propertyNameChanged(targetInputValue: String) =
        editProperty.propertyNameChanged(targetInputValue)

    fun numberOfUnitsChanged(targetInputValue: String) =
        editProperty.numberOfUnitsChanged(targetInputValue)

    fun addressChanged(targetInputValue: String) = editProperty.addressChanged(targetInputValue)

    fun cityChanged(targetInputValue: String) = editProperty.cityChanged(targetInputValue)

    fun stateChanged(state: String) = editProperty.stateChanged(state)

    fun zipChanged(targetInputValue: String) = editProperty.zipChanged(targetInputValue)

    fun managementCompanyChanged(targetInputValue: String) =
        editProperty.managementCompanyChanged(targetInputValue)

    suspend fun clickedSave(propertyId: Int) = editProperty.save(propertyId)
    suspend fun onLoad(propertyId: Int) = editProperty.onLoad(propertyId)
    fun timezoneIdChanged(timezoneId: String) = editProperty.timezoneIdChanged(timezoneId)
    fun removePropertyAdminModelFromServerClicked(
        propertyId: Int,
        propertyAdminModel: PropertyAdminModel
    ) =
        editProperty.removePropertyAdminModel(propertyId, propertyAdminModel)

    fun propertyAdminModelsChanged(propertyAdmins: List<PropertyAdminModel>) =
        editProperty.propertyAdminModelsChanged(propertyAdmins)

    fun linkPropertyAdmin(propertyId: Int, propertyAdmin: PropertyAdminModel) =
        editProperty.linkPropertyAdmin(propertyId, propertyAdmin)

    fun subscriptionTypeChanged(subscriptionType: SubscriptionType) =
        editProperty.subscriptionTypeChanged(subscriptionType)

    fun modeChanged(mode: Mode) =
        editProperty.modeChanged(mode)

    fun resendPropertyAdminInvite(propertyAdmin: PropertyAdminModel) =
        editProperty.resendPropertyAdminInvite(propertyAdmin)

    fun propertyTypeChanged(propertyType: PropertyType) {
        editProperty.propertyTypeChanged(propertyType)
    }

    fun fireClaimsChangedOneClaims(year: Int, claimCount: Int) {
        editProperty.fireClaimsChangedOneClaims(year, claimCount)
    }

    fun fireClaimsChangedOneCost(year: Int, cost: Double) {
        editProperty.fireClaimsChangedOneCost(year, cost)
    }

    fun fireClaimsChangedTwoClaims(year: Int, claimCount: Int) {
        editProperty.fireClaimsChangedTwoClaims(year, claimCount)
    }

    fun fireClaimsChangedTwoCost(year: Int, cost: Double) {
        editProperty.fireClaimsChangedTwoCost(year, cost)
    }

    fun fireClaimsChangedThreeClaims(year: Int, claimCount: Int) {
        editProperty.fireClaimsChangedThreeClaims(year, claimCount)
    }

    fun fireClaimsChangedThreeCost(year: Int, cost: Double) {
        editProperty.fireClaimsChangedThreeCost(year, cost)
    }

    fun fireClaimsChangedFourClaims(year: Int, claimCount: Int) {
        editProperty.fireClaimsChangedFourClaims(year, claimCount)
    }

    fun fireClaimsChangedFourCost(year: Int, cost: Double) {
        editProperty.fireClaimsChangedFourCost(year, cost)
    }

    fun fireClaimsChangedFiveClaims(year: Int, claimCount: Int) {
        editProperty.fireClaimsChangedFiveClaims(year, claimCount)
    }

    fun fireClaimsChangedFiveCost(year: Int, cost: Double) {
        editProperty.fireClaimsChangedFiveCost(year, cost)
    }
}