package com.fireavert.events.markastest.logic

import com.fireavert.events.common.logic.EventsNavigator
import com.fireavert.events.common.logic.EventsNavigator.Destination.PropertyDetails
import com.fireavert.events.common.logic.EventsRepository
import kotlinx.coroutines.launch

class ClientMarkAsTest(
    private val eventsRepository: EventsRepository,
    private val screen: MarkAsTestDialogScreen,
    private val eventsNavigator: EventsNavigator
): MarkAsTest {
    override fun markEventAsTest(eventId: Long) {
        screen.scope.launch {
            screen.setLoading(true)
            eventsRepository.markAsTest(eventId)
            screen.setLoading(false)
        }
    }

    override fun close(propertyId: Int) {
        eventsNavigator.navToDestination(PropertyDetails(propertyId))
    }
}