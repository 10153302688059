package com.fireavert.user.logic

data class User(
    val id: Int,
    val firstName: String,
    val lastName: String,
    val email: String,
    val phone: String,
    val roles: List<Role>
) {
    val isValid: Boolean
        get() {
            return (id != 0 &&
                    email.isNotEmpty())
        }
}
