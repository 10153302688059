package com.fireavert.components.common

import com.fireavert.app.interface_adapters.AppController
import com.fireavert.app.interface_adapters.AppViewModel
import com.fireavert.logging.Logger
import com.fireavert.utilities.getKoinInstance
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLElement
import react.FC
import react.router.useLocation
import react.useEffect
import react.useEffectOnce

val LoggedIn = FC {
    val logger = getKoinInstance<Logger>()
    val appController = getKoinInstance<AppController>()
    val appViewModel = getKoinInstance<AppViewModel>()
    val location = useLocation()
    useEffect(location) {
        appViewModel.scope.launch {
            appController.createPageView(location.pathname)
        }
    }
    useEffectOnce {
        val html = document.documentElement as HTMLElement
        html.style.apply {
            background = "none"
        }
    }
}