package com.fireavert.events.presentation

import com.fireavert.events.common.logic.EventsNavigator
import com.fireavert.events.common.logic.EventsNavigator.Destination.DeviceDetails
import com.fireavert.events.common.logic.EventsNavigator.Destination.PropertyDetails

class RoutedEventsNavigator(private val eventsRouter: EventsRouter) : EventsNavigator {
    override fun navToDestination(destination: EventsNavigator.Destination) {
        when (destination) {
            is DeviceDetails -> eventsRouter.routeToDeviceDetails(
                destination.propertyId,
                destination.deviceId
            )
            is PropertyDetails -> eventsRouter.routeToProperty(destination.propertyId)
        }
    }
}