package com.fireavert.info.presentation

import com.fireavert.common.ViewModel
import com.fireavert.info.logic.InfoPageScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class InfoPageViewModel : InfoPageScreen, ViewModel() {
    private val _viewState: MutableStateFlow<InfoPageViewState> =
        MutableStateFlow(InfoPageViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var propertyName: String
        get() = viewState.value.propertyName
        set(value) {
            _viewState.value = _viewState.value.copy(propertyName = value)
        }
    override var unitName: String
        get() = viewState.value.unit
        set(value) {
            _viewState.value = _viewState.value.copy(unit = value)
        }
    override var deviceType: String
        get() = viewState.value.deviceType
        set(value) {
            _viewState.value = _viewState.value.copy(deviceType = value)
        }
    override var error: Boolean
        get() = viewState.value.error
        set(value) {
            _viewState.value = _viewState.value.copy(error = value)
        }
}