package com.fireavert.properties.logic.add_new_property

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.PropertyNavigator.Destination.Properties
import com.fireavert.properties.logic.PropertyValidator
import com.fireavert.properties.logic.models.*
import com.fireavert.properties.logic.models.Mode.Active
import com.fireavert.properties.logic.models.request.CreatePropertyRequest
import com.fireavert.properties.logic.models.request.PropertyFireClaimRequest
import io.ktor.client.plugins.ClientRequestException
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime


class ClientAddNewProperty(
    private val screen: AddNewPropertyScreen,
    private val propertyNavigator: PropertyNavigator,
    private val propertyRepository: ClientPropertyRepository,
    private val logger: Logger
) : AddNewProperty {

    override fun cancel() {
        propertyNavigator.navigate(Properties)
    }

    override suspend fun save() {
        if (PropertyValidator.validate(screen)) {
            val propertyName = screen.getPropertyName() ?: ""
            val numberOfUnits = screen.getNumberOfUnits() ?: 0
            val address = screen.getAddress() ?: ""
            val city = screen.getCity() ?: ""
            val state = screen.getState() ?: ""
            val zip = screen.getZip() ?: 0
            val managementCompany = screen.getManagementCompany() ?: ""
            val subscriptionType = screen.getSubscriptionType() ?: SubscriptionType.Standard
            val mode = screen.getMode() ?: Active
            val propertyType = screen.getPropertyType()
            val timezoneId = screen.getTimezoneId() ?: ""

            val success =
                { propertyNavigator.navigate(Properties) }

            val createPropertyRequest = CreatePropertyRequest(
                name = propertyName,
                units = numberOfUnits,
                address = address,
                city = city,
                state = state,
                zip = zip,
                managementCompany = managementCompany,
                subscriptionType = subscriptionType,
                mode = mode,
                propertyType = propertyType,
                timezoneId = timezoneId,
            )

            val property = when (val maybeProperty = propertyRepository.createProperty(createPropertyRequest)) {
                is Try.Success -> maybeProperty.value
                is Try.Error -> {
                    screen.setSaveError(maybeProperty.exception)
                    screen.setErrorMessage("Save failed: ${maybeProperty.exception}")
                    return
                }
            }

            val fireClaimList = mutableListOf<FireClaimData>()
            val currentYear = Clock.System.now().toLocalDateTime(TimeZone.currentSystemDefault()).year
            fireClaimList.add(FireClaimData(property.id, currentYear - 0, screen.getFireClaimsOneClaimCount(), screen.getFireClaimsOneCost()))
            fireClaimList.add(FireClaimData(property.id, currentYear - 1, screen.getFireClaimsTwoClaimCount(), screen.getFireClaimsTwoCost()))
            fireClaimList.add(FireClaimData(property.id, currentYear - 2, screen.getFireClaimsThreeClaimCount(), screen.getFireClaimsThreeCost()))
            fireClaimList.add(FireClaimData(property.id, currentYear - 3, screen.getFireClaimsFourClaimCount(), screen.getFireClaimsFourCost()))
            fireClaimList.add(FireClaimData(property.id, currentYear - 4, screen.getFireClaimsFiveClaimCount(), screen.getFireClaimsFiveCost()))

            val result = when (val maybeFireClaims = propertyRepository.modifyFireClaims(PropertyFireClaimRequest(fireClaimList, property.id))) {
                is Try.Success -> maybeFireClaims.value
                is Try.Error -> {
                    screen.setSaveError(maybeFireClaims.exception)
                    screen.setErrorMessage("Save failed: ${maybeFireClaims.exception}")
                    false
                }
            }
            //Clear the default values for the screen, so they don't show up on a new property screen
            clearDefaultValues()

            val propertyAdmins = screen.getPropertyAdmins()
            if (propertyAdmins.isEmpty()) {
                success()
                return
            }
            propertyAdmins.forEach { propertyAdmin ->
                val errors = mutableListOf<String>()
                when (val maybeSuccess = propertyRepository.createPropertyAdmin(
                    propertyId = property.id,
                    propertyAdmin
                )) {
                    is Try.Success -> success()
                    is Try.Error -> {
                        if (maybeSuccess.exception is ClientRequestException &&
                            maybeSuccess.exception.response.status.value == 409) {
                            propertyAdmin.emailError = true
                            screen.setPropertyAdminsError(true)
                            errors += "Trying to add a Property Admin email that already exists."
                        }
                        screen.setSaveError(maybeSuccess.exception)
                        errors += "Save failed: ${maybeSuccess.exception}"
                        screen.setErrorMessage(errors.joinToString(", "))
                        return
                    }
                }
            }

            // Clear the property admin values, so they don't show up on a new property screen
            clearPropertyAdminValues()

        }
    }

    override fun propertyNameChanged(propertyName: String) {
        screen.setPropertyNameError(false)
        screen.setSaveError(null)
        screen.setPropertyName(propertyName)
    }

    override fun numberOfUnitsChanged(numberOfUnits: String) {

        screen.setSaveError(null)

        when (val maybeConverted: Try<Int> = Try.fromCallable { numberOfUnits.toInt() }) {
            is Try.Success -> {
                screen.setNumberOfUnits(maybeConverted.value)
                screen.setNumberOfUnitsError(false)
            }
            is Try.Error -> {
                screen.setNumberOfUnitsError(true)
            }
        }

    }

    override fun addressChanged(address: String) {
        screen.setSaveError(null)
        screen.setAddress(address)
        screen.setAddressError(false)
    }

    override fun cityChanged(city: String) {
        screen.setSaveError(null)
        screen.setCity(city)
        screen.setCityError(false)
    }

    override fun stateChanged(state: String) {
        screen.setSaveError(null)
        screen.setState(state)
        screen.setStateError(false)
    }

    override fun zipChanged(zip: String) {
        screen.setSaveError(null)
        when (val maybeConverted: Try<Int> = Try.fromCallable { zip.toInt() }) {
            is Try.Success -> {
                screen.setZip(maybeConverted.value)
                screen.setZipError(false)
            }
            is Try.Error -> {
                screen.setZipError(true)
            }
        }
    }

    override fun managementCompanyChanged(managementCompany: String) {
        screen.setSaveError(null)
        screen.setManagementCompanyError(false)
        screen.setManagementCompany(managementCompany)
    }

    override fun timezoneIdChanged(timezoneId: String) {
        screen.setSaveError(null)
        screen.setTimezoneId(timezoneId)
        screen.setTimezoneIdError(false)
    }

    override fun propertyAdminModelsChanged(toList: List<PropertyAdminModel>) {
        logger.e("In use case propertyAdminModelsChanged")
        screen.setPropertyAdmins(toList)
    }

    override fun subscriptionTypeChanged(subscriptionType: SubscriptionType) {
        screen.setSaveError(null)
        screen.setSubscriptionType(subscriptionType)
        screen.setSubscriptionTypeError(false)
    }

    override fun modeChanged(mode: Mode) {
        screen.setSaveError(null)
        screen.setMode(mode)
        screen.setModeError(false)
    }

    override fun propertyTypeChanged(propertyType: PropertyType) {
        screen.setSaveError(null)
        screen.setPropertyType(propertyType)
        screen.setPropertyTypeError(false)
    }

    override fun fireClaimsChangedOneClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsOneClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedOneCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsOneCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedTwoClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsTwoClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedTwoCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsTwoCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedThreeClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsThreeClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedThreeCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsThreeCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFourClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsFourClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFourCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsFourCost(cost)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFiveClaims(year: Int, claimCount: Int) {
        screen.setSaveError(null)
        screen.setFireClaimsFiveClaimCount(claimCount)
        screen.setFireClaimError(false)
    }

    override fun fireClaimsChangedFiveCost(year: Int, cost: Double) {
        screen.setSaveError(null)
        screen.setFireClaimsFiveCost(cost)
        screen.setFireClaimError(false)
    }

    private fun clearDefaultValues() {
        screen.setPropertyName("")
        screen.setNumberOfUnits(0)
        screen.setAddress("")
        screen.setCity("")
        screen.setState("")
        screen.setZip(0)
        screen.setManagementCompany("")
        screen.setSubscriptionType(SubscriptionType.Pro)
        screen.setMode(Active)
        screen.setPropertyType(PropertyType.MultiFamily)
        screen.setTimezoneId("")
        screen.setFireClaimsOneCost(0.0)
        screen.setFireClaimsTwoCost(0.0)
        screen.setFireClaimsThreeCost(0.0)
        screen.setFireClaimsFourCost(0.0)
        screen.setFireClaimsFiveCost(0.0)
        screen.setFireClaimsOneClaimCount(0)
        screen.setFireClaimsTwoClaimCount(0)
        screen.setFireClaimsThreeClaimCount(0)
        screen.setFireClaimsFourClaimCount(0)
        screen.setFireClaimsFiveClaimCount(0)
    }

    private fun clearPropertyAdminValues() {
        screen.setPropertyAdmins(emptyList())
    }

}