package com.fireavert.menu

object RoutePaths {
    const val appPrefix = "/app"
    const val root = "/"
    const val resetPassword = "$appPrefix/reset_password"
    const val invite = "$appPrefix/invite"
    const val infoPage = "$appPrefix/device_info"
    const val reboot = "$appPrefix/reboot"
    const val dashboard = "$appPrefix/dashboard"
    const val properties = "$appPrefix/properties"
    const val units = "$appPrefix/units"
    const val addNewProperty = "$appPrefix/properties/add_new"
    const val users = "$appPrefix/users"
    const val unsubscribe = "$appPrefix/unsubscribe"
    const val resetPasswordSent = "$appPrefix/reset_password_sent"
    const val administration = "$appPrefix/administration"
    const val reports = "$appPrefix/reports"
    const val help = "$appPrefix/help"
}
