package com.fireavert.user.logic

import kotlinx.serialization.Serializable

@Serializable
enum class PageName {
    Dashboard,
    Properties,
    PropertyDetails,
    Administration
}
