package com.fireavert.administration_page.user_tracking.frameworks

import com.fireavert.common.Try
import com.fireavert.administration_page.user_tracking.interface_adapters.UserTrackingRecordDTO
import com.fireavert.administration_page.user_tracking.interface_adapters.UserTrackingRepositoryDataSource
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class UserTrackingRepositoryDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : UserTrackingRepositoryDataSource {
    override suspend fun getUserTrackingData(token: String): Try<List<UserTrackingRecordDTO>> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/userTrackingRecords")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}