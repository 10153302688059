package com.fireavert.components.pages

import com.fireavert.components.unauthenticated.useQuery
import com.fireavert.logging.Logger
import com.fireavert.unsubscribe.adapters.UnsubscribeController
import com.fireavert.unsubscribe.adapters.UnsubscribeViewModel
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.launch
import react.FC
import react.dom.html.ReactHTML.div
import react.useEffectOnce
import web.cssom.TextAlign

val UnsubscribePage = FC {
    val queryString = useQuery() ?: ""

    val controller = getKoinInstance<UnsubscribeController>()
    val viewModel = getKoinInstance<UnsubscribeViewModel>()
    val logger = getKoinInstance<Logger>()

    useEffectOnce {
        val sections = queryString.split("&")
        for (section in sections) {
            val keyValue = section.split("=")
            if (keyValue.size < 2) continue
            if (keyValue[0] == "email") {
                viewModel.scope.launch {
                    controller.unsubscribe(keyValue[1])
                }
            }
        }
    }

    div {
        css {
            textAlign = TextAlign.center
        }
        +"You have been unsubscribed"
    }
}