package com.fireavert.components.sort_bar

import emotion.css.ClassName
import web.cssom.*

object SortBarStyles {
    val arrowsContainer = ClassName {
        display = Display.inlineBlock
    }
    val upArrowColumn = ClassName {
        marginLeft = 6.px
        width = 0.px
        height = 0.px
        borderBottom = Border(4.px, LineStyle.solid, Color("#A0ADBB"))
        borderTop = Border(0.px, LineStyle.solid, NamedColor.transparent)
        borderLeft = Border(3.px, LineStyle.solid, NamedColor.transparent)
        borderRight = Border(3.px, LineStyle.solid, NamedColor.transparent)
        marginBottom = 1.px
    }
    val downArrowColumn = ClassName {
        marginLeft = 6.px
        width = 0.px
        height = 0.px
        borderTop = Border(4.px, LineStyle.solid, Color("#A0ADBB"))
        borderBottom = Border(0.px, LineStyle.solid, NamedColor.transparent)
        borderLeft = Border(3.px, LineStyle.solid, NamedColor.transparent)
        borderRight = Border(3.px, LineStyle.solid, NamedColor.transparent)
        marginTop = 1.px
    }

}