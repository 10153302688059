package com.fireavert.properties.presentation

import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.PropertyNavigator.Destination.AddNewProperty
import com.fireavert.properties.logic.PropertyNavigator.Destination.EditProperty
import com.fireavert.properties.logic.PropertyNavigator.Destination.Properties
import com.fireavert.properties.logic.PropertyNavigator.Destination.Property
import com.fireavert.properties.logic.PropertyNavigator.Destination.Reload
import com.fireavert.properties.logic.PropertyNavigator.Destination.RemovePropertyAdmin

class RoutedPropertyNavigator(private val propertyRouter: PropertyRouter) : PropertyNavigator {
    override fun navigate(destination: PropertyNavigator.Destination) {
        when (destination) {
            AddNewProperty -> propertyRouter.routeToAddNewPropertyDialog()
            Properties -> propertyRouter.routeToProperties()
            is Property -> propertyRouter.routeToProperty(destination.propertyId)
            is RemovePropertyAdmin -> propertyRouter.routeToRemovePropertyAdmin(
                destination.propertyId,
                destination.propertyAdminUserId
            )
            is EditProperty -> propertyRouter.routeToEditProperty(destination.propertyId)
            Reload -> propertyRouter.reload()
        }
    }
}