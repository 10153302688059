package com.fireavert.properties.api

import com.fireavert.properties.logic.models.PropertyAdminModel
import com.fireavert.properties.logic.models.response.PropertyAdminResponse

private object PropertyAdminMapper {
    fun map(from: PropertyAdminResponse): PropertyAdminModel {
        val model = PropertyAdminModel(
            firstName = from.firstName ?: "",
            lastName = from.lastName ?: "",
            phone = from.phone ?: "",
            email = from.email ?: "",
            stoveTextNotifications = from.stoveTextNotifications ?: false,
            leakTextNotifications = from.leakTextNotifications ?: false,
            tamperTextNotifications = from.tamperTextNotifications ?: false,
            stoveEmailNotifications = from.stoveEmailNotifications ?: false,
            leakEmailNotifications = from.leakEmailNotifications ?: false,
            tamperEmailNotifications = from.tamperEmailNotifications ?: false,
            smokeAlarmEmailNotifications = from.smokeAlarmEmailNotifications ?: false,
            smokeAlarmTextNotifications = from.smokeAlarmTextNotifications ?: false,
            stoveOfflineTextNotifications = from.stoveOfflineTextNotifications ?: false,
            stoveOfflineEmailNotifications = from.stoveOfflineEmailNotifications ?: false,
            leakOfflineTextNotifications = from.leakOfflineTextNotifications ?: false,
            leakOfflineEmailNotifications = from.leakOfflineEmailNotifications ?: false,
            tamperOfflineTextNotifications = from.tamperOfflineTextNotifications ?: false,
            tamperOfflineEmailNotifications = from.tamperOfflineEmailNotifications ?: false,
            dailyReports = from.dailyReports ?: false,
            weeklyReports = from.weeklyReports ?: false,
            monthlyReports = from.monthlyReports ?: false,
            quarterlyReports = from.quarterlyReports ?: false,
            yearlyReports = from.yearlyReports ?: false,
            existsOnServer = true
        )
        model.id = from.userId.toString()
        return model
    }

    fun map(from: PropertyAdminModel): PropertyAdminResponse {
        val id = from.id.toInt()
        return PropertyAdminResponse(
            userId = id,
            firstName = from.firstName,
            lastName = from.lastName,
            phone = from.phone,
            email = from.email,
            stoveTextNotifications = from.stoveTextNotifications,
            leakTextNotifications = from.leakTextNotifications,
            tamperTextNotifications = from.tamperTextNotifications,
            stoveEmailNotifications = from.stoveEmailNotifications,
            leakEmailNotifications = from.leakEmailNotifications,
            tamperEmailNotifications = from.tamperEmailNotifications,
            smokeAlarmEmailNotifications = from.smokeAlarmEmailNotifications,
            smokeAlarmTextNotifications = from.smokeAlarmTextNotifications,
            stoveOfflineTextNotifications = from.stoveOfflineTextNotifications,
            stoveOfflineEmailNotifications = from.stoveOfflineEmailNotifications,
            leakOfflineTextNotifications = from.leakOfflineTextNotifications,
            leakOfflineEmailNotifications = from.leakOfflineEmailNotifications,
            tamperOfflineTextNotifications = from.tamperOfflineTextNotifications,
            tamperOfflineEmailNotifications = from.tamperOfflineEmailNotifications,
            dailyReports = from.dailyReports,
            weeklyReports = from.weeklyReports,
            monthlyReports = from.monthlyReports,
            quarterlyReports = from.quarterlyReports,
            yearlyReports = from.yearlyReports
        )
    }
}

fun List<PropertyAdminResponse>.toPropertyAdminModels(): List<PropertyAdminModel> {
    return this.map { PropertyAdminMapper.map(from = it) }
}

fun List<PropertyAdminModel>.toPropertyAdminResponses(): List<PropertyAdminResponse> {
    return this.map { PropertyAdminMapper.map(from = it) }
}