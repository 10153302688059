package com.fireavert.user.data

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.user.api.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*

class ClientUserDataSource(private val httpClient: HttpClient, private val baseUrl: String, private val logger: Logger) : UserDataSource {
    override suspend fun getUserAuth(request: UserAuthRequest): Try<UserAuthResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                url("$baseUrl/auth")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getUserByToken(request: UserByTokenRequest): Try<UserByTokenResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer ${request.token}")
                url("$baseUrl/user")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getUserAuthByRefresh(request: UserAuthByRefreshRequest): Try<UserAuthResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                url("$baseUrl/refresh")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getUserIdByInviteToken(token: UserIdByInviteTokenRequest): Try<UserIdByInviteTokenResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url("$baseUrl/user/invite/${token.token}")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun setPassword(request: SetPasswordRequest): Try<SetPasswordResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                url("$baseUrl/user/password")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun checkAdminRights(token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response: HttpResponse
            try {
                response = httpClient.post {
                    url("$baseUrl/user/adminCheck")
                    header("Authorization", "Bearer $token")
                }
            } catch (e: Exception) {
                logger.e("Error: $e")
                throw e
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}