    package com.fireavert.components.dialogs

    import com.fireavert.gateways.presentation.GatewayDialogController
    import com.fireavert.styles.GatewayStyles
    import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
    import emotion.css.ClassName
    import emotion.css.cx
    import kotlinx.coroutines.MainScope
    import kotlinx.coroutines.launch
    import mui.material.*
    import mui.system.Breakpoint
    import mui.system.sx
    import react.FC
    import react.Props
    import react.ReactNode
    import react.dom.html.ReactHTML
    import react.dom.onChange
    import react.useState
    import web.cssom.*
    import web.html.HTMLInputElement
    import web.html.HTMLTextAreaElement

    external interface EditGatewayDialogProps : Props {
        var propsGatewayName: String
        var propsGatewayDescription: String
        var propsGatewayId: String
        var propsController: GatewayDialogController
        var onClose: () -> Unit
    }

    val EditGatewayDialog = FC<EditGatewayDialogProps> { props ->
        var gatewayName by useState(props.propsGatewayName)
        var gatewayDescription by useState(props.propsGatewayDescription)

        Dialog {
            open = true
            onClose = { _, _ -> props.onClose() }
            onBackdropClick = { props.onClose() }
            fullWidth = true
            maxWidth = Breakpoint.lg
            sx {
                "& .MuiDialog-paper" {
                    width = 100.pct
                    maxWidth = 600.px
                    height = 400.px
                    margin = Auto.auto
                }
            }
            DialogTitle {
                sx {
                    backgroundColor = Color("#D9D9D9")
                }
                ReactHTML.div {
                    className = cx(GatewayStyles.gatewayHeaderWithButton, ClassName {
                        color = NamedColor.black
                    })
                    ReactHTML.div {
                        +"Edit Gateway"
                    }
                }
            }

            DialogContent {
                sx {
                    width = 100.pct
                    media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                        maxWidth = 100.pct
                    }
                    backgroundColor = NamedColor.white
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    gap = 20.px
                }

                TextField {
                    sx { marginTop = 20.px }
                    label = ReactNode("Gateway Name")
                    value = gatewayName
                    fullWidth = true
                    onChange = { event ->
                        gatewayName = (event.target as? HTMLInputElement)?.value ?: ""
                    }
                }

                TextField {
                    label = ReactNode("Gateway Description")
                    value = gatewayDescription
                    fullWidth = true
                    fullWidth = true
                    multiline = true
                    onChange = { event ->
                        gatewayDescription = (event.target as? HTMLTextAreaElement)?.value ?: ""
                    }
                }

                Button {
                    sx {
                        marginTop = 16.px
                        alignSelf = AlignSelf.flexEnd
                    }
                    variant = ButtonVariant.contained
                    onClick = {
                        MainScope().launch {
                            props.propsController.updateGatewayDetails(
                                gatewayName,
                                gatewayDescription,
                                props.propsGatewayId
                            )
                        }
                        props.onClose()
                    }
                    +"Save"
                }
            }
        }
    }