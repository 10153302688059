package com.fireavert.components.nav

import com.fireavert.common.Try
import com.fireavert.menu.presentation.MenuController
import com.fireavert.preferences.logic.Preferences
import com.fireavert.styles.NavStyles
import com.fireavert.user.api.ClientUserRepository
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import web.cssom.*



val MobileNavV2 = FC<SideNavProps> { props ->
    val controller = getKoinInstance<MenuController>()
    val preferences = getKoinInstance<Preferences>()
    val adminCheck = getKoinInstance<ClientUserRepository>()
    var isAdmin: Boolean by useState(false)

    useEffectOnce {
        MainScope().launch {
            isAdmin = when(val maybe = adminCheck.userAdminCheck(preferences.userId)) {
                is Try.Success -> maybe.value
                is Try.Error -> false
            }
        }
    }

    ReactHTML.div {
        css {
            width = 240.px
            padding = 16.px
            backgroundColor = Color("#00000")
        }

        ReactHTML.div {
            ReactHTML.img {
                src = "/static/logo_v2.svg"
                alt = "FireAvert Logo"
                css {
                    width = 100.pct
                    maxWidth = 150.px
                }
            }
        }
    }

    mui.material.List {
        MobileNavItemV2 {
            onClick = { controller.clickedDashboard() }
            text = "Dashboard"
            isActive = props.activeNavOption is NavOption.Dashboard
            iconActiveLocation = "/static/dashboard_icon_20.svg"
        }
        MobileNavItemV2 {
            onClick = {
                controller.clickedProperties()
            }
            text = "Properties"
            isActive = props.activeNavOption is NavOption.Properties
            iconActiveLocation = "/static/properties_icon_22.svg"
        }

        MobileNavItemV2  {
            onClick = { controller.clickedReports() }
            isActive = props.activeNavOption is NavOption.Reports
            text = "Reports"
        }

        MobileNavItemV2 {
            onClick = {
                controller.clickedHelp()
            }
            text = "Help"
            isActive = props.activeNavOption is NavOption.Help
            iconActiveLocation = "/static/help_icon.svg"
        }
        if (isAdmin) {
            MobileNavItemV2 {
                onClick = {
                    controller.clickedAdministration()
                }
                text = "Administration"
                isActive = props.activeNavOption is NavOption.Administration
            }
        }
    }
}


val MobileNavItemV2 = FC<NavItemProps> { props ->

    div {
        className = if (props.isActive) NavStyles.navItemActive else NavStyles.navItem
        onClick = {
            props.onClick()
        }
        span {
            +props.text
        }
    }
}