package com.fireavert.properties.logic.models.request

import com.fireavert.properties.logic.models.FireClaimData
import kotlinx.serialization.Serializable

@Serializable
data class PropertyFireClaimRequest(
    val fireClaims: List<FireClaimData>? = emptyList(),
    val propertyId: Int
)
