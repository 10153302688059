package com.fireavert.reports_page.frameworks

import kotlinx.serialization.Serializable

@Serializable
enum class EventTypes {
    SMOKE,
    SHUTOFF,
    LEAK,
    TAMPER
}