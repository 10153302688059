package com.fireavert.events.activeevents

import com.fireavert.devices.logic.response.ActiveDeviceEventResponse
import com.fireavert.properties.logic.models.response.PropertyResponse

@kotlinx.serialization.Serializable
data class PropertyWithActiveEventsResponse(
    val property: PropertyResponse? = null,
    val activeDeviceEvents: List<ActiveDeviceEventResponse>? = null,
    val alarmActiveDeviceMap: Map<Int, Boolean>? = null
)