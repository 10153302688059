package com.fireavert.events.rebootdevice.presentation

import com.fireavert.common.ViewModel
import com.fireavert.events.rebootdevice.logic.RebootDeviceDialogScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class RebootDeviceDialogViewModel : ViewModel(), RebootDeviceDialogScreen {
    private val _viewState = MutableStateFlow(RebootDeviceViewState())
    val viewState = _viewState.asStateFlow()
    override val scope: CoroutineScope
        get() = viewModelScope
    override var loading: Boolean
        get() = _viewState.value.loading
        set(value) {
            _viewState.value = viewState.value.copy(loading = value)
        }
    override var success: Boolean
        get() = viewState.value.success
        set(value) {
            _viewState.value = viewState.value.copy(success = value)
        }
}