package com.fireavert.units.presentation

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceType.FireAvert
import com.fireavert.devices.logic.models.PowerStatus
import com.fireavert.devices.logic.models.StatusLevel
import com.fireavert.devices.logic.models.StatusLevel.None
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.EventType.Unknown

data class UnitDetailsViewState(
    val isLoading: Boolean = false,
    val unitNumber: String = "",
    val propertyName: String = "",
    val address: String = "",
    val city: String = "",
    val state: String = "",
    val zip: String = "",
    val tenantName: String = "",
    val tenantEmail: String = "",
    val tenantPhone: String = "",
    val totalAlarmEvents: Int = 0,
    val totalShutoffEvents: Int = 0,
    val totalLeakEvents: Int = 0,
    val totalFlowEvents: Int = 0,
    val totalTamperEvents: Int = 0,
    val devices: List<UnitDetailsDeviceData> = emptyList(),
    val eventHistory: List<UnitDetailsEventHistoryData> = emptyList(),
    val isPowerOn: Boolean = false,
) {
}

data class UnitDetailsDeviceData(
    val id: Int = 0,
    val type: DeviceType = FireAvert,
    val location: String = "",
    val deviceId: String = "",
    val connectionStatus: String = "",
    val connectionStatusLevel: StatusLevel = None,
    val deviceStatus: String = "",
    val deviceStatusLevel: StatusLevel = None,
    val deviceIsTriggered: Boolean = false,
    val powerOn: PowerStatus = PowerStatus.UNKNOWN,
)

data class UnitDetailsEventHistoryData(
    val eventId: Long = 0,
    val dateTime: String = "",
    val timestamp: Long = 0,
    val deviceType: DeviceType = FireAvert,
    val location: String = "",
    val eventType: EventType = Unknown,
    val alarmActive: Boolean = false,
    val deviceTriggered: Boolean = false,
    val isTest: Boolean = false,
    val isFalseAlert: Boolean = false
)