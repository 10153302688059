package com.fireavert.dashboard.adapters

import com.fireavert.common.ViewModel
import com.fireavert.dashboard.logic.DashboardScreen
import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.events.logic.models.EventLinkedAction
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.datetime.LocalDate

class DashboardViewModel: ViewModel(), DashboardScreen {
    val scope = viewModelScope
    private val _viewState = MutableStateFlow(DashboardViewState())
    val viewState = _viewState.asStateFlow()
    override var propertyMap: Map<String, Int?>
        get() = viewState.value.propertyMap
        set(value) {_viewState.value = viewState.value.copy(propertyMap = value)}
    override var startDate: LocalDate?
        get() = viewState.value.startDate
        set(value) {_viewState.value = viewState.value.copy(startDate = value)}
    override var endDate: LocalDate?
        get() = viewState.value.endDate
        set(value) {_viewState.value = viewState.value.copy(endDate = value)}
    override var propertyId: Int?
        get() = viewState.value.propertyId
        set(value) {_viewState.value = viewState.value.copy(propertyId = value)}
    override var isLoading: Boolean
        get() = viewState.value.isLoading
        set(value) {_viewState.value = viewState.value.copy(isLoading = value)}
    override var resetActions: List<EventLinkedAction>
        get() = viewState.value.resetActions
        set(value) {_viewState.value = viewState.value.copy(resetActions = value)}
    override var textAlertActions: List<EventLinkedAction>
        get() = viewState.value.textAlertActions
        set(value) {_viewState.value = viewState.value.copy(textAlertActions = value)}
    override var shutoffActions: List<EventLinkedAction>
        get() = viewState.value.shutoffActions
        set(value) {_viewState.value = viewState.value.copy(shutoffActions = value)}
    override var offlineEvents: List<DeviceEvent>
        get() = viewState.value.offlineEvents
        set(value) {_viewState.value = viewState.value.copy(offlineEvents = value)}
    override var tamperEvents: List<DeviceEvent>
        get() = viewState.value.tamperEvents
        set(value) {_viewState.value = viewState.value.copy(tamperEvents = value)}
    override var waterEvents: List<DeviceEvent>
        get() = viewState.value.waterEvents
        set(value) {_viewState.value = viewState.value.copy(waterEvents = value)}
    override var smokeEvents: List<DeviceEvent>
        get() = viewState.value.smokeEvents
        set(value) {_viewState.value = viewState.value.copy(smokeEvents = value)}
}