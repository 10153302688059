package com.fireavert.components.administration

import com.fireavert.common.TableColumn
import com.fireavert.components.dialogs.UserMobileDialog
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.events.logic.InstantTimeStringFormatter
import com.fireavert.styles.LoadingCircleStyle
import com.fireavert.logging.Logger
import com.fireavert.styles.HelpStyles
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.TableStyles
import com.fireavert.administration_page.user_tracking.interface_adapters.UserTrackingSectionController
import com.fireavert.administration_page.user_tracking.interface_adapters.UserTrackingSectionViewModel
import com.fireavert.administration_page.user_tracking.logic.UserTrackingRecord
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import mui.material.CircularProgress
import mui.material.CircularProgressColor
import mui.material.TextField
import mui.system.useMediaQuery
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.px
import web.html.HTMLInputElement

external interface UserTrackingSectionProps : Props {
    var isUserDialogOpen: Boolean
}

val UserTrackingSection = FC<UserTrackingSectionProps> { props ->
    val controller = getKoinInstance<UserTrackingSectionController>()
    val viewModel = getKoinInstance<UserTrackingSectionViewModel>()
    val isMobile = useMediaQuery(MOBILE_VIEW_WIDTH)
    val logger = getKoinInstance<Logger>()
    if (props.isUserDialogOpen) {
        UserMobileDialog {}
    }
    var desktopColumns: Array<TableColumn> by useState(arrayOf(
        TableColumn(
            text = "NAME",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "EMAIL",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "PHONE NUMBER",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "MANAGEMENT COMPANY",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "LAST WEBSITE VISIT",
            sortOrder = TableColumn.SortOrder.LowToHigh,
            alignment = TableColumn.Alignment.Left
        )
    ))

    var mobileColumns: Array<TableColumn> by useState(arrayOf(
        TableColumn(
            text = "NAME",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        ),
        TableColumn(
            text = "MANAGEMENT COMPANY",
            sortOrder = TableColumn.SortOrder.Neutral,
            alignment = TableColumn.Alignment.Left
        )
    ))
    var loading: Boolean by useState(false)
    var trackingRecords: Array<UserTrackingRecord> by useState(emptyArray())
    var expandedRows: Set<Int> by useState(emptySet())

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            loading = viewState.loading
            trackingRecords = viewState.userTrackingRecords.toTypedArray()
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad()
        }
        cleanup {
            viewStateJob.cancel()
            onLoadJob.cancel()
        }
    }

    fun toggleRowExpansion(index: Int) {
        expandedRows = if (expandedRows.contains(index)) {
            expandedRows - index
        } else {
            expandedRows + index
        }
    }
    div {
        className = HelpStyles.helpSectionTitle
        +"Administration Page"
    }
    if (isMobile) {
        div {
            className = TableStyles.tableTitle
            +"Users"
        }
        div {
            className = TableStyles.tableContainer
            if (loading) {
                div {
                    className = LoadingCircleStyle.loadingCircleAdminPage
                    span {
                        CircularProgress {
                            color = CircularProgressColor.info
                            size = 150.px
                        }
                    }
                }
            } else {
                div {
                    className = TableStyles.table
                    SortBar {
                        this.columns = mapToControlColumns(mobileColumns)
                        includeLoading = false
                        isLoading = loading
                        columnsChanged = {
                            mobileColumns = mapToStateColumns(it)
                        }
                    }

                    val userTrackingRecords =
                        controller.sortTrackingRecordsByColumn(mobileColumns, trackingRecords.toList(), "")

                    for ((index, userTrackingRecord) in userTrackingRecords.withIndex()) {
                        div {
                            className = TableStyles.tableRow
                            onClick = { toggleRowExpansion(index) }
                            div {
                                className = TableStyles.tableCell
                                +userTrackingRecord.name
                            }
                            div {
                                className = TableStyles.tableCell
                                if (userTrackingRecord.managementCompany != "") {
                                    +userTrackingRecord.managementCompany
                                } else {
                                    +"-"
                                }
//                                img {
//                                    src = "/static/mobile_arrow_black.svg"
//                                }
                            }
                        }
                    }
                }
            }
        }
    }
    else {
        val (searchValue, setSearchValue) = useState("")
        div {
            className = TableStyles.tableContainer
            div {
                className = TableStyles.tableTitle
                +"Users"
                TextField {
                    className = SearchbarStyles.searchBarInputNoButton(30)
                    placeholder = "Search users..."
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }
            if (loading) {
                div {
                    className = LoadingCircleStyle.loadingCircleAdminPage
                    span {
                        CircularProgress {
                            color = CircularProgressColor.info
                            size = 150.px
                        }
                    }
                }
            } else {
                div {
                    className = TableStyles.table
                    SortBar {
                        this.columns = mapToControlColumns(desktopColumns)
                        includeLoading = true
                        isLoading = loading
                        columnsChanged = {
                            desktopColumns = mapToStateColumns(it)
                        }
                    }

                    val userTrackingRecords =
                        controller.sortTrackingRecordsByColumn(desktopColumns, trackingRecords.toList(), searchValue)

                    for ((index, userTrackingRecord) in userTrackingRecords.withIndex()) {
                        val lastVisitMap: Map<Instant, String?> = userTrackingRecord.userVisitLog.mapKeys { entry ->
                            entry.key ?: Instant.fromEpochSeconds(0)
                        }

                        div {
                            className = TableStyles.tableRow
                            onClick = { toggleRowExpansion(index) }
                            div {
                                className = TableStyles.tableCell
                                +userTrackingRecord.name
                            }
                            div {
                                className = TableStyles.tableCell
                                +userTrackingRecord.email
                            }
                            div {
                                className = TableStyles.tableCell
                                +userTrackingRecord.phone
                            }
                            div {
                                className = TableStyles.tableCell
                                +userTrackingRecord.managementCompany
                            }
                            div {
                                className = TableStyles.tableCell
                                if (lastVisitMap.isNotEmpty()) {
                                    +InstantTimeStringFormatter.formatA(
                                        lastVisitMap.keys.first()
                                    )
                                }
                            }
                        }
                        if (expandedRows.contains(index)) {
                            for ((timestamp, page) in lastVisitMap.toList().drop(1)) {
                                div {
                                    className = TableStyles.tableRowVisits

                                    div { className = TableStyles.tableCell }
                                    div { className = TableStyles.tableCell }
                                    div { className = TableStyles.tableCell }
                                    div {
                                        className = TableStyles.tableCellUserVisit
                                        +"Page: ${page.orEmpty()}"
                                    }
                                    div {
                                        className = TableStyles.tableCellUserVisit
                                        +"Time: ${InstantTimeStringFormatter.formatA(timestamp)}"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
