package com.fireavert.units.presentation

import com.fireavert.common.ViewModel
import com.fireavert.units.logic.UnitsSectionScreen
import com.fireavert.units.logic.models.UnitModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class UnitsSectionViewModel : ViewModel(), UnitsSectionScreen {
    override val scope = viewModelScope
    override var unitModels: List<UnitModel>
        get() = viewState.value.unitModels
        set(value) {
            _viewState.value = viewState.value.copy(unitModels = value)
        }
    override var loading: Boolean
        get() = viewState.value.loading
        set(value) {
            _viewState.value = viewState.value.copy(loading = value)
        }
    override var showAddUnit: Boolean
        get() = viewState.value.showAddUnit
        set(value) {
            _viewState.value = _viewState.value.copy(showAddUnit = value)
        }
    override var showEditUnit: Boolean
        get() = viewState.value.showEditUnit
        set(value) {
            _viewState.value = viewState.value.copy(showEditUnit = value)
        }
    override var showDeleteUnit: Boolean
        get() = viewState.value.showDeleteUnit
        set(value) {
            _viewState.value = viewState.value.copy(showDeleteUnit = value)
        }

    private val _viewState = MutableStateFlow(UnitsSectionViewState())
    val viewState = _viewState.asStateFlow()

}