package com.fireavert.devices.logic.requests

import com.fireavert.devices.logic.models.DeviceType
import kotlinx.serialization.Serializable

@Serializable
data class ModifyDeviceRequest(
    val id: Int? = null,
    val deviceLocator: String? = null,
    val location: String? = null,
    val unitId: Int? = null,
    val type: DeviceType? = null,
    val commVersion: Int? = null,
    val rebootUUID: String = "",
    val infoUUID: String = "",
)
