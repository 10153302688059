package com.fireavert.components.dashboard

import com.fireavert.styles.NavStylesV2
import emotion.react.css
import react.FC
import react.Props
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import web.cssom.Color
import web.cssom.px

external interface DashboardDropdownProps : Props {
    var labelText: String
    var optionMap: Map<String, Int?>
    var onChange: (Int?) -> Unit
    var mobileVersion: Boolean
    var disabled: Boolean
}

val DashboardDropdown = FC<DashboardDropdownProps> { props ->
    div {
        ariaLabel = "Dashboard Dropdown"
        div {
            css {
                fontSize = 16.px
                color = Color("#939598")
            }
            if (!props.mobileVersion) {
                +props.labelText
            }
        }

        select {
            className = if(props.disabled) NavStylesV2.dashboardDropdownDisabled else NavStylesV2.dashboardDropdown
            onChange = { changeEvent ->
                props.onChange(changeEvent.target.value.toIntOrNull())
            }
            props.optionMap.forEach { mapEntry ->
                option {
                    +mapEntry.key
                    value = mapEntry.value.toString()
                }
            }
        }
    }
}