package com.fireavert.theme

import js.objects.jso
import mui.material.PaletteMode
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import web.cssom.*
import web.cssom.atrule.maxWidth

private val TYPOGRAPHY_OPTIONS = TypographyOptions {
    fontFamily = string("Open Sans")
    fontWeight = integer(400)

    TypographyVariant.h6 {
        fontSize = 1.5.rem

        media(maxWidth(599.px)) {
            fontSize = 1.25.rem
        }
    }
}

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.light
                primary = jso {
                    main = Color("#3580E1")
                }
                secondary = jso {
                    main = Color("#FB6B5B")
                }
                info =  jso {
                    main = Color("#000000")
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.dark
                primary = jso {
                    main = Color("#3580E1")
                }
                secondary = jso {
                    main = Color("#FB6B5B")
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        }
    )
}