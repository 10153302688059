package com.fireavert.properties.logic

import com.fireavert.common.Try
import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.logic.models.PropertyAdminModel
import com.fireavert.properties.logic.models.request.CreatePropertyRequest
import com.fireavert.properties.logic.models.request.PropertyFireClaimRequest
import com.fireavert.gateways.models.request.PropertyGatewayChangeRequest

interface ClientPropertyRepository {
    suspend fun createProperty(request: CreatePropertyRequest): Try<Property>
    suspend fun getProperties(idList: List<Int> = emptyList()): Try<List<Property>>
    fun getPropertyByIdSync(id: Int): Try<Property>
    suspend fun getPropertyByIdAsync(id: Int): Try<Property>
    suspend fun modifyProperty(
       property: Property
    ): Try<Property>
    suspend fun deleteProperty(propertyId: Int): Try<Boolean>
    suspend fun getPropertyAdmins(propertyId: Int): Try<List<PropertyAdminModel>>
    suspend fun deletePropertyAdmin(propertyId: Int, propertyAdminUserId: Int): Try<Boolean>
    suspend fun createPropertyAdmin(propertyId: Int, propertyAdminModel: PropertyAdminModel): Try<Boolean>
    suspend fun modifyPropertyAdmin(propertyId: Int, propertyAdminModel: PropertyAdminModel): Try<Boolean>
    suspend fun getUnlinkedPropertyAdmins(propertyId: Int): Try<List<PropertyAdminModel>>
    suspend fun linkPropertyAdmin(propertyId: Int, propertyAdminId: Int): Try<Boolean>
    suspend fun resendPropertyAdminInvite(id: String): Try<Boolean>
    suspend fun getFireClaims(propertyId: Int): Try<List<FireClaimData>>
    suspend fun modifyFireClaims(fireClaimRequest: PropertyFireClaimRequest): Try<Boolean>
    suspend fun getPropertyGateways(propertyId: Int): Try<List<GatewayModel>>
    suspend fun modifyGatewayChanges(propertyGatewayChangeRequest: PropertyGatewayChangeRequest): Try<Boolean>
}