package com.fireavert.components.datepicker

import emotion.react.css
import react.FC
import react.Props
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.div
import web.cssom.Color
import web.cssom.px
import kotlin.js.Date

external interface DashboardDatePickerProps : Props {
    var startDate: Date?
    var endDate: Date?
    var onChange: (startDate: Date?, endDate: Date?) -> Unit
}

val DashboardDatePicker = FC<DashboardDatePickerProps> { props ->

    div {
        ariaLabel = "Dashboard Date Picker"
        div {
            css {
                fontSize = 16.px
                color = Color("#939598")
            }
            +"Custom Date Range"
        }
        ReactDatePicker {
            selectsRange = true
            swapRange = true
            todayButton = "Today"
            onChange = {
                props.onChange(it[0], it[1])
            }
            startDate = props.startDate
            endDate = props.endDate
        }
    }
}