package com.fireavert.properties.presentation.property_portfolio

import com.fireavert.properties.logic.models.Property

data class PropertyPortfolioViewState(
    val properties: List<Property> = emptyList(),
    val loading: Boolean = true,
    val showCreateButton: Boolean = false,
    val showEditProperty: Boolean = false,
    val showDeleteProperty: Boolean = false
)
