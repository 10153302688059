package com.fireavert.user.api

import com.fireavert.user.logic.UserAuth

private object UserAuthMapper {
    fun mapToUserAuth(from: UserAuthResponse): UserAuth {
        return UserAuth(id = from.id ?: 0,
            token = from.token ?: "",
            refresh = from.refresh ?: "",
            roles = from.roles?.map { it.toRole() } ?: emptyList())
    }
}

fun UserAuthResponse.toUserAuth() = UserAuthMapper.mapToUserAuth(this)