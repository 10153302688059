package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.Padding
import web.cssom.VerticalAlign
import web.cssom.px

object ButtonStyles {
    val addButtonIcon = ClassName {
        width = 16.px
        height = 16.px
    }
    val trashIconContainerStyle = ClassName {
        padding = Padding(11.px, 0.px, 11.px, 60.px)
        width = 26.px
    }
    val trashIconStyle = ClassName {
        padding = 0.px
        verticalAlign = VerticalAlign.baseline
    }
}