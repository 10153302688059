package com.fireavert.units.logic

import com.fireavert.common.TableColumn
import com.fireavert.common.TableColumn.SortOrder.Neutral
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.units.logic.models.UnitModel
import com.fireavert.user.logic.Permission

class ClientUnitsInteractor(
    private val screen: UnitsSectionScreen,
    private val preferences: Preferences,
    private val unitsRepository: UnitsRepository,
    private val unitsNavigator: UnitsNavigator,
    private val logger: Logger
) : UnitsInteractor {
    override suspend fun onLoad(propertyId: Int) {
        screen.showAddUnit = preferences.permissions.contains(Permission.CreateUnits)
        screen.showEditUnit = preferences.permissions.contains(Permission.EditUnits)
        screen.showDeleteUnit = preferences.permissions.contains(Permission.DeleteUnits)
        screen.loading = true
        val unitModels = when (val maybeUnitModels = getUnitModels(propertyId)) {
            is Try.Error -> {
                screen.loading = false
                logger.d("Failed to get units for property id $propertyId.")
                null
            }
            is Try.Success -> {
                maybeUnitModels.value
            }
        } ?: return
        screen.unitModels = unitModels
        screen.loading = false
    }

    override fun showAddNewUnit(propertyId: Int) {
        unitsNavigator.navigate(UnitsNavigator.Destination.AddNewUnit(propertyId = propertyId))
    }

    override fun stringOutputForCount(count: Int): String {
        return if (count == -1) "-" else count.toString()
    }

    override fun clearState() {
        screen.unitModels = emptyList()
    }

    override fun searchAndsortWithColumns(
        columns: Array<TableColumn>,
        unitModels: Array<UnitModel>,
        searchValue: String
    ): Array<UnitModel> {
        val filteredUnits = searchUnits(searchValue, unitModels)
        val sortColumn = columns.firstOrNull { it.sortOrder != Neutral } ?: return filteredUnits

        return when (sortColumn.text) {
            "UNIT" -> {
                TableColumn.sortList(true, sortColumn, filteredUnits.toList()) { it.number }
                    .toTypedArray()
            }
            "CONNECTION STATUS" -> {
                TableColumn.sortList(true, sortColumn, filteredUnits.toList()) { it.status }
                    .toTypedArray()
            }
            "SMOKE EVENTS" -> {
                TableColumn.sortList(false, sortColumn, filteredUnits.toList()) { it.alarmEventCount }
                    .toTypedArray()
            }
            "STOVE SHUTOFFS" -> {
                TableColumn.sortList(
                    false,
                    sortColumn,
                    filteredUnits.toList()
                ) { it.shutoffEventCount }.toTypedArray()
            }
            "LEAK EVENTS" -> {
                TableColumn.sortList(false, sortColumn, filteredUnits.toList()) { it.leakEventCount }
                    .toTypedArray()
            }
            "TAMPER EVENTS" -> {
                TableColumn.sortList(false, sortColumn, filteredUnits.toList()) { it.tamperEventCount }
                    .toTypedArray()
            }
            else -> {
                filteredUnits
            }
        }
    }

    private fun searchUnits(searchValue: String, unitModels: Array<UnitModel>): Array<UnitModel> {
        return unitModels.filter { unit ->
            unit.number.contains(searchValue, ignoreCase = true)
        }.toTypedArray()
    }

    override fun showAddNewUnitCSV(propertyId: Int) {
        unitsNavigator.navigate(UnitsNavigator.Destination.AddNewUnitCSV(propertyId = propertyId))
    }

    override fun clickedCommV2Conversion(propertyId: Int) {
        unitsNavigator.navigate(UnitsNavigator.Destination.ConvertToCommV2(propertyId = propertyId))
    }

    private suspend fun getUnitModels(propertyId: Int): Try<List<UnitModel>> {
        return unitsRepository.getUnitsForPropertyId(propertyId)
    }
}