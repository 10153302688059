package com.fireavert.auth.interface_adapters

import com.fireavert.auth.logic.AuthenticateRequest
import com.fireavert.auth.logic.UserAuthenticator

class AuthController(private val userAuthenticator: UserAuthenticator) {
    fun clickedSignIn(email: String, password: String) {
        val authenticateRequest = AuthenticateRequest(email = email, password = password)
        userAuthenticator.authenticate(authenticateRequest)
    }

    fun didLoad() {
        userAuthenticator.checkAuthStatus()
    }

    fun emailChanged(email: String) = userAuthenticator.emailChanged(email)
    fun passwordChanged(password: String) = userAuthenticator.passwordChanged(password)
}