package com.fireavert.actions

import com.fireavert.styles.DashboardStylesV2.dialogIconContainer
import com.fireavert.styles.DialogStyles
import emotion.react.css
import mui.material.Dialog
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import web.cssom.*


external interface ActionCardMobileDialogProps : ActionCardProps {
    var titleText: String
    var onClose: () -> Unit
}

val ActionCardMobileDialog = FC<ActionCardMobileDialogProps> { props ->

    fun getImageSrc(titleText: String): String {
        return when (titleText) {
            "Stove Shutoff" -> "/static/mobile_stove_shutoff.svg"
            "Smoke detected" -> "/static/mobile_text_alert.svg"
            "Stove Reset" -> "/static/mobile_stove_reset.svg"
            else -> "/static/mobile_text_alert.svg"
        }
    }

    ReactHTML.div {
        css {
            position = Position.relative
        }
        ReactHTML.div {
            className = dialogIconContainer
            ReactHTML.img {
                src = getImageSrc(props.titleText)
                css {
                    width = 40.px
                    height = 40.px
                }
            }
        }
    }
    Dialog {
        open = true
        onClose = { _, _ ->
            props.onClose()
        }
        onBackdropClick = {}
        maxWidth = Breakpoint.lg
        fullWidth = true
        sx {
            "& .MuiDialog-paper" {
                height = 65.pct
                maxHeight = 65.pct
                margin = Auto.auto
                display = Display.flex
                flexDirection = FlexDirection.column
                padding = 0.px
                borderRadius = 10.px
            }
        }
        DialogTitle { sx {padding = 0.px} }
        DialogContent {
            sx {
                flex = number(1.0)
                display = Display.flex
                flexDirection = FlexDirection.column
                overflow = Overflow.hidden
                padding = 0.px
            }

            div {
                className = DialogStyles.mobileDialogActionTitle
                div {
                    css { fontSize = 16.px }
                    +props.titleText
                }
                div {
                    +props.number.toString()
                }
            }
            css {
                minHeight = 70.pct
            }
            if (props.actionCardTableData.isNotEmpty()) {
                ActionCardTable {
                    isMobile = true
                    actionCardTableData = props.actionCardTableData
                }
            }
            else{
                div {
                    css {
                        display = Display.flex
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        height = 100.pct
                    }
                    div {
                        css {
                            fontSize = 16.px
                            color = Color("#A6B0CF")
                            height = 70.pct
                        }
                        +"No data available"
                    }
                }
            }
        }
    }
}