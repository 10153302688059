package com.fireavert.components.properties

import com.fireavert.common.TableColumn
import com.fireavert.components.common.ThreeDotsButton
import com.fireavert.components.events.EventTotalCell
import com.fireavert.components.events.EventTypeIcon
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.events.activedeviceevents.interface_adapters.ActiveDeviceEventsController
import com.fireavert.events.activedeviceevents.interface_adapters.ActiveDeviceEventsViewModel
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvent
import com.fireavert.events.logic.models.EventType
import com.fireavert.events.logic.models.UnitEventTotals
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.styles.EventsStyles
import com.fireavert.styles.TableStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.dom.Link
import react.useEffectOnce
import react.useState

external interface DamageEventsHappeningNowProps : Props {
    var propertyId: Int
    var mobileVersion: Boolean
}

external interface EventsHappeningNowProps: DamageEventsHappeningNowProps {
    var propController: ActiveDeviceEventsController
    var propPreferences: Preferences
    var propIsLoading: Boolean
    var eventList: List<ActiveDeviceEvent>
    var mapUnitList: Map<Int, UnitEventTotals>

}

val DamageEventsHappeningNow = FC<DamageEventsHappeningNowProps> { props ->
    var events: List<ActiveDeviceEvent> by useState(emptyList())
    var alarmOnDeviceMap: Map<Int, Boolean> by useState(emptyMap())
    var unitEventTotalsMap: Map<Int, UnitEventTotals> by useState(emptyMap())
    var loading: Boolean by useState(false)

    val controller = getKoinInstance<ActiveDeviceEventsController>()
    val viewModel = getKoinInstance<ActiveDeviceEventsViewModel>()
    val preferences = getKoinInstance<Preferences>()
    val logger = getKoinInstance<Logger>()

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            events = viewState.events
            loading = viewState.loading
            alarmOnDeviceMap = viewState.alarmOnDeviceMap
            unitEventTotalsMap = viewState.unitEventTotalsMap
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(props.propertyId)
        }

        cleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    if (!props.mobileVersion) {
        DesktopEventsHappeningNowView {
            propertyId = props.propertyId
            propController = controller
            propPreferences = preferences
            propIsLoading = loading
            eventList = events
            mapUnitList = unitEventTotalsMap
        }
    }
    else {
        MobileEventsHappeningNowView {
            propertyId = props.propertyId
            propController = controller
            propPreferences = preferences
            propIsLoading = loading
            eventList = events
            mapUnitList = unitEventTotalsMap
        }

    }
}
val DesktopEventsHappeningNowView = FC<EventsHappeningNowProps> { props ->
    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "UNIT", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "DATE/TIME", sortOrder = TableColumn.SortOrder.HighToLow, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "EVENT TYPE", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "SMOKE EVENTS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "LEAK EVENTS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
            TableColumn(
                text = "TAMPER EVENTS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            ),
        )
    )

    div {
        className = TableStyles.scrolledTableContainer
        div {
            className = TableStyles.tableTitle
            +"Events Happening Right Now"
        }
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(stateColumns)
                includeLoading = true
                isLoading = props.propIsLoading
                columnsChanged = { newColumns ->
                    stateColumns = mapToStateColumns(newColumns)
                }
            }

            val sortedEvents = props.propController.sortWithColumns(
                stateColumns, props.eventList, props.mapUnitList
            )

            sortedEvents.forEach { activeDeviceEvent ->
                div {
                    className = TableStyles.tableRow
                    div {
                        className = TableStyles.tableCell
                        Link {
                            to = "${RoutePaths.properties}/${props.propertyId}/units/${activeDeviceEvent.unitId}"
                            +activeDeviceEvent.unit
                        }
                    }
                    div {
                        className = TableStyles.tableCell
                        +props.propController.getTimeForEvent(
                            event = activeDeviceEvent, timezoneId = props.propPreferences.timeZone
                        )
                    }
                    div {
                        className = TableStyles.tableCell
                        EventTypeIcon {
                            deviceTriggered = activeDeviceEvent.deviceTriggered
                            alarmActive = activeDeviceEvent.alarmActive
                            deviceType = activeDeviceEvent.deviceType
                            deviceOffline = activeDeviceEvent.deviceOffline
                            eventType = activeDeviceEvent.eventType
                            isMobile = false
                        }
                    }

                    val unitTotals = props.mapUnitList[activeDeviceEvent.unitId] ?: UnitEventTotals()


                    EventTotalCell {
                        hasEvents = unitTotals.alarmEvents > 0
                        style = EventsStyles.smokeText
                        text = props.propController.stringOutputForCount(unitTotals.alarmEvents)
                    }
                    EventTotalCell {
                        hasEvents = unitTotals.leakEvents > 0
                        style = EventsStyles.waterText
                        text = props.propController.stringOutputForCount(unitTotals.leakEvents)
                    }
                    EventTotalCell {
                        hasEvents = unitTotals.tamperEvents > 0
                        style = EventsStyles.tamperText
                        text = props.propController.stringOutputForCount(unitTotals.tamperEvents)
                    }

                    if ((activeDeviceEvent.eventType == EventType.SmokeAlarmActive && (activeDeviceEvent.alarmActive || activeDeviceEvent.deviceTriggered)) || (activeDeviceEvent.eventType == EventType.DeviceTriggered && activeDeviceEvent.deviceTriggered) || activeDeviceEvent.eventType == EventType.DeviceOffline) {
                        /** Show Nothing **/
                        div {
                            className = TableStyles.tableCell
                        }
                    } else {
                        div {
                            className = TableStyles.tableCell
                            ThreeDotsButton {
                                this.pathAndTextPairs = arrayOf(
                                    "${RoutePaths.properties}/${props.propertyId}/dismiss_event/${activeDeviceEvent.id}" to "Log Event",
                                    "${RoutePaths.properties}/${props.propertyId}/mark_as_test/${activeDeviceEvent.id}" to "Mark As Test",
                                    "${RoutePaths.properties}/${props.propertyId}/mark_as_false_alarm/${activeDeviceEvent.id}" to "Mark As False Alarm"
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

val MobileEventsHappeningNowView = FC<EventsHappeningNowProps> { props ->
    val loading: Boolean by useState(false)
    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "UNIT", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "DATE/TIME", sortOrder = TableColumn.SortOrder.HighToLow, alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "EVENT TYPE", sortOrder = TableColumn.SortOrder.Neutral, alignment = TableColumn.Alignment.Left
            )
        )
    )

    div {
        className = TableStyles.tableTitle
        +"Events Happening Right Now"
    }
    div {
        className = TableStyles.scrolledTableContainer
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(stateColumns)
                includeLoading = false
                isLoading = loading
                columnsChanged = { newColumns ->
                    stateColumns = mapToStateColumns(newColumns)
                }
            }

            val sortedEvents = props.propController.sortWithColumns(
                stateColumns, props.eventList, props.mapUnitList
            )

            sortedEvents.forEach { activeDeviceEvent ->
                div {

                    className = TableStyles.tableRow
                    div {
                        className = TableStyles.tableCell
                        Link {
                            to = "${RoutePaths.properties}/${props.propertyId}/units/${activeDeviceEvent.unitId}"
                            +activeDeviceEvent.unit
                        }
                    }
                    div {
                        className = TableStyles.tableCell
                        +props.propController.getTimeForEvent(
                            event = activeDeviceEvent, timezoneId = props.propPreferences.timeZone
                        )
                    }
                    div {
                        className = TableStyles.tableCell
                        EventTypeIcon {
                            deviceTriggered = activeDeviceEvent.deviceTriggered
                            alarmActive = activeDeviceEvent.alarmActive
                            deviceType = activeDeviceEvent.deviceType
                            deviceOffline = activeDeviceEvent.deviceOffline
                            eventType = activeDeviceEvent.eventType
                            isMobile = true
                        }
                    }
                }
            }
        }
    }
}
