package com.fireavert.units.logic

interface UnitsNavigator {
    sealed class Destination {
        data class AddNewUnit(val propertyId: Int) : Destination()
        data class AddNewUnitCSV(val propertyId: Int) : Destination()
        data class PropertyDetails(val propertyId: Int) : Destination()
        data class UnitDetails(val propertyId: Int, val unitId: Int) : Destination()
        data class ConvertToCommV2(val propertyId: Int) : Destination()
    }

    fun navigate(destination: Destination)
}