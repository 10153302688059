package com.fireavert.common

import com.fireavert.user.logic.TokenRefreshService
import io.ktor.client.plugins.ClientRequestException
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

open class ApiRepository(
    protected val ioContext: CoroutineDispatcher,
    protected val tokenRefreshService: TokenRefreshService,
) {
    private suspend fun <T> callWithRefreshCountdown(
        doRefresh: Boolean,
        callback: suspend () -> Try<T>
    ): Try<T> =
        withContext(ioContext) {
            when (val result = callback()) {
                is Try.Success -> {
                    result
                }
                is Try.Error -> {
                    if (result.exception is ClientRequestException &&
                        result.exception.response.status.value == 401 &&
                        doRefresh
                    ) {
                        tokenRefreshService.refreshToken { success ->
                            if (success) {
                                callWithRefreshCountdown(doRefresh = false, callback)
                            } else {
                                Try.Error(result.exception)
                            }
                        }
                    } else {
                        Try.Error(result.exception)
                    }
                }
            }
        }

    protected suspend fun <T> callWithRefresh(callback: suspend () -> Try<T>): Try<T> =
        withContext(ioContext) {
            callWithRefreshCountdown(doRefresh = true, callback)
        }
}