package com.fireavert.events.logic

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

/**
 * Formats Instant objects to strings
 */
object InstantTimeStringFormatter {
    /**
     * The timezone to use when converting the Instant to the desired format
     */
    private const val defaultZoneId = "America/Denver"
    val availableTimeZoneIds = TimeZone.availableZoneIds

    /**
     * Format an Instant to a string as listed below
     * 05/27/22 - 5:10 PM
     */
    fun formatA(instant: Instant, zoneId: String = defaultZoneId): String {
        val timeZone = TimeZone.of(zoneId = zoneId)
        val time = instant.toLocalDateTime(timeZone)

        val month = "${time.monthNumber}".padStart(2, '0')
        val day = "${time.dayOfMonth}".padStart(2, '0')
        val yearSecondLastDigit = "${time.year}"[2]
        val yearLastDigit = "${time.year}"[3]
        val year = "$yearSecondLastDigit$yearLastDigit"
        val hour = if (time.hour == 0 || time.hour == 12) 12 else (time.hour % 12)
        val minutes = "${time.minute}".padStart(2, '0')
        val ampm = if (time.hour >= 12) "PM" else "AM"

        return "$month/$day/$year - $hour:$minutes $ampm"
    }

    /**
     * Format an Instant to a string as listed below
     * 11/17/22 @ 2:07 PM
     */
    fun formatB(instant: Instant, zoneId: String = defaultZoneId): String {
        val timeZone = TimeZone.of(zoneId = zoneId)
        val time = instant.toLocalDateTime(timeZone)

        val month = "${time.monthNumber}".padStart(2, '0')
        val day = "${time.dayOfMonth}".padStart(2, '0')
        val yearSecondLastDigit = "${time.year}"[2]
        val yearLastDigit = "${time.year}"[3]
        val year = "$yearSecondLastDigit$yearLastDigit"
        val hour = if (time.hour == 0 || time.hour == 12) 12 else (time.hour % 12)
        val minutes = "${time.minute}".padStart(2, '0')
        val ampm = if (time.hour >= 12) "PM" else "AM"

        return "$month/$day/$year @ $hour:$minutes $ampm"
    }

    /**
     * Format an Instant to a string as listed below
     * 11/17/22
     */
    fun formatC(instant: Instant, zoneId: String = defaultZoneId): String {
        val timeZone = TimeZone.of(zoneId = zoneId)
        val time = instant.toLocalDateTime(timeZone)

        val month = "${time.monthNumber}".padStart(2, '0')
        val day = "${time.dayOfMonth}".padStart(2, '0')
        val yearSecondLastDigit = "${time.year}"[2]
        val yearLastDigit = "${time.year}"[3]
        val year = "$yearSecondLastDigit$yearLastDigit"
        return "$month/$day/$year"
    }

    /**
     * Format an instant to a time string as listed below
     * 5:10pm
     */
    fun formatTimeOnly(instant: Instant, zoneId: String = defaultZoneId): String {
        val timeZone = TimeZone.of(zoneId = zoneId)
        val time = instant.toLocalDateTime(timeZone)

        val hour = if (time.hour == 0 || time.hour == 12) 12 else (time.hour % 12)
        val minutes = "${time.minute}".padStart(2, '0')
        val ampm = if (time.hour >= 12) "pm" else "am"

        return "$hour:$minutes$ampm"
    }
}