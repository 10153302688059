package com.fireavert.user.api

import com.fireavert.user.logic.PageName
import kotlinx.serialization.Serializable

@Serializable
data class RoleResponse(
    val id: Int? = 0,
    val name: String? = "",
    val permissions: List<PermissionResponse>? = emptyList(),
    val pageVersions: Map<PageName, Int> = emptyMap()
)
