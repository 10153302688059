package com.fireavert.components.dialogs

import com.fireavert.styles.DialogStyles
import emotion.react.css
import mui.material.*
import mui.system.Breakpoint
import react.FC
import react.Props
import web.cssom.JustifyContent
import web.cssom.TextAlign
import web.cssom.important
import web.cssom.px

external interface EventActionDialogProps : Props {
    var title: String
    var close: () -> Unit
    var loading: Boolean
    var successMessage: String
}

val EventActionDialog = FC<EventActionDialogProps> { props ->

    Dialog {
        open = true
        onBackdropClick = { props.close() }
        onClose = { _,_ -> props.close() }
        maxWidth = Breakpoint.lg

        DialogTitle {
            +props.title
            className = DialogStyles.dialogTitle
        }
        if (props.loading) {
            DialogContent {
                css {
                    textAlign = TextAlign.center
                }
                CircularProgress {}
            }
        }
        else {
            DialogContent {
                css {
                    textAlign = TextAlign.center
                    paddingTop = important(20.px)
                }
                +props.successMessage
                DialogActions {
                    css {
                        height = 104.px
                        justifyContent = important(JustifyContent.center)
                    }
                    Button {
                        +"OK"
                        color = ButtonColor.inherit
                        onClick = {
                            props.close()
                        }
                        variant = ButtonVariant.contained
                        css {
                            width = 163.px
                        }
                    }
                }
            }
        }
    }

}