package com.fireavert.events.activeevents.presentation

import com.fireavert.common.ViewModel
import com.fireavert.events.activeevents.logic.ActiveEventsScreen
import com.fireavert.events.activeevents.domain.ActiveEvent
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ActiveEventsViewModel : ViewModel(), ActiveEventsScreen {
    private val _viewState = MutableStateFlow(ActiveEventsViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope

    override fun setLoading(loading: Boolean) {
        _viewState.value = _viewState.value.copy(loading = loading)
    }

    override fun setEvents(events: List<ActiveEvent>) {
        _viewState.value = _viewState.value.copy(events = events)
    }

    override fun setAlarmOnDeviceMap(alarmOnDeviceMap: Map<Int, Boolean>) {
       _viewState.value = _viewState.value.copy(alarmOnDeviceMap = alarmOnDeviceMap)
    }
}