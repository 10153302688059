package com.fireavert.units.presentation

import com.fireavert.reports.WeGotYouBarsModel
import com.fireavert.units.logic.AddNewUnitCSV

class AddNewUnitsCSVDialogController(private val addNewUnitCSV: AddNewUnitCSV) {
    fun clickedCancel(propertyId: Int) = addNewUnitCSV.clickedCancel(propertyId = propertyId)

    fun onClose() = addNewUnitCSV.onClose()

    suspend fun processCSVData(propertyId: Int, csvData: List<Map<String, String>>) =
        addNewUnitCSV.processCSVData(propertyId = propertyId, csvData = csvData)

    suspend fun onLoad(propertyId: Int) = addNewUnitCSV.onLoad(propertyId)
    suspend fun commit(propertyId: Int) = addNewUnitCSV.commit(propertyId)
    suspend fun check(propertyId: Int, jobId: Int) = addNewUnitCSV.checkJob(propertyId = propertyId, jobId = jobId)
}