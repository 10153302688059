package com.fireavert.units.logic.models.request

import kotlinx.serialization.Serializable

@Serializable
data class UnitCreationRequest(
    val propertyId: Int? = null,
    val number: String? = null,
    val streetAddress: String? = null,
    val city: String? = null,
    val state: String? = null,
    val zip: String? = null,
    val tenantName: String? = null,
    val phone: String? = null,
    val email: String? = null,
    val notifyStoveCurrent: Boolean? = null,
)
