package com.fireavert.units.api

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.jobs.logic.JobModel
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.units.logic.UnitsRepository
import com.fireavert.units.logic.models.UnitModel
import com.fireavert.units.logic.models.UnitOnlyModel
import com.fireavert.units.logic.models.request.MultiUnitCreationRequest
import com.fireavert.units.logic.models.request.UnitCreationRequest
import com.fireavert.units.logic.models.request.UnitModificationRequest
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ApiUnitsRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val dataSource: UnitsDataSource,
    private val preferences: Preferences,
    private val logger: Logger
) : ApiRepository(ioContext = ioContext, tokenRefreshService = tokenRefreshService),
    UnitsRepository {
    override suspend fun createUnit(request: UnitCreationRequest): Try<Int> =
        withContext(ioContext) {
            callWithRefresh { dataSource.createUnit(preferences.token, request) }.map { it.id ?: 0 }
        }

    override suspend fun getUnitsForPropertyId(propertyId: Int): Try<List<UnitModel>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getUnitsForPropertyId(
                    preferences.token, propertyId
                )
            }.map { it.toUnitsList() }
        }

    override suspend fun getUnitsCountForPropertyIds(propertyIds: List<Int>): Try<Int> {
        TODO("Not yet implemented")
    }

    override suspend fun getUnitById(unitId: Int): Try<UnitModel> = withContext(ioContext) {
        callWithRefresh {
            dataSource.getUnitById(
                preferences.token, unitId
            )
        }.map { it.unitResponse?.toUnitModel() ?: throw Exception("Failed to get unit response") }
    }

    override suspend fun getUnitByIdSync(unitId: Int): Try<UnitModel> {
        TODO("Not yet implemented")
    }

    override suspend fun modifyUnit(request: UnitModificationRequest): Try<Boolean> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.modifyUnit(preferences.token, request)
            }.map { it.success ?: false }
        }

    override suspend fun delete(unitId: Int): Try<Boolean> = withContext(ioContext) {
        callWithRefresh {
            dataSource.delete(
                preferences.token, unitId
            )
        }.map { it.success ?: false }
    }

    override suspend fun createMultipleUnits(request: MultiUnitCreationRequest): Try<JobModel> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.createMultipleUnits(
                    preferences.token,
                    request
                )
            }
        }

    override suspend fun getPropertyIdByUnitId(unitId: Int): Try<Int> {
        TODO("Not yet implemented")
    }

    override suspend fun getUnitForAppsheetInstall(unitName: String, propertyId: Int): Try<UnitOnlyModel> {
        TODO("Not yet implemented")
    }
}