package com.fireavert.invite.interface_adapters

import com.fireavert.common.ViewModel
import com.fireavert.invite.logic.InviteScreen
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class InviteViewModel : ViewModel(), InviteScreen {
    private val _viewState = MutableStateFlow(InviteViewState())
    val viewState = _viewState.asStateFlow()
    val scope = viewModelScope
    override var password: String
        get() = _viewState.value.password
        set(value) {
            _viewState.value = _viewState.value.copy(password = value)
        }
    override var passwordCheck: String
        get() = _viewState.value.passwordCheck
        set(value) {
            _viewState.value = _viewState.value.copy(passwordCheck = value)
        }
    override var loading: Boolean
        get() = _viewState.value.loading
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var error: Exception?
        get() = _viewState.value.error
        set(value) {
            _viewState.value = _viewState.value.copy(error = value)
        }
    override var userId: Int
        get() = _viewState.value.userId
        set(value) {
            _viewState.value = _viewState.value.copy(userId = value)
        }
    override var token: String
        get() = _viewState.value.token
        set(value) {
            _viewState.value = _viewState.value.copy(token = value)
        }
}