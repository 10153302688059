    package com.fireavert.events.logic.models

    import com.fireavert.actions.logic.EventLinkedActionType
    import kotlinx.serialization.Serializable

    @Serializable
    data class EventLinkedAction(
        val id: Long,
        val created: Long,
        val eventId: Long,
        val type: EventLinkedActionType,
        val propertyName: String,
        val unitName: String
    )
