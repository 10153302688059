package com.fireavert.components.properties

import com.fireavert.common.TableColumn
import com.fireavert.components.common.ThreeDotsButton
import com.fireavert.components.icons.AddIcon
import com.fireavert.components.sort_bar.SortBar
import com.fireavert.components.sort_bar.mapToControlColumns
import com.fireavert.components.sort_bar.mapToStateColumns
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.properties.logic.models.Property
import com.fireavert.properties.presentation.property_portfolio.PropertyPortfolioController
import com.fireavert.properties.presentation.property_portfolio.PropertyPortfolioViewModel
import com.fireavert.styles.SearchbarStyles
import com.fireavert.styles.TableStyles
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import mui.material.*
import mui.system.sx
import mui.system.useMediaQuery
import react.*
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.onChange
import react.router.dom.Link
import web.cssom.important
import web.cssom.integer
import web.cssom.pct
import web.cssom.px

import web.html.HTMLInputElement
external interface MobilePropertyPortfolioProps : Props {
    var propertiesProps: Array<Property>
    var loadingProps: Boolean
    var showCreateButtonProps: Boolean
    var controllerProps: PropertyPortfolioController
}

external interface DesktopPropertyPortfolioProps : Props {
    var propertiesProps: Array<Property>
    var loadingProps: Boolean
    var showCreateButtonProps: Boolean
    var stateColumnsProps: Array<TableColumn>
    var setStateColumns: (Array<TableColumn>) -> Unit
    var controllerProps: PropertyPortfolioController
}

val PropertyPortfolio = FC {
    val logger = getKoinInstance<Logger>()
    val viewModel = getKoinInstance<PropertyPortfolioViewModel>()
    val controller = getKoinInstance<PropertyPortfolioController>()

    var properties: Array<Property> by useState(emptyArray())
    var loading: Boolean by useState(false)
    var showCreateButton: Boolean by useState(false)
    var showEditProperty: Boolean by useState(false)
    var showDeleteProperty: Boolean by useState(false)
    val isMobile = useMediaQuery("(max-width: 768px)")
    var stateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "NAME",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "ADDRESS",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Left
            ),
            TableColumn(
                text = "SUBSCRIPTION TYPE",
                sortOrder = TableColumn.SortOrder.Neutral,
                alignment = TableColumn.Alignment.Center
            )
        )
    )

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            properties = viewState.properties.toTypedArray()
            loading = viewState.loading
            showCreateButton = viewState.showCreateButton
            showEditProperty = viewState.showEditProperty
            showDeleteProperty = viewState.showDeleteProperty
        }.launchIn(viewModel.scope)

        controller.onLoad()

        cleanup {
            viewStateJob.cancel()
        }
    }

    if (isMobile) {
        MobilePropertyPortfolio{
            propertiesProps = properties
            loadingProps = loading
            showCreateButtonProps = showCreateButton
            controllerProps = controller
        }
    } else {
        DesktopPropertyPortfolio {
            propertiesProps = properties
            loadingProps = loading
            showCreateButtonProps = showCreateButton
            stateColumnsProps = stateColumns
            setStateColumns = { stateColumns = it }
            controllerProps = controller
        }
    }
}

val MobilePropertyPortfolio = FC<MobilePropertyPortfolioProps> { props ->
    val (searchValue, setSearchValue) = useState("")
    var mobileStateColumns: Array<TableColumn> by useState(
        arrayOf(
            TableColumn(
                text = "Property",
                sortOrder = TableColumn.SortOrder.HighToLow,
                alignment = TableColumn.Alignment.Left
            ),
        )
    )
    if (props.showCreateButtonProps) {
        a {
            href = "#"
            +"Add Property"
            onClick = { event ->
                event.preventDefault()
                props.controllerProps.clickedAddNewProperty()
            }
        }
    }
    div {
        className = SearchbarStyles.searchBarAndMobileTitle
        div {
            className = TableStyles.tableTitle
            css {
                marginTop = important(0.px)
                fontWeight = integer(700)
            }
            +"Property Portfolio"
        }
        div {
            className = SearchbarStyles.searchBar
            TextField {
                sx {
                    height = 60.px
                }
                className = SearchbarStyles.searchBarInput(10)
                placeholder = "Search..."
                value = searchValue
                onChange = { event ->
                    val target = event.target as? HTMLInputElement
                    setSearchValue(target?.value ?: "")
                }
            }
        }
    }
    div {
        className = TableStyles.tableContainer
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(mobileStateColumns)
                includeLoading = false
                isLoading = props.loadingProps
                columnsChanged = {
                    mobileStateColumns = mapToStateColumns(it)
                }
            }
            val sortedProperties = props.controllerProps.sortPropertiesByColumn(mobileStateColumns, props.propertiesProps, searchValue)
            sortedProperties.forEach { property ->
                div {
                    className = TableStyles.tableRowPropertyList
                    Link {
                        to = "${RoutePaths.properties}/${property.id}"
                        div {
                            className = TableStyles.mobileTableRowWithArrow
                            +property.name
                            div {
                                img {
                                    src = "/static/mobile_arrow_black.svg"
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

val DesktopPropertyPortfolio = FC<DesktopPropertyPortfolioProps> { props ->
    val (searchValue, setSearchValue) = useState("")
    div {
        className = TableStyles.tableContainer
        div {
            className = TableStyles.tableTitle
            +"Property Portfolio"
            div {
                className = SearchbarStyles.searchBar
                TextField {
                    className = SearchbarStyles.searchBarInput(30)
                    placeholder = "Search properties..."
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }
            if (props.showCreateButtonProps) {
                Button {
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    startIcon = AddIcon.create()
                    +"Add New Property"
                    onClick = { props.controllerProps.clickedAddNewProperty() }
                }
            }
        }
        div {
            className = TableStyles.table
            SortBar {
                columns = mapToControlColumns(props.stateColumnsProps)
                includeLoading = true
                isLoading = props.loadingProps
                columnsChanged = {
                    props.setStateColumns(mapToStateColumns(it))
                }
            }
            if (!props.loadingProps) {
                val sortedProperties = props.controllerProps.sortPropertiesByColumn(
                    props.stateColumnsProps,
                    props.propertiesProps,
                    searchValue
                )
                sortedProperties.forEach { property ->
                    div {
                        className = TableStyles.tableRow
                        div {
                            className = TableStyles.tableCell
                            Link {
                                to = "${RoutePaths.properties}/${property.id}"
                                +property.name
                            }
                        }
                        div {
                            className = TableStyles.tableCell
                            +property.address
                        }
                        div {
                            className = TableStyles.tableCellCentered
                            +property.subscriptionType.value
                        }
                        div {
                            className = TableStyles.tableCell
                            ThreeDotsButton {
                                pathAndTextPairs = arrayOf(
                                    "${RoutePaths.properties}/${property.id}" to "View Property Details",
                                    "${RoutePaths.properties}/${property.id}/edit" to "Edit Property",
                                    "${RoutePaths.properties}/${property.id}/delete" to "Delete Property"
                                )
                            }
                        }
                    }
                }
            } else {
                Skeleton {
                    sx {
                        borderRadius = 10.px
                    }
                    variant = SkeletonVariant.rectangular
                    width = 100.pct
                    height = 100.pct
                }
            }
        }
    }
}
