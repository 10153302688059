package com.fireavert.components.properties

import com.fireavert.components.icons.DeleteIconButton
import com.fireavert.components.targetInputValue
import com.fireavert.logging.Logger
import com.fireavert.properties.presentation.AddRemovePropertyAdminRowController
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.utilities.getKoinInstance
import emotion.css.ClassName
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.material.TextField
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.dom.onChange
import web.cssom.*

external interface AddRemovePropertyAdminRowProps : Props {
    var id: String
    var existsOnServer: Boolean
    var firstName: String
    var firstNameError: Boolean
    var firstNameChanged: (String, String, Boolean) -> Unit
    var lastName: String
    var lastNameError: Boolean
    var lastNameChanged: (String, String, Boolean) -> Unit
    var phone: String
    var phoneError: Boolean
    var phoneChanged: (String, String, Boolean) -> Unit
    var email: String
    var isEmailReadOnly: Boolean
    var emailError: Boolean
    var emailChanged: (String, String, Boolean) -> Unit
    var stoveShutoffTextNotifications: Boolean
    var stoveShutoffTextNotificationsChanged: (String, Boolean) -> Unit
    var stoveShutoffEmailNotifications: Boolean
    var stoveShutoffEmailNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmTextNotifications: Boolean
    var smokeAlarmTextNotificationsChanged: (String, Boolean) -> Unit
    var smokeAlarmEmailNotifications: Boolean
    var smokeAlarmEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakTextNotifications: Boolean
    var leakTextNotificationsChanged: (String, Boolean) -> Unit
    var leakEmailNotifications: Boolean
    var leakEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperTextNotifications: Boolean
    var tamperTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperEmailNotifications: Boolean
    var tamperEmailNotificationsChanged: (String, Boolean) -> Unit
    var stoveOfflineEmailNotifications: Boolean
    var stoveOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var stoveOfflineTextNotifications: Boolean
    var stoveOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var leakOfflineEmailNotifications: Boolean
    var leakOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var leakOfflineTextNotifications: Boolean
    var leakOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var tamperOfflineEmailNotifications: Boolean
    var tamperOfflineEmailNotificationsChanged: (String, Boolean) -> Unit
    var tamperOfflineTextNotifications: Boolean
    var tamperOfflineTextNotificationsChanged: (String, Boolean) -> Unit
    var dailyReports: Boolean
    var dailyReportsChanged: (String, Boolean) -> Unit
    var weeklyReports: Boolean
    var weeklyReportsChanged: (String, Boolean) -> Unit
    var monthlyReports: Boolean
    var monthlyReportsChanged: (String, Boolean) -> Unit
    var quarterlyReports: Boolean
    var quarterlyReportsChanged: (String, Boolean) -> Unit
    var yearlyReports: Boolean
    var yearlyReportsChanged: (String, Boolean) -> Unit
    var removeClicked: (String) -> Unit
    var resendInviteClicked: (String) -> Unit
}


val AddRemovePropertyAdminRow = FC<AddRemovePropertyAdminRowProps> { props ->
    val controller = getKoinInstance<AddRemovePropertyAdminRowController>()

    val logger = getKoinInstance<Logger>()
    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 694.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 10.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            TextField {
                label = ReactNode("First Name")
                value = props.firstName
                required = true
                fullWidth = true
                error = props.firstNameError
                onChange = {
                    val value = it.targetInputValue
                    props.firstNameChanged(
                        props.id,
                        value,
                        !controller.checkName(value)
                    )
                }
            }

            TextField {
                label = ReactNode("Last Name")
                value = props.lastName
                required = true
                fullWidth = true
                error = props.lastNameError
                onChange = {
                    val value = it.targetInputValue
                    props.lastNameChanged(
                        props.id,
                        value,
                        !controller.checkName(value)
                    )
                }
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 10.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            TextField {
                label = ReactNode("Phone Number")
                value = props.phone
                required = true
                fullWidth = true
                error = props.phoneError
                onChange = {
                    val value = it.targetInputValue
                    props.phoneChanged(
                        props.id,
                        value,
                        !controller.checkPhone(value)
                    )
                }
            }

            TextField {
                label = ReactNode("Email")
                value = props.email
                required = true
                fullWidth = true
                error = props.emailError
                onChange = {
                    val value = it.targetInputValue
                    props.emailChanged(
                        props.id,
                        value,
                        !controller.checkEmail(value)
                    )
                }
                disabled = props.isEmailReadOnly
            }
        }

        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                alignItems = AlignItems.start

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            div {
                className = ClassName {
                    flexGrow = number(1.0)
                }
                NotificationsCheckboxTable {
                    id = props.id
                    stoveShutoffTextNotifications = props.stoveShutoffTextNotifications
                    stoveShutoffTextNotificationsChanged = props.stoveShutoffTextNotificationsChanged
                    stoveShutoffEmailNotifications = props.stoveShutoffEmailNotifications
                    stoveShutoffEmailNotificationsChanged = props.stoveShutoffEmailNotificationsChanged
                    smokeAlarmTextNotifications = props.smokeAlarmTextNotifications
                    smokeAlarmTextNotificationsChanged = props.smokeAlarmTextNotificationsChanged
                    smokeAlarmEmailNotifications = props.smokeAlarmEmailNotifications
                    smokeAlarmEmailNotificationsChanged = props.smokeAlarmEmailNotificationsChanged
                    leakTextNotifications = props.leakTextNotifications
                    leakTextNotificationsChanged = props.leakTextNotificationsChanged
                    leakEmailNotifications = props.leakEmailNotifications
                    leakEmailNotificationsChanged = props.leakEmailNotificationsChanged
                    tamperTextNotifications = props.tamperTextNotifications
                    tamperTextNotificationsChanged = props.tamperTextNotificationsChanged
                    tamperEmailNotifications = props.tamperEmailNotifications
                    tamperEmailNotificationsChanged = props.tamperEmailNotificationsChanged
                    stoveOfflineEmailNotifications = props.stoveOfflineEmailNotifications
                    stoveOfflineEmailNotificationsChanged = props.stoveOfflineEmailNotificationsChanged
                    stoveOfflineTextNotifications = props.stoveOfflineTextNotifications
                    stoveOfflineTextNotificationsChanged = props.stoveOfflineTextNotificationsChanged
                    leakOfflineEmailNotifications = props.leakOfflineEmailNotifications
                    leakOfflineEmailNotificationsChanged = props.leakOfflineEmailNotificationsChanged
                    leakOfflineTextNotifications = props.leakOfflineTextNotifications
                    leakOfflineTextNotificationsChanged = props.leakOfflineTextNotificationsChanged
                    tamperOfflineEmailNotifications = props.tamperOfflineEmailNotifications
                    tamperOfflineEmailNotificationsChanged = props.tamperOfflineEmailNotificationsChanged
                    tamperOfflineTextNotifications = props.tamperOfflineTextNotifications
                    tamperOfflineTextNotificationsChanged = props.tamperOfflineTextNotificationsChanged
                    dailyReports = props.dailyReports
                    dailyReportsChanged = props.dailyReportsChanged
                    weeklyReports = props.weeklyReports
                    weeklyReportsChanged = props.weeklyReportsChanged
                    monthlyReports = props.monthlyReports
                    monthlyReportsChanged = props.monthlyReportsChanged
                    quarterlyReports = props.quarterlyReports
                    quarterlyReportsChanged = props.quarterlyReportsChanged
                    yearlyReports = props.yearlyReports
                    yearlyReportsChanged = props.yearlyReportsChanged
                }
            }

            div {
                className = ClassName {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.flexEnd
                    gap = 10.px

                    media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.spaceBetween
                        width = 100.pct
                    }
                }

                if (props.existsOnServer) {
                    Button {
                        +"Resend Invite"
                        variant = ButtonVariant.contained
                        color = ButtonColor.primary
                        onClick = {
                            props.resendInviteClicked(props.id)
                        }
                    }
                }

                DeleteIconButton {
                    onClicked = { props.removeClicked(props.id) }
                }
            }
        }
    }
}
