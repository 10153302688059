package com.fireavert.units.presentation

import com.fireavert.common.ViewModel
import com.fireavert.devices.logic.models.Device
import com.fireavert.events.markasfalsealarm.logic.Screen
import com.fireavert.jobs.logic.JobModel
import com.fireavert.units.logic.AddNewUnitCSVScreen
import com.fireavert.units.logic.models.SkippedCSVRow
import com.fireavert.units.logic.models.UnitModel
import com.fireavert.units.logic.models.request.MultiUnitCreationRequest
import com.fireavert.units.presentation.AddNewUnitsCSVDialogViewModel.ScreenState.NoPendingData
import com.fireavert.units.presentation.AddNewUnitsCSVDialogViewModel.ScreenState.PendingData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlin.js.JsExport

class AddNewUnitsCSVDialogViewModel : AddNewUnitCSVScreen, ViewModel() {

    private val _viewState = MutableStateFlow(AddNewUnitCSVScreenViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope
        get() = viewModelScope

    sealed class ScreenState {
        object PendingData : ScreenState()
        object NoPendingData : ScreenState()
    }

    val screenState: ScreenState
        get() = if (viewState.value.pendingRequest != null) PendingData else NoPendingData

    override var skippedCSVRows: List<SkippedCSVRow>
        get() = viewState.value.skippedCSVRows
        set(value) {
            _viewState.value = viewState.value.copy(skippedCSVRows = value)
        }

    override var pendingRequest: MultiUnitCreationRequest?
        get() = viewState.value.pendingRequest
        set(value) {
            _viewState.value = viewState.value.copy(pendingRequest = value)
        }

    override var existingRebootIds: List<String>
        get() = viewState.value.existingRebootIds
        set(value) {
            _viewState.value = viewState.value.copy(existingRebootIds = value)
        }

    override var existingInfoIds: List<String>
        get() = viewState.value.existingInfoIds
        set(value) {
            _viewState.value = viewState.value.copy(existingInfoIds = value)
        }

    override var loadingMessage: String
        get() = viewState.value.loadingMessage
        set(value) {
            _viewState.value = viewState.value.copy(loadingMessage = value)
        }

    override var loading: Boolean
        get() = viewState.value.loading
        set(value) {
            _viewState.value = viewState.value.copy(loading = value)
        }

    override var errorMessage: String
        get() = viewState.value.errorMessage
        set(value) {
            _viewState.value = viewState.value.copy(errorMessage = value)
        }

    override var existingUnits: List<UnitModel>
        get() = viewState.value.existingUnits
        set(value) {
            _viewState.value = viewState.value.copy(existingUnits = value)
        }

    override var existingDevices: List<Device>
        get() = viewState.value.existingDevices
        set(value) {
            _viewState.value = viewState.value.copy(existingDevices = value)
        }
    override var jobModel: JobModel?
        get() = viewState.value.jobModel
        set(value) {
            _viewState.value = viewState.value.copy(jobModel = value)
        }
    override var checking: Boolean
        get() = viewState.value.checking
        set(value) {
            _viewState.value = viewState.value.copy(checking = value)
        }
}