package com.fireavert.events.activedeviceevents.interface_adapters

import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvent
import com.fireavert.events.logic.models.UnitEventTotals

data class ActiveDeviceEventsViewState(
    val events: List<ActiveDeviceEvent> = emptyList(),
    val loading: Boolean = true,
    val alarmOnDeviceMap: Map<Int, Boolean> = emptyMap(),
    val unitEventTotalsMap: Map<Int, UnitEventTotals> = emptyMap()
)
