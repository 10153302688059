package com.fireavert.reports_page.interface_adaptors

import com.fireavert.properties.logic.models.FireClaimData
import com.fireavert.reports_page.logic.models.HighRiskTenantModel

data class ReportsPageViewState(
    val loading: Boolean = false,
    val loadingHighRiskTenants: Boolean = false,
    val loadingYearlyEvents: Boolean = false,
    val loadingAllTimeEvents: Boolean = false,
    var propertyId: Int? = null,
    var propertyMap: Map<String, Int?> = mapOf("None" to -1, "All" to null),
    val thisYearEventData: Map<String, Int> = emptyMap(),
    val thisYearSmokeEvents: Map<String, Int> = emptyMap(),
    val thisYearTamperEvents: Map<String, Int> = emptyMap(),
    val thisYearLeakEvents: Map<String, Int> = emptyMap(),
    val thisYearOfflineEvents: Map<String, Int> = emptyMap(),
    val thisYearStoveShutoffEvents: Map<String, Int> = emptyMap(),
    val allTimeData: Map<String, Int> = emptyMap(),
    val eventCount: Int = 0,

    val highRiskTenants: List<HighRiskTenantModel> = emptyList(),
    val averageEventsPerMonth: Double = 0.0,
    val propertyAverageComparison: Pair<Double, Boolean> = Pair(0.0, false),
    val allTimeAverageEventValue: Double = 0.0,
    val yearStartedUsingFireAvert: Int = 0,
    val lastFiveYearClaims: List<FireClaimData> = emptyList(),
    )