package com.fireavert.properties.logic.comm_v3_conversion_dialog

import com.fireavert.common.Try
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.PropertyNavigator

class CommV2Conversion(
    private val propertyNavigator: PropertyNavigator,
    private val deviceRepository: DeviceRepository,
    private val screen: CommV2ConversionScreen,
    private val logger: Logger
) {
    fun close(propertyId: Int) {
        propertyNavigator.navigate(PropertyNavigator.Destination.Property(propertyId))
    }

    suspend fun convertPropertyToCommV2(propertyId: Int) {
        screen.isConfirmed = true
        screen.isLoading = true
        val devices = when (val maybe = deviceRepository.getDevicesForPropertyId(propertyId)) {
            is Try.Success -> {
                maybe.value
            }

            is Try.Error -> {
                logger.e("Failed to get devices for propertyId $propertyId")
                screen.isLoading = false
                screen.success = false
                return
            }
        }

        var allSuccess = true
        devices.forEach { device ->
            val locator = when (device.type) {
                DeviceType.FireAvert,
                DeviceType.FireAvertGas,
                DeviceType.FireAvertAppliance -> {
                    device.deviceLocator.removePrefix("ttn-v3-integration_").lowercase()
                }

                DeviceType.WaterSensor -> {
                    if (device.deviceLocator.startsWith("water-sensor_ls-"))
                        "ws-${device.deviceLocator.removePrefix("water-sensor_ls-")}".lowercase()
                    else if (device.deviceLocator.startsWith("water-sensor_eui-"))
                        "ws-${device.deviceLocator.removePrefix("water-sensor_eui-")}".lowercase()
                    else device.deviceLocator
                }

                DeviceType.FlowSensor -> {
                    device.deviceLocator
                }

                DeviceType.TamperSensor -> {
                    if (device.deviceLocator.startsWith("door-sensor_ds-"))
                        "ts-${device.deviceLocator.removePrefix("door-sensor_ds-")}".lowercase()
                    else if (device.deviceLocator.startsWith("door-sensor_eui-"))
                        "ts-${device.deviceLocator.removePrefix("door-sensor_eui-")}".lowercase()
                    else device.deviceLocator
                }
            }
            val success = when (
                val maybe = deviceRepository.modifyDevice(
                    device.id,
                    locator,
                    device.location,
                    device.unitId,
                    device.type,
                    2,
                    device.rebootUUID,
                    device.infoUUID
                )) {
                is Try.Success -> {
                    maybe.value
                }

                is Try.Error -> {
                    val message =
                        "Failed to change device ${device.id} to comm v2 and locator $locator. ${maybe.exception.message}"
                    logger.e(message)
                    screen.addErrorMessage(message)
                    false
                }
            }
            if (!success) {
                allSuccess = false
            }
        }

        screen.success = allSuccess
        screen.isLoading = false
    }
}