package com.fireavert.units.logic

import com.fireavert.common.KotlinStringFormatter
import com.fireavert.common.Try
import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.jobs.logic.ClientJobRepository
import com.fireavert.logging.Logger
import com.fireavert.units.logic.UnitsNavigator.Destination
import com.fireavert.units.logic.models.SkippedCSVRow
import com.fireavert.units.logic.models.request.CsvEntry
import com.fireavert.units.logic.models.request.CsvEntryWithRebootId
import com.fireavert.units.logic.models.request.MultiUnitCreationRequest
import com.fireavert.units.logic.models.request.SimpleUnitCreationRequest

class ClientAddNewUnitCSV(
    private val screen: AddNewUnitCSVScreen,
    private val unitsRepository: UnitsRepository,
    private val deviceRepository: DeviceRepository,
    private val unitsNavigator: UnitsNavigator,
    private val jobsRepository: ClientJobRepository,
    private val logger: Logger
) : AddNewUnitCSV {
    override fun clickedCancel(propertyId: Int) {
        unitsNavigator.navigate(Destination.PropertyDetails(propertyId))
    }

    override fun onClose() {
    }

    private fun processRebootEntry(
        pattern: Regex,
        csvRowKey: String,
        csvRow: Map<String, String>,
        preNumberPattern: String,
        skipReasons: MutableList<String>,
        nonDuplicates: MutableList<CsvEntryWithRebootId>,
        processedRebootIds: MutableList<String>,
        processedInfoIds: MutableList<String>,
    ) {
        pattern.matchEntire(csvRowKey)?.groupValues?.forEach { number ->
            if (number != csvRowKey) {
                val locator = csvRow[csvRowKey]
                var add = true
                if (locator != null && locator.isNotBlank()) {
                    val location = csvRow["$preNumberPattern$number location"] ?: "Unknown"
                    val rebootId = csvRow["$preNumberPattern$number reboot"] ?: ""
                    if (screen.existingRebootIds.contains(rebootId)) {
                        skipReasons.add("Duplicate reboot ID $rebootId")
                        add = false
                    }
                    if (rebootId.isNotBlank() && processedRebootIds.contains(rebootId)) {
                        skipReasons.add("Data Contains Duplicate Reboot ID $rebootId")
                        add = false
                    }
                    processedRebootIds.add(rebootId)

                    val commVersionString = csvRow["$preNumberPattern$number comm"] ?: ""

                    val infoId = csvRow["$preNumberPattern$number info"] ?: ""
                    if (screen.existingInfoIds.contains(infoId)) {
                        skipReasons.add("Duplicate info ID $infoId")
                        add = false
                    }
                    if (infoId.isNotBlank() && processedInfoIds.contains(infoId)) {
                        skipReasons.add("Data Contains Duplicate Info ID $infoId")
                        add = false
                    }
                    processedInfoIds.add(infoId)

                    val commVersion = when (val maybe = Try.fromCallable {
                        commVersionString.toInt()
                    }) {
                        is Error -> {
                            skipReasons.add("Failed to Parse Comm Version $commVersionString")
                            add = false
                            0
                        }
                        is Success -> {
                            maybe.value
                        }
                    }
                    add = checkCommVersion(commVersion, skipReasons, add)

                    val locatorToUse =
                        if (commVersion == 1 && !locator.contains("ttn-v3-integration")) {
                            "ttn-v3-integration_$locator"
                        } else if (commVersion == 3 && !locator.contains("tti-v1-integration")) {
                            "tti-v1-integration_$locator"
                        } else {
                            locator
                        }

                    if (screen.existingDevices.firstOrNull { it.deviceLocator == locatorToUse } != null) {
                        skipReasons.add("Duplicate locator $locatorToUse")
                        add = false
                    }

                    if (add) nonDuplicates.add(
                        CsvEntryWithRebootId(
                            location = location,
                            infoId = infoId,
                            locator = locatorToUse,
                            rebootId = rebootId,
                            commVersion = commVersion,
                        )
                    )
                }
            }
        }
    }

    private fun checkCommVersion(
        commVersion: Int,
        skipReasons: MutableList<String>,
        add: Boolean
    ): Boolean {
        var add1 = add
        if (commVersion <= 0 || commVersion > 3) {
            skipReasons.add("Invalid Comm Version $commVersion")
            add1 = false
        }
        return add1
    }

    private fun processNonRebootEntry(
        pattern: Regex,
        csvRowKey: String,
        csvRow: Map<String, String>,
        preNumberPattern: String,
        skipReasons: MutableList<String>,
        nonDuplicates: MutableList<CsvEntry>,
        processedInfoIds: MutableList<String>,
    ) {
        pattern.matchEntire(csvRowKey)?.groupValues?.forEach { number ->
            if (number != csvRowKey) {
                var add = true
                val locator = csvRow[csvRowKey]
                if (locator != null && locator.isNotBlank()) {
                    val location = csvRow["$preNumberPattern$number location"] ?: "Unknown"
                    val infoId = csvRow["$preNumberPattern$number info"] ?: ""

                    if (infoId.isNotBlank() && processedInfoIds.contains(infoId)) {
                        skipReasons.add("Data Contains Duplicate Info ID $infoId")
                        add = false
                    }
                    processedInfoIds.add(infoId)

                    val commVersionString = csvRow["$preNumberPattern$number comm"] ?: ""
                    val commVersion = when (val maybe = Try.fromCallable {
                        commVersionString.toInt()
                    }) {
                        is Error -> {
                            skipReasons.add("Failed to Parse Comm Version $commVersionString")
                            add = false
                            0
                        }
                        is Success -> {
                            maybe.value
                        }
                    }

                    add = checkCommVersion(commVersion, skipReasons, add)

                    if (screen.existingInfoIds.contains(infoId)) {
                        skipReasons.add("Duplicate info ID $infoId")
                        add = false
                    }
                    if (screen.existingDevices.firstOrNull { it.deviceLocator == locator } != null) {
                        skipReasons.add("Duplicate locator $locator")
                        add = false
                    }

                    if (add) nonDuplicates.add(
                        CsvEntry(
                            location = location,
                            infoId = infoId,
                            locator = locator,
                            commVersion = commVersion
                        )
                    )
                }
            }
        }
    }

    override suspend fun processCSVData(propertyId: Int, csvData: List<Map<String, String>>) {
        val simpleRequests = mutableListOf<SimpleUnitCreationRequest>()
        val skippedCSVRows = mutableListOf<SkippedCSVRow>()

        val processedBuildingUnits = mutableListOf<String>()
        val processedRebootIds = mutableListOf<String>()
        val processedInfoIds = mutableListOf<String>()

        csvData.forEach csvRow@{ csvRow ->
            val csvRowKeys = csvRow.keys
            var unitNumber = "Unknown"
            var buildingNumber = "Unknown"
            val unitFireAverts = mutableListOf<CsvEntryWithRebootId>()
            val unitFireAvertGass = mutableListOf<CsvEntryWithRebootId>()
            val unitFireAvertAppliances = mutableListOf<CsvEntryWithRebootId>()
            val unitLeakSensors = mutableListOf<CsvEntry>()
            val unitTamperSensors = mutableListOf<CsvEntry>()

            val skipReasons = mutableListOf<String>()

            csvRowKeys.forEach csvRowKey@{ csvRowKey ->
                if (unitNumberPattern.matches(csvRowKey)) {
                    unitNumber = csvRow[csvRowKey] ?: "NULL"
                    val first = screen.existingUnits.firstOrNull { unitToCheck ->
                        unitNumber == unitToCheck.number
                    }
                    if (first != null) {
                        skipReasons.add("Duplicate Unit Number $unitNumber")
                        return@csvRowKey
                    }
                }
                if (buildingNumberPattern.matches(csvRowKey)) {
                    buildingNumber = csvRow[csvRowKey] ?: ""
                }

                processRebootEntry(
                    pattern = fireAvertPattern,
                    csvRowKey = csvRowKey,
                    csvRow = csvRow,
                    preNumberPattern = "fireavert",
                    skipReasons = skipReasons,
                    nonDuplicates = unitFireAverts,
                    processedRebootIds = processedRebootIds,
                    processedInfoIds = processedInfoIds,
                )
                processRebootEntry(
                    pattern = fireAvertAppliancePattern,
                    csvRowKey = csvRowKey,
                    csvRow = csvRow,
                    preNumberPattern = "fireavertappliance",
                    skipReasons = skipReasons,
                    nonDuplicates = unitFireAvertAppliances,
                    processedRebootIds = processedRebootIds,
                    processedInfoIds = processedInfoIds,
                )
                processRebootEntry(
                    pattern = fireAvertGasPattern,
                    csvRowKey = csvRowKey,
                    csvRow = csvRow,
                    preNumberPattern = "fireavertgas",
                    skipReasons = skipReasons,
                    nonDuplicates = unitFireAvertGass,
                    processedRebootIds = processedRebootIds,
                    processedInfoIds = processedInfoIds,
                )
                processNonRebootEntry(
                    pattern = leakSensorPattern,
                    csvRowKey = csvRowKey,
                    csvRow = csvRow,
                    preNumberPattern = "leaksensor",
                    skipReasons = skipReasons,
                    nonDuplicates = unitLeakSensors,
                    processedInfoIds = processedInfoIds,
                )
                processNonRebootEntry(
                    pattern = tamperSensorPattern,
                    csvRowKey = csvRowKey,
                    csvRow = csvRow,
                    preNumberPattern = "tampersensor",
                    skipReasons = skipReasons,
                    nonDuplicates = unitTamperSensors,
                    processedInfoIds = processedInfoIds,
                )
            }
            if (buildingNumber.isNotBlank() && !unitNumber.startsWith("-")) {
                buildingNumber = "$buildingNumber - "
            }
            val buildingUnit = "$buildingNumber$unitNumber"
            if (processedBuildingUnits.contains(buildingUnit)) {
                skipReasons.add("Contains Duplicate Building/Unit Combo $buildingUnit")
            }
            processedBuildingUnits.add(buildingUnit)
            if (skipReasons.isNotEmpty()) {
                skippedCSVRows.add(SkippedCSVRow(buildingUnit, reasons = skipReasons))
                return@csvRow
            }

            simpleRequests.add(
                SimpleUnitCreationRequest(
                    propertyId = propertyId,
                    building = buildingNumber,
                    unitNumber = unitNumber,
                    fireAverts = unitFireAverts,
                    fireAvertGass = unitFireAvertGass,
                    fireAvertAppliances = unitFireAvertAppliances,
                    leakSensors = unitLeakSensors,
                    tamperSensors = unitTamperSensors
                )
            )
        }
        val request = MultiUnitCreationRequest(unitRequests = simpleRequests)

        screen.pendingRequest = request
        screen.skippedCSVRows = skippedCSVRows

    }

    override suspend fun onLoad(propertyId: Int) {
        screen.loading = true
        screen.loadingMessage = "Retrieving all units for property..."

        val units = when (val maybe = unitsRepository.getUnitsForPropertyId(propertyId)) {
            is Error -> {
                val message = "Failed to get units for property id $propertyId"
                screen.loading = false
                screen.loadingMessage = ""
                screen.errorMessage = message
                return
            }
            is Success -> maybe.value
        }


        screen.loading = true
        screen.loadingMessage = "Retrieving all devices for all units..."

        val devices =
            when (val maybeDevices = deviceRepository.getDevicesForPropertyId(propertyId)) {
                is Error -> {
                    val message = "Failed to get devices for property id $propertyId"
                    logger.d(message)
                    screen.loading = false
                    screen.loadingMessage = ""
                    screen.errorMessage = message
                    return
                }
                is Success -> maybeDevices.value
            }

        screen.loading = true
        screen.loadingMessage = "Retrieving all reboot UUIDs..."

        val rebootIds = when (val maybe = deviceRepository.getAllDeviceRebootUUIDs()) {
            is Error -> {
                val message = "Failed to get all reboot UUIDs!"
                logger.d(message)
                screen.loading = false
                screen.loadingMessage = ""
                screen.errorMessage = message
                return
            }
            is Success -> maybe.value
        }

        screen.loading = true
        screen.loadingMessage = "Retrieving all info UUIDs..."

        val infoIds = when (val maybe = deviceRepository.getAllDeviceInfoUUIDs()) {
            is Error -> {
                val message = "Failed to get all reboot UUIDs!"
                logger.d(message)
                screen.loading = false
                screen.loadingMessage = ""
                screen.errorMessage = message
                return
            }
            is Success -> maybe.value
        }

        screen.existingUnits = units
        screen.existingDevices = devices
        screen.existingRebootIds = rebootIds
        screen.existingInfoIds = infoIds
        screen.loading = false
    }

    override suspend fun commit(propertyId: Int) {
        val pendingRequest = screen.pendingRequest
        if (pendingRequest?.unitRequests == null || pendingRequest.unitRequests.isEmpty()) {
            screen.loading = false
            unitsNavigator.navigate(Destination.PropertyDetails(propertyId))
            screen.jobModel = null
            return
        }
        screen.loading = true
        screen.jobModel =
            when (val maybe = unitsRepository.createMultipleUnits(request = pendingRequest)) {
                is Error -> {
                    screen.loading = false
                    screen.errorMessage = maybe.exception.toString()
                    null
                }
                is Success -> {
                    maybe.value
                }
            }
    }

    override suspend fun checkJob(propertyId: Int, jobId: Int) {
        screen.checking = true
        val jobModel = when (val maybe = jobsRepository.getJob(jobId)) {
            is Error -> {
                screen.loading = false
                screen.errorMessage = maybe.exception.toString()
                screen.jobModel = null
                screen.checking = false
                return
            }
            is Success -> maybe.value
        }

        if (jobModel.isError) {
            logger.e("JobModel isError is true")
            screen.loading = false
            screen.jobModel = null
            screen.checking = false
            screen.pendingRequest = null
            screen.errorMessage = jobModel.errorMessage ?: "Error"
            return
        }

        if (jobModel.isFinished) {
            screen.loading = false
            screen.jobModel = null
            screen.checking = false
            unitsNavigator.navigate(Destination.PropertyDetails(propertyId))
            return
        }
        screen.loading = true
        screen.loadingMessage = KotlinStringFormatter.format(jobModel.percentDone * 100.0, 2)
        screen.jobModel = jobModel
        screen.checking = false
    }

    companion object {
        val fireAvertPattern = Regex("^\\s*fireavert(\\d)\\s*$")
        val fireAvertGasPattern = Regex("^\\s*fireavertgas(\\d)\\s*$")
        val fireAvertAppliancePattern = Regex("^\\s*fireavertappliance(\\d)\\s*$")
        val leakSensorPattern = Regex("^\\s*leaksensor(\\d)\\s*$")
        val tamperSensorPattern = Regex("^\\s*tampersensor(\\d)\\s*$")
        val unitNumberPattern = Regex("^\\s*unit\\s*$")
        val buildingNumberPattern = Regex("^\\s*building\\s*$")
    }
}