package com.fireavert.components

import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import web.html.HTMLDivElement
import web.html.HTMLElement
import web.html.HTMLInputElement

val FormEvent<HTMLDivElement>.targetInputValue: String
    get() = (this.target as? HTMLInputElement)?.value ?: ""

val ChangeEvent<HTMLElement>.targetInputValue: String
    get() = (this.target as? HTMLInputElement)?.value ?: ""
