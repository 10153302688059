package com.fireavert.events.logic.models

import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.DeviceType.FireAvert
import com.fireavert.devices.logic.models.DeviceType.FireAvertGas
import com.fireavert.devices.logic.models.DeviceType.FireAvertAppliance
import com.fireavert.devices.logic.models.DeviceType.FlowSensor
import com.fireavert.devices.logic.models.DeviceType.TamperSensor
import com.fireavert.devices.logic.models.DeviceType.WaterSensor
import com.fireavert.events.logic.models.EventType.DeviceOffline
import com.fireavert.events.logic.models.EventType.DeviceReset
import com.fireavert.events.logic.models.EventType.DeviceTriggered
import com.fireavert.events.logic.models.EventType.SmokeAlarmActive

sealed class EventTypeIconOption : Comparable<EventTypeIconOption> {
    object Smoke : EventTypeIconOption()
    object ShutOff : EventTypeIconOption()
    object Reset : EventTypeIconOption()
    object Unknown : EventTypeIconOption()
    object Leak : EventTypeIconOption()
    object Flow : EventTypeIconOption()
    object Tamper : EventTypeIconOption()
    object Offline : EventTypeIconOption()

    override fun compareTo(other: EventTypeIconOption): Int {
        return this::class.simpleName?.compareTo(other::class.simpleName ?: "") ?: 0
    }

    companion object {
        fun from(deviceType: DeviceType, eventType: EventType): EventTypeIconOption {
            return when (deviceType) {
                FireAvert, FireAvertGas, FireAvertAppliance ->
                    when (eventType) {
                        SmokeAlarmActive -> Smoke
                        DeviceTriggered -> ShutOff
                        DeviceReset -> Reset
                        DeviceOffline -> Offline
                        EventType.Unknown -> Unknown
                    }
                WaterSensor ->
                    when (eventType) {
                        DeviceOffline -> Offline
                        else -> Leak
                    }
                FlowSensor ->
                    when (eventType) {
                        DeviceOffline -> Offline
                        else -> Flow
                    }
                TamperSensor ->
                    when (eventType) {
                        DeviceOffline -> Offline
                        else -> Tamper
                    }
            }
        }
    }
}