package com.fireavert.unsubscribe.data

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.unsubscribe.adapters.UnsubscribeSetterDataSource
import io.ktor.client.HttpClient
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.get
import io.ktor.client.request.url
import io.ktor.http.*

class APIUnsubscribeDataSource(
    private val httpClient: HttpClient,
    private val baseUrl: String,
    private val logger: Logger
) : UnsubscribeSetterDataSource {
    override suspend fun setEmailToUnsubscribe(email: String): Try<Boolean> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                url(urlString = "$baseUrl/unsubscribe?email=$email")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}