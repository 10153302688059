package com.fireavert.events.logic.models

import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvent
import com.fireavert.properties.logic.models.Property

data class PropertyWithActiveEvents(
    val property: Property,
    val activeDeviceEvents: List<ActiveDeviceEvent>,
    val alarmActiveDeviceMap: Map<Int, Boolean>
)
