package com.fireavert.components.properties

import mui.material.Checkbox
import react.FC
import react.Props
import react.dom.html.ReactHTML

external interface CheckBoxCellProps: Props {
    var checked: Boolean
    var onChange: (Boolean) -> Unit
    var text: String
}

val CheckBoxCell = FC<CheckBoxCellProps> { props ->
    ReactHTML.td {
        ReactHTML.span {
            Checkbox {
                checked = props.checked
                onChange = { _, value ->
                    props.onChange(value)
                }
            }
            +props.text
        }
    }
}