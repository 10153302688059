package com.fireavert.jobs.logic

@kotlinx.serialization.Serializable
data class JobModel(
    val id: Int,
    val percentDone: Double = 0.0,
    val errorMessage: String? = null
) {
    val isFinished: Boolean
        get() { return percentDone >= 1.0 && !isError }
    val isError: Boolean
        get() { return errorMessage?.isNotBlank() == true }
}
