package com.fireavert.jobs.interface_adapters

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.jobs.logic.ClientJobRepository
import com.fireavert.jobs.logic.JobModel
import com.fireavert.preferences.logic.Preferences
import com.fireavert.preferences.repository.KeyValuePreferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class CommonClientJobRepository(
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService,
    private val jobDataSource: ClientJobDataSource,
    private val preferences: Preferences,
) : ClientJobRepository,
    ApiRepository(ioContext = ioContext, tokenRefreshService = tokenRefreshService) {
    override suspend fun getJob(id: Int): Try<JobModel> = withContext(ioContext) {
        callWithRefresh {
            jobDataSource.getJob(token = preferences.token, id = id)
        }
    }
}