package com.fireavert.auth.interface_adapters

import com.fireavert.auth.logic.AuthScreen
import com.fireavert.common.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class AuthViewModel : ViewModel(), AuthScreen {
    private val _authViewState = MutableStateFlow(AuthViewState())
    val authViewState = _authViewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope

    override fun setErrorState(message: String) {
        _authViewState.value = _authViewState.value.copy(errorState = AuthErrorState(error = true, message = message))
    }

    override fun unsetErrorState() {
        _authViewState.value = _authViewState.value.copy(errorState = AuthErrorState(error = false, message = ""))
    }

    override fun setEmail(email: String) {
        _authViewState.value = _authViewState.value.copy(email = email)
    }

    override fun setPassword(password: String) {
        _authViewState.value = _authViewState.value.copy(password = password)
    }
}