package com.fireavert.administration_page.wireless_id_finder.frameworks

import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdRepositoryDataSource
import com.fireavert.common.Try
import io.ktor.client.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.client.call.*

class WirelessIdRepositoryDataSourceImpl(
    private val httpClient: HttpClient,
    private val baseUrl: String,
): WirelessIdRepositoryDataSource {
    override suspend fun loadWirelessIdData(deviceId: String, token: String): Try<String> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/wirelessId/$deviceId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}