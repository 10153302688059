package com.fireavert.events.activeevents.data

import com.fireavert.common.Try
import com.fireavert.events.activeevents.api.ActiveEventsDataSource
import com.fireavert.events.activeevents.GetActiveEventsForAllPropertiesResponse
import com.fireavert.events.activeevents.GetActiveEventsForPropertyResponse
import com.fireavert.events.activeevents.GetActiveEventsWithActionsResponse
import io.ktor.client.HttpClient
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.get
import io.ktor.client.request.header
import io.ktor.client.request.url
import io.ktor.http.*

class ClientActiveEventsDataSource(
    val httpClient: HttpClient,
    val baseUrl: String
): ActiveEventsDataSource {
    override suspend fun getActiveEventsForAllProperties(token: String): Try<GetActiveEventsForAllPropertiesResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/activeEventsForAllProperties")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getActiveEventsForProperty(propertyId: Int, token: String): Try<GetActiveEventsForPropertyResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/activeEventsForProperty/$propertyId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

    override suspend fun getActiveEventsWithActions(dateRange: Long, token: String): Try<GetActiveEventsWithActionsResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/activeEventsWithActions/$dateRange")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}