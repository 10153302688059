package com.fireavert.reboot.presentation

import com.fireavert.reboot.logic.RebootNavigator
import com.fireavert.reboot.logic.RebootNavigator.Destination
import com.fireavert.reboot.logic.RebootNavigator.Destination.NotFoundOrNotPro
import com.fireavert.reboot.logic.RebootNavigator.Destination.Success

class RoutedRebootNavigator(private val rebootRouter: RebootRouter): RebootNavigator {
    override fun navigate(destination: Destination) {
        when(destination) {
            Success -> rebootRouter.routeToSuccess()
            NotFoundOrNotPro -> rebootRouter.routeToNotFoundOrNotPro()
        }
    }
}