package com.fireavert.app.external

import com.fireavert.app.interface_adapters.ClientAnalyticsDataSource
import com.fireavert.app.logic.CreatePageViewRequest
import com.fireavert.app.logic.CreatePageViewResponse
import com.fireavert.common.Try
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class ClientAnalyticsDataSourceImpl(private val baseUrl: String, private val httpClient: HttpClient): ClientAnalyticsDataSource {
    override suspend fun createPageView(token: String, request: CreatePageViewRequest): Try<CreatePageViewResponse> {
        return Try.fromCoCallable {
            val response = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/analytics/user_pageview")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }

}