package com.fireavert.common

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.SupervisorJob

open class ViewModel {
    private val viewModelJob: Job = SupervisorJob()
    protected var viewModelScope: CoroutineScope = CoroutineScope(Dispatchers.Main + viewModelJob)
}