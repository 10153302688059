package com.fireavert.auth.interface_adapters

import com.fireavert.auth.logic.ResetPasswordScreen
import com.fireavert.common.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ResetPasswordViewModel : ResetPasswordScreen, ViewModel() {
    private val _viewState = MutableStateFlow(ResetPasswordViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope
    override var email: String
        get() = viewState.value.email
        set(value) {
            _viewState.value = _viewState.value.copy(email = value)
        }
    override var loading: Boolean
        get() = viewState.value.loading
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
    override var error: Boolean
        get() = viewState.value.error
        set(value) {
            _viewState.value = _viewState.value.copy(error = value)
        }
    override var errorMessage: String
        get() = viewState.value.errorMessage
        set(value) {
            _viewState.value = _viewState.value.copy(errorMessage = value)
        }
}