package com.fireavert.gateways.presentation

import com.fireavert.gateways.GatewayDialogInteractor

class GatewayDialogController(
    private val interactor: GatewayDialogInteractor
) {
    suspend fun updateGatewayDetails(gatewayName: String, gatewayDescription: String, gatewayId: String) {
        interactor.updateGatewayDetails(gatewayName, gatewayDescription, gatewayId)
    }
    suspend fun uploadGatewayImage(file: ByteArray, fileSize: Double, fileType: String,
                                   fileName: String, gatewayId: String, propertyId: Int) {
        interactor.uploadGatewayImage(file, fileSize, fileType, fileName, gatewayId, propertyId)
    }

    suspend fun onLoad(gatewayId: String, propertyId: Int) {
        interactor.onLoad(gatewayId, propertyId)
    }
}