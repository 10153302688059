package com.fireavert.events.markasfalsealarm.presentation

import com.fireavert.common.ViewModel
import com.fireavert.events.markasfalsealarm.logic.Screen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class MarkAsFalseAlarmViewModel : ViewModel(), Screen {
    private val _viewState = MutableStateFlow(ViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope

    override var loading: Boolean
        get() = _viewState.value.loading
        set(value) {
            _viewState.value = _viewState.value.copy(loading = value)
        }
}