package com.fireavert.properties.presentation.comm_v2_conversion_dialog

import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.comm_v3_conversion_dialog.CommV2ConversionScreen
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class CommV2ConversionDialogViewModel : CommV2ConversionScreen, ViewModel() {
    private val _viewState = MutableStateFlow(CommV2ConversionDialogViewState())
    val viewState = _viewState.asStateFlow()

    val scope = viewModelScope
    override var isLoading: Boolean
        get() = viewState.value.isLoading
        set(value) {
            _viewState.value = _viewState.value.copy(isLoading = value)
        }

    override var success: Boolean
        get() = viewState.value.success
        set(value) {
            _viewState.value = _viewState.value.copy(success = value)
        }
    override var isConfirmed: Boolean
        get() = viewState.value.isConfirmed
        set(value) {
            _viewState.value = viewState.value.copy(isConfirmed = value)
        }

    override fun addErrorMessage(message: String) {
        val newMessages: MutableList<String> = ArrayList()
        newMessages.addAll(viewState.value.errorMessages)
        newMessages.add(message)
        _viewState.value = _viewState.value.copy(
            errorMessages = newMessages
        )
    }
}