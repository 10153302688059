package com.fireavert.components.administration

import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionController
import com.fireavert.administration_page.wireless_id_finder.interface_adapters.WirelessIdSectionViewModel
import com.fireavert.styles.HelpStyles
import com.fireavert.styles.ReportPageStyles
import com.fireavert.styles.SearchbarStyles
import com.fireavert.utilities.getKoinInstance
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.*
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.px
import web.html.HTMLInputElement
import web.window.WindowTarget


val DeviceIdMatchingSection = FC {
    val controller = getKoinInstance<WirelessIdSectionController>()
    val viewModel = getKoinInstance<WirelessIdSectionViewModel>()

    var wirelessDeviceId: String by useState("")
    var deviceLocationLink: String by useState("")
    var unitName: String by useState("")
    var propertyName: String by useState("")
    val (searchValue, setSearchValue) = useState("")
    val (searchDeviceValue, setDeviceValue) = useState("")
    var gatewayDeviceInformation by useState(emptyMap<String, List<String>>())
    var gatewayDeviceInfoLoading by useState(false)
    var gatewayReloadDisabled by useState(false)

    useEffectOnce {
        viewModel.viewState.onEach { viewState ->
            wirelessDeviceId = viewState.wirelessDeviceId
            deviceLocationLink = viewState.deviceLocationLink
            unitName = viewState.unitName
            propertyName = viewState.propertyName
            gatewayDeviceInformation = viewState.gatewayDeviceData
            gatewayDeviceInfoLoading = viewState.gatewayDeviceDataLoading
        }.launchIn(viewModel.scope)
    }

    fun searchForId(value: String) {
        viewModel.scope.launch {
            controller.loadWirelessIdData(value)
        }
    }

    fun getGatewayDeviceData(value: String) {
        viewModel.scope.launch {
            controller.loadGatewayDeviceData(value)
        }
    }

    fun reloadAllGatewayInfo() {
        viewModel.scope.launch {
            controller.reloadAllGatewayInfo()
        }
    }


    div {
        className = ReportPageStyles.costSavingsContainer
        div {
            className = HelpStyles.eventTitle
            +"Wireless Device ID Matching (AWS)"
        }

        div {
            className = ReportPageStyles.headingAndDropDownContainer
            div {
                TextField {
                    className = SearchbarStyles.searchBarInput(15)
                    placeholder = "Enter Device ID"
                    value = searchValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setSearchValue(target?.value ?: "")
                    }
                }
            }

            div {
                Button {
                    variant = ButtonVariant.contained
                    color = ButtonColor.primary
                    onClick = {
                        searchForId(searchValue)
                    }
                    +"Search"
                }
            }
        }
        div {
            if (wirelessDeviceId.length > 20) {
                a {
                    href = wirelessDeviceId
                    target = WindowTarget._blank
                    +wirelessDeviceId
                }
            } else {
                +wirelessDeviceId
            }
        }

        div {
            className = ReportPageStyles.headingAndDropDownContainer
            +"Device Location"
        }
        div {
            +"Property Name: $propertyName "
            a {
                href = deviceLocationLink
                target = WindowTarget._blank
                +deviceLocationLink
            }
        }
    }

    div {
        className = ReportPageStyles.costSavingsContainer
        div {
            className = HelpStyles.eventTitle
            +"Gateway Device Data"
        }
        div {
            className = ReportPageStyles.headingAndDropDownContainer
            div {
                TextField {
                    className = SearchbarStyles.searchBarInput(15)
                    placeholder = "Enter Property ID"
                    value = searchDeviceValue
                    onChange = { event ->
                        val target = event.target as? HTMLInputElement
                        setDeviceValue(target?.value ?: "")
                    }
                }
            }

            div {
                if (!gatewayDeviceInfoLoading) {
                    Button {
                        variant = ButtonVariant.contained
                        color = ButtonColor.primary
                        onClick = {
                            getGatewayDeviceData(searchDeviceValue)
                        }
                        +"Get Data"
                    }
                } else {
                    div {
                        ReactHTML.span {
                            CircularProgress {
                                color = CircularProgressColor.info
                                size = 150.px
                            }
                        }
                    }
                }
            }
        }

        div {
            className = HelpStyles.eventTitle
            +"Results"
        }

        div {
            Button {
                variant = ButtonVariant.contained
                color = ButtonColor.primary
                disabled = gatewayReloadDisabled
                onClick = {
                    gatewayReloadDisabled = true
                    reloadAllGatewayInfo()
                }
                +"RELOAD ALL GATEWAY GATEWAY (ONLY FOR TESTING)"
            }
        }

        div {
            for ((key, value) in gatewayDeviceInformation) {
                div {
                    className = HelpStyles.eventTitle
                    +"$key - # of devices connected: ${value.size}"
                }
                div {
                    +"Sensors: ${value.joinToString(", ")}"
                }
            }
        }
    }
}