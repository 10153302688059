package com.fireavert.devices.logic.requests

import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.devices.logic.models.DeviceType
import kotlinx.serialization.Serializable

@Serializable
data class DeviceCreationRequest(
    val deviceLocator: String = "",
    val location: String = "",
    val unitId: Int = 0,
    val type: DeviceType = DeviceType.FireAvert,
    val commVersion: Int = 1,
    val rebootUUID: String = "",
    val infoUUID: String = "",
) {
    companion object {
        fun fromDeviceData(from: DeviceData, unitId: Int): DeviceCreationRequest {
            return DeviceCreationRequest(
                deviceLocator = from.locator,
                location = from.location,
                unitId = unitId,
                type = DeviceType.fromString(from.type),
                commVersion = from.commVersion,
                rebootUUID = from.rebootUUID,
                infoUUID = from.infoUUID
            )
        }
    }
}
