package com.fireavert.auth.logic

import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import com.fireavert.user.logic.User
import com.fireavert.user.logic.UserRepository
import org.koin.core.component.KoinComponent

class GetUser(
    private val userRepository: UserRepository,
    private val preferences: Preferences,
    private val tokenRefreshService: TokenRefreshService
) : KoinComponent {
    suspend fun execute(tryRefresh: Boolean = true): Try<User> {
        return when (val maybeUser = userRepository.getUserByToken(preferences.token)) {
            is Try.Success -> maybeUser
            is Try.Error -> {
                if (!tryRefresh) {
                    maybeUser
                } else {
                    tokenRefreshService.refreshToken { success ->
                        if (success) {
                            execute(false)
                        } else {
                            maybeUser
                        }
                    }
                }
            }
        }
    }
}