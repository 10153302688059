package com.fireavert.user.data

import com.fireavert.common.Try
import com.fireavert.user.logic.User
import com.fireavert.user.api.UserDataCache

class InMemoryUserDataCache : UserDataCache {
    private var user: User? = null

    override suspend fun setUser(user: User) {
        this.user = user
    }

    override suspend fun getUser(id: String): Try<User> {
        val user = this.user ?: return Try.Error(Exception("No User Found in Cache"))
        return Try.Success(user)
    }
}