package com.fireavert.devices.logic.models

import com.fireavert.events.logic.models.EventType

data class UnitDetailsEventHistoryDataState(
    val eventId: Long,
    val dateTime: String,
    val timestamp: Long,
    val deviceType: DeviceType,
    val location: String,
    val eventType: EventType,
    val alarmActive: Boolean,
    val deviceTriggered: Boolean,
    val isTest: Boolean,
    val isFalseAlert: Boolean
)