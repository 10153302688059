package com.fireavert.administration_page.wireless_id_finder.interface_adapters

import com.fireavert.administration_page.wireless_id_finder.logic.WirelessIdSectionScreen
import com.fireavert.common.ViewModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class WirelessIdSectionViewModel : WirelessIdSectionScreen, ViewModel() {
    private val _viewState = MutableStateFlow(WirelessIdSectionViewState())
    val viewState = _viewState.asStateFlow()

    val scope: CoroutineScope = viewModelScope

    override var wirelessDeviceId: String
        get() { return viewState.value.wirelessDeviceId }
        set(value) {
            _viewState.value = _viewState.value.copy(wirelessDeviceId = value)
        }
}