package com.fireavert.components.pages
import com.fireavert.components.common.LoggedIn
import com.fireavert.components.dialogs.*
import com.fireavert.components.nav.*
import com.fireavert.components.properties.DamageEventsHappeningNow
import com.fireavert.components.properties.GatewayDetails
import com.fireavert.components.properties.ListUnitsAndDevices
import com.fireavert.logging.Logger
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.properties.presentation.property_details.PropertyDetailsController
import com.fireavert.properties.presentation.property_details.PropertyDetailsViewModel
import com.fireavert.styles.PageStyles
import com.fireavert.styles.PageStylesV2
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import mui.system.useMediaQuery
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.router.dom.Link
import react.router.dom.useSearchParams
import react.router.useParams
import web.cssom.px
import web.dom.Element
import web.html.HTMLElement

external interface UnitDetailsDialogProps: Props {
    var isUserAdmin: Boolean
}

const val SESSION_STORAGE_SCROLL_KEY = "SESSION_STORAGE_SCROLL_KEY"
external interface PropertyDetailsProps : Props {
    var unitDetailsDialogIsOpen: Boolean
    var addNewUnitDialogIsOpen: Boolean
    var editPropertyDialogIsOpen: Boolean
    var editUnitDialogIsOpen: Boolean
    var deleteUnitDialogIsOpen: Boolean
    var dismissEventDialogIsOpen: Boolean
    var markAsTestDialogIsOpen: Boolean
    var markAsFalseAlarmDialogIsOpen: Boolean
    var rebootDeviceDialogIsOpen: Boolean
    var deletePropertyAdminDialogIsOpen: Boolean
    var rebootIsGas: Boolean
    var addNewUnitCSVDialogIsOpen: Boolean
    var convertToV2DialogIsOpen: Boolean
}


val PropertyDetailsV2 = FC<PropertyDetailsProps> { props ->
    val propertyIdParam = useParams()["propertyId"]?.toInt() ?: 0
    val queryStringParam = useSearchParams().component1().toString()
    var propertyName: String by useState("")
    var propertyTotalUnitsState: Int by useState(0)
    val mainContentDivRef = useRef<Element>()
    var isAdmin: Boolean by useState(false)
    val isMobile = useMediaQuery("(max-width: 768px)")

    val viewModel = getKoinInstance<PropertyDetailsViewModel>()
    val controller = getKoinInstance<PropertyDetailsController>()
    val preferences = getKoinInstance<Preferences>()
    val logger = getKoinInstance<Logger>()

    fun saveScroll() {
        val element = mainContentDivRef.current as? HTMLElement
        element?.scrollTop?.let { scrollPosition ->
            sessionStorage.setItem(SESSION_STORAGE_SCROLL_KEY, scrollPosition.toString())
        }
    }

    fun restoreScroll() {
        window.requestAnimationFrame {
            val scrollRestore = sessionStorage.getItem(SESSION_STORAGE_SCROLL_KEY)?.toDouble() ?: 0.0
            val element = mainContentDivRef.current as? HTMLElement
            element?.scrollTo(0.0, scrollRestore)
        }
    }

    useEffectOnce {
        val viewStateJob = viewModel.viewState.onEach { viewState ->
            propertyName = viewState.propertyName
            propertyTotalUnitsState = viewState.propertyTotalUnits
            isAdmin = viewState.isAdmin
        }.launchIn(viewModel.scope)

        val onLoadJob = viewModel.scope.launch {
            controller.onLoad(propertyIdParam, preferences.userId)
        }

        restoreScroll()
        cleanup {
            onLoadJob.cancel()
            viewStateJob.cancel()
        }
    }

    useEffect(props.unitDetailsDialogIsOpen) {
        if (!props.unitDetailsDialogIsOpen) {
            restoreScroll()
        }
    }

    LoggedIn {}
    if (props.editPropertyDialogIsOpen) {
        EditPropertyDialog {}
    } else if (props.deletePropertyAdminDialogIsOpen) {
        DeletePropertyAdminDialog {}
    } else if (props.unitDetailsDialogIsOpen) {
        if (isMobile) {
            UnitDetailsMobileDialog {
                isUserAdmin = isAdmin
            }
        }
        else {
            UnitDetailsDialog {
                isUserAdmin = isAdmin
            }
        }
    } else if (props.addNewUnitDialogIsOpen) {
        AddUnitDialog {}
    } else if (props.editUnitDialogIsOpen) {
        EditUnitDialog {}
    } else if (props.addNewUnitCSVDialogIsOpen) {
        AddNewUnitsCSVDialog {}
    } else if (props.convertToV2DialogIsOpen) {
        ConvertToV2Dialog {}
    } else if (props.deleteUnitDialogIsOpen) {
        DeleteUnitDialog {}
    } else if (props.dismissEventDialogIsOpen) {
        DismissEventDialog {}
    } else if (props.markAsTestDialogIsOpen) {
        MarkAsTestDialog {}
    } else if (props.markAsFalseAlarmDialogIsOpen) {
        MarkAsFalseAlarmDialog {}
    } else if (props.rebootDeviceDialogIsOpen) {
        RebootDeviceDialog {
            isGas = props.rebootIsGas
        }
    }
    ReactHTML.div {
        className = PageStyles.flexContainer
        SideNavV2 {
            activeNavOption = NavOption.Properties
        }
        ReactHTML.div {
            className = PageStylesV2.mainContainer
            onScroll = { _ ->
                saveScroll()
            }
            ref = mainContentDivRef
            TopNavBarV2 { activeNavOption = NavOption.Properties }
            ReactHTML.div {
                className = PageStylesV2.mainContainerContent
                if (isMobile) {
                    ReactHTML.div {
                        a {
                            href = RoutePaths.properties
                            +"< Properties"
                        }
                    }
                }
                ReactHTML.div {
                    className = PageStyles.sectionTitleWithRightHandButton
                    +propertyName
                    if (isAdmin and !isMobile) {
                        Link {
                            to = "${RoutePaths.properties}/${propertyIdParam}/edit"
                            Button {
                                className = PageStylesV2.genericButton
                                color = ButtonColor.primary
                                variant = ButtonVariant.contained
                                +"Edit Property"
                            }
                        }
                    }
                    else if (isAdmin and isMobile) {
                        div {
                            css {
                                fontSize = 16.px
                            }
                            Link {
                                to = "${RoutePaths.properties}/${propertyIdParam}/add_new_unit"
                                a {
                                    +"Add Unit"
                                }
                            }
                            +"  |  "
                            Link {
                                to = "${RoutePaths.properties}/${propertyIdParam}/edit"
                                a {
                                    +"Edit Property"
                                }
                            }
                        }
                    }
                }
                DamageEventsHappeningNow {
                    propertyId = propertyIdParam
                    mobileVersion = isMobile
                }
                GatewayDetails {
                    propsIsAdmin = isAdmin
                    propsIsMobile = isMobile
                    propertyId = propertyIdParam
                    propsPropertyName = propertyName
                }

                ListUnitsAndDevices {
                    propertyId = propertyIdParam
                    propertyTotalUnitCount = propertyTotalUnitsState
                    queryString = queryStringParam
                    isMobileVersion = isMobile
                }
            }
        }
    }
}