package com.fireavert.user.api

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import com.fireavert.user.logic.UserRepository

class UserPreferencesTokenRefreshService(
    private val userRepository: UserRepository,
    private val preferences: Preferences,
    private val logger: Logger
) : TokenRefreshService {
    override suspend fun <T> refreshToken(callback: suspend (success: Boolean) -> Try<T>): Try<T> {
        return when (val maybeAuth = userRepository.authenticateByRefresh(preferences.userId, preferences.refresh)) {
            is Try.Success -> {
                preferences.setFromAuth(maybeAuth.value)
                callback(true)
            }
            is Try.Error -> {
                logger.e("Failed to refresh token for user ${preferences.userId}")
                callback(false)
            }
        }
    }
}