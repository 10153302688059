package com.fireavert.components.events

import com.fireavert.styles.EventsStyles
import com.fireavert.styles.TableStyles
import emotion.css.ClassName
import emotion.css.cx
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import web.cssom.*

external interface UnitTotalCellProps : Props {
    var hasEvents: Boolean
    var style: ClassName
    var isOffline: Boolean
    var isActive: Boolean
    var activeIcon: String
    var text: String
}

val UnitTotalCell = FC<UnitTotalCellProps> { props ->
    div {
        className = if (props.hasEvents) {
            cx(
                TableStyles.tableCellCentered,
                props.style,
                ClassName {
                    fontWeight = FontWeight.bold
                }
            )
        } else {
            TableStyles.tableCellCentered
        }
        if (props.isOffline and (props.style != EventsStyles.smokeText)) {
            div {
                css {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                }
                div {
                    css { paddingBottom = 2.px }
                    +"${props.text} "
                }
                ReactHTML.img {
                    src = "/static/active_offline_icon.svg"
                    alt = "Offline"
                    className = TableStyles.activeIconNoMargin
                }
            }

        } else if (props.isActive) {
            ReactHTML.img {
                src = props.activeIcon
                alt = "Active"
                className = TableStyles.activeIconNoMargin
            }
        } else {
            +props.text
        }
    }
}