package com.fireavert.devices.logic.response

import com.fireavert.devices.logic.models.DeviceType
import kotlinx.serialization.Serializable

@Serializable
data class GetDeviceEventsResponse(
    val events: List<DeviceEventResponse>? = null
)

@Serializable
data class DeviceEventResponse(
    val id: Long? = null,
    val timestamp: String? = null,
    val deviceId: Int? = null,
    val unitId: Int? = null,
    val type: EventTypeResponse? = null,
    val deviceType: DeviceType? = null,
    val isCleared: Boolean? = null,
    val isTest: Boolean? = null,
    val isFalseAlert: Boolean? = null,
    val timezoneId: String? = null,
)

@Serializable
enum class EventTypeResponse {
    SmokeAlarmActive,
    DeviceTriggered,
    DeviceReset,
    DeviceOffline,
    Unknown
}