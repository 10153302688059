package com.fireavert.units.presentation

import com.fireavert.common.ViewModel
import com.fireavert.units.logic.DeleteUnitDialogScreen
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class DeleteUnitDialogViewModel: ViewModel(), DeleteUnitDialogScreen {
    private val _viewState = MutableStateFlow(DeleteUnitDialogViewState())
    val viewState = _viewState.asStateFlow()
    val scope = viewModelScope
    override var isDeleting: Boolean
        get() = viewState.value.isDeleting
        set(value) {
            _viewState.value = viewState.value.copy(isDeleting = value)
        }

}
