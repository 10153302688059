package com.fireavert.preferences.repository

import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.PageName
import com.fireavert.user.logic.UserAuth
import com.russhwolf.settings.Settings

class KeyValuePreferences(private val settings: Settings, private val logger: Logger) : Preferences {
    companion object {
        const val USER_ID_KEY = "USER_ID_KEY"
        const val TOKEN_KEY = "TOKEN"
        const val REFRESH_KEY = "REFRESH"
        const val PERMISSIONS_KEY = "PERMISSIONS"
        const val ROLES_KEY = "ROLES"
        const val PAGE_VERSIONS_KEY = "PAGE_VERSIONS"
    }

    override var permissions: List<String>
        get() {
            return settings.getString(PERMISSIONS_KEY, "").splitToSequence(",").toList()
        }
        set(value) {
            settings.putString(PERMISSIONS_KEY, value.joinToString(separator = ",") { it })
        }
    override var roles: List<String>
        get() {
            return settings.getString(ROLES_KEY, "").splitToSequence(",").toList()
        }
        set(value) {
            settings.putString(ROLES_KEY, value.joinToString(separator = ",") { it })
        }
    override var pageVersions: Map<PageName, Int>
        get() {
            val pageVersionsString = settings.getString(PAGE_VERSIONS_KEY, "")
            if (pageVersionsString.isBlank()) {
                return emptyMap()
            }
            val pairList = pageVersionsString.splitToSequence(",").toList()
            val map: MutableMap<PageName, Int> = mutableMapOf()
            pairList.forEach { pair ->
                val keyvalue = pair.splitToSequence("=").toList()
                val key = PageName.valueOf(keyvalue[0])
                val value = keyvalue[1]
                map[key] = value.toInt()
            }
            return map
        }
        set(value) {
            val str = value.map {
                "${it.key}=${it.value}"
            }.joinToString(",")
            settings.putString(PAGE_VERSIONS_KEY, str)
        }
    override val isAdmin: Boolean
        get() = roles.contains("Admin")

    override var userId: Int
        get() {
            return settings.getInt(USER_ID_KEY, 0)
        }
        set(value) {
            settings.putInt(USER_ID_KEY, value)
        }
    override var token: String
        get() {
            return settings.getString(TOKEN_KEY, "")
        }
        set(value) {
            settings.putString(TOKEN_KEY, value)
        }
    override var refresh: String
        get() {
            return settings.getString(REFRESH_KEY, "")
        }
        set(value) {
            settings.putString(REFRESH_KEY, value)
        }

    override var timeZone: String
        get() {
            return settings.getString("TIME_ZONE", "")
        }
        set(value) {
            settings.putString("TIME_ZONE", value)
        }

    override fun setFromAuth(auth: UserAuth) {
        userId = auth.id
        token = auth.token
        refresh = auth.refresh
        permissions = auth.roles.flatMap { role -> role.permissions.map { permission -> permission.key }}
        roles = auth.roles.map { role -> role.name }
        val tmpPageVersions = auth.roles.flatMap { role -> role.pageVersions.map {it} }
        val permissionsMap: MutableMap<PageName,Int> = mutableMapOf()
        tmpPageVersions.forEach { pageVersion ->
            permissionsMap[pageVersion.key] = pageVersion.value
        }
        pageVersions = permissionsMap.toMap()
    }

    override fun clearAuth() {
        userId = 0
        token = ""
        refresh = ""
        permissions = emptyList()
        roles = emptyList()
        pageVersions = emptyMap()
    }
}