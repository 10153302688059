package com.fireavert.common

sealed class Try<T> {
    data class Success<T>(val value: T) : Try<T>()
    data class Error<T>(val exception: Exception) : Try<T>() {
        override fun equals(other: Any?): Boolean {
            if (other is Error<*>) {
                if (other.exception.message == exception.message) {
                    return true
                }
            }
            return super.equals(other)
        }
    }

    companion object {
        fun <T> fromNullable(value: T?): Try<T> {
            return if (value == null) Error(
                NullPointerException()
            )
            else Success(value)
        }

        fun <T : Collection<*>> fromCollection(value: T): Try<T> {
            return if (value.isEmpty()) Error(
                Exception("Collection is empty")
            )
            else Success(value)
        }

        fun <T> fromCallable(function: () -> T): Try<T> {
            return try {
                val value = function()
                Success(value)
            } catch (e: Exception) {
                Error(e)
            }
        }

        suspend fun <T> fromCoCallable(function: suspend () -> T): Try<T> {
            return try {
                val value = function()
                Success(value)
            } catch (e: Exception) {
                Error(e)
            }
        }
    }

    suspend fun <V> coMap(mapper: suspend (T) -> V): Try<V> {
        return when (this) {
            is Success -> fromCoCallable { mapper(value) }
            is Error -> Error(exception)
        }
    }

    fun validate(validator: (T) -> Boolean): Try<T> {
        return when (this) {
            is Success -> {
                when (validator.invoke(value)) {
                    true -> Success(value)
                    false -> Error(Exception("Failed Validation"))
                }
            }
            is Error -> Error(exception)
        }
    }


    fun <V> map(mapper: (T) -> V): Try<V> {
        return when (this) {
            is Success -> fromCallable { mapper(value) }
            is Error -> Error(exception)
        }
    }

    fun <V> then(function: (T) -> Try<V>): Try<V> {
        return when (this) {
            is Success -> function(value)
            is Error -> Error(exception)
        }
    }

    suspend fun <V> coThen(function: suspend (T) -> Try<V>): Try<V> {
        return when (this) {
            is Success -> function(value)
            is Error -> Error(exception)
        }
    }

    suspend fun <V> coCombine(function: suspend (T) -> Try<V>): Try<Pair<V, T>> {
        return when (this) {
            is Success -> {
                when (val tryToAdd = function(value)) {
                    is Success -> fromCallable { tryToAdd.value to this.value }
                    is Error -> Error(tryToAdd.exception)
                }
            }
            is Error -> Error(exception)
        }
    }
}