package com.fireavert.styles

import emotion.css.ClassName
import web.cssom.*

object MobileStyles {

    const val MOBILE_VIEW_WIDTH = "(max-width: 768px)"

    val verticalContainer = ClassName {
        minHeight = 100.vh
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.spaceBetween
    }
    val mobileForm = ClassName {
        minHeight = 100.vh
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.spaceBetween
    }
    val topContainer = ClassName {
        backgroundImage = url("/static/qr_reset_header.svg")
        backgroundRepeat = BackgroundRepeat.noRepeat
        backgroundPositionX = BackgroundPositionX.center
        backgroundPositionY = BackgroundPositionY.top
        height = 116.px
        width = 100.vw
    }
    val topContainerLogo = ClassName {
        backgroundImage = url("/static/qr_reset_fireavertlogo.svg")
        backgroundRepeat = BackgroundRepeat.noRepeat
        backgroundPositionX = BackgroundPositionX.left
        backgroundPositionY = BackgroundPositionY.center
        height = 116.px
        width = 100.vw
    }
    val middleContainer = ClassName {
        width = 100.vw
        margin = Margin(0.px, Auto.auto)
    }
    val innerContainer = ClassName {
        padding = Padding(0.px, 20.px)
    }
    val error = ClassName {
        fontWeight = FontWeight.bold
        fontSize = 33.px
        color = Color("#CF3338")
        textAlign = TextAlign.center
    }
    val title = ClassName {
        fontWeight = FontWeight.bold
        fontSize = 33.px
        textAlign = TextAlign.center
    }
    val unit = ClassName {
        color = Color("#CF3338")
        fontWeight = FontWeight.bold
        fontSize = 62.px
        textAlign = TextAlign.center
        marginTop = 10.px
        marginBottom = 10.px
    }
    val propertyInfo = ClassName {
        fontWeight = FontWeight.bold
        fontSize = 17.px
        textAlign = TextAlign.center
        color = Color("#444444")
    }
    val bottomContainer = ClassName {
        backgroundImage = url("/static/qr_reset_footer.svg")
        backgroundRepeat = BackgroundRepeat.noRepeat
        backgroundPositionX = BackgroundPositionX.center
        backgroundPositionY = BackgroundPositionY.bottom
        margin = Margin(25.px, Auto.auto, 0.px, Auto.auto)
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.end
        width = 100.vw
        height = 115.px
        color = Color("#FFFFFF")
        paddingBottom = 10.px
    }
    val bottomContainerInner = ClassName {
        padding = Padding(0.px, 20.px)
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
    }
    val bottomLink = ClassName {
        color = Color("#FFFFFF")
        textDecoration = None.none
    }
    val outerBlock = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.column
        width = 390.px
        margin = Margin(0.px, Auto.auto)
        padding = 0.px
    }
    val topRectBlack = ClassName {
        display = Display.flex
        width = 390.px
        height = 105.px
        background = Color("#000000")
    }
    val fireAvertLogo = ClassName {
        margin = Margin((-30).px, Auto.auto, 0.px, Auto.auto)
        width = 301.px
        height = 163.px
        flexShrink = number(0.0)
        backgroundImage = url("/static/FireAvert_White_Logo-01.png")
        backgroundRepeat = BackgroundRepeat.noRepeat
    }
    val letsGetYouBackToCooking = ClassName {
        textAlign = TextAlign.center
        width = 330.px
        color = Color("#000")
        fontFamily = string("Poppins, sans-serif")
        fontWeight = integer(700)
        fontStyle = FontStyle.normal
        fontSize = 58.px
        fontStyle = FontStyle.normal
        lineHeight = 62.px
        margin = Margin(40.px, Auto.auto, 40.px, Auto.auto)
    }
    val notice = ClassName {
        color = Color("#000")
        textAlign = TextAlign.center
        fontFamily = string("Poppins, sans-serif")
        fontSize = 14.px
        fontStyle = FontStyle.normal
        fontWeight = FontWeight.bold
        width = 210.px
        margin = Margin(0.px, Auto.auto, 20.px, Auto.auto)
        border = Border(1.px, LineStyle.solid, NamedColor.black)
        padding = Padding(10.px, 30.px, 0.px, 30.px)
    }
    val noticeP = ClassName {
        marginTop = 0.px
        fontWeight = FontWeight.normal
    }
    val noticePSpan = ClassName {
        color = Color("#CF3338")
    }
    val stoveIdNumberTitle = ClassName {
        margin = Margin(0.px, Auto.auto, 5.px, Auto.auto)
        fontFamily = string("Poppins, sans-serif")
        fontSize = 14.px
    }
    val stoveIdInput = ClassName {
        display = important(Display.block)
        background = Color("#FFF")
        border = important(Border(1.px, LineStyle.solid, Color("#383838")))
        width = 228.px
        height = 34.px
        textAlign = important(TextAlign.center)
    }
    val turnStoveBackOnOuter = ClassName {
        margin = Margin(20.px, Auto.auto, 20.px, Auto.auto)
        backgroundColor = Color("#CF3338")
        width = 277.px
        height = 63.px
        borderRadius = 48.px
        boxShadow =  BoxShadow(0.px, 4.px, 11.px, 0.px, rgb(0, 0, 0, 0.49))
        textDecoration = None.none
    }
    val turnStoveBackOnText = ClassName {
        color = NamedColor.white
        marginTop = 18.px
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.center
        textAlign = TextAlign.center
        fontFamily = string("Poppins, sans-serif")
        fontSize = 19.px
        fontWeight = integer(700)
    }
    val contactCustomerServiceLinkOuter = ClassName {
        margin = Margin(10.px, Auto.auto, 0.px, Auto.auto)
    }
    val contactCustomerServiceLink = ClassName {
        fontFamily = string("Poppins, sans-serif")
        fontSize = 12.px
        textDecoration = None.none
        color = Color("#4C80D3")
        fontWeight = FontWeight.bold
    }
    val bottomRect = ClassName {
        color = Color("#000")
        marginTop = 20.px
        height = 88.px
    }
    val bottomRectRow = ClassName {
        padding = Padding(35.px , 30.px)
        display = Display.flex
        justifyContent = JustifyContent.spaceBetween
    }
    val bottomRectLinkBlack = ClassName {
        color = Color("#000")
        textDecoration = None.none
    }
    val yourHuman = ClassName {
        width = 175.px
        height = 190.px
        color = Color("#000")
        fontFamily = string("Poppins, sans-serif")
        fontWeight = integer(600)
        fontStyle = FontStyle.normal
        fontSize = 48.px
        lineHeight = 50.px
        margin = Margin(60.px, 10.px, 0.px, 10.px)
    }

    val yourHumanContainer = ClassName {
        display = Display.flex
        flexDirection = FlexDirection.row
    }

    val andAccidentsHappen = ClassName {
        fontSize = 20.px
        lineHeight = 27.px
        fontWeight = integer(700)
    }
    val turnOnSafetyAlertsButtonOuter = ClassName {
        margin = Margin(20.px, Auto.auto, 20.px, Auto.auto)
        backgroundColor = Color("#FFD600")
        width = 250.px
        height = 63.px
        borderRadius = 48.px
        boxShadow = BoxShadow( 0.px, 4.px, 11.px, 0.px, rgb(0, 0, 0, 0.49))
        textDecoration = None.none
    }

    val turnMyStoveOnButton = ClassName {
        margin = Margin(20.px, Auto.auto, 20.px, Auto.auto)
        backgroundColor = Color("#CF3338")
        width = 277.px
        height = 63.px
        borderRadius = 48.px
        boxShadow = BoxShadow( 0.px, 4.px, 11.px, 0.px, rgb(0, 0, 0, 0.49))
        textDecoration = None.none
    }
    val turnOnSafetyAlertsText = ClassName {
        marginTop = 18.px
        display = Display.flex
        flexDirection = FlexDirection.column
        justifyContent = JustifyContent.center
        color = Color("#000")
        textAlign = TextAlign.center
        fontFamily = string("Poppins, sans-serif")
        fontSize = 19.px
        fontWeight = integer(490)
    }
    val gradientRect = ClassName {
        backgroundImage = url("/static/gradientRect.png")
        height = 70.px
        marginBottom = (-60).px
        zIndex = integer(100)
    }
    val phoneImage = ClassName {
        backgroundImage = url("/static/phone_text_alert.png")
        height = 250.px
        width = 200.px
    }

    val stoveIdContainer = ClassName {
        backgroundColor = Color("#D9D9D9")
        display = Display.flex
        flexDirection = FlexDirection.column
        padding = 15.px
    }

    val stoveIdButtonContainer = ClassName {
        backgroundColor = Color("#D9D9D9")
        display = Display.flex
        flexDirection = FlexDirection.column
        paddingLeft = 30.px
        paddingBottom = 15.px
        paddingTop = 15.px
    }
}