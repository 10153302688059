package com.fireavert.components.active_events

import com.fireavert.actions.logic.EventLinkedActionType
import com.fireavert.events.activeevents.domain.ActiveSensorType
import com.fireavert.events.logic.InstantTimeStringFormatter
import com.fireavert.menu.RoutePaths
import com.fireavert.preferences.logic.Preferences
import com.fireavert.styles.DialogStyles
import com.fireavert.styles.EventsStylesV2
import com.fireavert.utilities.getKoinInstance
import emotion.react.css
import kotlinx.datetime.Instant
import mui.material.*
import mui.system.Breakpoint
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.img
import react.router.dom.Link
import web.cssom.*


external interface ActiveEventsMobileDialogProps : ActiveEventsRowMobileV2Props {
    var titleText: String
    var onClose: () -> Unit
    var backgroundColor: Color
}

val ActiveEventsMobileDialog = FC<ActiveEventsMobileDialogProps> { props ->
    val preferences = getKoinInstance<Preferences>()
    Dialog {
        open = true
        onClose = { _, _ ->
            props.onClose()
        }
        onBackdropClick = {}
        maxWidth = Breakpoint.lg
        fullWidth = true
        sx {
            "& .MuiDialog-paper" {
                height = 50.pct
                maxHeight = 50.pct
                margin = Auto.auto
                padding = 0.px
                display = Display.flex
                borderRadius = 10.px
            }
        }
        DialogTitle {
            sx { padding = 0.px }
        }
        DialogContent {
            sx {
                flex = number(1.0)
                display = Display.flex
                flexDirection = FlexDirection.column
                overflow = Overflow.hidden
                padding = 0.px
            }
            ReactHTML.div {
                className = DialogStyles.mobileDialogActiveEventTitle(props.backgroundColor)
                ReactHTML.div {
                    ReactHTML.div {
                        className = DialogStyles.mobileDialogActiveEventPropertyTitle
                        +props.event.propertyName
                    }
                    ReactHTML.div {
                        DialogStyles.mobileDialogActiveEventUnitTitle
                        Link {
                            to = "${RoutePaths.properties}/${props.event.propertyId}/units/${props.event.unitId}"
                            css {
                                color = important(NamedColor.black)
                                textDecoration = TextDecoration.underline
                            }
                            +props.event.unitNumber
                        }
                    }
                }
                ReactHTML.div {
                    +props.titleText
                }
            }
            ReactHTML.div {
                className = DialogStyles.mobileDialogActiveEventTime
                ReactHTML.div {
                    +"Time of event: "
                    ReactHTML.span {
                        css {
                            fontWeight = FontWeight.bold
                        }
                        +InstantTimeStringFormatter.formatTimeOnly(
                            Instant.fromEpochSeconds(props.event.eventTimestamp),
                            preferences.timeZone
                        )
                    }
                }
                ReactHTML.div {
                    +"Date: "
                    ReactHTML.span {
                        css {
                            fontWeight = FontWeight.bold
                        }
                        +InstantTimeStringFormatter.formatC(
                            Instant.fromEpochSeconds(props.event.eventTimestamp),
                            preferences.timeZone
                        )
                    }
                }
            }
            ReactHTML.div {
                className = EventsStylesV2.automatedActionsTakenIconSetContainer
                ReactHTML.div {
                    +"Actions Taken:"
                }
                ReactHTML.div {
                    ReactHTML.div {
                        className = DialogStyles.mobileDialogActiveEventsActionContainer
                        if (props.event.actionsTaken.contains(EventLinkedActionType.StoveShutoff)) {
                            img {
                                src = "/static/mobile_stove_shutoff_active.svg"
                                alt = "Stove Shutoff"
                            }
                        } else {
                            img {
                                src = "/static/mobile_stove_shutoff_inactive.svg"
                                alt = "Stove Shutoff"
                            }
                        }
                        +"Stove shutoff"
                    }
                    ReactHTML.div {
                        className = DialogStyles.mobileDialogActiveEventsActionContainer
                        if (props.event.actionsTaken.contains(EventLinkedActionType.TextAlert)) {
                            img {
                                src = "/static/mobile_text_alert_active.svg"
                                alt = "Stove shutoff"
                            }
                        } else {
                            img {
                                height
                                src = "/static/mobile_text_alert_inactive.svg"
                                alt = "Text alert"
                            }
                        }
                        +"Text alert"
                    }
                    ReactHTML.div {
                        className = DialogStyles.mobileDialogActiveEventsActionContainer
                        if (props.event.actionsTaken.contains(EventLinkedActionType.StoveReset)) {
                            img {
                                src = "/static/mobile_stove_reset.svg"
                                alt = "Stove Shutoff"
                            }
                        } else {
                            img {
                                src = "/static/mobile_stove_reset_inactive.svg"
                                alt = "Stove Shutoff"
                            }
                        }
                        +"Stove reset"
                    }
                }
            }
            ReactHTML.div {
                className = DialogStyles.mobileDialogActiveEventButtonContainer
                if (props.event.activeSensors.contains(ActiveSensorType.Shutoff) && !props.event.activeSensors.contains(
                        ActiveSensorType.Offline)) {
                    ReactHTML.span {
                        className = DialogStyles.mobileDialogRebootDeviceButton
                        Link {
                            to =
                                "${RoutePaths.properties}/${props.event.propertyId}/reboot_device/${props.event.deviceId}"
                            Button {
                                sx {
                                    borderRadius = 30.px
                                    paddingLeft = 20.px
                                    paddingRight = 20.px
                                }
                                color = ButtonColor.info
                                variant = ButtonVariant.contained
                                +"Reboot Device"
                            }
                        }
                    }
                }
                else {
                    ReactHTML.span {
                        className = EventsStylesV2.notReadyToReboot
                        +"Reboot"
                    }
                }
            }
        }
    }
}

