package com.fireavert.reports_page.logic.models.responses

import com.fireavert.reports_page.logic.models.HighRiskTenantModel
import kotlinx.serialization.Serializable

@Serializable
data class HighRiskTenantsResponse(
    val highRiskTenants: List<HighRiskTenantModel>,
    val averageEventsPerMonth: Double,
)
