package com.fireavert.events.dismissevent.presentation

import com.fireavert.common.ViewModel
import com.fireavert.events.dismissevent.logic.DismissEventDialogScreen
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class DismissEventDialogViewModel : ViewModel(), DismissEventDialogScreen {
    private val _viewState = MutableStateFlow(DismissEventDialogViewState())
    val viewState = _viewState.asStateFlow()

    override val scope: CoroutineScope
        get() = viewModelScope

    override fun setLoading(loading: Boolean) {
        _viewState.value = _viewState.value.copy(loading = loading)
    }

}