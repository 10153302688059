package com.fireavert.gateways.frameworks

import com.fireavert.common.Try
import com.fireavert.devices.logic.response.GetDeviceRecentGatewayAssociationResponse
import com.fireavert.gateways.models.GatewayModel
import com.fireavert.gateways.models.request.GatewayDetailChangeRequest
import com.fireavert.gateways.models.request.GatewayImageDataRequest
import com.fireavert.gateways.models.request.GatewayImageUploadRequest
import com.fireavert.gateways.models.request.PropertyGatewayChangeRequest
import com.fireavert.gateways.models.response.GatewayImageDataResponse
import com.fireavert.gateways.models.response.GatewaysStatusResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.request.*
import io.ktor.http.*

class GatewayPropertyDataSourceImpl(
    private val baseUrl: String,
    private val httpClient: HttpClient,
) : GatewayPropertyDataSource {

    override suspend fun getAssociatedGateways(propertyId: Int, token: String): Try<List<GatewayModel>> {
        return Try.fromCoCallable {
            val request = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways/$propertyId")
            }
            if (request.status.isSuccess()) request.body()
            else throw ClientRequestException(request, "")
        }
    }

    override suspend fun getAllGateways(token: String): Try<List<GatewayModel>> {
        return Try.fromCoCallable {
            val request = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways")
            }
            if (request.status.isSuccess()) request.body()
            else throw ClientRequestException(request, "")
        }
    }

    override suspend fun refreshGatewayStatus(propertyId: Int, token: String): Try<GatewaysStatusResponse> {
        return Try.fromCoCallable {
            val request = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways/status/$propertyId")
            }
            if (request.status.isSuccess()) {
                request.body()
            } else {
                throw ClientRequestException(request, "")
            }
        }
    }

    override suspend fun saveAssociatedGateways(
        request: PropertyGatewayChangeRequest,
        token: String,
    ): Try<Boolean> {
        return Try.fromCoCallable {
            val result = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (result.status.isSuccess()) {
                result.body()
            } else {
                throw ClientRequestException(result, "")
            }
        }
    }

    override suspend fun updateGatewayDetails(request: GatewayDetailChangeRequest, token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val result = httpClient.post() {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways/updateDetails")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (result.status.isSuccess()) {
                result.body()
            } else {
                throw ClientRequestException(result, "")
            }
        }
    }

    override suspend fun uploadGatewayImage(request: GatewayImageUploadRequest, token: String): Try<Boolean> {
        return Try.fromCoCallable {
            val result = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways/uploadImage")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (result.status.isSuccess()) {
                result.body()
            } else {
                throw ClientRequestException(result, "")
            }
        }
    }

    override suspend fun getGatewayImages(request: GatewayImageDataRequest, token: String): Try<GatewayImageDataResponse> {
        return Try.fromCoCallable {
            val result = httpClient.post {
                header("Authorization", "Bearer $token")
                url("$baseUrl/properties/gateways/getImages")
                contentType(ContentType.Application.Json)
                setBody(request)
            }
            if (result.status.isSuccess()) {
                result.body()
            } else {
                throw ClientRequestException(result, "")
            }
        }
    }

    override suspend fun loadGatewayDeviceData(propertyId: String, token: String): Try<GetDeviceRecentGatewayAssociationResponse> {
        return Try.fromCoCallable {
            val response = httpClient.get {
                header("Authorization", "Bearer $token")
                url("$baseUrl/devices/lastGatewayConnected/$propertyId")
            }
            if (response.status.isSuccess()) response.body()
            else throw ClientRequestException(response, "")
        }
    }
}