package com.fireavert.app.interface_adapters

import com.fireavert.app.logic.ClientAnalyticsRepository
import com.fireavert.app.logic.CreatePageViewRequest
import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.preferences.logic.Preferences
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher

class ClientAnalyticsRepositoryImpl(
    private val clientAnalyticsDataSource: ClientAnalyticsDataSource,
    private val preferences: Preferences,
    private val logger: Logger,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
) :
    ClientAnalyticsRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun createPageView(path: String): Try<Boolean> {
        val request = CreatePageViewRequest(path = path)
        return callWithRefresh {
            clientAnalyticsDataSource.createPageView(preferences.token, request)
        }.map { it.success }
    }

}