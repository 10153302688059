package com.fireavert.components.help

import com.fireavert.styles.HelpStyles
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div


external interface HelpActionCardProps : Props {
    var imageSrc: String
    var title: String
    var helpDialog: String
    var isMobileView: Boolean
}

val HelpActionCard = FC<HelpActionCardProps> { props ->

    div {
        className = HelpStyles.actionHelpCard

        div {
            className = HelpStyles.icon
            ReactHTML.img {
                src = props.imageSrc
            }
        }
        div {
            className = HelpStyles.eventTitle
            +props.title
        }
        div {
            className = HelpStyles.lineSeparatorAction
            +"----"
        }
        div {
            +props.helpDialog
        }
    }
}