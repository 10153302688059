package com.fireavert.dashboard.adapters

import com.fireavert.events.logic.models.DeviceEvent
import com.fireavert.events.logic.models.EventLinkedAction
import kotlinx.datetime.LocalDate

data class DashboardViewState(
    val isLoading: Boolean = false,
    var startDate: LocalDate? = null,
    var endDate: LocalDate? = null,
    var propertyId: Int? = null,
    var propertyMap: Map<String, Int?> = mapOf("All" to null),
    val smokeEvents: List<DeviceEvent> = emptyList(),
    val waterEvents: List<DeviceEvent> = emptyList(),
    val tamperEvents: List<DeviceEvent> = emptyList(),
    val offlineEvents: List<DeviceEvent> = emptyList(),
    val shutoffActions: List<EventLinkedAction> = emptyList(),
    val textAlertActions: List<EventLinkedAction> = emptyList(),
    val resetActions: List<EventLinkedAction> = emptyList(),
)
