package com.fireavert.devices.interface_adapters

import com.fireavert.devices.logic.models.Device
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.models.FireAvertStatus
import com.fireavert.devices.logic.response.*

private object DeviceMapper {
    fun withDeviceResponse(from: DeviceResponse): Device {
        return Device(
            id = from.id ?: -1,
            deviceLocator = from.deviceLocator ?: "",
            location = from.location ?: "",
            unit = from.unit ?: "",
            unitId = from.unitId ?: 0,
            propertyId = from.propertyId ?: -1,
            tenantName = from.tenantName ?: "",
            phone = from.phone ?: "",
            email = from.email ?: "",
            alarmEvents = from.alarmEvents ?: 0,
            triggerEvents = from.shutoffEvents ?: 0,
            status = from.status?.toFireAvertStatus() ?: FireAvertStatus.Offline,
            alarmIsOn = from.alarmIsOn ?: false,
            deviceIsTriggered = from.deviceIsTriggered ?: false,
            offline = from.deviceOffline ?: false,
            monitoring = from.monitoring ?: false,
            monitoringAccountId = from.monitoringAccountId ?: 0,
            commVersion = from.commVersion ?: 1,
            type = from.type ?: DeviceType.FireAvert,
            ignoreBefore = from.ignoreBefore ?: 0,
            watchdogCount = from.watchdogCount ?: 0,
            lastWatchdogSend = from.lastWatchdogSend ?: 0,
            rebootUUID = from.rebootUUID ?: "",
            infoUUID = from.infoUUID ?: ""
        )
    }

    fun withDevice(from: Device): DeviceResponse {
        return DeviceResponse(
            id = from.id,
            deviceLocator = from.deviceLocator,
            location = from.location,
            unit = from.unit,
            unitId = from.unitId,
            propertyId = from.propertyId,
            tenantName = from.tenantName,
            phone = from.phone,
            email = from.email,
            alarmEvents = from.alarmEvents,
            shutoffEvents = from.triggerEvents,
            status = when (from.status) {
                FireAvertStatus.Active -> FireAvertStatusResponse.Active
                FireAvertStatus.Listening -> FireAvertStatusResponse.Listening
                FireAvertStatus.Offline -> FireAvertStatusResponse.Offline
                FireAvertStatus.NotFound -> FireAvertStatusResponse.NotFound
                FireAvertStatus.Error -> FireAvertStatusResponse.Error
                FireAvertStatus.Rebooting -> FireAvertStatusResponse.Rebooting
            },
            alarmIsOn = from.alarmIsOn,
            deviceIsTriggered = from.deviceIsTriggered,
            deviceOffline = from.offline,
            monitoring = from.monitoring,
            monitoringAccountId = from.monitoringAccountId,
            commVersion = from.commVersion,
            type = from.type,
            ignoreBefore = from.ignoreBefore,
            watchdogCount = from.watchdogCount,
            lastWatchdogSend = from.lastWatchdogSend,
            rebootUUID = from.rebootUUID,
            infoUUID = from.infoUUID
        )
    }

    fun withGetDevicesByPropertyIdResponse(from: GetDevicesByPropertyIdResponse): List<Device> {
        return from.devices?.map { withDeviceResponse(it) } ?: emptyList()
    }

    fun withGetDevicesByUnitIdResponse(from: GetDevicesByUnitIdResponse): List<Device> {
        return from.devices?.map { withDeviceResponse(it) } ?: emptyList()
    }

    fun withFireAvertStatusResponse(from: FireAvertStatusResponse): FireAvertStatus {
        return when (from) {
            FireAvertStatusResponse.Active -> FireAvertStatus.Active
            FireAvertStatusResponse.Listening -> FireAvertStatus.Listening
            FireAvertStatusResponse.Offline -> FireAvertStatus.Offline
            FireAvertStatusResponse.NotFound -> FireAvertStatus.NotFound
            FireAvertStatusResponse.Error -> FireAvertStatus.Error
            FireAvertStatusResponse.Rebooting -> FireAvertStatus.Rebooting
        }
    }
}

fun FireAvertStatusResponse.toFireAvertStatus(): FireAvertStatus =
    DeviceMapper.withFireAvertStatusResponse(this)
fun DeviceResponse.toDevice(): Device = DeviceMapper.withDeviceResponse(this)
fun Device.toDeviceResponse(): DeviceResponse = DeviceMapper.withDevice(this)
fun GetDevicesByPropertyIdResponse.toDeviceList(): List<Device> =
    DeviceMapper.withGetDevicesByPropertyIdResponse(this)
fun GetDevicesByUnitIdResponse.toDeviceList(): List<Device> =
    DeviceMapper.withGetDevicesByUnitIdResponse(this)