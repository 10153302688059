package com.fireavert.properties.logic.models.request

@kotlinx.serialization.Serializable
data class ModifyPropertyAdminRequest(
    val userId: Int? = null,
    val propertyId: Int? = null,
    var firstName: String? = null,
    var lastName: String? = null,
    var phone: String? = null,
    var email: String? = null,
    var stoveTextNotifications: Boolean? = null,
    var leakTextNotifications: Boolean? = null,
    var tamperTextNotifications: Boolean? = null,
    var stoveEmailNotifications: Boolean? = null,
    var leakEmailNotifications: Boolean? = null,
    var tamperEmailNotifications: Boolean? = null,
    var smokeAlarmEmailNotifications: Boolean? = null,
    var smokeAlarmTextNotifications: Boolean? = null,
    var stoveOfflineEmailNotifications: Boolean? = null,
    var stoveOfflineTextNotifications: Boolean? = null,
    var leakOfflineEmailNotifications: Boolean? = null,
    var leakOfflineTextNotifications: Boolean? = null,
    var tamperOfflineEmailNotifications: Boolean? = null,
    var tamperOfflineTextNotifications: Boolean? = null,
    var dailyReports: Boolean? = null,
    var weeklyReports: Boolean? = null,
    var monthlyReports: Boolean? = null,
    var quarterlyReports: Boolean? = null,
    var yearlyReports: Boolean? = null,
)
