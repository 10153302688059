package com.fireavert.properties.presentation.delete_property_admin_dialog

import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.property_admin.DeletePropertyAdmin

class DeletePropertyAdminDialogController(
    private val propertyNavigator: PropertyNavigator,
    private val deletePropertyAdmin: DeletePropertyAdmin
) {
    fun dismissDialog(propertyId: Int) {
        propertyNavigator.navigate(PropertyNavigator.Destination.EditProperty(propertyId))
    }

    suspend fun clickedDeletePropertyAdmin(propertyId: Int, propertyAdminUserId: Int) =
        deletePropertyAdmin.deletePropertyAdmin(propertyId, propertyAdminUserId)

    suspend fun onLoad(propertyId: Int, propertyAdminUserId: Int) =
        deletePropertyAdmin.onLoad(propertyId, propertyAdminUserId)
}