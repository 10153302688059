package com.fireavert.units.presentation

data class UnitViewState(
    val isLoading: Boolean = false,
    val number: String? = null,
    val numberError: Boolean = false,
    val streetAddress: String? = null,
    val streetError: Boolean = false,
    val city: String? = null,
    val cityError: Boolean = false,
    val state: String? = null,
    val stateError: Boolean = false,
    val zip: String? = null,
    val zipError: Boolean = false,
    val tenantName: String? = null,
    val tenantNameError: Boolean = false,
    val phone: String? = null,
    val phoneError: Boolean = false,
    val email: String? = null,
    val emailError: Boolean = false,
    val saveError: String? = null,
    val saveDisabled: Boolean = false,
    val notifyStoveCurrent: Boolean = false,
    val deviceDataStateList: List<DeviceDataViewState> = emptyList()
)

data class DeviceDataViewState(
    val type: String? = null,
    val typeError: Boolean = false,
    val location: String? = null,
    val locationError: Boolean = false,
    val locator: String? = null,
    val locatorError: Boolean = false,
    val deviceId: Int? = null,
    val commVersion: Int? = null,
    val commVersionError: Boolean = false,
    val rebootUUID: String = "",
    val rebootUUIDError: Boolean = false,
    val infoUUID: String = "",
    val infoUUIDError: Boolean = false,
)