package com.fireavert.events.activeevents.domain

import com.fireavert.actions.logic.EventLinkedActionType
import kotlinx.serialization.Serializable

@Serializable
data class ActiveEventWithActions(
    val eventId: Long,
    val activeSensors: Set<ActiveSensorType>,
    val actionsTaken: Set<EventLinkedActionType>,
    val unitNumber: String,
    val propertyId: Int,
    val propertyName: String,
    val propertyTimezone: String,
    val unitId: Int,
    val eventTimestamp: Long,
    val deviceId: Int
)
