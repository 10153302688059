package com.fireavert.menu.presentation

import com.fireavert.menu.logic.MenuNavigator

class RoutedMenuNavigator(private val router: MenuRouter) : MenuNavigator {
    override fun navigate(destination: MenuNavigator.Destination) {
        when (destination) {
            MenuNavigator.Destination.Dashboard -> router.routeToDashboard()
            MenuNavigator.Destination.Properties -> router.routeToProperties()
            MenuNavigator.Destination.Administration -> router.routeToAdministration()
            MenuNavigator.Destination.Reports -> router.routeToReports()
            MenuNavigator.Destination.Help -> router.routeToHelp()
        }
    }
}