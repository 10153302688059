package com.fireavert.units.logic

import com.fireavert.common.Try.Error
import com.fireavert.common.Try.Success
import com.fireavert.devices.logic.DeviceRepository
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.devices.logic.models.DeviceType
import com.fireavert.devices.logic.requests.DeviceCreationRequest
import com.fireavert.logging.Logger
import com.fireavert.units.logic.models.request.UnitModificationRequest
import io.ktor.client.plugins.*

class ClientEditUnit(
    private val unitsNavigator: UnitsNavigator,
    private val screen: AddOrEditUnitScreen,
    private val unitsRepository: UnitsRepository,
    private val deviceRepository: DeviceRepository,
    private val logger: Logger
) : EditUnit {
    override suspend fun onLoad(propertyId: Int, unitId: Int) {
        screen.saveError = null
        screen.isLoading = true
        val unit =
            when (val maybeUnit = unitsRepository.getUnitById(unitId)) {
                is Error -> {
                    logger.e("ClientEditUnit:: Failed to get unit with exception: ${maybeUnit.exception}")
                    screen.isLoading = false
                    return
                }

                is Success -> {
                    maybeUnit.value
                }
            }

        screen.number = unit.number
        screen.city = unit.city
        screen.state = unit.state
        screen.streetAddress = unit.streetAddress
        screen.zip = unit.zip
        screen.tenantName = unit.tenantName
        screen.email = unit.tenantEmail
        screen.phone = unit.tenantPhone
        screen.notifyStoveCurrent = unit.notifyStoveCurrent

        val devices =
            when (val maybeDevices = deviceRepository.getDevicesForUnitId(unit.id)) {
                is Error -> {
                    logger.e("ClientEditUnit:: Failed to get devices for unit id $unitId with exception: ${maybeDevices.exception}")
                    screen.isLoading = false
                    return
                }

                is Success -> maybeDevices.value
            }

        screen.deviceData = devices.map {
            DeviceData(
                type = it.type.string,
                typeError = false,
                location = it.location,
                locationError = false,
                locator = it.deviceLocator,
                locatorError = false,
                commVersion = it.commVersion,
                commVersionError = false,
                deviceId = it.id,
                rebootUUID = it.rebootUUID,
                rebootUUIDError = false,
                infoUUID = it.infoUUID,
                infoUUIDError = false,
            )
        }
        screen.isLoading = false
    }

    override fun clickedCancel(propertyId: Int) {
        unitsNavigator.navigate(UnitsNavigator.Destination.PropertyDetails(propertyId))
    }

    override fun numberChanged(value: String) {
        screen.saveError = null
        screen.number = value
        screen.numberError = !UnitValidator.numberIsValid(screen)
    }

    override fun addressChanged(value: String) {
        screen.saveError = null
        screen.streetAddress = value
        screen.streetAddressError = !UnitValidator.addressIsValid(screen)
    }

    override fun cityChanged(value: String) {
        screen.saveError = null
        screen.city = value
        screen.cityError = !UnitValidator.cityIsValid(screen)
    }

    override fun stateChanged(state: String) {
        screen.saveError = null
        screen.state = state
        screen.stateError = !UnitValidator.stateIsValid(screen)
    }

    override fun zipChanged(value: String) {
        screen.saveError = null
        screen.zip = value
        screen.zipError = !UnitValidator.zipIsValid(screen)
    }

    override fun tenantNameChanged(value: String) {
        screen.saveError = null
        screen.tenantName = value
        screen.tenantNameError = false
    }

    override fun phoneChanged(value: String) {
        screen.saveError = null
        screen.phone = value
        screen.phoneError = !UnitValidator.phoneIsValid(screen)
    }

    override fun emailChanged(value: String) {
        screen.saveError = null
        screen.email = value
        screen.emailError = !UnitValidator.emailIsValid(screen)
    }

    override suspend fun clickedSave(propertyId: Int, unitId: Int) {
        screen.saveDisabled = true
        screen.isLoading = true
        doSave(propertyId, unitId)
        screen.isLoading = false
        screen.saveDisabled = false
    }

    override fun notifyStoveCurrentChanged(value: Boolean) {
        screen.saveError = null
        screen.notifyStoveCurrent = value
    }

    private suspend fun doSave(propertyId: Int, unitId: Int) {

        if (!UnitValidator.validate(screen)) {
            logger.d("Failed to validate unit.")
            screen.saveError = "Invalid data entry for unit."
            return
        }

        /// Get the unit data
        val number = screen.number
        val streetAddress = screen.streetAddress
        val city = screen.city
        val state = screen.state
        val zip = screen.zip
        val tenantName = screen.tenantName
        val phone = screen.phone
        val email = screen.email
        val notifyStoveCurrent = screen.notifyStoveCurrent

        /// Validate the devices data
        if (!DeviceDataValidator.validate(screen)) {
            logger.d("Failed to validate Device Creation Data")
            screen.saveError = "Invalid data entry for one or more devices."
            return
        }

        val devices = when (val maybeDevices = deviceRepository.getDevicesForUnitId(unitId)) {
            is Error -> {
                logger.e("Failed to get devices for Unit ID $unitId. Exception: ${maybeDevices.exception}")
                return
            }

            is Success -> maybeDevices.value
        }

        val requestedDeviceDataIds = screen.deviceData.mapNotNull { it.deviceId }.toSet()
        val existingDeviceIds = devices.map { it.id }.toSet()
        val deletedDeviceIds = existingDeviceIds.subtract(requestedDeviceDataIds)
        val modifiedDeviceIds = existingDeviceIds.subtract(deletedDeviceIds)
        val modifiedDeviceData = screen.deviceData.filter {
            modifiedDeviceIds.contains(it.deviceId)
        }

        /// Delete devices missing
        for (deviceId in deletedDeviceIds) {
            val response = when (val maybeResponse = deviceRepository.deleteDevice(deviceId)) {
                is Error -> {
                    logger.e("Failed to delete device $deviceId. Exception: ${maybeResponse.exception}")
                    continue
                }

                is Success -> maybeResponse.value
            }
            if (!response) {
                logger.e("Failed to delete device $deviceId. Got back success is false.")
            }
        }

        val newDevicesToCreate = screen.deviceData.filter { it.deviceId == null }

        /// Add devices with no ids
        for (device in newDevicesToCreate) {
            val deviceCreationRequest = DeviceCreationRequest.fromDeviceData(device, unitId)
            when (val maybeResponse = deviceRepository.createDevice(deviceCreationRequest)) {
                is Error -> {
                    logger.e("Failed to create new device with locator ${device.locator}. Exception: ${maybeResponse.exception}")
                    continue
                }

                is Success -> maybeResponse.value
            }
        }

        /// Modify all other devices
        modifiedDeviceData.forEachIndexed { index, device ->
            val deviceId = device.deviceId ?: return@forEachIndexed
            val response = when (val maybeResponse = deviceRepository.modifyDevice(
                id = device.deviceId,
                deviceLocator = device.locator,
                location = device.location,
                unitId = unitId,
                type = DeviceType.fromString(device.type),
                commVersion = device.commVersion,
                rebootUUID = device.rebootUUID,
                infoUUID = device.infoUUID
            )) {
                is Error -> {
                    logger.e("Type of exception is ${maybeResponse.exception::class}")
                    if (maybeResponse.exception is ClientRequestException) {
                        val message = if (maybeResponse.exception.response.status.value == 409) {
                            screen.setDeviceLocatorError(index, true)
                            "A device with the locator ${device.locator} already exists."
                        } else if (maybeResponse.exception.response.status.value == 410) {
                            screen.setRebootUUIDError(index, true)
                            "A device with the reboot UUID ${device.rebootUUID} already exists."
                        } else if (maybeResponse.exception.response.status.value == 411) {
                            screen.setInfoUUIDError(index, true)
                            "A device with the info UUID ${device.infoUUID} already exists."
                        } else "Failed to create device. Unknown Error."
                        logger.e(message)
                        screen.saveError = message
                        return
                    }
                    val message = "Failed to modify device id $deviceId.  Exception: ${maybeResponse.exception}"
                    logger.e(message)
                    screen.saveError = message
                    return
                }

                is Success -> maybeResponse.value
            }
            if (!response) {
                logger.e("Failed to modify device id $deviceId. Got false back for success.")
                return
            }

        }

        val response = when (val maybeResponse = unitsRepository.modifyUnit(
            UnitModificationRequest(
                unitId = unitId,
                number = number,
                tenantName = tenantName,
                tenantPhone = phone,
                tenantEmail = email,
                propertyId = propertyId,
                streetAddress = streetAddress,
                city = city,
                state = state,
                zip = zip,
                notifyStoveCurrent = notifyStoveCurrent
            )
        )) {
            is Error -> {
                logger.e("Failed to modify unit id $unitId exception ${maybeResponse.exception}")
                return
            }

            is Success -> maybeResponse.value
        }
        if (!response) {
            logger.e("Failed to modify unit id $unitId go success == false.")

        }
        unitsNavigator.navigate(UnitsNavigator.Destination.PropertyDetails(propertyId))
    }
}