package com.fireavert.units.presentation

import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.units.logic.AddEditDevice

open class ClientUnitController(protected val addEditDevice: AddEditDevice) {
    fun deviceAtIndexChanged(index: Int, old: DeviceData, new: DeviceData) {
        if (old.type != new.type) {
            addEditDevice.deviceTypeChanged(index, new.type)
        }
        if (old.location != new.location) {
            addEditDevice.deviceLocationChanged(index, new.location)
        }
        if (old.locator != new.locator) {
            addEditDevice.deviceLocatorChanged(index, new.locator)
        }
        if (old.commVersion != new.commVersion) {
            addEditDevice.deviceCommVersionChanged(index, new.commVersion)
        }
        if (old.rebootUUID != new.rebootUUID) {
            addEditDevice.rebootUUIDChanged(index, new.rebootUUID)
        }
        if (old.infoUUID != new.infoUUID) {
            addEditDevice.infoUUIDChanged(index, new.infoUUID)
        }
    }
}