package com.fireavert.properties.logic.delete_property

import com.fireavert.common.Try
import com.fireavert.logging.Logger
import com.fireavert.properties.logic.ClientPropertyRepository
import com.fireavert.properties.logic.PropertyNavigator
import com.fireavert.properties.logic.PropertyNavigator.Destination.Properties
import kotlinx.coroutines.launch

class ClientDeleteProperty(
    private val propertyNavigator: PropertyNavigator,
    private val screen: DeletePropertyDialogScreen,
    private val propertyRepository: ClientPropertyRepository,
    private val logger: Logger
) : DeleteProperty {
    override fun cancel() {
        propertyNavigator.navigate(Properties)
    }

    override fun deleteProperty(propertyId: Int) {
        screen.scope.launch {
            screen.setIsDeleting(true)
            when (val maybeSuccess = propertyRepository.deleteProperty(propertyId)) {
                is Try.Error -> {
                    logger.e("Error deleting property $propertyId, exception was ${maybeSuccess.exception.message}")
                    screen.setIsDeleting(false)
                }
                is Try.Success -> {
                    screen.setIsDeleting(false)
                    propertyNavigator.navigate(Properties)
                }
            }
        }
    }
}