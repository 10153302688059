package com.fireavert.components.redirect

import kotlinx.browser.window
import react.FC
import react.Props
import react.router.useNavigate
import react.useEffect

external interface RedirectProps: Props {
    var location: String
    var external: Boolean
}
val Redirect = FC<RedirectProps> { props ->
    val navigate = useNavigate()
    useEffect {
        if (props.external) {
            window.document.location?.href = props.location
        } else {
            navigate(props.location)
        }
    }
}
