package com.fireavert.units.logic

class AddEditDeviceImpl(private val screen: AddOrEditUnitScreen) : AddEditDevice {
    override fun deviceTypeChanged(deviceIndex: Int, value: String) {
        screen.saveError = null
        screen.setDeviceType(deviceIndex, value)
        screen.setDeviceTypeError(
            deviceIndex,
            !DeviceDataValidator.isDeviceTypeValid(deviceIndex, screen.deviceData)
        )
    }

    override fun deviceLocationChanged(index: Int, value: String) {
        screen.saveError = null
        screen.setDeviceLocation(index, value)
        screen.setDeviceLocatorError(
            index,
            !DeviceDataValidator.isLocationValid(index, screen.deviceData)
        )
    }

    override fun deviceLocatorChanged(index: Int, value: String) {
        screen.saveError = null
        screen.setDeviceLocator(index, value)
        screen.setDeviceLocatorError(
            index,
            !DeviceDataValidator.isDeviceLocatorValid(
                index,
                screen.deviceData
            )
        )
    }

    override fun deviceCommVersionChanged(index: Int, commVersion: Int) {
        screen.saveError = null
        screen.setDeviceCommVersion(index, commVersion)
        screen.setCommVersionError(
            index,
            !DeviceDataValidator.isDeviceCommVersionValid(
                index = index,
                deviceDataList = screen.deviceData
            )
        )
    }

    override fun deleteDeviceAtIndex(index: Int) {
        screen.removeDeviceAtIndex(index)
    }

    override fun clickedAddNewDevice() {
        screen.addNewDeviceRow()
    }

    override fun addNewDevice() {
        screen.addNewDeviceRow()
    }
    override fun rebootUUIDChanged(index: Int, rebootUUID: String) {
        screen.saveError = null
        screen.setRebootUUID(index, rebootUUID)
        screen.setRebootUUIDError(
            index,
            !DeviceDataValidator.isDeviceRebootUUIDValid(
                index = index,
                deviceDataList = screen.deviceData
            )
        )
    }
    override fun infoUUIDChanged(index: Int, infoUUID: String) {
        screen.saveError = null
        screen.setInfoUUID(index, infoUUID)
        screen.setInfoUUIDError(
            index,
            !DeviceDataValidator.isDeviceInfoUUIDValid(
                index = index,
                deviceDataList = screen.deviceData
            )
        )
    }
}