package com.fireavert.components.sort_bar

import react.FC
import react.Props
import react.dom.html.ReactHTML.div

sealed class UpDownArrowState {
    data object Up : UpDownArrowState()
    data object Down : UpDownArrowState()
    data object Neutral : UpDownArrowState()
}

external interface UpDownTableArrowsProps : Props {
    var arrowState: UpDownArrowState
}

val UpDownTableArrows = FC<UpDownTableArrowsProps> {props ->
    div {
        className = SortBarStyles.arrowsContainer
        if (props.arrowState == UpDownArrowState.Up ||
            props.arrowState == UpDownArrowState.Neutral
        ) {
            div{
                className = SortBarStyles.upArrowColumn
            }
        }
        if (props.arrowState == UpDownArrowState.Down ||
            props.arrowState == UpDownArrowState.Neutral
        ) {
            div {
                className = SortBarStyles.downArrowColumn
            }
        }
    }
}