package com.fireavert.components.properties

import com.fireavert.components.targetInputValue
import com.fireavert.devices.logic.models.DeviceData
import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import com.fireavert.styles.UnitStyles
import emotion.css.ClassName
import mui.material.Checkbox
import mui.material.FormControlLabel
import mui.material.TextField
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import react.dom.onChange
import web.cssom.*

external interface UnitFormProps: Props {
    var number: String
    var numberError: Boolean
    var numberChanged: (String) -> Unit

    var address: String
    var addressError: Boolean
    var addressChanged: (String) -> Unit

    var city: String
    var cityError: Boolean
    var cityChanged: (String) -> Unit

    var state: String
    var stateError: Boolean
    var stateChanged: (String) -> Unit

    var zip: String
    var zipError: Boolean
    var zipChanged: (String) -> Unit

    var tenantName: String
    var tenantNameError: Boolean
    var tenantNameChanged: (String) -> Unit

    var phone: String
    var phoneError: Boolean
    var phoneChanged: (String) -> Unit

    var email: String
    var emailError: Boolean
    var emailChanged: (String) -> Unit

    var notifyStoveCurrent: Boolean
    var notifyStoveCurrentChanged: (Boolean) -> Unit

    var deviceCreationData: Array<DeviceData>
    var saveError: String?

    var clickedAddNewDevice: () -> Unit
    var deleteDeviceAtIndex: (Int) -> Unit

    var deviceAtIndexChanged: (Int, DeviceData) -> Unit
}

val UnitForm = FC<UnitFormProps> { props ->
    div {
        className = ClassName {
            width = 100.pct
            maxWidth = 990.px
            margin = Auto.auto
            padding = 10.px
            boxSizing = BoxSizing.borderBox

            media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                maxWidth = 100.pct
                padding = 5.px
            }
        }

        // Unit Number
        div {
            className = ClassName { marginBottom = 15.px }
            TextField {
                label = ReactNode("Unit #")
                fullWidth = true
                value = props.number
                error = props.numberError
                onChange = { props.numberChanged(it.targetInputValue) }
            }
        }

        // Address, City, State, Zip
        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 15.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            TextField {
                label = ReactNode("Unit Street Address")
                fullWidth = true
                value = props.address
                error = props.addressError
                onChange = { props.addressChanged(it.targetInputValue) }
            }

            TextField {
                label = ReactNode("City")
                fullWidth = true
                value = props.city
                error = props.cityError
                onChange = { props.cityChanged(it.targetInputValue) }
            }

            StateDropDown {
                value = props.state
                error = props.stateError
                changed = { newState -> props.stateChanged(newState) }
            }

            TextField {
                label = ReactNode("Zip")
                fullWidth = true
                value = props.zip
                error = props.zipError
                onChange = { props.zipChanged(it.targetInputValue) }
            }
        }

        // Tenant Info
        div {
            className = ClassName {
                display = Display.flex
                flexDirection = FlexDirection.row
                gap = 10.px
                marginBottom = 15.px

                media(MediaQuery(MOBILE_VIEW_WIDTH)) {
                    flexDirection = FlexDirection.column
                }
            }

            TextField {
                label = ReactNode("Tenant Name")
                fullWidth = true
                value = props.tenantName
                error = props.tenantNameError
                onChange = { props.tenantNameChanged(it.targetInputValue) }
            }

            TextField {
                label = ReactNode("Phone")
                fullWidth = true
                value = props.phone
                error = props.phoneError
                onChange = { props.phoneChanged(it.targetInputValue) }
            }

            TextField {
                label = ReactNode("Email")
                fullWidth = true
                value = props.email
                error = props.emailError
                onChange = { props.emailChanged(it.targetInputValue) }
            }
        }

        // Notify Stove Current
        div {
            className = ClassName { marginBottom = 15.px }
            FormControlLabel {
                control = Checkbox.create {
                    checked = props.notifyStoveCurrent
                    onChange = { _, value -> props.notifyStoveCurrentChanged(value) }
                }
                label = ReactNode("Notify tenant when stove is turned on.")
            }
        }

        hr { className = UnitStyles.addUnitTableSeparator }

        // Devices Container
        AddRemoveDevicesContainer {
            deviceCreationData = props.deviceCreationData
            saveError = props.saveError
            clickedAddNewDevice = props.clickedAddNewDevice
            deleteDeviceAtIndex = props.deleteDeviceAtIndex
            deviceAtIndexChanged = props.deviceAtIndexChanged
        }

        hr { className = UnitStyles.addUnitTableSeparator }
    }
}