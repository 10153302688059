package com.fireavert.properties.logic.models


data class PropertyAdminModel(
    var firstName: String = "",
    var lastName: String = "",
    var phone: String = "",
    var email: String = "",
    var stoveTextNotifications: Boolean = false,
    var stoveEmailNotifications: Boolean = false,
    var leakTextNotifications: Boolean = false,
    var leakEmailNotifications: Boolean = false,
    var tamperTextNotifications: Boolean = false,
    var tamperEmailNotifications: Boolean = false,
    var smokeAlarmTextNotifications: Boolean = false,
    var smokeAlarmEmailNotifications: Boolean = false,
    var stoveOfflineTextNotifications: Boolean = false,
    var stoveOfflineEmailNotifications: Boolean = false,
    var leakOfflineTextNotifications: Boolean = false,
    var leakOfflineEmailNotifications: Boolean = false,
    var tamperOfflineTextNotifications: Boolean = false,
    var tamperOfflineEmailNotifications: Boolean = false,
    var dailyReports: Boolean = false,
    var weeklyReports: Boolean = false,
    var monthlyReports: Boolean = false,
    var quarterlyReports: Boolean = false,
    var yearlyReports: Boolean = false,
    var firstNameError: Boolean = false,
    var lastNameError: Boolean = false,
    var phoneError: Boolean = false,
    var emailError: Boolean = false,
    var existsOnServer: Boolean = false,
) {
    var id = getRandomString(idLength)
    fun isValid(): Boolean {
        return !(firstNameError || lastNameError || phoneError || emailError)
    }
    private fun getRandomString(length: Int) : String {
        val allowedChars = ('A'..'Z') + ('a'..'z') + ('0'..'9')
        return (1..length)
            .map { allowedChars.random() }
            .joinToString("")
    }
    companion object {
        const val idLength = 10
    }
}