package com.fireavert.properties.presentation.delete_property_admin_dialog

import com.fireavert.common.ViewModel
import com.fireavert.properties.logic.property_admin.DeletePropertyAdminDialogScreen
import com.fireavert.properties.logic.models.PropertyAdminModel
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class DeletePropertyAdminDialogViewModel : DeletePropertyAdminDialogScreen, ViewModel() {
    private val _viewState = MutableStateFlow(DeletePropertyAdminDialogViewState())
    val viewState = _viewState.asStateFlow()
    fun clear() {
        _viewState.value = DeletePropertyAdminDialogViewState()
    }
    override val scope: CoroutineScope
        get() = viewModelScope
    override var isLoading: Boolean
        get() = _viewState.value.isLoading
        set(value) {
            _viewState.value = viewState.value.copy(isLoading = value)
        }
    override var isDeleting: Boolean
        get() = _viewState.value.isDeleting
        set(value) {
            _viewState.value = viewState.value.copy(isDeleting = value)
        }
    override var deletionAttempted: Boolean
        get() = _viewState.value.deletionAttempted
        set(value) {
            _viewState.value = viewState.value.copy(deletionAttempted = value)
        }
    override var deletionFailed: Boolean
        get() = _viewState.value.deletionFailed
        set(value) {
            _viewState.value = viewState.value.copy(deletionFailed = value)
        }
    override var loadFailed: Boolean
        get() = _viewState.value.loadFailed
        set(value) {
            _viewState.value = viewState.value.copy(loadFailed = value)
        }
    override var propertyAdminModel: PropertyAdminModel?
        get() = _viewState.value.propertyAdminModel
        set(value) {
            _viewState.value = viewState.value.copy(propertyAdminModel = value)
        }
}