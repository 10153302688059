package com.fireavert.menu.presentation

import com.fireavert.menu.logic.MenuInteractor
import com.fireavert.menu.logic.MenuNavigator

class MenuController(private val navigator: MenuNavigator, private val interactor: MenuInteractor) {
    fun didLoad() = interactor.didLoad()
    fun clickedDashboard() = navigator.navigate(MenuNavigator.Destination.Dashboard)
    fun clickedProperties() = navigator.navigate(MenuNavigator.Destination.Properties)
    fun clickedAdministration() = navigator.navigate(MenuNavigator.Destination.Administration)
    fun clickedReports() = navigator.navigate(MenuNavigator.Destination.Reports)
    fun clickedHelp() = navigator.navigate(MenuNavigator.Destination.Help)
}