package com.fireavert.auth.logic

import com.fireavert.events.logic.EmailValidator

class ResetPasswordUseCase(
    private val screen: ResetPasswordScreen,
    private val resetPasswordSender: ResetPasswordSender,
    private val authNavigator: AuthNavigator,
) {
    fun emailChanged(email: String) {
        clearErrors()
        screen.email = email
        checkEmail(email)
    }

    suspend fun submit() {
        clearErrors()
        screen.loading = true

        val email = screen.email

        if (checkEmail(email)) return

        resetPasswordSender.send(email)
        authNavigator.navigate(AuthNavigator.Destination.ResetPasswordSent)
    }

    private fun checkEmail(email: String): Boolean {
        if (!EmailValidator.isEmailValid(email)) {
            screen.error = true
            screen.errorMessage = "The Email You Entered Is Invalid"
            return true
        }
        return false
    }

    private fun clearErrors() {
        screen.error = false
        screen.errorMessage = ""
    }
}