package com.fireavert.styles

import com.fireavert.styles.MobileStyles.MOBILE_VIEW_WIDTH
import emotion.css.ClassName
import web.cssom.*

object UnitStyles {
    val devicesTitle = ClassName {
        fontSize = 18.pt
        fontWeight = FontWeight.bold
    }
    val addUnitTableSeparator = ClassName {
        marginLeft = (-24).px
        marginRight = (-24).px
        marginTop = 25.px
        marginBottom = 25.px
        borderTop = Border(1.px, LineStyle.solid, Color("#E9ECEF"))
    }
    val totalSlash = ClassName {
        paddingLeft = 3.px
        paddingRight = 3.px
    }
    val unitDetailsInfoTable = ClassName {
        width = 100.pct
        color = Color("#525F7F")
        backgroundColor = Color("#F6F7F8")
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            backgroundColor = NamedColor.white
            borderBottom = Border(3.px, LineStyle.solid, NamedColor.black)
        }
    }
    val unitInfoRow = ClassName {
        verticalAlign = VerticalAlign.top
    }
    val unitInfoTitleCell = ClassName {
        padding = 28.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            padding = 15.px

        }
    }
    val unitInfoTitle = ClassName {
        fontWeight = FontWeight.bold
        fontSize = 15.pt
        marginBottom = 10.px
        media(MediaQuery(MOBILE_VIEW_WIDTH)) {
            color = NamedColor.black
        }
    }
}