package com.fireavert.devices.logic.models

import kotlin.js.JsExport

enum class FireAvertStatus {
    Active,
    Listening,
    Offline,
    NotFound,
    Rebooting,
    Error
}