package com.fireavert.reports_page.logic.models

import kotlinx.serialization.Serializable

@Serializable
data class HighRiskTenantModel(
    val propertyName: String,
    val unitId: Int,
    val unitNumber: String,
    val smokeEventCount: Int,
    val shutOffEventCount: Int,
    val waterLeakEventCount: Int,
    val tamperEventCount: Int,
)
