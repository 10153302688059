package com.fireavert.reports_page.interface_adaptors

import com.fireavert.common.ApiRepository
import com.fireavert.common.Try
import com.fireavert.preferences.logic.Preferences
import com.fireavert.reports_page.logic.ReportsPageRepository
import com.fireavert.reports_page.logic.models.requests.HighRiskTenantRequest
import com.fireavert.reports_page.logic.models.requests.ReportsEventsRequest
import com.fireavert.reports_page.logic.models.requests.YearlyReportRequest
import com.fireavert.reports_page.logic.models.responses.HighRiskTenantsResponse
import com.fireavert.reports_page.logic.models.responses.ReportYearlyDataResponse
import com.fireavert.reports_page.logic.models.responses.ReportsFireClaimReportResponse
import com.fireavert.user.logic.TokenRefreshService
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.withContext

class ReportsPageSectionRepositoryImpl(
    private val dataSource: ReportsPageRepositoryDataSource,
    private val preferences: Preferences,
    ioContext: CoroutineDispatcher,
    tokenRefreshService: TokenRefreshService
): ReportsPageRepository, ApiRepository(ioContext, tokenRefreshService) {
    override suspend fun getEventsAndActions(propertyId: Int?, eventTypeValue: String): Try<Pair<Map<String, Int>, Int>> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getReportsSectionData(ReportsEventsRequest(
                    eventTypeValue, propertyId, preferences.token, preferences.timeZone))
            }
                .map { response ->
                    response.eventIncidentToMonthCount to response.eventSize
                }
        }

    override suspend fun getYearlyEvents(propertyId: Int?, yearToGet: Int): Try<ReportYearlyDataResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getYearlyEvents(YearlyReportRequest(propertyId, preferences.token, yearToGet, preferences.timeZone))
            }
        }

    override suspend fun getHighRiskTenants(propertyId: Int?, eventTypeList: List<String>): Try<HighRiskTenantsResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getHighRiskTenants(HighRiskTenantRequest(propertyId, eventTypeList), preferences.token)
            }
                .map { response ->
                    HighRiskTenantsResponse(
                        highRiskTenants = response.highRiskTenants,
                        averageEventsPerMonth = response.averageEventsPerMonth
                    )
                }
    }

    override suspend fun getAllPropertiesAverageValue(): Try<Double> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getAllPropertiesAverageValue(preferences.token)
            }
                .map { response -> response }

    }

    override suspend fun getPropertyEventsOverTime(propertyId: Int?): Try<ReportsFireClaimReportResponse> =
        withContext(ioContext) {
            callWithRefresh {
                dataSource.getPropertyEventsOverTime(propertyId, preferences.token)
            }
    }
}
