package com.fireavert.components.common

import emotion.react.css
import react.FC
import react.dom.html.ReactHTML
import web.cssom.Auto
import web.cssom.Display
import web.cssom.px

val WarningIcon = FC {
    ReactHTML.img {
        alt = "Warning Icon"
        src = "/static/bell.svg"
        css {
            marginBottom = 15.px
            display = Display.block
            marginLeft = Auto.auto
            marginRight = Auto.auto
        }
    }
}
