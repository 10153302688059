package com.fireavert.components.help

import com.fireavert.styles.HelpStyles
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import web.cssom.Color

external interface HelpCardProps : Props {
    var imageSrc: String
    var text: String
    var titleColor: String
    var quote: String
    var quoteColor: String
    var helpDialog: String
    var showActionsList: List<Boolean>
    var isMobileView: Boolean
}


val HelpEventCard = FC<HelpCardProps> { props ->
    div {
        className = HelpStyles.eventTypeHelpCard

        div {
            className = HelpStyles.actionTitle(Color(props.titleColor))
            +props.text
        }

        div {
            className = HelpStyles.eventIconAndQuote
            div {
                img {
                    className = HelpStyles.helpEventCardImage
                    src = props.imageSrc
                }
            }

            div {
                className = HelpStyles.eventTypeQuote(Color(props.quoteColor))
                +props.quote
            }
        }

        div {
            className = HelpStyles.lineSeparator
            +"----"
        }

        div {
            +props.helpDialog
        }
        if (!props.isMobileView) {
            div {
                className = HelpStyles.eventActions
                +"Actions"

                div {
                    if (props.showActionsList[0]) {
                        img {
                            className = HelpStyles.helpEventCardImage
                            src = "/static/automated_actions_stove_shutoff_icon_active.svg"
                        }
                    }
                    if (props.showActionsList[1]) {
                        img {
                            className = HelpStyles.helpEventCardImage
                            src = "/static/automated_actions_text_alerts_icon_active.svg"
                        }
                    }
                    if (props.showActionsList[2]) {
                        img {
                            className = HelpStyles.helpEventCardImage
                            src = "/static/automated_actions_stove_reset_icon_active.svg"
                        }
                    }
                }
            }
        }
    }
}