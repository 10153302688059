package com.fireavert.events.activedeviceevents.interface_adapters

import com.fireavert.common.ViewModel
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEventsScreen
import com.fireavert.events.activedeviceevents.logic.ActiveDeviceEvent
import com.fireavert.events.logic.models.UnitEventTotals
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow

class ActiveDeviceEventsViewModel: ViewModel(), ActiveDeviceEventsScreen {
    private val _viewState = MutableStateFlow(ActiveDeviceEventsViewState())
    val viewState = _viewState.asStateFlow()

    override fun setActiveDeviceEvents(activeDeviceEvents: List<ActiveDeviceEvent>) {
       _viewState.value = _viewState.value.copy(events = activeDeviceEvents)
    }

    override fun setLoading(loading: Boolean) {
       _viewState.value = _viewState.value.copy(loading = loading)
    }

    override fun setAlarmOnDeviceMap(alarmOnDeviceMap: Map<Int, Boolean>) {
        _viewState.value = _viewState.value.copy(alarmOnDeviceMap = alarmOnDeviceMap)
    }

    override fun setUnitEventTotalsMap(unitEventTotalsMap: Map<Int, UnitEventTotals>) {
        _viewState.value = _viewState.value.copy(unitEventTotalsMap = unitEventTotalsMap)
    }

    override val scope: CoroutineScope
        get() = viewModelScope
}